import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import { SbxBarChartLib } from "../SbxChart";
import BasicLanguage from "../../languages/Basic";
import moment from "moment";
import PropTypes from "prop-types";
export default function RobotFloorCoverageChart({
  date,
  dateDisplay,
  robotData,
}) {
  const language = useRecoilValue(languageState);
  if (dateDisplay === "day") {
    robotData = generateRobotDataByDay(robotData, language, date);
  } else if (dateDisplay === "week") {
    robotData = generateRobotDataByWeek(robotData, language, date);
  } else if (dateDisplay === "month") {
    robotData = generateRobotDataByMonth(robotData, language, date);
  }
  return (
    <Stack sx={{ width: "50%" }} justifyContent="center">
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "1.2em",
        }}
      >
        {BasicLanguage.facilitySettings.operationKpi.robotCleaning[language]}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ width: "100%", height: "300px" }}>
        <SbxBarChartLib
          data={robotData}
          dataKey={
            BasicLanguage.dashboard.kpi.robotFloorCoverage.dataKey[language]
          }
        />
      </Box>
    </Stack>
  );
}
RobotFloorCoverageChart.propTypes = {
  date: PropTypes.object.isRequired,
  dateDisplay: PropTypes.string.isRequired,
  robotData: PropTypes.array.isRequired,
};

const generateRobotDataByDay = (activityHistory, language, date) => {
  const defaultDays = {};
  for (let i = 1; i <= moment(date).daysInMonth(); i++) {
    defaultDays[i] = 0;
  }

  const activityHistorySum = activityHistory.reduce(
    (activityHistoryObject, history) => {
      const historyDate = moment(history.createdAt).date();
      activityHistoryObject[historyDate] =
        (activityHistoryObject[historyDate] || 0) + history.value;
      return activityHistoryObject;
    },
    defaultDays
  );

  return Object.keys(activityHistorySum)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        [BasicLanguage.dashboard.kpi.robotFloorCoverage.dataKey[language]]:
          parseFloat(activityHistorySum[key].toFixed(1)),
      };
    });
};

const generateRobotDataByWeek = (activityHistory, language, date) => {
  const startWeek = moment(date).startOf("month").week();
  const endWeek = moment(date).endOf("month").week();
  const defaultWeeks = {};
  for (let i = startWeek; i <= endWeek; i++) {
    defaultWeeks[i] = 0;
  }

  const activityHistorySum = activityHistory.reduce(
    (activityHistoryObject, history) => {
      const week = moment(history.createdAt).week();
      activityHistoryObject[week] =
        (activityHistoryObject[week] || 0) + history.value;
      return activityHistoryObject;
    },
    defaultWeeks
  );

  return Object.keys(activityHistorySum)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        [BasicLanguage.dashboard.kpi.robotFloorCoverage.dataKey[language]]:
          parseFloat(activityHistorySum[key].toFixed(1)),
      };
    });
};

const generateRobotDataByMonth = (activityHistory, language, date) => {
  const activityHistorySum = activityHistory.reduce(
    (activityHistoryObject, history) => {
      activityHistoryObject.month =
        (activityHistoryObject.month || 0) + history.value;
      return activityHistoryObject;
    },
    { month: 0 }
  );

  return Object.keys(activityHistorySum).map((key) => {
    return {
      name: moment(date).format(
        BasicLanguage.dashboard.kpi.robotFloorCoverage.yearMonthFormat[language]
      ),
      [BasicLanguage.dashboard.kpi.robotFloorCoverage.dataKey[language]]:
        parseFloat(activityHistorySum[key].toFixed(1)),
    };
  });
};
