import React, { useMemo, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import moment from "moment";
import { useRecoilValue } from "recoil";
import languageState from "../../../../recoil/atoms/languageState";
import BasicLanguage from "../../../languages/Basic";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../utils/firebase";
import { useParams } from "react-router";
import "moment/locale/ja";
import DateRangePicker from "../../shared/DateRangePicker";
import { TimeZoneContext } from "../../../utils/setTimeZoneContext";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function DownloadQuestionnaire() {
  const language = useRecoilValue(languageState);
  const { facilityId } = useParams();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const start = moment(startDate).startOf("day").toDate();
  const end = moment(endDate).endOf("day").toDate();
  const { currentTimeZoneName } = React.useContext(TimeZoneContext);
  const getQRData = async () => {
    try {
      const snapshot = await getDocs(
        collection(db, ENV, VERSION, "facilities", facilityId, "qr")
      );
      const qrData = [];
      snapshot.forEach((doc) => {
        qrData.push({ id: doc.id, ...doc.data() });
      });
      return qrData;
    } catch (error) {
      console.error("Error fetching QR data:", error);
      throw error;
    }
  };

  const getSurveyResults = async () => {
    try {
      const snapshot = await getDocs(
        query(
          collection(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "surveyResults"
          ),
          where("createdAt", ">=", start),
          where("createdAt", "<=", end),
          orderBy("createdAt", "desc")
        )
      );
      const surveyResults = [];
      snapshot.forEach((doc) => {
        surveyResults.push({ id: doc.id, ...doc.data() });
      });
      return surveyResults;
    } catch (error) {
      console.error("Error fetching survey results:", error);
      throw error;
    }
  };
  const buttonDisabled = useMemo(() => {
    return (
      !startDate || !endDate || !!startDateError || !!endDateError || isLoading
    );
  }, [startDate, endDate, startDateError, endDateError, isLoading]);

  const processNewData = async () => {
    setIsLoading(true);
    try {
      const qrData = await getQRData();
      const surveyResults = await getSurveyResults();

      const qrDataMap = qrData.reduce((acc, qr) => {
        acc[qr.id] = qr;
        return acc;
      }, {});

      const combinedData = surveyResults
        .map((result) => {
          const qrDoc = qrDataMap[result.qrId];

          if (qrDoc) {
            return Object.keys(result.answers)
              .sort()
              .map((question) => ({
                date: moment(result.createdAt.toDate())
                  .tz(currentTimeZoneName)
                  .format("YYYY-MM-DD HH:mm:ss"),
                place: qrDoc.place,
                question: question,
                rating: result.answers[question],
              }));
          }
          return null;
        })
        .flat()
        .filter((data) => data !== null);

      // CV データ作成
      const csvHeaders = BasicLanguage.surveyQuestionsHeader.title[language];
      if (combinedData.length !== 0) {
        const csvRows = combinedData.map((doc) => [
          doc.date,
          doc.place,
          doc.question,
          doc.rating,
        ]);

        let csvContent = csvHeaders.join(",") + "\n";
        csvContent += csvRows.map((row) => row.join(",")).join("\n");

        // ダウンロード
        const filename = `${facilityId}-${
          BasicLanguage.qr.survey.title[language]
        }-(${moment(startDate).format("YYYYMMDD")}~${moment(endDate).format(
          "YYYYMMDD"
        )}).csv`;
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.download = filename;
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);
      } else {
        alert(BasicLanguage.qr.csv.noDataSurvey[language]);
        return;
      }
    } catch (error) {
      console.error("Error processing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        startDateError={startDateError}
        setStartDateError={setStartDateError}
        endDateError={endDateError}
        setEndDateError={setEndDateError}
      />
      <Button
        variant="outlined"
        color="primary"
        disabled={isLoading || buttonDisabled}
        onClick={processNewData}
        sx={{ minWidth: "150px" }}
      >
        {isLoading ? (
          <CircularProgress size="1.75rem" sx={{ color: "white" }} />
        ) : (
          BasicLanguage.common.download[language]
        )}
      </Button>
    </Box>
  );
}
