import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { SbxPieChart2 } from "../SbxChart";
import parse from "html-react-parser";
import BasicLanguage from "../../languages/Basic";
import languageState from "../../../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router";
import { collection, getDocs, orderBy, limit, query } from "firebase/firestore";
import { db } from "../../utils/firebase";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function NpsChart({ thickness }) {
  const language = useRecoilValue(languageState);
  const [visible, setVisible] = React.useState(false);
  const params = useParams();
  const facilityId = params.facilityId;
  const [nps, setNps] = React.useState(-100);
  const [pastNps, setPastNps] = React.useState(-100);
  const [positiveSummary, setPositiveSummary] = React.useState("");
  const [negativeSummary, setNegativeSummary] = React.useState("");
  const [overallSummary, setOverallSummary] = React.useState("");
  const [followUpEvaluation, setFollowUpEvaluation] = React.useState("");

  const clickAlert = () => {
    setVisible((p) => !p);
  };

  const formatText = (text, positives, negatives, keywords) => {
    let formattedText = text;

    positives.forEach((pos) => {
      formattedText = formattedText.replace(
        new RegExp(pos, "g"),
        `<span style="color: #50aeea; font-weight: bold;">${pos}</span>`
      );
    });

    negatives.forEach((neg) => {
      formattedText = formattedText.replace(
        new RegExp(neg, "g"),
        `<span style="color: #ff2d2d; font-weight: bold;">${neg}</span>`
      );
    });

    keywords.forEach((keyword) => {
      formattedText = formattedText.replace(
        new RegExp(keyword, "g"),
        `<b>${keyword}</b>`
      );
    });

    return formattedText;
  };
  const parseSummary = (summary) => {
    if (!Array.isArray(summary)) {
      console.error(summary);
      return "<ul></ul>";
    }

    return (
      "<ul>" +
      summary
        .map((item) => {
          const {
            context = "",
            positives = [],
            negatives = [],
            keywords = [],
          } = item;

          return (
            `<li>` +
            formatText(context, positives, negatives, keywords) +
            `</li>`
          );
        })
        .join("") +
      "</ul>"
    );
  };

  const getPreEvaluation = useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "preEvaluation"),
        orderBy("createdAt", "desc"),
        limit(2)
      )
    )
      .then((preEvaluationDocs) => {
        let firstDataFlag = true;
        preEvaluationDocs.forEach((preEvaluationDoc) => {
          const data = preEvaluationDoc.data();
          if (firstDataFlag) {
            firstDataFlag = false;
            setNps(data.nps);
            setPositiveSummary(parseSummary(data.positive_summary || []));
            setNegativeSummary(parseSummary(data.negative_summary || []));
            setOverallSummary(parseSummary(data.overall_summary || []));
            setFollowUpEvaluation(
              parseSummary(data.follow_up_evaluation || [])
            );
          } else {
            setPastNps(parseInt(data.nps));
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [facilityId]);

  useEffect(() => {
    getPreEvaluation();
  }, [getPreEvaluation]);

  return (
    <Stack
      sx={{ width: "100%", maxWidth: "400px" }}
      justifyContent=""
      alignItems="top"
    >
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "1.2em",
        }}
      >
        NPS
      </Typography>
      <Typography sx={{ textAlign: "center" }}>
        {BasicLanguage.facilitySettings.nps.subTitle[language]}
      </Typography>
      <Divider sx={{ my: 2 }} />

      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            px: "2px",
            backgroundColor: "rgba(2,136,209,0.1)",
            zIndex: 120,
            backdropFilter: "blur(7px)",
            borderRadius: "10px",
            overflow: visible ? "auto" : "hidden",
            p: "0.3rem",
            position: "absolute",
            height: visible ? "100%" : "2em",
            transition: "all 0.4s ease-in-out",
            cursor: "pointer",
          }}
          onClick={clickAlert}
        >
          <Box>
            <Typography sx={{ fontSize: "1em", lineHeight: "1em" }}>
              {BasicLanguage.facilitySettings.nps.feedback[language]}
            </Typography>
          </Box>
          <Box
            borderRadius={5}
            boxShadow={"0.8"}
            sx={{
              ul: { paddingLeft: "0.7rem", textAlign: "left" },
              li: { paddingLeft: "0.2rem", textAlign: "left" },
              bgcolor: "rgba(255,255,255,0.8)",
              px: "1rem",
              mt: "1px",
              pb: "2px",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2em",
                fontWeight: 700,
                my: 1,
              }}
            >
              {BasicLanguage.facilitySettings.nps.feedbackAll[language]}
            </Typography>
            <Typography textAlign={"left"}>{parse(overallSummary)}</Typography>
            <Typography
              sx={{
                fontSize: "1.2em",
                fontWeight: 700,
                my: 1,
              }}
            >
              {BasicLanguage.facilitySettings.nps.feedbackPositive[language]}
            </Typography>
            <Typography textAlign={"left"}>{parse(positiveSummary)}</Typography>
            <Typography
              sx={{
                fontSize: "1.2em",
                fontWeight: 700,
                my: 1,
              }}
            >
              {BasicLanguage.facilitySettings.nps.feedbackNegative[language]}
            </Typography>
            <Typography textAlign={"left"}>{parse(negativeSummary)}</Typography>
            <Typography
              sx={{
                fontSize: "1.2em",
                fontWeight: 700,
                my: 1,
              }}
            >
              {BasicLanguage.facilitySettings.nps.changedServices[language]}
            </Typography>
            <Typography textAlign={"left"}>
              {parse(followUpEvaluation)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ position: "relative" }}>
          <Stack
            sx={{
              position: "absolute",
              height: "100%",
              width: "100%",
              marginTop: "100px",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="row" alignItems="flex-end" spacing={1}>
              <Typography
                sx={{
                  fontSize: "3em",
                  fontWeight: 700,
                  zIndex: 100,
                }}
              >
                {nps}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="flex-end"
              spacing={1}
              sx={{
                color: `${nps - pastNps > 0 ? "green" : "red"}`,
                zIndex: 100,
              }}
            >
              {nps - pastNps > 0 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
              <Typography>{nps - pastNps}</Typography>
            </Stack>
          </Stack>
          <Box sx={{ width: "100%", position: "relative" }}>
            <SbxPieChart2
              value={nps}
              pastValue={pastNps}
              maxValue={100}
              minValue={-100}
              innerRadius={50 - thickness * 0.5}
              outerRadius={50}
              animStartAngle={120}
              startAngle={-120}
              endAngle={120}
              cornerRadius={10}
              animDelay={100}
              animDuration={600}
              rotate={0}
            />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
