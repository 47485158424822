import { Box, Button } from "@mui/material";
import React from "react";
import { useRecoilValue } from "recoil";
import languageState from "../../recoil/atoms/languageState";
import BasicLanguage from "../languages/Basic";
import PropTypes from "prop-types";

export default function PreviewImage(props) {
  const uploadedImage = props.uploadedImage;
  const addImage = props.addImage;
  const clearImage = props.clearImage;
  const language = useRecoilValue(languageState);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        component="img"
        src={uploadedImage}
        style={{
          objectFit: "contain",
          width: "200px",
          height: "200px",
        }}
        alt="uploaded"
      />
      <>
        <Button
          component="span"
          variant="outlined"
          color="success"
          onClick={addImage}
        >
          {BasicLanguage.common.ok[language]}
        </Button>
        <Button
          component="span"
          variant="outlined"
          color="error"
          onClick={clearImage}
        >
          {BasicLanguage.common.cancel[language]}
        </Button>
      </>
    </Box>
  );
}

PreviewImage.propTypes = {
  uploadedImage: PropTypes.string,
  addImage: PropTypes.func,
  clearImage: PropTypes.func,
};
