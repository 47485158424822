import React, { useCallback, useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";
import { db } from "../utils/firebase";
import SBXStyles from "../utils/Styles";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import Loading from "./Loading";
import moment from "moment";
import "moment/locale/ja";
import BasicLanguage from "../languages/Basic";

const ENV = process.env.REACT_APP_FIRESTORE_ENV
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION

export default function UserTimetableDownload(props) {
  const user = props.user;
  const facilityId = props.facilityId;
  const language = props.language;
  const [activityHistory, setActivityHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateHasError, setHasDateError] = useState(false);

  const getActivityHistory = useCallback(() => {
    const activityHistoryRef = collection(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "activityHistory"
    );
    const q = query(
      activityHistoryRef,
      where("uid", "==", user.uid),
      orderBy("createdAt", "desc")
    );

    getDocs(q)
      .then((snapshot) => {
        const activityHistory = snapshot.docs.map((doc) => {
          return { ...doc.data() };
        });
        setActivityHistory(activityHistory);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(setIsLoading(false));
  }, [facilityId, user.uid]);

  useEffect(() => {
    getActivityHistory()
  }, [getActivityHistory]);

  useEffect(() => {
    moment.locale(language);
  });

  const downloadActivityHistory = () => {
    if (selectedDate === null) {
      setHasDateError(true);
      return;
    }
    const startDate = selectedDate.clone().startOf("month");
    const endDate = selectedDate.clone().endOf("month");
    const history = activityHistory.filter((activity) => {
      const date = activity.createdAt.toDate();
      return (
        moment(date).isSameOrAfter(startDate) &&
        moment(date).isSameOrBefore(endDate)
      );
    });

    downloadCSV(history);
  };

  const downloadCSV = (activityHistory) => {
    const csvTitleString = `${BasicLanguage.common.dateTime[language]},${BasicLanguage.users.status[language]}\n`;
    const csvBodyString = activityHistory.map((activity) => {
      const activityDate = moment(activity.createdAt.toDate()).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const status = BasicLanguage.users.statusName[activity.status][language];

      return `"${activityDate}","${status}"`;
    });

    const csvString = csvTitleString + csvBodyString.join("\n");

    const file_name = `${facilityId}_${
      user.displayName
    }_activityHistory_${selectedDate.format("YYYYMM")}.csv`;

    const bom = new Uint8Array([0xef, 0xbb, 0xbf]); //UTF-8文字コード設定
    let blob = new Blob([bom, csvString], { type: "text/csv" });
    let uri = URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.download = file_name;
    link.href = uri;
    link.click();
    link.remove();
  };

  return (
    <Box sx={SBXStyles.mainBox}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Typography variant="h5">
            {BasicLanguage.users.activityReport.title[language]}
          </Typography>
          {activityHistory.length > 0 ? (
            <>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={language}
                localeText={{
                  cancelButtonLabel:
                    BasicLanguage.common.date.datePicker.cancel[language],
                }}
              >
                <FormControl error={dateHasError}>
                  <Box sx={{ display: "flex", gap: 3, mt: 2 }}>
                    <DatePicker
                      views={["year", "month"]}
                      disableFuture={true}
                      minDate={moment(
                        activityHistory[
                          activityHistory.length - 1
                        ].createdAt.toDate()
                      )}
                      maxDate={moment(activityHistory[0].createdAt.toDate())}
                      value={selectedDate}
                      onChange={(value) => {
                        setHasDateError(false);
                        setSelectedDate(value);
                      }}
                      slotProps={{
                        actionBar: {
                          actions: ["cancel", "accept"],
                        },
                        textField: {
                          error: dateHasError,
                        },
                      }}
                      format={
                        BasicLanguage.users.activityReport.date.format[language]
                      }
                      closeOnSelect={false}
                    />
                    <Button
                      variant="outlined"
                      onClick={downloadActivityHistory}
                    >
                      {BasicLanguage.users.activityReport.button[language]}
                    </Button>
                  </Box>
                  {dateHasError && (
                    <FormHelperText>
                      {BasicLanguage.users.activityReport.error[language]}
                    </FormHelperText>
                  )}
                </FormControl>
              </LocalizationProvider>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: 3,
                mt: 3,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Typography>
                {BasicLanguage.users.activityReport.none[language]}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
