/// app.js
import React, { useCallback, useEffect, useState } from "react";
import DeckGL from "@deck.gl/react";
import { BitmapLayer, ScatterplotLayer } from "@deck.gl/layers";
import { IconLayer, OrthographicController } from "deck.gl";
import { OrthographicView } from "@deck.gl/core";
import chroma from "chroma-js";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import BasicLanguage from "../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../recoil/atoms/languageState";

import { useParams } from "react-router";
import Typography from "../parts-ui/Typography";
import { getDownloadURL, ref } from "@firebase/storage";
import { db, storage } from "../utils/firebase";
import AddIcon from "@mui/icons-material/Add";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import PlotDialog from "./PlotDialog";
import StorageImage from "./StorageImage";
import { DialogYesNo } from "./Dialog";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const colorScale = chroma
  .scale(["#43B060", "#D3CD52", "#FA0101"])
  .domain([0, 100]);

const initialViewState = {
  target: [0, 0],
  zoom: 0.5, // ズーム
  minZoom: 0, // 最小ズーム
  maxZoom: 4, // 最大ズーム
  pitch: 0, // 傾き
  bearing: 0, // 回転
};

/**
 * @typedef MapImage
 * @property {string} url
 * @property {number} width
 * @property {number} height
 */

// DeckGL react component
const Map = (props) => {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;
  const mapId = props.map.id;

  const [ViewState, setViewState] = React.useState(initialViewState);
  const [plots, setPlots] = React.useState([]);
  const [ignoredPlot, setIgnoredPlot] = useState();
  const [pickedPoint, setPickedPoint] = React.useState();
  const [mapImage, setMapImage] = useState(/** @type {MapImage?} */ (null));
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [plotToDelete, setPlotToDelete] = useState("");

  useEffect(() => {
    const storageRef = ref(storage, props.map.image);
    getDownloadURL(storageRef)
      .then(async (imageUrl) => {
        const resolution = await getImageResolution(imageUrl);
        setMapImage({
          url: imageUrl,
          ...resolution,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }, [props.map.image]);

  useEffect(() => {
    setPlots([
      ...props.atps.filter((atp) => atp.map === mapId),
      ...props.sensors.filter((sensor) => sensor.map === mapId),
    ]);
  }, [ignoredPlot, mapId, props.atps, props.sensors]);

  const mapHandler = (e, index) => {
    const current = plots.filter((plot) => plot.selected)[0];
    setPlots([
      ...plots.slice(0, index).map((x) => {
        return { ...x, selected: false };
      }),
      { ...e, selected: !e.selected },
      ...plots.slice(index + 1).map((x) => {
        return { ...x, selected: false };
      }),
    ]);

    setViewState((prevViewState) => {
      const state = {
        ...prevViewState,
        transitionDuration: 350, // アニメーションの秒数を設定する
      };

      if (current?.id === e.id) {
        // クリックしたのが現在選択されているものと同じなら初期化
        return {
          ...state,
          zoom: initialViewState.zoom,
          target: initialViewState.target,
        };
      }

      return {
        ...state,
        zoom: 1.5,
        target: e.coordinates,
      };
    });
  };

  /**
   * DeckGL layers 作成
   *
   * @param {object} props
   * @param {MapImage} props.mapImage
   * @returns
   */
  const RenderLayers = (props) => {
    const type = props.type;
    const bitmapLayerBonds = calImageBounds(
      props.mapImage.width,
      props.mapImage.height
    );

    const bitmapLayer = new BitmapLayer({
      id: "bitmapLayer-" + mapId,
      bounds: bitmapLayerBonds,
      image: props.mapImage.url,
      opacity: 1,
      pickable: true,
      ...(type === "editor" &&
        selectedDevice && {
          onClick: handleClickBitmap(bitmapLayerBonds),
        }),
    });

    const scatterATP = new ScatterplotLayer({
      id: "scatterplotLayer-" + mapId,
      pickable: true,
      onClick: (event) => onClick(event.object),
      data: plots.filter((x) => x.type === "ATP"),
      radiusUnits: "pixels",
      opacity: 0.8,
      stroked: true,
      filled: true,
      radiusMinPixels: 8,
      radiusMaxPixels: 16,
      getPosition: (d) => d.coordinates,
      getRadius: (d) => (d.selected ? 16 : 8),
      getFillColor: (d) =>
        d.status ? colorScale(d.value).rgb() : [180, 180, 180],
      getLineColor: () => [0, 0, 255],
    });

    const iconLayer = new IconLayer({
      id: "iconLayer-" + mapId,
      data: plots.filter((plot) => plot.id !== ignoredPlot),
      pickable: true,
      onClick: (event) => onClick(event.object),
      iconAtlas: "/mapicon.png",
      iconMapping: {
        atp: { x: 0, y: 0, width: 128, height: 128, mask: true },
        sensor: { x: 128, y: 0, width: 128, height: 128, mask: true },
      },
      getIcon: (d) => d.deviceType,

      sizeScale: 4,
      getPosition: (d) => d.coordinates,
      getSize: (d) => (d.selected ? 8 : 5),
      getColor: (d) => (d.deviceType === "atp" ? [255, 0, 0] : [0, 0, 255]),
    });

    const pickingLayer = new IconLayer({
      id: "pickingLayer-" + mapId,
      ...(pickedPoint && { data: [pickedPoint] }),
      onClick: (event) => onClick(event.object),
      getIcon: () => selectedDevice.deviceType,
      iconAtlas: "/mapicon.png",
      iconMapping: {
        atp: { x: 0, y: 0, width: 128, height: 128, mask: true },
        sensor: { x: 128, y: 0, width: 128, height: 128, mask: true },
      },

      sizeScale: 4,
      getPosition: (d) => d.coordinates,
      getSize: () => 8,
      getColor: () =>
        selectedDevice.deviceType === "atp" ? [255, 0, 0] : [0, 0, 255],
    });
    return [bitmapLayer, iconLayer, pickingLayer];
  };

  /**
   * editor typeのBitmapLayerをクリックしたときの処理を生成
   *
   * @param {ReturnType<calImageBounds>} bounds
   * @returns {(event: {bitmap: {uv: [normalizedX: number, normalizedY: number]}?}) => void}
   */
  const handleClickBitmap =
    (bounds) =>
    ({ bitmap }) => {
      if (!bitmap) {
        return;
      }
      const boundsWidth = bounds[2] - bounds[0];
      const boundsHeight = bounds[1] - bounds[3];

      setPickedPoint({
        coordinates: [
          boundsWidth * (bitmap.uv[0] - 0.5),
          boundsHeight * (bitmap.uv[1] - 0.5),
        ],
      });
    };

  const onClick = (d) => {
    if (d) {
      setPlots(
        plots.map((plot) =>
          plot.id !== d.id
            ? { ...plot, selected: false }
            : { ...plot, selected: true }
        )
      );

      setViewState((viewState) => {
        return {
          ...viewState,
          zoom: 1.5,
          target: [d.coordinates[0], d.coordinates[1]],
          transitionDuration: 350, //アニメーションの秒数を設定する
        };
      });
    }
  };

  const selectDeviceClick = (device, deviceType) => {
    setSelectedDevice({ ...device, deviceType: deviceType });
    setModalOpen(false);
  };

  const clearAddDevice = () => {
    setSelectedDevice(null);
    setPickedPoint();
    setIgnoredPlot();
  };

  const addDevice = () => {
    if (!pickedPoint) {
      return alert(BasicLanguage.maps.noCoordinates[language]);
    }
    writeDevice(selectedDevice.deviceType);
  };

  const firestoreDeleteDevice = (plot) => {
    let deviceCollection;
    switch (plot.deviceType) {
      case "atp":
        deviceCollection = "atps";
        break;
      case "sensor":
        deviceCollection = "sensors";
        break;
      default:
        deviceCollection = "";
        break;
    }
    updateDoc(
      doc(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        deviceCollection,
        plot.id
      ),
      {
        map: deleteField(),
        coordinates: deleteField(),
      }
    ).then(() => {
      if (plot.deviceType === "atp") {
        props.getAtps();
      }
      if (plot.deviceType === "sensor") {
        props.getSensors();
      }
      clearDeletePlot();
    });
  };

  const editDevice = (plot) => {
    setIgnoredPlot(plot.id);
    setSelectedDevice(plot);
    setPickedPoint({ coordinates: plot.coordinates });
  };

  const deleteDevice = (plot) => {
    setPlotToDelete(plot);
    setDeleteModalOpen(true);
  };

  const clearDeletePlot = () => {
    setPlotToDelete("");
    setDeleteModalOpen(false);
  };

  const writeDevice = (deviceType) => {
    let deviceCollection;
    switch (deviceType) {
      case "atp":
        deviceCollection = "atps";
        break;
      case "sensor":
        deviceCollection = "sensors";
        break;
      default:
        deviceCollection = "";
        break;
    }
    updateDoc(
      doc(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        deviceCollection,
        selectedDevice.id
      ),
      {
        coordinates: pickedPoint.coordinates,
        map: mapId,
      }
    )
      .then(() => {
        if (selectedDevice.deviceType === "atp") {
          props.getAtps();
        }
        if (selectedDevice.deviceType === "sensor") {
          props.getSensors();
        }
        clearAddDevice();
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            position: "relative",
            width: "50%",
            height: "450px",
            backgroundColor: "#ddd",
            maxWidth: "600px",
          }}
        >
          {mapImage && (
            <DeckGL
              layers={RenderLayers({
                data: plots,
                mapImage: mapImage,
                type: props.type,
              })}
              views={
                new OrthographicView({
                  repeat: false,
                })
              }
              initialViewState={ViewState}
              controller={{ type: OrthographicController, dragRotate: true }}
              getTooltip={({ object }) => object && `${object.name}`}
            />
          )}
        </Box>

        <Box sx={{ width: "50%" }}>
          {selectedDevice && (
            <Paper sx={{ mb: 2, p: 2 }} elevation={24}>
              <Typography variant="h5">
                {BasicLanguage.map.add.title[language]}
              </Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.map.add.table.name[language]}
                    </TableCell>
                    <TableCell>{selectedDevice.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.map.add.table.type[language]}
                    </TableCell>
                    <TableCell>{selectedDevice.type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.map.add.table.coordinates[language]}
                    </TableCell>
                    <TableCell>
                      {pickedPoint && (
                        <>
                          <Box>{pickedPoint.coordinates[0]}</Box>
                          <Box>{pickedPoint.coordinates[1]}</Box>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box sx={{ mt: 2 }}>
                <Button sx={{ mr: 2 }} variant="outlined" onClick={addDevice}>
                  {BasicLanguage.map.add.table.confirm[language]}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={clearAddDevice}
                >
                  {BasicLanguage.map.add.table.cancel[language]}
                </Button>
              </Box>
            </Paper>
          )}
          <Paper sx={{ p: 1 }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography>
                        {BasicLanguage.map.dashboardTableName[language]}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {BasicLanguage.map.dashboardTableType[language]}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {props.type === "editor"
                          ? ""
                          : BasicLanguage.map.dashboardTableValue[language]}
                      </Typography>
                    </TableCell>
                    {props.type === "editor" && (
                      <TableCell sx={{ textAlign: "right" }}>
                        <IconButton onClick={() => setModalOpen(true)}>
                          <AddIcon />
                        </IconButton>
                        <IconButton LinkComponent={Link} to={`./edit/${mapId}`}>
                          <SettingsIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {plots.map((plot, index) => (
                    <TableRow
                      key={plot.id}
                      onClick={() => {
                        mapHandler(plot, index);
                      }}
                      sx={{
                        "& .MuiTableCell-body, & .MuiBox-root": {
                          fontWeight: plot.selected ? "bold" : null,
                        },
                      }}
                    >
                      <TableCell sx={{ cursor: "pointer" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {plot.name && plot.name !== ""
                            ? plot.name
                            : BasicLanguage.map.noName[language]}
                        </Box>
                      </TableCell>
                      <TableCell
                        colSpan={props.type === "editor" ? 2 : 1}
                        sx={{ cursor: "pointer" }}
                      >
                        {plot.type}
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                          ...(props.type === "editor" && {
                            display: "flex",
                            justifyContent: "end",
                          }),
                        }}
                      >
                        {props.type === "editor" ? (
                          <Stack direction="row">
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                editDevice(plot);
                              }}
                            >
                              {BasicLanguage.map.edit[language]}
                            </Button>
                            <Button
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteDevice(plot);
                              }}
                            >
                              {BasicLanguage.map.delete[language]}
                            </Button>
                          </Stack>
                        ) : (
                          plot.value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {
            <Box
              sx={{ mt: 2 }}
              justifyContent="center"
              display="flex"
              flexWrap="wrap"
              gap={2}
            >
              {plots
                .filter((plot) => plot.selected)[0]
                ?.images?.map((image) => {
                  return (
                    <Box
                      key={image}
                      sx={{
                        width: "200px",
                        height: "200px",
                        border: "1px solid #ddd",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <StorageImage
                        imagePath={image}
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  );
                })}
            </Box>
          }
        </Box>
      </Stack>
      {props.type === "editor" && (
        <>
          <PlotDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            selectDeviceClick={selectDeviceClick}
            atps={props.atps.filter((atp) => !atp.map)}
            sensors={props.sensors.filter((sensor) => !sensor.map)}
          />
          <DialogYesNo
            open={[deleteModalOpen, setDeleteModalOpen]}
            noAction={clearDeletePlot}
            yesAction={() => firestoreDeleteDevice(plotToDelete)}
            title={BasicLanguage.map.dialog.yesNo.title[language]}
            message={BasicLanguage.map.dialog.yesNo.message[language]}
          />
        </>
      )}
    </>
  );
};

/**
 * 画像の解像度を取得する
 *
 * @param {string} imageUrl 画像のURL
 * @returns {Promise<{width: number, height: number}>} 解像度
 */
const getImageResolution = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = reject;
    img.src = imageUrl;
  });
};

/**
 * Calculate image bounds
 *
 * @param {number} width
 * @param {number} height
 * @returns {[left: number, bottom: number, right: number, top: number]}
 */
const calImageBounds = (width, height) => {
  if (width / height > 4 / 3) {
    return [-180, 180 * (height / width), 180, -180 * (height / width)];
  }
  return [-135 * (width / height), 135, 135 * (width / height), -135];
};

export default Map;
