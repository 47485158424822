import * as React from 'react';
import { Alert, AlertTitle, Box, Divider, List, ListItem, ListItemText, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip } from '@mui/material';
import BasicLanguage from '../components/languages/Basic';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import languageState from '../recoil/atoms/languageState';
import Typography from '../components/parts-ui/Typography';


const Alerts = (props) => {
    const sensors = props.sensors ? props.sensors : [];
    const params = useParams();
    const facilityId = params.facilityId;

    const [alertSensorDetailVisible, setAlertSensorDetailVisible] = React.useState(false);
    const [alertBatteryDtailVisible, setBatterySensorDetailVisible] = React.useState(false);

    const language = useRecoilValue(languageState)

    return <Stack spacing={1}>
        {/* {JSON.stringify(selectedSensor)} */}
        {
            sensors.filter(x => !x.status).length > 0 ?
                <>
                    <Alert severity="error" sx={{ cursor: "pointer" }} onClick={() => setAlertSensorDetailVisible(!alertSensorDetailVisible)}>

                        {
                            alertSensorDetailVisible ? <>
                                <AlertTitle> {BasicLanguage.alert.sensorStatusError[language]}</AlertTitle>
                                {
                                    sensors.filter(x => !x.status).map(y => <Typography key={y.name}>
                                        <Link to={"/" + facilityId + "/" + (y.type === "atp" ? "atp" : "sensors") + "/" + y.id}>
                                            {y.map ? y.map + ": " : ""}
                                            {y.name}</Link>
                                    </Typography>)
                                }
                            </> :
                                <Typography>{BasicLanguage.alert.sensorStatusError[language]}</Typography>
                        }
                    </Alert>
                </> :
                <>
                </>
        }

        {
            sensors.filter(x => x.battery < 20).length > 0 ?
                <>

                    <Alert severity="warning" sx={{ cursor: "pointer" }} onClick={() => setBatterySensorDetailVisible(!alertBatteryDtailVisible)}>

                        {
                            alertBatteryDtailVisible ? <>
                                <AlertTitle> {BasicLanguage.alert.sensorBatteryError[language]}</AlertTitle>
                                {sensors.filter(x => x.battery < 20).map(y => <Typography key={y.name}>
                                    <Link to={"/" + facilityId + "/" + (y.type === "atp" ? "atp" : "sensors") + "/" + y.id}>
                                        {y.map ? y.map + ": " : ""}
                                        {y.name}</Link>
                                </Typography>)
                                }
                            </> :
                                <Typography>{BasicLanguage.alert.sensorBatteryError[language]}</Typography>
                        }
                    </Alert>

                </> :
                <>
                </>
        }

    </Stack>
}
export default Alerts;