import * as React from 'react';
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import Typography from '../components/parts-ui/Typography';


import SBXStyles from '../components/utils/Styles';
import { useParams } from 'react-router';

export default function CommonError(props) {
    return (
        <>
            {
                props.title ?
                    <Typography variant='h4'>
                        {props.title}
                    </Typography> : null

            }

            <Typography variant='h4'>
                {
                    props.message ?
                        <Typography>{props.message}</Typography>
                        : <Typography color="error">不明なエラーが発生しました</Typography>
                }
            </Typography>
        </>
    );
}