import React, { useCallback, useEffect, useState } from "react";
import BasicLanguage from "../../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { adminRobotsAdd, getRobotsList } from "../../utils/functions";
import { Robot } from "./Robot";
import Pager from "../shared/Pager";

const ROBOT_TYPES = ["Scrubber 50", "Phantas"];

export default function TabRobots() {
  const language = useRecoilValue(languageState);

  const [robots, setRobots] = useState([]);
  const [robotsLoading, setRobotsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [robotId, setRobotId] = useState("");
  const [robotName, setRobotName] = useState("");
  const [robotType, setRobotType] = useState("");
  const [page, setPage] = useState(1);
  const [pagerRobots, setPagerRobots] = useState([]);

  const getRobots = useCallback(() => {
    setRobotsLoading(true);
    setRobots([]);
    getRobotsList()
      .then((res) => {
        setRobots(res?.data?.robots ? res.data.robots : []);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(setRobotsLoading(false));
  }, []);

  useEffect(() => {
    getRobots();
  }, [getRobots]);

  const submit = (e) => {
    e.preventDefault();
    const robotData = { id: robotId, name: robotName, type: robotType };

    adminRobotsAdd(robotData).then((result) => {
      if (result.data?.status === "success") {
        getRobots();
        handleModalClose();
      } else {
        getRobots();
      }
    });
  };

  const handleModalClose = () => {
    setRobotId("");
    setRobotName("");
    setModal(false);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" className="subTitle">
          {BasicLanguage.admin.robots.title[language]}
        </Typography>
        <Button onClick={() => setModal(true)}>
          {BasicLanguage.admin.common.add[language]}
        </Button>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>ID</TableCell>
            <TableCell sx={{ width: "15%" }}>
              {BasicLanguage.admin.robots.name[language]}
            </TableCell>
            <TableCell sx={{ width: "15%" }}>
              {BasicLanguage.admin.robots.type[language]}
            </TableCell>
            <TableCell sx={{ width: "15%" }}>
              {BasicLanguage.admin.robots.facility[language]}
            </TableCell>
            <TableCell sx={{ width: "15%" }}>
              {BasicLanguage.admin.robots.siteName[language]}
            </TableCell>
            <TableCell sx={{ width: "15%" }}>
              {BasicLanguage.admin.robots.siteId[language]}
            </TableCell>
            <TableCell sx={{ width: "5%" }}>
              {BasicLanguage.admin.robots.active[language]}
            </TableCell>
            <TableCell sx={{ width: "10%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {robotsLoading ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            pagerRobots.map((robot) => (
              <Robot key={robot.id} robot={robot} getRobots={getRobots} />
            ))
          )}
        </TableBody>
      </Table>
      <Pager
        page={page}
        setPage={setPage}
        items={robots}
        setPagerItems={setPagerRobots}
      />
      <Modal
        open={modal}
        onClose={handleModalClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            width: "60%",
            maxWidth: "600px",
            minWidth: "400px",
            overflow: "auto",
            p: 2,
          }}
        >
          <Typography>{BasicLanguage.admin.common.add[language]}</Typography>
          <form onSubmit={submit}>
            <Stack spacing={1}>
              <FormLabel>
                <Typography>
                  {BasicLanguage.admin.robots.id[language]}
                </Typography>
                <Typography variant="body2"></Typography>
                <TextField
                  fullWidth
                  value={robotId}
                  onChange={(e) => setRobotId(e.target.value)}
                  maxLength={20}
                  name="id"
                />
              </FormLabel>
              <FormLabel>
                <Typography>
                  {BasicLanguage.admin.robots.name[language]}
                </Typography>
                <Typography variant="body2">
                  {BasicLanguage.admin.robots.nameUse[language]}
                </Typography>
                <TextField
                  fullWidth
                  value={robotName}
                  onChange={(e) => setRobotName(e.target.value)}
                  maxLength={20}
                  name="name"
                  sx={{ mb: 2 }}
                />
              </FormLabel>
              <FormLabel>
                <Typography>
                  {BasicLanguage.admin.robots.type[language]}
                </Typography>
                <Select
                  fullWidth
                  value={robotType}
                  onChange={(e) => setRobotType(e.target.value)}
                  maxLength={20}
                  name="name"
                  sx={{ mb: 2 }}
                >
                  {ROBOT_TYPES.map((robotType) => (
                    <MenuItem key={robotType} value={robotType}>
                      {robotType}
                    </MenuItem>
                  ))}
                </Select>
              </FormLabel>
              <Button
                type="submit"
                variant="contained"
                disabled={!robotId || !robotName || !robotType}
              >
                {BasicLanguage.admin.common.send[language]}
              </Button>
            </Stack>
          </form>
        </Paper>
      </Modal>
    </>
  );
}
