import { useEffect, useState } from "react";
import React from 'react';
import chroma from 'chroma-js';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useParams } from 'react-router';
import { Box, Typography } from '@mui/material';
import BasicLanguage from '../../components/languages/Basic';
import {collection, getCountFromServer, getDocs, orderBy, query, where} from "firebase/firestore";
import { db } from "../utils/firebase";

const ENV = process.env.REACT_APP_FIRESTORE_ENV
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION

export default function Chart(props) {

    const params = useParams();
    const facilityId = params.facilityId;
    const language = props.language || "ja";
    const [historyData, setHistoryData] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const delimiteTime = 15;
    const dataCount = 96;
    const targetDate = new Date();
    
    const [sensorsHistoryToShow, setSensorsHistoryToShow] = React.useState([]);
    const [series, setSeries] = React.useState([]);
    const selectedSensorId = null;

    const getData = () => {
        setIsLoading(true);
        targetDate.setDate(targetDate.getDate() - 1);

        setHistoryData([]);
        const historyQuery = query(collection(db, ENV, VERSION, "facilities", facilityId, "sensorCurrentHistory"),
                                    where("createdAt", ">=", targetDate),
                                    orderBy("createdAt", 'asc'));
        getDocs(historyQuery)
        .then(querysnapshot => {
            setHistoryData(querysnapshot.docs.map((doc) => {
                return { ...doc.data(), id: doc.id }
            }))
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getData();
        CreateChart();
    }, [facilityId]);

    useEffect(() => {
        const now = new Date();
        const targetDate = new Date();
        const resultDates = [];
        targetDate.setDate(targetDate.getDate() - 1);
        for (let date = targetDate; date < now; date.setMinutes(date.getMinutes() + delimiteTime)) {
            resultDates.push(Math.floor( date.getTime() / 1000 ));
        }

        let createData = []
        let seriesData = []
        for (let a = 0; a < dataCount ; a++) {
            createData[a] = {};
        }
        if (historyData.length !== 0) {
            historyData.forEach((data) => {
                let time = data.createdAt.seconds
                let value = data.value
                let sensorId = data.sensorId
                let flag = true

                seriesData.push(sensorId)

                resultDates.forEach((x, index) => {
                    if (flag && x >= time) {
                        createData[index][sensorId]= value
                        flag = false
                    } else if (!createData[index][sensorId]) {
                        createData[index][sensorId]= 0
                    }
                });
            });

            seriesData = [...new Set(seriesData)];
            let seriesArray = []
            seriesData.forEach((s) => {
                seriesArray[s] = s
            });
            setSeries(seriesArray)
            setSensorsHistoryToShow(createData)
            CreateChart();
        }
    }, [historyData]);

    function CreateChart() {

        return <>
        {
            sensorsHistoryToShow.length === 0 ? 
            <Box sx={{ width: "100%", height: 350, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="h5">
                    {BasicLanguage.sensor.none[language]}
                </Typography>
            </Box> 
            : 
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={sensorsHistoryToShow}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey="name" />

                    <Tooltip content={({ active, payload, label }) => {
                        if (active && payload && payload.length) {
                            return <Box sx={{ p: 1, backgroundColor: "rgba(255,255,255,0.5)", backdropFilter: "blur(3px)" }}>
                                {payload.map(x => {
                                    return <Typography sx={{ color: x.stroke }}>{series[x.dataKey]}:{x.value}</Typography>
                                })}
                            </Box>
                        }
                    }} />
                    < CartesianGrid stroke="#f5f5f5" />
                    {
                        Object.keys(sensorsHistoryToShow[0])
                            .map((x, index) => <Line
                                isAnimationActive={true}
                                dataKey={x}
                                strokeWidth={x === selectedSensorId ? 3 : 1}
                                stroke={getColor(index, x)} />)
                    }
                </LineChart>
            </ResponsiveContainer>
        }
        </>
    }
    
    return <>
        <CreateChart/>
    </>
}

export const getColor = (index, x) => {
    let code = ""
    const colors = chroma.scale(['red', 'orange', 'green','blue', 'black']).domain([0, x.length])
    code = colors(index).hex()
    return code
};
