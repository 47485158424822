import { Button, Stack, Switch, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BasicLanguage from "../../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import { adminKpiDelete, adminKpiUpdate } from "../../utils/functions";
import { DialogYesNo } from "../Dialog";
import { Link } from "react-router-dom";

export default function Kpi(props) {
  const savedKpi = props.kpi;
  const kpiItems = props.kpiItems;
  const getKpis = props.getKpis;
  const language = useRecoilValue(languageState);
  const [kpi, setKpi] = useState(savedKpi);
  const [editMode, setEditMode] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const submit = () => {
    adminKpiUpdate({ kpi: kpi })
      .then((result) => {
        if (result?.data?.status === "success") {
          getKpis();
          setEditMode(false);
        } else {
          throw new Error(result?.data?.status);
        }
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  useEffect(() => {
    setKpi(savedKpi);
  }, [savedKpi]);

  const cancel = () => {
    setKpi(savedKpi);
    setEditMode(false);
  };

  const handleSwitchChange = (e) => {
    const name = e.target.name;
    setKpi((prevKpi) => ({ ...prevKpi, [name]: e.target.checked }));
  };

  const deleteKpi = () => {
    adminKpiDelete({ kpiId: kpi.id, facilityId: kpi.facilityId })
      .then((result) => {
        if (result?.data?.status === "success") {
          getKpis();
          setEditMode(false);
        } else {
          throw new Error(result?.data?.status);
        }
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  return (
    <>
      <TableRow>
        {kpiItems.map((kpiItem) => {
          if (kpiItem === "facilityId") {
            return (
              <TableCell key={kpiItem}>
                <Link to={`/${kpi.facilityId}/dashboard`} target="_blank">
                  {kpi.facilityId}
                </Link>
              </TableCell>
            );
          } else {
            return (
              <TableCellSwitch
                key={kpiItem}
                active={kpi[kpiItem]}
                disabled={!editMode}
                handleChange={handleSwitchChange}
                name={kpiItem}
              />
            );
          }
        })}
        <TableCell sx={{ textAlign: "center" }}>
          {editMode ? (
            <Stack spacing={1}>
              <Button onClick={submit} variant="outlined" color="success">
                {BasicLanguage.admin.common.save[language]}
              </Button>
              <Button
                onClick={() => setDeleteModalOpen(true)}
                variant="outlined"
                color="error"
              >
                {BasicLanguage.admin.common.delete[language]}
              </Button>
              <Button onClick={cancel} variant="outlined" color="secondary">
                {BasicLanguage.admin.common.cancel[language]}
              </Button>
            </Stack>
          ) : (
            <Button
              onClick={() => setEditMode((prev) => !prev)}
              variant="outlined"
              fullWidth
            >
              {BasicLanguage.admin.common.edit[language]}
            </Button>
          )}
        </TableCell>
      </TableRow>
      <DialogYesNo
        open={[deleteModalOpen, setDeleteModalOpen]}
        yesAction={deleteKpi}
        noAction={() => setDeleteModalOpen(false)}
        title={kpi.facilityId}
        message={BasicLanguage.admin.kpi.deleteMessage[language]}
      />
    </>
  );
}

Kpi.propTypes = {
  kpi: PropTypes.object,
  kpiItems: PropTypes.array,
  getKpis: PropTypes.func,
};

const TableCellSwitch = (props) => {
  const active = props.active;
  const disabled = props.disabled;
  const handleChange = props.handleChange;
  const name = props.name;

  return (
    <TableCell>
      <Switch
        onChange={handleChange}
        checked={active}
        disabled={disabled}
        name={name}
      />
    </TableCell>
  );
};

TableCellSwitch.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  name: PropTypes.string,
};
