import React, { createContext, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";
const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;
const TIMEZONE = process.env.REACT_APP_TIMEZONE;

export const TimeZoneContext = createContext();

export const TimeZoneProvider = ({ children }) => {
  const { facilityId } = useParams();
  const [currentTimeZoneName, setCurrentTimeZoneName] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchFacilityData = useCallback(async () => {
    try {
      const facilityRef = doc(db, ENV, VERSION, "facilities", facilityId);
      const facilitySnapshot = await getDoc(facilityRef);
      const data = facilitySnapshot.data();
      if (data?.timeZoneName) {
        setCurrentTimeZoneName(data.timeZoneName);
      } else {
        setCurrentTimeZoneName(TIMEZONE);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [facilityId]);

  useEffect(() => {
    fetchFacilityData();
  }, [fetchFacilityData, facilityId]);

  const contextValue = loading ? "..." : currentTimeZoneName;

  return (
    <TimeZoneContext.Provider
      value={{ currentTimeZoneName: contextValue, setCurrentTimeZoneName }}
    >
      {children}
    </TimeZoneContext.Provider>
  );
};

export default TimeZoneProvider;
