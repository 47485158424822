import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SBXStyles from "../utils/Styles";
import BasicLanguage from "../languages/Basic";
import languageState from "../../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";
import RobotCleaningMode from "./RobotCleaningMode";
import PropTypes from "prop-types";
import { getV1RobotStatus } from "../utils/functions";
import { collection, doc, getDocs, runTransaction } from "firebase/firestore";
import { db } from "../utils/firebase";
import { useParams } from "react-router";

RobotCleaningModes.propTypes = {
  robot: PropTypes.object,
  getRobot: PropTypes.func,
};

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;
const PHANTAS_CLEANING_MODES = ["清洗", "清扫", "尘推", "吸尘"];

export default function RobotCleaningModes(props) {
  const language = useRecoilValue(languageState);
  const robot = props.robot;
  const params = useParams();
  const facilityId = params.facilityId;
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [qrsToFix, setQrsToFix] = useState([]);
  const [sensorsToFix, setSensorsToFix] = useState([]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const getCleaningModes = () => {
    if (robot.type === "Scrubber 50") {
      return robot.cleaningModes ? Object.keys(robot.cleaningModes) : [];
    } else {
      return PHANTAS_CLEANING_MODES;
    }
  };

  const updateCleaningModeNames = async () => {
    try {
      const result = await getV1RobotStatus({robotId:robot.id});
      const robotStatus = result.data.result
      console.log("---------------robotStatus")
      console.log(robotStatus)

      if (!robotStatus?.cleanModes) {
        alert(BasicLanguage.alert.cleaningModesDoNotExist[language]);
        return;
      }
      const cleanModeNames = robotStatus.cleanModes.map(
        (cleanMode) => cleanMode?.name
      );
      await runTransaction(db, async (transaction) => {
        const robotDocRef = doc(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "robots",
          robot.id
        );
        const robotDoc = await transaction.get(robotDocRef);
        if (!robotDoc.exists()) {
          alert(BasicLanguage.alert.robotDoesNotExist[language]);
          return;
        }
        const robotCleaningModes = robotDoc.data().cleaningModes;
        let newCleaningModes;

        if (robotCleaningModes === undefined) {
          newCleaningModes = cleanModeNames.reduce(
            (acc, curr) => ({ ...acc, [curr]: [] }),
            {}
          );
        } else {
          newCleaningModes = cleanModeNames.reduce((acc, curr) => {
            const cleaningModeDays = robotCleaningModes[curr] || [];
            return { ...acc, [curr]: cleaningModeDays };
          }, {});
        }
        const qrIds = [];
        setQrsToFix([]);
        const triggerIds = [];
        setSensorsToFix([]);

        if (robot.floors) {
          Object.values(robot.floors).forEach((floor) => {
            if (floor.areas) {
              Object.values(floor.areas).forEach((area) => {
                if (area.qrIds && area.qrIds.length !== 0) {
                  qrIds.push(...area.qrIds);
                }
                if (area.triggerIds && area.triggerIds.length !== 0) {
                  triggerIds.push(...area.triggerIds);
                }
              });
            }
          });
        }
        for (let qrId of qrIds) {
          const qrDoc = await transaction.get(
            doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId)
          );
          if (!qrDoc.exists) {
            continue;
          }
          if (!qrDoc.data().cleaningMode) {
            continue;
          }
          if (
            ![...PHANTAS_CLEANING_MODES, ...Object.keys(newCleaningModes)].includes(qrDoc.data().cleaningMode)
          ) {
            setQrsToFix((prev) => [...prev, qrId]);
          }
        }

        const sensorDocs = await getDocs(
          collection(db, ENV, VERSION, "facilities", facilityId, "sensors")
        );
        const sensorIds = sensorDocs.docs.map((doc) => doc.id);
        for (let sensorId of sensorIds) {
          const triggerDocs = await getDocs(
            collection(
              db,
              ENV,
              VERSION,
              "facilities",
              facilityId,
              "sensors",
              sensorId,
              "triggers"
            )
          );
          const triggers = triggerDocs.docs
            .filter((doc) => triggerIds.includes(doc.id))
            .map((doc) => ({
              id: doc.id,
              cleaningMode: doc.data().cleaningMode,
            }));
          for (let trigger of triggers) {
            if (!trigger.cleaningMode) {
              continue;
            }
            if (
              ![...PHANTAS_CLEANING_MODES, ...Object.keys(newCleaningModes)].includes(trigger.cleaningMode)
            ) {
              setSensorsToFix((prev) => [...prev, sensorId]);
              break;
            }
          }
        }
        transaction.update(robotDocRef, {
          cleaningModes: newCleaningModes,
        });
      });
      setAlertTitle(BasicLanguage.alert.updated[language]);
      props.getRobot();
    } catch (e) {
      setAlertTitle(BasicLanguage.alert.error.default[language]);
      console.error(e);
    } finally {
      setAlertOpen(true);
    }
  };

  return (
    <Box sx={SBXStyles.mainBox}>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">
            {BasicLanguage.robots.detail.cleaningModes.title[language]}
          </Typography>
          {robot.type === "Scrubber 50" && (
            <Button variant="contained" onClick={updateCleaningModeNames}>
              {BasicLanguage.common.update[language]}
            </Button>
          )}
        </Box>
        <Table>
          <TableBody>
            {getCleaningModes()
              .sort((a, b) => a.localeCompare(b))
              .map((cleaningMode) => {
                return (
                  <RobotCleaningMode
                    key={cleaningMode}
                    mode={cleaningMode}
                    robot={robot}
                    getRobot={props.getRobot}
                  />
                );
              })}
          </TableBody>
        </Table>
      </Box>
      <Dialog open={alertOpen} onClose={handleAlertClose}>
        <DialogTitle>{alertTitle}</DialogTitle>
        <DialogContent>
          {(qrsToFix.length !== 0 || sensorsToFix.length !== 0) && (
            <>
              <Typography variant="subtitle1">
                {
                  BasicLanguage.robots.detail.cleaningModes.updateCleaningMode[
                    language
                  ]
                }
              </Typography>
              <br />
            </>
          )}
          {qrsToFix.length !== 0 && (
            <>
              <Typography variant="h6">QR IDS</Typography>
              {qrsToFix.map((qrId) => (
                <DialogContentText>{qrId}</DialogContentText>
              ))}
              <br />
            </>
          )}
          {sensorsToFix.length !== 0 && (
            <>
              <Typography variant="h6">Sensor IDS</Typography>
              {sensorsToFix.map((sensorId) => (
                <DialogContentText>{sensorId}</DialogContentText>
              ))}
              <br />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
