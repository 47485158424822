import { getDownloadURL, ref } from "@firebase/storage";
import { storage } from "../utils/firebase";
import * as React from "react";

const StorageImage = (props) => {
  const imgRef = React.useRef();
  React.useEffect(() => {
    getDownloadURL(ref(storage, props.imagePath))
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpeg'

        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
          const blob = xhr.response;
        };
        xhr.open("GET", url);
        xhr.send();

        // Or inserted into an <img> element
        const img = imgRef.current;
        img.setAttribute("src", url);
      })
      .catch((error) => {
        console.error("storageImage", error);
      });
  }, [props.imagePath]);
  return (
    <>
      <img ref={imgRef} style={props.sx} />
    </>
  );
};

export default StorageImage;
