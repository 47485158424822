import React, { useCallback, useEffect, useState } from "react";
import { MuiColorInput, matchIsValidColor } from "mui-color-input";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRecoilValue } from "recoil";
import languageState from "../../recoil/atoms/languageState";
import { deleteField, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { useParams } from "react-router";
import Typography from "../parts-ui/Typography";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import BasicLanguage from "../languages/Basic";
import Colors from "../utils/Colors";
import { DialogYesNo } from "./Dialog";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function QrRequestColor() {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;

  const [color, setColor] = useState(Colors.primary.main);
  const [selectedColor, setSelectedColor] = useState(Colors.primary.main);
  const [isEditMode, setIsEditMode] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);

  const handleChange = (changeColor) => {
    if (matchIsValidColor(changeColor)) {
      setSelectedColor(changeColor);
    }
  };

  const handleCancel = () => {
    setSelectedColor(color);
    setIsEditMode(false);
  };

  const handleSave = () => {
    updateDoc(doc(db, ENV, VERSION, "facilities", facilityId), {
      qrRequestColor: selectedColor,
    })
      .then(() => {
        getFacilityRequestColor();
        setIsEditMode(false);
      })
      .catch((error) => {
        console.error(error);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  const handleReset = () => {
    updateDoc(doc(db, ENV, VERSION, "facilities", facilityId), {
      qrRequestColor: deleteField(),
    })
      .then(() => {
        getFacilityRequestColor();
      })
      .catch((error) => {
        console.error(error);
        alert(BasicLanguage.alert.error.default[language]);
      })
      .finally(() => {
        setResetModalOpen(false);
      });
  };

  const handleClick = () => {
    setResetModalOpen(true);
  };

  const getFacilityRequestColor = useCallback(() => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
      .then((doc) => {
        const qrRequestColor = doc.data().qrRequestColor || Colors.primary.main;
        setColor(qrRequestColor);
        setSelectedColor(qrRequestColor);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [facilityId]);

  useEffect(() => {
    getFacilityRequestColor();
  }, [getFacilityRequestColor]);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6">
        {BasicLanguage.qr.settings.qrRequestColor[language]}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="20%"></TableCell>
            <TableCell width="60%"></TableCell>
            <TableCell width="20%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography>
                {BasicLanguage.qr.settings.backgroundColor[language]}
              </Typography>
            </TableCell>
            <TableCell>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                {isEditMode ? (
                  <>
                    <MuiColorInput
                      value={selectedColor}
                      onChange={handleChange}
                      format="hex"
                      isAlphaHidden={true}
                    />
                    <IconButton onClick={handleSave} color="success">
                      <CheckCircleIcon />
                    </IconButton>
                    <IconButton onClick={handleCancel} color="error">
                      <CancelIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        width: "100px",
                        height: "50px",
                        borderRadius: "5px",
                        backgroundColor: color,
                      }}
                    />
                    <Typography>{color}</Typography>
                    <IconButton onClick={() => setIsEditMode(true)}>
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </TableCell>
            <TableCell>
              <Button onClick={handleClick} variant="outlined" size="large">
                {BasicLanguage.qr.settings.reset.button[language]}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <DialogYesNo
        open={[resetModalOpen, setResetModalOpen]}
        title={BasicLanguage.qr.settings.reset.title[language]}
        message={BasicLanguage.qr.settings.reset.message[language]}
        yesAction={handleReset}
        noAction={() => setResetModalOpen(false)}
      />
    </Box>
  );
}
