import styled from "@emotion/styled";
import { Box } from "@mui/material";


const Container = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        minWidth: "320px",
        maxWidth: "600px",
        mx: "auto",
        '& .containerChild':
        {
            background: "linear-gradient(145deg, rgb(244,244,248), rgb(251,251,253))",
            py: "3rem",
            px: 5,
            mx: 1,
            mb: "40px",
            borderRadius: "40px",

        }
    }));
export default Container;