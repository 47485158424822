import * as React from "react";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { auth } from "../components/utils/firebase";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Button, Divider, Stack, Switch, TextField } from "@mui/material";
import Typography from "../components/parts-ui/Typography";
import { useRecoilState, useRecoilValue } from "recoil";
import { Helmet } from "react-helmet-async";
import { useSession, login, logout } from "../components/utils/session";
import sessionState from "../recoil/atoms/sessionState";
import { useTheme } from "@emotion/react";
import languageState from "../recoil/atoms/languageState";
import styled from "@emotion/styled";
import CommonHeaderItems from "../components/parts/CommonHeaderItems";

import Link from "@mui/material/Link";
import {
  userUpdate,
  userUpdateEmail,
  userUpdateNumber,
  userVerificationEmail,
} from "../components/utils/functions";
import validations from "../components/utils/Validations";
import BasicLanguage from "../components/languages/Basic";
import UserRegisterationForm from "../components/parts/forms/userNumberForm";

const validationEmailPattern = validations.email;

const validateEmail = (e) => {
  return e.match(validationEmailPattern) ? true : false;
};

const Header = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    height: "80px",
    display: "flex",
    alignItems: "center",
    mr: 5,
  })
);

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    minWidth: "320px",
    maxWidth: "600px",
    mx: "auto",
    "& .containerChild": {
      background: "linear-gradient(145deg, rgb(244,244,248), rgb(251,251,253))",
      py: "3rem",
      px: 5,
      mx: 1,
      mb: "40px",
      borderRadius: "40px",
    },
  })
);
const Account = (props) => {
  const navigate = useNavigate();

  const language = useRecoilValue(languageState);
  const [displayName, setDisplayName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [email2, setEmail2] = React.useState("");
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [WhatsAppNumber, setWhatsAppNumber] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPassword2, setNewPassword2] = React.useState("");
  const [whatsAppnotifications, setwhatsAppnotifications] =
    React.useState(false);

  useSession();
  const session = useRecoilValue(sessionState);

  const submitUserDisplayName = (e) => {
    // console.log(e.crurrentTarget)
    e.preventDefault();
    userUpdate({
      displayName: displayName,
    }).then((x) => {
      if (x.data.status === "success") {
        alert(BasicLanguage.alert.nameUpdated[language]);
        window.location.reload();
      } else {
        alert(BasicLanguage.alert.error.default[language]);
      }
    });
  };

  const isValidWhatsAppNumber = (number) => {
    const pattern = /^([0|\+[0-9]{1,5})?([0-9]{10})$/;
    return pattern.test(number);
  };

  const submitUserDisplayPhoneNumber = async (e) => {
    let phoneNumber = whatsAppnotifications ? WhatsAppNumber : null;
    let notifications = whatsAppnotifications;
    e.preventDefault();
    setLoading(true);
    if (!whatsAppnotifications && !WhatsAppNumber) {
      setLoading(false);
      return;
    }

    if (whatsAppnotifications) {
      if (!WhatsAppNumber) {
        alert(BasicLanguage.whatsAppRegisteration.noEntry[language]);
        setLoading(false);
        return;
      } else if (!isValidWhatsAppNumber(WhatsAppNumber)) {
        alert(BasicLanguage.whatsAppRegisteration.valid[language]);
        setLoading(false);
        return;
      }
    }
    try {
      const x = await userUpdateNumber({
        phoneNumber: phoneNumber,
        whatsAppnotifications: notifications,
      });
      if (
        x.data.status === "success" &&
        whatsAppnotifications &&
        WhatsAppNumber
      ) {
        alert(BasicLanguage.whatsAppRegisteration.success2[language]);
        await auth.currentUser.getIdToken(true);
        window.location.reload();
      } else if (
        x.data.status === "success" &&
        !whatsAppnotifications &&
        WhatsAppNumber
      ) {
        alert(BasicLanguage.whatsAppRegisteration.success[language]);
        await auth.currentUser.getIdToken(true);
        window.location.reload();
      } else if (
        x.data.reason ===
        "The user with the provided phone number already exists."
      ) {
        alert(BasicLanguage.whatsAppRegisteration.numberExistsError[language]);
        window.location.reload();
      } else {
        alert(BasicLanguage.alert.error.default[language]);
      }
    } catch (error) {
      console.log(error);
    }
    if (!whatsAppnotifications) {
      setWhatsAppNumber("");
    }
    setLoading(false);
  };
  const submitPassword = async (e) => {
    e.preventDefault();

    const authCredential = EmailAuthProvider.credential(
      session.email,
      oldPassword
    );
    reauthenticateWithCredential(auth.currentUser, authCredential)
      .then((x) => {
        updatePassword(auth.currentUser, newPassword);
      })
      .then((x) => logout())
      .catch((e) => console.error(e));
  };

  const submitEmail = async (e) => {
    e.preventDefault();

    const authCredential = EmailAuthProvider.credential(
      session.email,
      currentPassword
    );

    reauthenticateWithCredential(auth.currentUser, authCredential)
      .then((x) => {
        userUpdateEmail({ email: email })
          .then((x) => {
            alert(BasicLanguage.alert.emailChangeSuccess[language]);
            logout();
          })
          .catch((e) => {
            console.error(e);
            alert(BasicLanguage.alert.error.default[language]);
          });
      })
      .catch((e) => {
        console.error(e);
        alert("PASSWORD ERROR");
      });
  };
  const onChangeDisplayName = (e) => {
    setDisplayName(e.target.value);
  };
  const onChangeDisplayNumber = (e) => {
    setWhatsAppNumber(e.target.value);
  };
  const onChangeDisplaySwitch = (e) => {
    setwhatsAppnotifications(!whatsAppnotifications);
  };

  const onChangeCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangeEmail2 = (e) => {
    setEmail2(e.target.value);
  };
  const onChangeOldPassword = (e) => {
    setOldPassword(e.target.value);
  };
  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };
  const onChangeNewPassword2 = (e) => {
    setNewPassword2(e.target.value);
  };

  const emailVerification = async () => {
    await userVerificationEmail().catch((e) => {
      console.error(e);
      alert(BasicLanguage.alert.error.default[language]);
    });
    alert(BasicLanguage.alert.sendEmail[language]);
  };

  useEffect(() => {
    if (session) {
      setDisplayName(session?.displayName ?? "");
      setWhatsAppNumber(session?.phoneNumber ?? "");
      setwhatsAppnotifications(
        session?.customClaims.WhatsAppNotifications === true
      );
    }
  }, [session]);

  return (
    <>
      <Helmet>
        <title>SmartBX Connect</title>
      </Helmet>
      <Header>
        <Box sx={{ flex: 1 }}></Box>
        <CommonHeaderItems />
      </Header>
      <Box
        sx={{
          mx: "auto",
          my: 1,
          // width: "600px"
        }}
      ></Box>
      <Container>
        <Typography sx={{ ml: 1 }}>
          <Link href={"/"}>
            &lt; {BasicLanguage.account.backHome[language]}
          </Link>
        </Typography>
        <Box className="containerChild">
          <Stack spacing={1}>
            <Typography variant="h4" className="pageTitle">
              {BasicLanguage.account.profile.title[language]}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            {session && !session.emailVerified ? (
              <>
                <Typography variant="h5" className="subTitle">
                  {BasicLanguage.account.verification.title[language]}
                </Typography>
                <Typography>
                  {BasicLanguage.account.verification.content[language]}
                </Typography>
                <Button
                  variant="contained"
                  type="button"
                  onClick={emailVerification}
                >
                  {BasicLanguage.account.verification.button[language]}
                </Button>
                <Divider sx={{ my: 4 }} />
              </>
            ) : (
              <></>
            )}
          </Stack>
          <form onSubmit={submitUserDisplayName}>
            <Stack spacing={1}>
              <Typography variant="h5" className="subTitle">
                {BasicLanguage.account.name.title[language]}
              </Typography>
              <Box>
                <TextField
                  name="displayName"
                  fullWidth
                  value={displayName}
                  onChange={onChangeDisplayName}
                />
              </Box>
              <Button variant="contained" type="submit">
                {BasicLanguage.account.name.button[language]}
              </Button>
            </Stack>
          </form>

          <Divider sx={{ my: 4 }} />

          <Typography variant="h5" className="subTitle">
            {BasicLanguage.account.email.title[language]}
          </Typography>
          <form onSubmit={submitEmail}>
            <Stack spacing={1}>
              <Typography>
                {BasicLanguage.account.password.current[language]}
              </Typography>
              <TextField
                type="password"
                fullWidth
                value={currentPassword}
                onChange={onChangeCurrentPassword}
              />
              <Typography>
                {BasicLanguage.account.email.new[language]}
              </Typography>
              <TextField
                name="email"
                fullWidth
                value={email}
                onChange={onChangeEmail}
              />
              <Typography>
                {BasicLanguage.account.email.confirmation[language]}
              </Typography>
              <TextField
                name="email"
                fullWidth
                value={email2}
                onChange={onChangeEmail2}
              />
              <Button
                variant="contained"
                type="submit"
                disabled={
                  !email || !email2 || !currentPassword || email !== email2
                }
              >
                {BasicLanguage.account.email.button[language]}
              </Button>
            </Stack>
          </form>
          <Divider sx={{ my: 4 }} />
          <Typography variant="h5" className="subTitle">
            {BasicLanguage.account.password.title[language]}
          </Typography>
          <form onSubmit={submitPassword}>
            <Stack spacing={1}>
              <Box>
                <Typography>
                  {BasicLanguage.account.password.current[language]}
                </Typography>
                <TextField
                  type="password"
                  fullWidth
                  value={oldPassword}
                  onChange={onChangeOldPassword}
                />
              </Box>
              <Box>
                <Typography>
                  {BasicLanguage.account.password.new[language]}
                </Typography>
                <TextField
                  type="password"
                  fullWidth
                  value={newPassword}
                  onChange={onChangeNewPassword}
                />
              </Box>
              <Box>
                <Typography>
                  {BasicLanguage.account.password.confirmation[language]}
                </Typography>
                <TextField
                  type="password"
                  fullWidth
                  value={newPassword2}
                  onChange={onChangeNewPassword2}
                />
              </Box>
              <Button
                variant="contained"
                type="submit"
                disabled={
                  !oldPassword || !newPassword || newPassword !== newPassword2
                }
              >
                {BasicLanguage.account.password.button[language]}
              </Button>
            </Stack>
          </form>
          <Divider sx={{ my: 4 }} />
          <form onSubmit={submitUserDisplayPhoneNumber}>
            <Stack spacing={1}>
              <Typography variant="h5" className="subTitle">
                {BasicLanguage.whatsAppRegisteration.register[language]}
              </Typography>

              <Box
                display={"flex"}
                alignContent={"center"}
                justifyContent={"space-between"}
              >
                <Typography varinat="h5">
                  {BasicLanguage.whatsAppRegisteration.notifications[language]}
                </Typography>
                <Switch
                  checked={whatsAppnotifications}
                  onChange={onChangeDisplaySwitch}
                />
              </Box>
              <Box>
                <Typography
                  color={!whatsAppnotifications ? "grey" : "inherit"}
                  varinat="h5"
                >
                  {" "}
                  {
                    BasicLanguage.whatsAppRegisteration.phoneLabel[language]
                  }{" "}
                </Typography>
              </Box>
              <Box>
                <TextField
                  fullWidth
                  type="text"
                  value={WhatsAppNumber}
                  disabled={!whatsAppnotifications}
                  onChange={onChangeDisplayNumber}
                />
              </Box>
              <Button
                variant="contained"
                type="submit"
                disabled={
                  loading || (!whatsAppnotifications && !WhatsAppNumber)
                }
              >
                {BasicLanguage.whatsAppRegisteration.registerButton[language]}
              </Button>
            </Stack>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default Account;
