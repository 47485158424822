import React from "react";
import TimeZoneFacility from "../components/parts/TimeZoneFacility";
import { Box, Typography } from "@mui/material";
import SBXStyles from "../components/utils/Styles";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import BasicLanguage from "../components/languages/Basic";
import { useParams } from "react-router";
import SensorResetTime from "../components/parts/SensorResetTime";
import CustomerSatisfactionCsv from "../components/parts/CustomerSatisfactionCsv";
import KpiFacilitySettings from "../components/parts/KpiFacilitySettings";
import RobotCleaningModeRegistration from "../components/parts/RobotCleaningModeRegistration";
import SensorRegister from "../components/parts/SensorRegister";
import { collection, getDocs, limit, query } from "firebase/firestore";
import { db } from "../components/utils/firebase";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function FacilitySettings() {
  const params = useParams();
  const facilityId = params.facilityId;
  const language = useRecoilValue(languageState);
  const [kpiActive, setKpiActive] = React.useState(false);
  const [kpiData, setKpiData] = React.useState([]);
  const carbonFootprint = !!kpiData?.carbonFootprint;
  const customerSatisfaction = !!kpiData?.customerSatisfaction;
  const robotFloorCoverage = !!kpiData?.robotFloorCoverage;
  const peopleCount = !!kpiData?.peopleCount;
  const getKpi = React.useCallback(() => {
    setKpiData([]);
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "kpis"),

        limit(1)
      )
    )
      .then((querysnapshot) => {
        const data = querysnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
        setKpiData(data[0]);
        if (!querysnapshot.empty) {
          setKpiActive(!!data[0].active);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [facilityId]);

  React.useEffect(() => {
    getKpi();
  }, [facilityId, getKpi]);

  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      <Typography className="pageTitle" variant="h4">
        {BasicLanguage.facilitySettings.title[language]}
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        {BasicLanguage.facilitySettings.description[language]}
      </Typography>
      <TimeZoneFacility />
      <SensorResetTime enableResetBatch={true} />
      {peopleCount && kpiActive && <SensorRegister />}
      {customerSatisfaction && kpiActive && <CustomerSatisfactionCsv />}
      {carbonFootprint && kpiActive && <KpiFacilitySettings />}
      {robotFloorCoverage && kpiActive && <RobotCleaningModeRegistration />}
    </Box>
  );
}
