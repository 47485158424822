import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";
import SBXStyles from "../components/utils/Styles";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Link, useParams } from "react-router-dom";

import languageState from "../recoil/atoms/languageState";

import Typography from "../components/parts-ui/Typography";

import { db } from "../components/utils/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useRecoilValue } from "recoil";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function Robots() {
  const params = useParams();
  const facilityId = params.facilityId;
  const language = useRecoilValue(languageState);

  const [robots, setRobots] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRobots = useCallback(() => {
    setLoading(true);
    setRobots([]);
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "robots"),
        where("active", "==", true)
      )
    )
      .then((querysnapshot) => {
        setRobots(
          querysnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        );
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(setLoading(false));
  }, [facilityId]);

  useEffect(() => {
    getRobots();
  }, [getRobots]);

  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
        <Typography className="pageTitle" variant="h4">
          {BasicLanguage.common.menu.robots[language]}
        </Typography>
        <Typography sx={{ mt: 2, mb: 2 }}>
          {BasicLanguage.robots.description[language]}
        </Typography>

        <Box sx={SBXStyles.mainBox}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{BasicLanguage.robots.table.id[language]}</TableCell>
                <TableCell>
                  {BasicLanguage.robots.table.type[language]}
                </TableCell>
                <TableCell>
                  {BasicLanguage.robots.table.name[language]}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableCell colSpan={5}>
                  <Box
                    sx={{
                      display: "flex",
                      height: 200,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              ) : (
                <>
                  {robots.map((robot) => (
                    <TableRow key={robot.id}>
                      <TableCell>{robot.id}</TableCell>
                      <TableCell>{robot.type}</TableCell>
                      <TableCell>{robot.name}</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        <IconButton component={Link} to={"./" + robot.id}>
                          <SettingsOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
              {!loading && robots.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Box
                      sx={{
                        width: "100%",
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {BasicLanguage.robots.none[language]}
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </Box>
      </Box>
  );
}
