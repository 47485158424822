import BasicLanguage from "../../languages/Basic";
import languageState from "../../../recoil/atoms/languageState";
import validations from "../../utils/Validations";
import {
  Box,
  Button,
  FormLabel,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DialogYesNo } from "../Dialog";
import UserAuth from "./UserAuth";
import Pager from "../shared/Pager";
import { useRecoilValue } from "recoil";
import { useState, useEffect, useCallback } from "react";
import {
  adminUserAdd,
  adminUserRemoveAdmin,
  adminUserAddAdmin,
  getUserListAsAdmin,
} from "../../utils/functions";

const validationEmailPattern = validations.email;
const validateEmail = (e) => {
  return !!e.match(validationEmailPattern);
};

export const TabUsersAuth = () => {
  const language = useRecoilValue(languageState);
  const [page, setPage] = useState(1);
  const [pagerAuthUsers, setPagerAuthUsers] = useState([]);

  const [authUsers, setAuthUsers] = useState([]);
  const [createResult, setCreateResult] = useState({});
  const [modal, setModal] = useState(false);
  const [addAdminConfirmModal, setAddAdminConfirmModal] = useState(false);
  const [removeAdminConfirmModal, setRemoveAdminConfirmModal] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const onChangeUserName = (e) => {
    setUserName(e.target.value);
  };
  const onChangeUserEmail = (e) => {
    const value = e.target.value;
    setErrorEmail(value === "" ? false : !validateEmail(value));
    setUserEmail(value);
  };

  const handleModalClose = () => {
    setModal(false);
    setCreateResult({});
    setErrorEmail(false);
    setUserName("");
    setUserEmail("");
  };

  const submit = async (e) => {
    e.preventDefault();
    const result = await adminUserAdd({ name: userName, email: userEmail });
    if (result?.data?.status === "success") {
      setCreateResult(result.data.account);
      getAuthUsers();
    } else {
      alert(
        `${BasicLanguage.alert.userCreateFailed[language]}:` +
          result?.data?.reason
      );
    }
  };

  const getAuthUsers = useCallback(() => {
    getUserListAsAdmin({ target: "authentication" })
      .then((res) =>
        setAuthUsers(
          res?.data?.user
            ? res.data.user.filter((user) => user.disabled !== true)
            : []
        )
      )
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    getAuthUsers();
  }, [getAuthUsers]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" className="subTitle">
          {BasicLanguage.admin.users.auth.title[language]}
        </Typography>
        <Button onClick={() => setModal(true)}>
          {BasicLanguage.admin.common.add[language]}
        </Button>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={"20%"}>ID</TableCell>
            <TableCell width={"15%"}>
              {BasicLanguage.admin.users.common.name[language]}
            </TableCell>
            <TableCell width={"25%"}>
              {BasicLanguage.admin.users.common.email[language]}
            </TableCell>
            <TableCell width={"7%"}>
              {BasicLanguage.admin.users.auth.password[language]}
              <br />
              {BasicLanguage.admin.users.auth.reset[language]}
            </TableCell>
            <TableCell width={"7%"}>
              {BasicLanguage.admin.users.auth.mailAuth[language]}
            </TableCell>
            <TableCell width={"7%"}>
              {BasicLanguage.admin.users.auth.admin[language]}
            </TableCell>
            <TableCell width={"12%"}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pagerAuthUsers.map((user) => {
            return (
              <UserAuth
                user={user}
                key={user.uid}
                setAddAdminConfirmModal={setAddAdminConfirmModal}
                setRemoveAdminConfirmModal={setRemoveAdminConfirmModal}
                getAuthUsers={getAuthUsers}
              />
            );
          })}
        </TableBody>
      </Table>
      <Pager
        page={page}
        setPage={setPage}
        items={authUsers}
        setPagerItems={setPagerAuthUsers}
      />

      <Modal
        open={modal}
        onClose={handleModalClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            width: "60%",
            maxWidth: "600px",
            minWidth: "400px",
            height: "60%",
            maxHeight: "600px",
            minHeight: "400px",
            overflow: "auto",
            p: 2,
          }}
        >
          <Typography>{BasicLanguage.admin.common.add[language]}</Typography>
          <form onSubmit={submit}>
            <Stack spacing={1}>
              <FormLabel>
                <Typography>
                  {BasicLanguage.admin.users.common.name[language]}
                </Typography>
                <TextField
                  fullWidth
                  value={userName}
                  onChange={onChangeUserName}
                  maxLength={20}
                  name="name"
                />
              </FormLabel>
              <FormLabel>
                <Typography>
                  {BasicLanguage.admin.users.common.email[language]}
                </Typography>
                <TextField
                  error={errorEmail}
                  fullWidth
                  type="email"
                  value={userEmail}
                  onChange={onChangeUserEmail}
                  helperText={
                    !errorEmail
                      ? null
                      : BasicLanguage.userAdd.errorEmail[language]
                  }
                  name="email"
                />
              </FormLabel>
              <Button
                type="submit"
                variant="contained"
                disabled={!userName || errorEmail || !userEmail}
              >
                {BasicLanguage.admin.common.complete[language]}
              </Button>
              <Box sx={{ mt: 2 }}>
                {createResult?.uid ? (
                  <Box>
                    {BasicLanguage.admin.users.auth.userCreated[language]}
                    <br />
                    {BasicLanguage.admin.users.common.email[language]}:{" "}
                    {createResult.email}
                    <br />
                    UID: {createResult.uid}
                    <br />
                    {BasicLanguage.admin.users.auth.password[language]}:{" "}
                    {createResult.password}
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Stack>
          </form>
        </Paper>
      </Modal>

      <DialogYesNo
        open={[addAdminConfirmModal, setAddAdminConfirmModal]}
        yesAction={() => {
          adminUserAddAdmin(addAdminConfirmModal)
            .then((x) => {
              if (x.data.status === "success") {
                setAddAdminConfirmModal(false);
                getAuthUsers();
              } else {
                setAddAdminConfirmModal(false);
              }
            })
            .catch((e) => {
              console.error(e);
              setAddAdminConfirmModal(false);
            });
        }}
        noAction={() => {
          setAddAdminConfirmModal(false);
        }}
        title={addAdminConfirmModal?.displayName}
        message={BasicLanguage.admin.users.auth.grantAdmin[language]}
      />

      <DialogYesNo
        open={[removeAdminConfirmModal, setRemoveAdminConfirmModal]}
        yesAction={() => {
          adminUserRemoveAdmin(removeAdminConfirmModal)
            .then((x) => {
              if (x.data.status === "success") {
                setRemoveAdminConfirmModal(false);
                getAuthUsers();
              } else {
                setRemoveAdminConfirmModal(false);
              }
            })
            .catch((e) => {
              console.error(e);
              setRemoveAdminConfirmModal(false);
            });
        }}
        noAction={() => {
          setRemoveAdminConfirmModal(false);
        }}
        title={removeAdminConfirmModal?.displayName}
        message={BasicLanguage.admin.users.auth.revokeAdmin[language]}
      />
    </>
  );
};
