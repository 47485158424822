import { useCallback, useEffect, useState } from "react";
import { Box, IconButton, Tab, Tabs } from "@mui/material";
import BasicLanguage from "../languages/Basic";
import AddRounded from "@mui/icons-material/AddRounded";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { collection, getDocs, query, where } from "firebase/firestore";
import Map from "./Map";
import { db } from "../utils/firebase";
import Loading from "./Loading";
import SBXStyles from "../utils/Styles";
import languageState from "../../recoil/atoms/languageState";
import Typography from "../parts-ui/Typography";

const ENV = process.env.REACT_APP_FIRESTORE_ENV
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION

export default function MapsDisplay(props) {
  const params = useParams();
  const facilityId = params.facilityId;
  const language = useRecoilValue(languageState);
  const [isLoading, setIsLoading] = useState(false);
  const [maps, setMaps] = useState([]);
  const [dashboardTab, setDashboardTab] = useState(0);

  const getMaps = useCallback(() => {
    setIsLoading(true);
    getDocs(collection(db, ENV, VERSION, "facilities", facilityId, "maps"))
      .then((querySnap) => {
        const collator = new Intl.Collator([], { numeric: true });
        setMaps(
          querySnap.docs
            .sort((a, b) => collator.compare(a.data().name, b.data().name))
            .map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [facilityId]);

  const [atps, setAtps] = useState([]);
  const [sensors, setSensors] = useState([]);

  const getAtps = useCallback(() => {
    const coll = collection(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "atps"
    );
    getDocs(coll)
      .then((snapShot) => {
        setAtps(
          snapShot.docs.map((doc) => ({
            id: doc.id,
            deviceType: "atp",
            type: "ATP",
            ...doc.data(),
          }))
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }, [facilityId]);

  const getSensors = useCallback(() => {
    const coll = collection(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "sensors"
    );
    getDocs(query(coll, where("active", "==", true)))
      .then((snapShot) => {
        setSensors(
          snapShot.docs.map((doc) => ({
            id: doc.id,
            deviceType: "sensor",
            value: doc.data().currentValue,
            ...doc.data(),
          }))
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }, [facilityId]);

  useEffect(() => {
    getAtps();
    getSensors();
  }, [getAtps, getSensors]);

  useEffect(() => {
    getMaps();
  }, [getMaps]);

  const tag =
    maps.length === 0 ? (
      <Box sx={{ width: "100%", height: 350, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h5">
          {BasicLanguage.maps.noMaps[language]}
        </Typography>
      </Box>
    ) : (
      <Box>
        <Tabs
          value={dashboardTab}
          onChange={(e, v) => setDashboardTab(v)}
          sx={{ mb: 1 }}
          variant="scrollable"
        >
          {maps.map((map) => (
            <Tab label={map.name} key={map.name} />
          ))}
        </Tabs>
        {maps.map((map, index) => (
          <Box hidden={dashboardTab !== index} key={map.name}>
            <Map
              type={props.type}
              map={map}
              atps={atps}
              sensors={sensors}
              getAtps={getAtps}
              getSensors={getSensors}
            />
          </Box>
        ))}
      </Box>
    );

  return (
    <>
      <Box component="main" sx={SBXStyles.mainContainer}>
        {props.type !== "editor" && (
          <Typography variant='h5'>
            {BasicLanguage.common.menu.maps[language]}
          </Typography>
        )}
        {props.type === "editor" && (
          <Typography className="pageTitle" variant="h4">
            {BasicLanguage.common.menu.maps[language]}
            <IconButton LinkComponent={Link} to="./add">
              <AddRounded />
            </IconButton>
          </Typography>
        )}        
        {props.type === "editor" && (
          <Typography sx={{ mt: 2, mb: 2 }}>
            {BasicLanguage.maps.description[language]}
          </Typography>
        )}
        <Box>{isLoading ? <Loading /> : tag}</Box>
      </Box>
    </>
  );
}
