import {
    Box,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import React from "react";
  import SBXStyles from "../utils/Styles";
  import BasicLanguage from "../languages/Basic";
  import { useRecoilValue } from "recoil";
  import languageState from "../../recoil/atoms/languageState";
  import { Link } from "react-router-dom";
  import RobotFloor from "./RobotFloor";
  
  export default function RobotFloors(props) {
    const language = useRecoilValue(languageState);
    const robot = props.robot;
  
    return (
      <Box sx={SBXStyles.mainBox}>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography variant="h5">
              {BasicLanguage.robots.detail.floors.title[language]}
            </Typography>
          </Box>
          <Button component={Link} to={"./floors"}>
            {BasicLanguage.common.add[language]}
          </Button>
        </Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "15%" }}>
                {BasicLanguage.robots.detail.floors.index[language]}
              </TableCell>
              <TableCell sx={{ width: "20%" }}>
                {BasicLanguage.robots.detail.floors.name[language]}
              </TableCell>
              <TableCell sx={{ width: "20%" }}>
                {BasicLanguage.robots.detail.floors.mapId[language]}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {robot.floors &&
              Object.keys(robot.floors).map((floorId) => {
                return (
                  <RobotFloor
                    key={floorId}
                    floor={{ id: floorId, ...robot.floors[floorId] }}
                  />
                );
              })}
          </TableBody>
        </Table>
      </Box>
    );
  }
  