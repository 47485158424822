import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SBXStyles from "../components/utils/Styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MapsForm from "../components/parts/forms/MapsForm";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../components/utils/firebase";
import Loading from "../components/parts/Loading";
import CommonError from "./CommonError";
import BasicLanguage from "../components/languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";

const ENV = process.env.REACT_APP_FIRESTORE_ENV
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION

export default function MapsEdit() {
  const params = useParams();
  const language = useRecoilValue(languageState);
  const facilityId = params.facilityId;
  const mapId = params.mapId;
  const navigate = useNavigate();

  const [map, setMap] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "maps", mapId))
      .then((doc) => {
        if (!doc.exists()) {
          return navigate("../maps");
        }
        setMap({ ...doc.data(), id: doc.id });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [facilityId, mapId, navigate]);

  const mapBox = map ? <MapsForm map={map} /> : <CommonError />;

  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      <Typography variant="h4">
        <IconButton LinkComponent={Link} to={"/" + params.facilityId + "/maps"}>
          <ArrowBackIcon />
        </IconButton>
        {BasicLanguage.mapsEdit.title[language]}
      </Typography>
      <Box sx={SBXStyles.mainBox}>{isLoading ? <Loading /> : mapBox}</Box>
    </Box>
  );
}
