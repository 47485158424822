import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SBXStyles from "../../utils/Styles";
import BasicLanguage from "../../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import { AddRounded } from "@mui/icons-material";
import { DialogYesNo } from "../Dialog";

export default function RequestLayout({
  title,
  requests,
  addRequest,
  deleteRequest,
}) {
  const language = useRecoilValue(languageState);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newRequest, setNewRequest] = useState("");

  const handleClose = () => {
    setAddModalOpen(false);
    setNewRequest("");
  };

  return (
    <Box sx={SBXStyles.mainBox}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {title}
        <IconButton onClick={() => setAddModalOpen(true)}>
          <AddRounded />
        </IconButton>
      </Typography>
      <Table>
        {requests.map((request) => (
          <Request
            request={request}
            deleteRequest={deleteRequest}
            key={request.id}
          />
        ))}
      </Table>
      <Dialog
        open={addModalOpen}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            if (!newRequest || newRequest === "") {
              return;
            }
            addRequest(newRequest)
              .then(() => {
                handleClose();
                alert(BasicLanguage.alert.added[language]);
              })
              .catch((e) => {
                console.alert(e);
                alert(BasicLanguage.alert.error.default[language]);
              });
          },
        }}
      >
        <DialogTitle>
          {BasicLanguage.facilitySettings.requests.new[language]}
        </DialogTitle>
        <TextField
          autoFocus
          fullWidth
          sx={{
            px: 2,
          }}
          onChange={(e) => setNewRequest(e.target.value)}
        />
        <DialogActions sx={{ my: 1, mx: 1 }}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="error"
            fullWidth
          >
            {BasicLanguage.common.cancel[language]}
          </Button>
          <Button type="submit" variant="contained" fullWidth>
            {BasicLanguage.common.add[language]}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

RequestLayout.propTypes = {
  title: PropTypes.string,
  requests: PropTypes.array,
  addRequest: PropTypes.func,
  getRequests: PropTypes.func,
};

function Request({ request, deleteRequest }) {
  const language = useRecoilValue(languageState);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDelete = () => {
    setDeleteModalOpen(false);
    deleteRequest(request);
  };

  return (
    <>
      <TableRow>
        <TableCell width={"90%"}>{request.name}</TableCell>
        <TableCell>
          <Button
            color="error"
            variant="outlined"
            onClick={() => setDeleteModalOpen(true)}
          >
            {BasicLanguage.common.delete[language]}
          </Button>
        </TableCell>
      </TableRow>
      <DialogYesNo
        open={[deleteModalOpen, setDeleteModalOpen]}
        yesAction={handleDelete}
        noAction={() => setDeleteModalOpen(false)}
        title={request.name}
        message={BasicLanguage.facilitySettings.requests.delete[language]}
      />
    </>
  );
}

RequestLayout.propTypes = {
  request: PropTypes.object,
  deleteRequest: PropTypes.func,
};
