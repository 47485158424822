import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import BasicLanguage from "../../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import dayjs from "dayjs";

const PHANTAS_CLEANING_MODES = ["清洗", "清扫", "尘推", "吸尘"];

export default function QrSensorRobotInfo(props) {
  const robotGroups = props.robotGroups;
  const robots = props.robots;
  const robotAreas = props.robotAreas;
  const language = useRecoilValue(languageState);
  const getAreaRestrictedTime = (startTime, endTime) => {
    if (
      startTime !== undefined &&
      startTime !== null &&
      endTime !== undefined &&
      endTime !== null
    ) {
      return (
        <Box>
          {dayjs()
            .hour(startTime)
            .format(BasicLanguage.robotFloorArea.hour[language])}
          ～
          {dayjs()
            .hour(endTime)
            .format(BasicLanguage.robotFloorArea.hour[language])}
        </Box>
      );
    }
  };

  const getCleaningModeArray = (robot) => {
    return robot.type === "Scrubber 50"
      ? Object.keys(robot.cleaningModes)
      : PHANTAS_CLEANING_MODES;
  };

  return Object.keys(robotGroups).sort((a, b) => {
    const robotIds = robotAreas.map(area => area.robotId);
    return robotIds.indexOf(a) - robotIds.indexOf(b);
  }).map((robotId) => {
    const robotGroup = robotGroups[robotId];
    const robot = robots.find((robot) => robot.id === robotId);
    return (
      <Box key={robotId}>
        <Typography sx={{ my: 2 }} variant="h4">
          {robot.name || BasicLanguage.robots.undefined[language]}
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              {robot.cleaningModes &&
                getCleaningModeArray(robot).sort((a, b) => a.localeCompare(b)).map((mode) => {
                  return (
                    <TableCell key={mode}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            textAlign: "top",
                          }}
                        >
                          {robot.type === "Phantas"
                            ? BasicLanguage.robots.detail.cleaningModes.mode[
                                mode
                              ][language]
                            : mode}
                        </Typography>
                        <Typography>
                          {robot.cleaningModes &&
                            robot.cleaningModes[mode] &&
                            robot.cleaningModes[mode]
                              .map(
                                (day) =>
                                  BasicLanguage.robots.detail.cleaningModes.day
                                    .long[day][language]
                              )
                              .join("、")}
                        </Typography>
                      </Box>
                    </TableCell>
                  );
                })}
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <Typography variant="h5">
                  {BasicLanguage.robots.detail.floors.areas[language]}
                </Typography>
              </TableCell>
              <TableCell colSpan={robot.cleaningModes ? getCleaningModeArray(robot)?.length - 1 : 2}>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {BasicLanguage.robotFloor.name[language]}
                        </TableCell>
                        <TableCell>
                          {BasicLanguage.robotFloor.restrictedTime[language]}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {robotGroup.map((ids) => {
                        const area =
                          robot.floors[ids.floorId].areas[ids.areaId];
                        return (
                          <TableRow
                            key={`${ids.robotId}_${ids.floorId}_${ids.areaId}`}
                          >
                            <TableCell>{area.name}</TableCell>
                            <TableCell>
                              {getAreaRestrictedTime(
                                area.restrictedStartTime,
                                area.restrictedEndTime
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    );
  });
}
