import { useCallback, useEffect, useRef, useState } from "react";
import React from "react";
import chroma from "chroma-js";
import { Divider } from "@mui/material";
import { useParams } from "react-router";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Stack, Typography } from "@mui/material";
import BasicLanguage from "../../../components/languages/Basic";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import * as d3 from "d3";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";

const getColor = (i) => {
  let code = "";
  const colours = chroma
    .scale(["#FF2d2d", "#FDE415", "#37C000", "#50AEEA"])
    .domain([1, 0]);
  code = colours(i).hex();
  return code;
};

const generateArc = (
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  cornerRadius
) => {
  return d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(startAngle)
    .endAngle(endAngle)
    .cornerRadius(cornerRadius);
};
const toRad = (deg) => {
  return (deg * Math.PI) / 180;
};

export const SbxPieChart = (props) => {
  const ref = useRef();

  const progress = props.progress > 0.02 ? props.progress : 0.02;

  const innerRadius = props.innerRadius ? props.innerRadius : 10;
  const outerRadius = props.outerRadius ? props.outerRadius : 50;
  const startAngle = props.startAngle ? props.startAngle : 0;
  const endAngle = props.endAngle ? props.endAngle : 360;
  const cornerRadius = props.cornerRadius ? props.cornerRadius : 10;
  const animDuration = props.animDuration ? props.animDuration : 1000;
  const animDelay = props.animDelay ? props.animDelay : 500;
  const animStartAngle = props.animStartAngle
    ? props.animStartAngle
    : startAngle;

  const rotate = props.rotate ? props.rotate : 0;

  const colour = props.colour;

  useEffect(() => {
    const radEnd = (progress * endAngle * Math.PI) / 180;
    const interpolate = d3.interpolate(toRad(animStartAngle), radEnd);
    const arc0 = generateArc(
      innerRadius,
      outerRadius,
      toRad(startAngle),
      toRad(startAngle),
      cornerRadius
    );
    const arc = generateArc(
      innerRadius,
      outerRadius,
      toRad(startAngle),
      toRad(endAngle),
      cornerRadius
    );

    const svg = d3.select(ref.current);
    svg.selectAll("path").remove();
    svg
      .append("path")
      .attr("transform", "translate(75,75)")
      .attr("fill", "#EEEEEE")
      .attr(
        "d",
        generateArc(
          innerRadius,
          outerRadius,
          toRad(startAngle),
          toRad(endAngle),
          cornerRadius
        )
      )
      .attr(
        "style",
        `filter: drop-shadow(1px 1px 2px #bebebe) drop-shadow(-1px -1px 2px #ffffff)`
      );

    svg
      .append("path")
      .attr("transform", "translate(75,75)")
      .attr("fill", colour ? colour : getColor(progress))
      .attr("d", arc0)
      .transition()
      .duration(animDuration)
      .delay(animDelay)
      .ease(d3.easeBounce)
      .attrTween("d", function (d, i) {
        return function (t) {
          arc.endAngle(interpolate(t));
          return arc();
        };
      });
  }, [
    animDuration,
    animStartAngle,
    colour,
    cornerRadius,
    endAngle,
    innerRadius,
    outerRadius,
    progress,
    startAngle,
    animDelay,
  ]);

  return (
    <>
      <svg
        ref={ref}
        viewBox="0 0 150 150"
        style={{ transform: `rotate(${rotate}deg)` }}
      />
    </>
  );
};

export default function Co2Chart({ thickness }) {
  const params = useParams();
  const facilityId = params.facilityId;
  const language = useRecoilValue(languageState);
  const [co2, setCo2] = useState(0);
  const [co2ConcentrationDifference, setCo2ConcentrationDifference] =
    useState(0);
  const fetchCo2Data = useCallback(async () => {
    const docRef = doc(
      db,
      "test",
      "data",
      "facilities",
      facilityId,
      "co2",
      "data"
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const currentCo2 = docSnap.data().history[0]?.co2;
      const currentMeters = docSnap.data().history[0]?.meters;
      const history = docSnap.data().history;
      const pastCo2 = history.length > 1 ? history[1].co2 : 0;
      const pastMeters = history.length > 1 ? history[1].meters : 0;
      const currentConcentration = currentMeters
        ? currentCo2 / currentMeters
        : 0;
      const pastConcentration = pastMeters ? pastCo2 / pastMeters : 0;
      const difference = currentConcentration - pastConcentration;

      const formattedDifference = difference.toFixed(1);
      setCo2(currentConcentration);
      setCo2ConcentrationDifference(formattedDifference);
    } else {
      console.error("No such document!");
    }
  }, [facilityId]);

  useEffect(() => {
    fetchCo2Data();
  }, [fetchCo2Data]);

  return (
    <Stack
      sx={{ width: "100%", maxWidth: "400px" }}
      justifyContent="center"
      alignItems="top"
      alignContent={"center"}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "1.2em",
        }}
      >
        CO<sub>2</sub>
      </Typography>
      <Typography sx={{ textAlign: "center" }}>
        1m<sup>2</sup>
        {BasicLanguage.facilitySettings.CO2Dashboard.subtitle[language]}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ position: "relative" }}>
        <Stack
          spacing={0}
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: 100,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack direction="row" alignItems="flex-end" spacing={1}>
            <Typography sx={{ fontSize: "2em", fontWeight: 700 }}>
              {parseFloat(co2).toFixed(1)}
            </Typography>
            <Typography sx={{ fontSize: "1.3em", fontWeight: 700 }}>
              kg
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={1}
            sx={{ color: "#888" }}
          >
            {co2ConcentrationDifference < 0 ? (
              <ArrowDownwardIcon />
            ) : (
              <ArrowUpwardIcon />
            )}
            <Typography>{co2ConcentrationDifference}</Typography>
          </Stack>
        </Stack>
        <Box sx={{ width: "100%", position: "relative" }}>
          <SbxPieChart
            progress={co2 / 100}
            innerRadius={50 - thickness * 0.5}
            outerRadius={50}
            startAngle={0}
            endAngle={-360}
            cornerRadius={4}
            animDelay={100}
            animDuration={600}
            rotate={0}
          />
        </Box>
      </Box>
    </Stack>
  );
}
