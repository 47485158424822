import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import React, { useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import FloorAreas from "./FloorAreas";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";

RobotFloor.propTypes = {
  floor: PropTypes.object,
};

const duration = 500;

const defaultStyle = {
  display: "none",
};

const transitionStyles = {
  entering: { display: "table-row" },
  entered: { display: "table-row" },
  exiting: { display: "none" },
  exited: { display: "none" },
};

export default function RobotFloor(props) {
  const [expanded, setExpanded] = useState(false);
  const floor = props.floor;
  const nodeRef = useRef(null);

  return (
    <>
      <TableRow key={floor.id}>
        <TableCell>{floor.id}</TableCell>
        <TableCell>{floor.name}</TableCell>
        <TableCell>{floor.mapId}</TableCell>
        <TableCell sx={{ textAlign: "end" }}>
          <Box>
            {floor.areas && (Object.keys(floor.areas).length > 0 &&
              (expanded ? (
                <IconButton onClick={() => setExpanded(false)}>
                  <KeyboardArrowUpIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setExpanded(true)}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              )))}
            <IconButton LinkComponent={Link} to={`./floors/${floor.id}`}>
              <EditIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {floor.areas && (Object.keys(floor.areas).length > 0 && (
        <Transition nodeRef={nodeRef} in={expanded} timeout={duration}>
          {(state) => (
            <TableRow
              ref={nodeRef}
              style={{ ...defaultStyle, ...transitionStyles[state] }}
            >
              <TableCell colSpan={4}>
                <FloorAreas floorId={floor.id} floorAreas={floor.areas} />
              </TableCell>
            </TableRow>
          )}
        </Transition>
      ))}
    </>
  );
}
