import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { SbxPieChart, SbxPieChartMulti } from "../SbxChart";
import PropTypes from "prop-types";
import BasicLanguage from "../../languages/Basic";
import languageState from "../../../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase";

const COLORS = ["#50AEEA", "#37C000", "#FDD405", "#FF2d2d"];
const CLEANLINESS_LEVELS = ["veryClean", "clean", "dirty", "veryDirty"];
const CLEANLINESS_RATINGS = ["good", "recommenders", "neutrals", "critics"];
const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function FacilityCleanlinessChart({ mode, thickness }) {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;

  const [atpCleanliness, setAtpCleanliness] = React.useState([0, 0, 0, 0]);
  const atpTotal = atpCleanliness.reduce((sum, element) => sum + element, 0);

  const getAtpCleanliness = useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "atpHistory"),
        where("showDashboard", "==", true)
      )
    )
      .then((atpDocs) => {
        const atpArray = [0, 0, 0, 0];
        atpDocs.forEach((atpDoc) => {
          const value = parseInt(atpDoc.data().value);
          if (value > 17000) {
            atpArray[3]++;
          } else if (value > 13000) {
            atpArray[2]++;
          } else if (value > 3000) {
            atpArray[1]++;
          } else if (value >= 0) {
            atpArray[0]++;
          }
          setAtpCleanliness(atpArray);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [facilityId]);

  useEffect(() => {
    getAtpCleanliness();
  }, [getAtpCleanliness]);

  return (
    <Stack
      sx={{ width: "100%", maxWidth: "400px" }}
      justifyContent=""
      alignItems="top"
    >
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "1.2em",
        }}
      >
        {BasicLanguage.dashboard.kpi.facilityCleanliness.title[language]}
      </Typography>
      <Typography sx={{ textAlign: "center" }}>
        {BasicLanguage.dashboard.kpi.facilityCleanliness.description[language]}
      </Typography>

      <Divider sx={{ my: 2 }} />
      <Box sx={{ position: "relative" }}>
        {mode ? (
          <>
            <Stack
              sx={{
                position: "absolute",
                height: "100%",
                width: "100%",
                zIndex: 100,
              }}
              justifyContent="center"
              alignItems="center"
            >
              <Stack spacing={1}>
                {atpCleanliness.map((atpCount, index) => {
                  return (
                    <Stack
                      key={CLEANLINESS_RATINGS[index]}
                      direction="row"
                      sx={{ color: COLORS[index] }}
                      alignItems="flex-end"
                    >
                      <Typography
                        sx={{
                          lineHeight: "1.1em",
                          fontSize: "1em",
                          fontWeight: 500,
                        }}
                      >
                        {
                          BasicLanguage.dashboard.kpi.facilityCleanliness
                            .ratings[CLEANLINESS_RATINGS[index]][language]
                        }
                        :
                      </Typography>
                      <Typography
                        sx={{
                          lineHeight: "1.1em",
                          fontSize: "1.4em",
                          fontWeight: 500,
                        }}
                      >
                        {atpCount}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
            <Box sx={{ width: "100%", position: "relative" }}>
              <SbxPieChartMulti
                data={atpCleanliness}
                innerRadius={50 - thickness * 0.5}
                outerRadius={50}
                startAngle={0}
                endAngle={360}
                cornerRadius={10}
                animDelay={100}
                animDuration={600}
                rotate={0}
                colors={COLORS}
              />
            </Box>
          </>
        ) : (
          <Stack spacing={1}>
            {atpCleanliness.map((atpCount, index) => {
              return (
                <CleanessBlock
                  key={CLEANLINESS_LEVELS[index]}
                  step={index}
                  atpCount={atpCount}
                  atpTotal={atpTotal}
                  thickness={thickness}
                  label={
                    BasicLanguage.dashboard.kpi.facilityCleanliness.levels[
                      CLEANLINESS_LEVELS[index]
                    ][language]
                  }
                />
              );
            })}
          </Stack>
        )}
      </Box>
    </Stack>
  );
}

FacilityCleanlinessChart.propTypes = {
  mode: PropTypes.bool,
  thickness: PropTypes.number,
};

const CleanessBlock = ({ thickness, step, label, atpCount, atpTotal }) => {
  const langauge = useRecoilValue(languageState);
  const percentage = atpTotal > 0 ? atpCount / atpTotal : 0;
  const place = BasicLanguage.dashboard.kpi.facilityCleanliness.place[langauge];

  const color = COLORS[step];

  const bgColor = ["#f0f0f8", "#f0f8f3", "#f8f8f3", "#f8f0f3"];

  const innerRadius = 50 - thickness * 0.7;
  const outerRadius = 50;
  const startAngle = 0;
  const endAngle = 360;
  const cornerRadius = 6;
  const animDelay = 100;
  const animDuration = 600;
  const rotate = 0;

  const size = 4;

  return (
    <Box
      sx={{
        borderRadius: 5,
        background: bgColor[step],
        boxShadow: `inset ${size}px ${size}px ${size * 2}px #e0e0e3,
            inset -${size}px -${size}px ${size * 2}px #ffffff`,
        border: "1px solid #efefef",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            position: "relative",
            width: "23%",
            "&:before": {
              content: `""`,
              display: "block",
              paddingTop: "100%",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <SbxPieChart
              progress={percentage}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              cornerRadius={cornerRadius}
              animDelay={animDelay}
              animDuration={animDuration}
              rotate={rotate}
              colour={color}
            />
          </Box>
        </Box>
        <Stack sx={{ color: color }} justifyContent="center">
          <Typography
            sx={{ fontSize: "0.8em", fontWeight: 700, lineHeight: 1 }}
          >
            {label}
          </Typography>
          <Typography
            sx={{ fontSize: "1.8em", fontWeight: 200, lineHeight: 1 }}
          >
            {Math.round(percentage * 100)}%
          </Typography>
        </Stack>
        <Stack sx={{ width: "40%" }} justifyContent="center">
          <Typography sx={{ fontSize: "0.8em", textAlign: "right", pr: 3 }}>
            {`${atpCount}${place}/${atpTotal}${place}`}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

CleanessBlock.propTypes = {
  thickness: PropTypes.number,
  step: PropTypes.number,
  label: PropTypes.string,
  atpCount: PropTypes.number,
  atpTotal: PropTypes.number,
};
