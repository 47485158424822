import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import moment from "moment-timezone";
import { Button } from "@mui/material";
import { useRecoilValue } from "recoil";
import styled from "@emotion/styled";
import { doc, serverTimestamp, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import languageState from "../../recoil/atoms/languageState";
import BasicLanguage from "../languages/Basic";
import Typography from "../parts-ui/Typography";
import Loading from "./Loading";
import SBXStyles from "../utils/Styles";
import { TimeZoneContext } from "../utils/setTimeZoneContext";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    background: "linear-gradient(145deg, rgb(244,244,248), rgb(251,251,253))",
    py: "3rem",
    px: 2,
    mx: "auto",
    mb: "40px",
    borderRadius: "40px",
    minWidth: "320px",
    maxWidth: "600px",
  })
);

const TimeZoneFacility = () => {
  const { facilityId } = useParams();
  const language = useRecoilValue(languageState);
  const australiaTimeZones = moment.tz
    .names()
    .filter((tz) =>
      Object.values(
        BasicLanguage.timezoneCollection.languageStrings.australiaCitiesFilter
      ).some((city) => tz.startsWith(city))
    );
  const EuropeTimeZones = moment.tz
    .names()
    .filter((tz) =>
      Object.values(
        BasicLanguage.timezoneCollection.languageStrings.europeCitiesFilter
      ).some((city) => tz.startsWith(city))
    );
  const [australiaCities, setAustraliaCities] = useState([]);
  const [europeCities, setEuropeCities] = useState([]);
  const [timeZoneName, setTimeZoneName] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [savedTimeZoneName, setSavedTimeZoneName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { setCurrentTimeZoneName } = useContext(TimeZoneContext);

  const getCurrentTime = useCallback(
    (timeZone) => {
      moment.locale(language);
      return moment()
        .tz(timeZone)
        .format(BasicLanguage.timezoneCollection.format[language]);
    },
    [language]
  );

  const getCityName = useCallback(
    (city) => {
      const cityName = city.includes("/") ? city.split("/")[1] : city;
      return (
        BasicLanguage.timezoneCollection.languageStrings.cityNames[cityName]?.[
          language
        ] || cityName
      );
    },
    [language]
  );

  const fetchFacilityData = useCallback(async () => {
    const facilityRef = doc(db, ENV, VERSION, "facilities", facilityId);
    const facilitySnapshot = await getDoc(facilityRef);
    const data = facilitySnapshot.data();
    if (data && data.timeZoneName) {
      const timeZone = data.timeZoneName;
      setSavedTimeZoneName(timeZone);
      setCurrentTime(getCurrentTime(timeZone));
      setTimeZoneName(timeZone);
    }
    setIsLoading(false);
  }, [facilityId, getCurrentTime]);

  useEffect(() => {
    fetchFacilityData();
  }, []);

  const generateAustraliaCities = useCallback(() => {
    return australiaTimeZones.map((city) => {
      const cityName = city.split("/")[1];
      return {
        en: `Australia/${cityName}`,
        ja: `オーストラリア/${
          BasicLanguage.timezoneCollection.languageStrings.cityNames[
            cityName
          ]?.[language] || cityName
        }`,
      };
    });
  }, [language]);

  const generateEuropeanCities = useCallback(() => {
    return EuropeTimeZones.map((city) => {
      const cityName = city.split("/")[1];
      return {
        en: `Europe/${cityName}`,
        ja: `ヨーロッパ/${
          BasicLanguage.timezoneCollection.languageStrings.cityNames[
            cityName
          ]?.[language] || cityName
        }`,
      };
    });
  }, [language]);

  useEffect(() => {
    setEuropeCities(generateEuropeanCities());
    setAustraliaCities(generateAustraliaCities());
  }, [generateAustraliaCities, generateEuropeanCities]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeZoneName) {
        setCurrentTime(getCurrentTime(timeZoneName));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timeZoneName, getCurrentTime]);

  const handleSave = async () => {
    if (timeZoneName !== savedTimeZoneName) {
      const facilityRef = doc(db, ENV, VERSION, "facilities", facilityId);
      try {
        await updateDoc(facilityRef, {
          timeZoneName,
          timeZone: serverTimestamp(),
        });
        setSavedTimeZoneName(timeZoneName);
        setCurrentTimeZoneName(timeZoneName);
        alert(BasicLanguage.timezoneCollection.alerts[language]);
      } catch (error) {
        console.error(error);
        alert(BasicLanguage.alert.resetAllSensor.Fail[language], error);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Loading />
        </Container>
      ) : (
        <Box sx={SBXStyles.mainBox}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {BasicLanguage.timezoneCollection.timezoneHeading[language]}
          </Typography>
          <FormControl fullWidth>
            <InputLabel size="small">
              {BasicLanguage.timezoneCollection.labels.RegionSelect[language]}
            </InputLabel>
            <Select
              size="small"
              value={timeZoneName}
              label={BasicLanguage.timezoneCollection.labels.select[language]}
              onChange={(e) => {
                setTimeZoneName(e.target.value);
                setCurrentTime(getCurrentTime(e.target.value));
              }}
              sx={{ width: "50%" }}
            >
              {australiaCities.map((city, index) => (
                <MenuItem key={index} value={city.en}>
                  {city[language]}
                </MenuItem>
              ))}
              <MenuItem value="NZ">
                {BasicLanguage.timezoneCollection.MenuItem.NZ[language]}
              </MenuItem>

              <MenuItem
                value={BasicLanguage.timezoneCollection.MenuItem.singapore.en}
              >
                {
                  BasicLanguage.timezoneCollection.languageStrings.cityNames
                    .Singapore[language]
                }
              </MenuItem>

              <MenuItem
                value={BasicLanguage.timezoneCollection.MenuItem.Seoul.en}
              >
                {
                  BasicLanguage.timezoneCollection.languageStrings.cityNames
                    .Seoul[language]
                }
              </MenuItem>
              <MenuItem
                value={BasicLanguage.timezoneCollection.MenuItem.japan.en}
              >
                {
                  BasicLanguage.timezoneCollection.languageStrings.cityNames
                    .Tokyo[language]
                }
              </MenuItem>
              <MenuItem
                value={
                  BasicLanguage.timezoneCollection.MenuItem.Johannesburg.en
                }
              >
                {
                  BasicLanguage.timezoneCollection.languageStrings.cityNames
                    .Johannesburg[language]
                }
              </MenuItem>
              <MenuItem
                value={BasicLanguage.timezoneCollection.MenuItem.Dubai.en}
              >
                {
                  BasicLanguage.timezoneCollection.languageStrings.cityNames
                    .Dubai[language]
                }
              </MenuItem>
              <MenuItem
                value={BasicLanguage.timezoneCollection.MenuItem.Riyadh.en}
              >
                {
                  BasicLanguage.timezoneCollection.languageStrings.cityNames
                    .Riyadh[language]
                }
              </MenuItem>

              {europeCities.map((city, index) => (
                <MenuItem key={index} value={city.en}>
                  {city[language]}
                </MenuItem>
              ))}
            </Select>

            <Button
              disabled={!timeZoneName || timeZoneName === savedTimeZoneName}
              variant="contained"
              size="small"
              sx={{ mt: "10px", width: "10%" }}
              onClick={handleSave}
            >
              {BasicLanguage.admin.common.save[language]}
            </Button>
          </FormControl>

          {savedTimeZoneName && (
            <Typography className="pageDescription">
              {BasicLanguage.timezoneCollection.labels.RegionSelect[language]}:{" "}
              {getCityName(savedTimeZoneName)}
            </Typography>
          )}
          {currentTime && (
            <Typography className="pageDescription">
              {BasicLanguage.timezoneCollection.labels.CurrentTime[language]}{" "}
              {currentTime}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
export default TimeZoneFacility;
