import * as React from "react";
import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SBXStyles from "../components/utils/Styles";
import { useNavigate, useParams } from "react-router";
import CommonError from "./CommonError";
import { Link } from "react-router-dom";
import Typography from "../components/parts-ui/Typography";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import { db } from "../components/utils/firebase";
import languageState from "../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";
import triggerComparison from "../components/utils/triggerComparison";
import Loading from "../components/parts/Loading";
import QrSensorRobotInfo from "../components/parts/forms/QrSensorRobotInfo";
import QrSensorRobotForm from "../components/parts/forms/QrSensorRobotForm";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function SensorTriggerAdd() {
  const params = useParams();
  const navigate = useNavigate();
  const facilityId = params.facilityId;
  const sensorId = params.sensorId;

  const [isLoading, setIsLoading] = React.useState(true);
  const [sensor, setSensor] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const [robots, setRobots] = React.useState([]);
  const [address, setAddress] = React.useState([]);
  const [selectedAreas, setSelectedAreas] = React.useState([]);
  const [cleaningMode, setCleaningMode] = React.useState("");
  const [robotAreas, setRobotAreas] = React.useState([]);
  const [threshold, setThreshold] = React.useState(null);
  const [comparison, setcomparison] = React.useState();

  const language = useRecoilValue(languageState);

  const getSensorData = React.useCallback(() => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "sensors", sensorId))
      .then((sensorDocSnap) =>
        setSensor({ ...sensorDocSnap.data(), id: sensorDocSnap.id })
      )
      .catch((e) => console.error(e));
  }, [facilityId, sensorId]);

  const getUserData = React.useCallback(() => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
      .then((res) => {
        const data = res.data();
        let adminAndUsers = Object.keys(data.users ? data.users : {})
          .map((uid) => data.users[uid])
          .concat(
            Object.keys(data.admin ? data.admin : {}).map((y) => data.admin[y])
          )
          .filter((user) => !user.isDeleted);
        setUsers(adminAndUsers);
      })
      .catch((e) => console.error(e));
  }, [facilityId]);

  const getRobotAreas = React.useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "robots"),
        where("active", "==", true)
      )
    )
      .then((docsnap) => {
        if (docsnap.empty) {
          return;
        }
        setRobots(
          docsnap.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        );
        let robotFloorAreas = [];
        docsnap.docs.forEach((doc) => {
          const floors = doc.data().floors;
          if (!floors) {
            return;
          }
          const robotId = doc.id;
          const robotName = doc.data().name;
          const floorIds = Object.keys(floors);
          const type = doc.data().type;
          floorIds.forEach((floorId) => {
            const areas = floors[floorId].areas;
            if (!areas) {
              return;
            }
            Object.keys(areas).forEach((areaId) => {
              const areaObject = {
                robotId: robotId,
                robotName: robotName,
                floorId: floorId,
                areaId: areaId,
                areaName: areas[areaId].name,
                type: type,
              };

              robotFloorAreas = [...robotFloorAreas, areaObject];
            });
          });
        });
        setRobotAreas(robotFloorAreas);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [facilityId]);

  React.useEffect(() => {
    getSensorData();
    getUserData();
    getRobotAreas();
  }, [facilityId, getRobotAreas, getSensorData, getUserData]);

  const submit = async (e) => {
    e.preventDefault();
    const robotIds = selectedAreas.map((ids) => ids.robotId);
    if (new Set(robotIds).size !== robotIds.length) {
      alert(BasicLanguage.alert.oneRobotOnly[language]);
      return;
    }
    if (robotAreas.length === 0 && cleaningMode !== "") {
      alert(BasicLanguage.alert.cleaningModeNoRobots[language]);
      return;
    }
    try {
      await runTransaction(db, async (transaction) => {
        const active = e.target.active.checked;
        const triggerDocRef = doc(
          collection(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "sensors",
            sensorId,
            "triggers"
          )
        );

        transaction.set(triggerDocRef, {
          active: active,
          statements: {
            type: "condition",
            threshold: Number(threshold),
            comparison: comparison,
          },
          address: address.map((user) => user.uid),
          ...(robotAreas.length !== 0 && {
            cleaningMode: cleaningMode,
            robotAreas: selectedAreas.map((area) => {
              return {
                robotId: area.robotId,
                floorId: area.floorId,
                areaId: area.areaId,
                type: area.type,
              };
            }),
          }),
        });

        selectedAreas.forEach((area) => {
          transaction.update(
            doc(
              db,
              ENV,
              VERSION,
              "facilities",
              facilityId,
              "robots",
              area.robotId
            ),
            {
              [`floors.${area.floorId}.areas.${area.areaId}.triggerIds`]:
                arrayUnion(triggerDocRef.id),
            },
            {
              merge: true,
            }
          );
        });
      });
      alert(BasicLanguage.alert.added[language]);
      navigate(`/${facilityId}/sensors/${sensorId}`);
    } catch (e) {
      alert(BasicLanguage.alert.error.default[language]);
      console.error(e);
    }
  };

  const handleAddressChange = (event) => {
    const {
      target: { value },
    } = event;
    setAddress(typeof value === "string" ? value.split(",") : value);
  };

  const handleCleaningModeChange = (event) => {
    setCleaningMode(event.target.value);
  };

  const handleRobotAreaChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAreas(typeof value === "string" ? value.split(",") : value);
  };

  const robotGroups = () => {
    return selectedAreas.reduce((area, nextArea) => {
      (area[nextArea.robotId] = area[nextArea.robotId] || []).push(nextArea);
      return area;
    }, {});
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Box component="main" sx={SBXStyles.mainContainer}>
      {sensor ? (
        <Box>
          <form onSubmit={submit}>
            <Typography className="pageTitle" variant="h4">
              <IconButton
                LinkComponent={Link}
                to={"/" + facilityId + "/sensors/" + sensorId}
              >
                <ArrowBackIcon />
              </IconButton>
              {sensor.name}({sensor.id})
            </Typography>
            <Box sx={SBXStyles.mainBox}>
              <Typography variant="h5">
                {BasicLanguage.sensor.triggers.triggers[language]}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "30%" }}></TableCell>
                    <TableCell sx={{ width: "70%" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.common.active[language]}
                    </TableCell>
                    <TableCell>
                      <Switch name="active" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.sensor.trigger.threshold[language]}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <TextField
                          name="threshold"
                          value={threshold}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              setThreshold("");
                            } else if (e.target.value === 0) {
                              setThreshold(0);
                            } else if (Number(e.target.value)) {
                              setThreshold(e.target.value);
                            }
                          }}
                        />

                        <Select
                          name="comparison"
                          value={comparison}
                          onChange={(e) => setcomparison(e.target.value)}
                        >
                          {Object.keys(triggerComparison).map((k) => (
                            <MenuItem key={k} value={k}>
                              {triggerComparison[k][language]}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.sensor.trigger.assignee[language]}
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          name="address"
                          value={address}
                          multiple
                          onChange={handleAddressChange}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value.id}
                                  label={value.displayName}
                                />
                              ))}
                            </Box>
                          )}
                        >
                          {users.map((user) => (
                            <MenuItem key={user.uid} value={user}>
                              {user.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  {robots.length !== 0 && (
                    <QrSensorRobotForm
                      robotAreas={robotAreas}
                      selectedAreas={selectedAreas}
                      cleaningMode={cleaningMode}
                      handleCleaningModeChange={handleCleaningModeChange}
                      handleRobotAreaChange={handleRobotAreaChange}
                      robots={robots}
                    />
                  )}
                </TableBody>
              </Table>
              {selectedAreas.length !== 0 && (
                <QrSensorRobotInfo
                  robots={robots}
                  robotGroups={robotGroups()}
                  robotAreas={selectedAreas}
                />
              )}
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 2 }}
                disabled={!threshold || address.length === 0 || !comparison}
                type="submit"
              >
                {BasicLanguage.common.create[language]}
              </Button>
            </Box>
          </form>
        </Box>
      ) : (
        <CommonError></CommonError>
      )}
    </Box>
  );
}
