import {
  Box,
  Button,
  Chip,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";

import BasicLanguage from "../components/languages/Basic";

import FormControl from "@mui/material/FormControl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import SBXStyles from "../components/utils/Styles";
import languageState from "../recoil/atoms/languageState";

import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import Typography from "../components/parts-ui/Typography";

import { db } from "../components/utils/firebase";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import { useRecoilValue } from "recoil";
import CreateTask from "../components/parts/CreateTask";
import QrSensorRobotForm from "../components/parts/forms/QrSensorRobotForm";
import QrSensorRobotInfo from "../components/parts/forms/QrSensorRobotInfo";
import Loading from "../components/parts/Loading";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const QrGenerator = () => {
  const params = useParams();
  const facilityId = params.facilityId;
  const navigate = useNavigate();

  const [requests, setRequests] = React.useState([]);
  const [requestsList, setRequestsList] = React.useState([]);
  const [fetch, setFetch] = React.useState();

  const [place, setPlace] = React.useState("");
  const [users, setUsers] = React.useState([]);
  const [address, setAddress] = React.useState([]);

  const [robots, setRobots] = React.useState([]);
  const [robotAreas, setRobotAreas] = React.useState([]);
  const [cleaningMode, setCleaningMode] = React.useState("");
  const [selectedAreas, setSelectedAreas] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const language = useRecoilValue(languageState);

  const getRobotAreas = React.useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "robots"),
        where("active", "==", true)
      )
    )
      .then((docsnap) => {
        if (docsnap.empty) {
          return;
        }
        setRobots(
          docsnap.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        );
        let robotFloorAreas = [];
        docsnap.docs.forEach((doc) => {
          const floors = doc.data().floors;
          if (!floors) {
            return;
          }
          const robotId = doc.id;
          const robotName = doc.data().name;
          const floorIds = Object.keys(floors);
          const type = doc.data().type;
          floorIds.forEach((floorId) => {
            const areas = floors[floorId].areas;
            if (!areas) {
              return;
            }
            Object.keys(areas).forEach((areaId) => {
              const areaObject = {
                robotId: robotId,
                robotName: robotName,
                floorId: floorId,
                areaId: areaId,
                areaName: areas[areaId].name,
                type: type,
              };

              robotFloorAreas = [...robotFloorAreas, areaObject];
            });
          });
        });
        setRobotAreas(robotFloorAreas);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [facilityId]);

  const robotGroups = () => {
    return selectedAreas.reduce((area, nextArea) => {
      (area[nextArea.robotId] = area[nextArea.robotId] || []).push(nextArea);
      return area;
    }, {});
  };

  const getDataUser = () => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
      .then((facilityDocSnap) => {
        const _users = []
          .concat(
            Object.keys(facilityDocSnap.data().admin).map((uid) => {
              return { ...facilityDocSnap.data().admin[uid], role: 0 };
            })
          )
          .concat(
            Object.keys(facilityDocSnap.data().users).map((uid) => {
              return { ...facilityDocSnap.data().users[uid], role: 1 };
            })
          )
          .filter((user) => !user.isDeleted);
        setUsers(_users);
      })
      .catch((e) => console.error(e));
  };
  const getDataTask = () => {
    getDocs(
      query(collection(db, ENV, VERSION, "facilities", facilityId, "qrRequest"))
    )
      .then((querysnapshot) =>
        setRequests(
          querysnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
        )
      )
      .catch((e) => console.error(e));
  };

  React.useEffect(() => {
    getDataUser();
    getDataTask();
    getRobotAreas();
  }, [fetch, getRobotAreas]);

  const handleChangeRequests = (event) => {
    const {
      target: { value },
    } = event;
    setRequestsList(typeof value === "string" ? value.split(",") : value);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAddress(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleCleaningModeChange = (event) => {
    setCleaningMode(event.target.value);
  };

  const handleRobotAreaChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAreas(typeof value === "string" ? value.split(",") : value);
  };

  const submit = async (e) => {
    e.preventDefault();

    const robotIds = selectedAreas.map((ids) => ids.robotId);
    if (new Set(robotIds).size !== robotIds.length) {
      alert(BasicLanguage.alert.oneRobotOnly[language]);
      return;
    }

    const uuid = uuidv4();
    const data = {
      active: true,
      assignee: address.map((x) => x.uid),
      requestsList: requestsList.map((x) => x.name),
      place: place,
      id: uuid,
      ...(robots.length !== 0 && {
        cleaningMode: cleaningMode,
        robotAreas: selectedAreas.map((area) => {
          return {
            robotId: area.robotId,
            floorId: area.floorId,
            areaId: area.areaId,
            type: area.type,
          };
        }),
      }),
    };

    try {
      await runTransaction(db, async (transaction) => {
        const qrDocRef = doc(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "qr",
          uuid
        );

        transaction.set(qrDocRef, data);

        selectedAreas.forEach((area) => {
          transaction.update(
            doc(
              db,
              ENV,
              VERSION,
              "facilities",
              facilityId,
              "robots",
              area.robotId
            ),
            {
              [`floors.${area.floorId}.areas.${area.areaId}.qrIds`]: arrayUnion(
                qrDocRef.id
              ),
            },
            {
              merge: true,
            }
          );
        });
      });
      alert(BasicLanguage.alert.added[language]);
      navigate("../qr");
    } catch (e) {
      alert(BasicLanguage.alert.error.default[language]);
    }
  };

  const canSubmit = () => {
    if (address.length === 0) {
      return false;
    }

    if (!place) {
      return false;
    }

    if (requestsList.length === 0) {
      return cleaningMode && selectedAreas.length !== 0;
    }

    return true;
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "30%" }}></TableCell>
            <TableCell sx={{ width: "70%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{BasicLanguage.qr.address[language]}</TableCell>
            <TableCell>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Select
                  name="address"
                  value={address}
                  multiple
                  onChange={handleChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value, index) => (
                        <Chip key={index} label={value.displayName} />
                      ))}
                    </Box>
                  )}
                >
                  {users.map((user, index) => (
                    <MenuItem key={index} value={user}>
                      {user.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{BasicLanguage.qr.request[language]}</TableCell>
            <TableCell>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Stack direction="row" spacing={1}>
                  <Select
                    sx={{ flexGrow: 1 }}
                    name="requestsList"
                    value={requestsList}
                    multiple
                    onChange={handleChangeRequests}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value, index) => (
                          <Chip key={index} label={value.name} />
                        ))}
                      </Box>
                    )}
                  >
                    {requests.map((x, index) => (
                      <MenuItem key={index} value={x}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <CreateTask
                    existingTasks={requests}
                    taskType="qrRequest"
                    fetch={setFetch}
                  />
                </Stack>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{BasicLanguage.qr.place[language]}</TableCell>
            <TableCell>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ m: 0 }}
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                />
              </FormControl>
            </TableCell>
          </TableRow>
          {robots.length !== 0 && (
            <QrSensorRobotForm
              robotAreas={robotAreas}
              selectedAreas={selectedAreas}
              cleaningMode={cleaningMode}
              handleCleaningModeChange={handleCleaningModeChange}
              handleRobotAreaChange={handleRobotAreaChange}
              robots={robots}
            />
          )}
        </TableBody>
      </Table>
      {selectedAreas.length !== 0 && (
        <QrSensorRobotInfo
          robots={robots}
          robotGroups={robotGroups()}
          robotAreas={selectedAreas}
        />
      )}
      <Button
        sx={{ width: "100%" }}
        variant="contained"
        disabled={!canSubmit()}
        onClick={submit}
      >
        {BasicLanguage.qr.generate[language]}
      </Button>
    </>
  );
};

const QrAdd = () => {
  const language = useRecoilValue(languageState);
  const params = useParams();
  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      <Typography className="pageTitle" variant="h4">
        <IconButton LinkComponent={Link} to={"/" + params.facilityId + "/qr"}>
          <ArrowBackIcon />
        </IconButton>
        {BasicLanguage.qrAdd.title[language]}
      </Typography>
      <Box sx={SBXStyles.mainBox}>
        <QrGenerator />
      </Box>
    </Box>
  );
};

export default QrAdd;
