import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import {
  adminFacilityDelete,
  adminFacilityUpdate,
} from "../../utils/functions";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import BasicLanguage from "../../languages/Basic";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { DialogYesNo } from "../Dialog";
import Pager from "../shared/Pager";

const Facility = (props) => {
  const facility = props.facility;
  const getFacilities = props.getFacilities;
  const language = useRecoilValue(languageState);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(facility.name);
  const [firstDeleteModalOpen, setFirstDeleteModalOpen] = useState(false);
  const [secondDeleteModalOpen, setSecondDeleteModalOpen] = useState(false);

  const submit = () => {
    const facilityData = {
      id: facility.id,
      name: name,
    };

    adminFacilityUpdate(facilityData)
      .then((result) => {
        if (result.data?.status === "success") {
          getFacilities();
          setEditMode(false);
        }
      })
      .catch((e) => {
        console.error(e);
      });

    setEditMode(false);
  };

  const cancel = () => {
    setName(facility.name);
    setEditMode(false);
  };

  const deleteFacility = () => {
    adminFacilityDelete({ facilityId: facility.id })
      .then((result) => {
        if (result.data?.status === "success") {
          setEditMode(false);
          alert(BasicLanguage.admin.facilities.deleteComplete[language]);
          getFacilities();
        } else {
          throw new Error(result.data?.status);
        }
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      })
      .finally(setSecondDeleteModalOpen(false));
  };

  const openSecondDialog = () => {
    setFirstDeleteModalOpen(false);
    setSecondDeleteModalOpen(true);
  };

  return (
    <>
      <TableRow key={facility.id}>
        <TableCell>
          {editMode ? (
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></TextField>
          ) : (
            name
          )}
        </TableCell>
        <TableCell>
          <Link to={"/" + facility.id} target="_blank">
            {facility.id}
          </Link>
        </TableCell>
        <TableCell sx={{ textAlign: "right" }}>
          {Object.keys(facility.admin).length}
        </TableCell>
        <TableCell sx={{ textAlign: "right" }}>
          {Object.keys(facility.users).length}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          {editMode ? (
            <Stack spacing={1}>
              <Button onClick={submit} variant="outlined" color="success">
                {BasicLanguage.admin.common.save[language]}
              </Button>
              <Button
                onClick={() => setFirstDeleteModalOpen(true)}
                variant="outlined"
                color="error"
              >
                {BasicLanguage.admin.common.delete[language]}
              </Button>
              <Button onClick={cancel} variant="outlined" color="secondary">
                {BasicLanguage.admin.common.cancel[language]}
              </Button>
            </Stack>
          ) : (
            <Button
              onClick={() => setEditMode((prev) => !prev)}
              variant="outlined"
              fullWidth
            >
              {BasicLanguage.admin.common.edit[language]}
            </Button>
          )}
        </TableCell>
      </TableRow>
      {firstDeleteModalOpen && (
        <DialogYesNo
          open={[firstDeleteModalOpen, setFirstDeleteModalOpen]}
          yesAction={openSecondDialog}
          noAction={() => setFirstDeleteModalOpen(false)}
          title={facility.name}
          message={BasicLanguage.admin.facilities.firstDeleteWarning[language]}
        />
      )}
      {secondDeleteModalOpen && (
        <DialogYesNo
          open={[secondDeleteModalOpen, setSecondDeleteModalOpen]}
          yesAction={deleteFacility}
          noAction={() => setSecondDeleteModalOpen(false)}
          title={facility.name}
          message={BasicLanguage.admin.facilities.secondDeleteWarning[language]}
          warningMessage={
            BasicLanguage.admin.facilities.secondDeleteWarning2[language]
          }
        />
      )}
    </>
  );
};

Facility.propTypes = {
  facility: PropTypes.object,
  getFacilities: PropTypes.func,
  getSensors: PropTypes.func,
  getRobots: PropTypes.func,
  getKpis: PropTypes.func,
};

export const Facilities = (props) => {
  const loading = props.loading;
  const getFacilities = props.getFacilities;
  const facilities = props.facilities;
  const language = useRecoilValue(languageState);

  const [page, setPage] = useState(1);
  const [pagerFacilities, setPagerFacilities] = useState([]);

  useEffect(() => {
    getFacilities();
  }, [getFacilities]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "30%" }}>
              {BasicLanguage.admin.common.name[language]}
            </TableCell>
            <TableCell sx={{ width: "30%" }}>ID</TableCell>
            <TableCell sx={{ width: "10%" }}>
              {BasicLanguage.admin.facilities.adminNumber[language]}
            </TableCell>
            <TableCell sx={{ width: "10%" }}>
              {BasicLanguage.admin.facilities.userNumber[language]}
            </TableCell>
            <TableCell sx={{ width: "10%", textAlign: "center" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5}>
                <Box
                  sx={{
                    width: "100%",
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            pagerFacilities.map((facility) => (
              <Facility
                key={facility.id}
                facility={facility}
                getFacilities={getFacilities}
              />
            ))
          )}
        </TableBody>
      </Table>
      <Pager
        page={page}
        setPage={setPage}
        items={facilities}
        setPagerItems={setPagerFacilities}
      />
    </>
  );
};

Facilities.propTypes = {
  facilities: PropTypes.array,
  loading: PropTypes.bool,
  getFacilities: PropTypes.func,
  getSensors: PropTypes.func,
  getRobots: PropTypes.func,
  getKpis: PropTypes.func,
};
