const Colors = {
    primary: {
        main: "#68CBD7",
        light: "#B7E9EE",
        dark: "#387E85",
        darkness: "#2B5B5C",
    },
    secondary: {
        main: "#0BD6B0",
        light: "#AEEDEE",
        dark: "#008C61",
        darkness: "#2B5B5C",
    },
    tertiary: "#0B24D6",
    white: "#fcfefe",
    black: "#253140",
    text: "rgb(53,57,60)",
    grey: "#888888"


}
export default Colors;