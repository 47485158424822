const uploadedImageFileType = ".jpg";
const { v4: uuidv4 } = require("uuid");
export const fileExtension =
  uploadedImageFileType === ".png" ? ".png" : ".jpeg";

export const acceptInput = "image/png, image/jpeg, image/jpg";
export const isImageFile = (file) => {
  return file.type === "image/png" || file.type === "image/jpeg";
};
export const storageRefImage = (facilityId) => {
  return `public/${facilityId}/logo.png` || `public/${facilityId}/logo.jpeg`;
};
export const storageRefImageMaps = (facilityId) => {
  return (
    `uploaded_image/${facilityId}/maps/${uuidv4}.png` ||
    `uploaded_image/${facilityId}/maps/${uuidv4}.jpeg`
  );
};
export const storageRefImageAtp = (facilityId, atpId) => {
  return (
    `uploaded_image/${facilityId}/atps/${atpId}/${uuidv4()}.png` ||
    `uploaded_image/${facilityId}/atps/${atpId}/${uuidv4()}.jpeg`
  );
};

export const writeStorageImagePath = (facilityId, atpId, index) => {
  return (
    `uploaded_image/${facilityId}/atps/${atpId}/${uuidv4()}-${
      parseInt(index) + 1
    }.png` ||
    `uploaded_image/${facilityId}/atps/${atpId}/${uuidv4()}-${
      parseInt(index) + 1
    }.jpeg`
  );
};

export const writeStorageFileExtentions = (file) => {
  console.log("File type:", file.type);
  return file.type === "image/jpeg" ? "jpeg" : "png";
};
