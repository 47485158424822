import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import SBXStyles from "../components/utils/Styles";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BasicLanguage from "../components/languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import RobotFloorForm from "../components/parts/forms/RobotFloorForm";

export default function RobotFloorAdd() {
  const params = useParams();
  const robotId = params.robotId;
  const language = useRecoilValue(languageState);
  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      <Typography variant="h4">
        <IconButton
          LinkComponent={Link}
          to={"/" + params.facilityId + `/robots/${robotId}`}
        >
          <ArrowBackIcon />
        </IconButton>
        {BasicLanguage.robotFloor.add.title[language]}
      </Typography>
      <RobotFloorForm />
    </Box>
  );
}
