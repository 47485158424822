import { getUserListAsAdmin } from "../../utils/functions";
import Pager from "../shared/Pager";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import { useEffect, useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import BasicLanguage from "../../languages/Basic";
import { auth } from "../../utils/firebase";

export const TabUsersFirestore = () => {
  const language = useRecoilValue(languageState);

  const [firestoreUsers, setFirestoreUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pagerFirestoreUsers, setPagerFirestoreUsers] = useState([]);

  const getFirestoreUsers = useCallback(() => {
    getUserListAsAdmin({ target: "firestore" })
      .then((res) => setFirestoreUsers(res?.data?.user ? res.data.user : []))
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    getFirestoreUsers();
  }, [getFirestoreUsers]);
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="40%">ID</TableCell>
            <TableCell width="30%">
              {BasicLanguage.admin.users.common.name[language]}
            </TableCell>
            <TableCell width="30%">
              {BasicLanguage.admin.users.common.email[language]}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pagerFirestoreUsers.map((user) => {
            return (
              <TableRow
                key={user.uid}
                sx={{
                  "& td": {
                    fontWeight: auth.currentUser.uid === user.uid ? "bold" : "",
                  },
                }}
              >
                <TableCell>{user.uid || ""}</TableCell>
                <TableCell>{user.displayName || ""}</TableCell>
                <TableCell>{user.email || ""}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Pager
        page={page}
        setPage={setPage}
        items={firestoreUsers}
        setPagerItems={setPagerFirestoreUsers}
      />
    </Box>
  );
};
