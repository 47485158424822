import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import MuiDivider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import MuiList from "@mui/material/List";
import MuiListItem from "@mui/material/ListItem";
import MuiListItemButton from "@mui/material/ListItemButton";
import MuiListItemIcon from "@mui/material/ListItemIcon";
import MuiListItemText from "@mui/material/ListItemText";

import DashboardIcon from "@mui/icons-material/Dashboard";

import { Link as MuiLink, NavLink, useParams } from "react-router-dom";

import ColorizeIcon from "@mui/icons-material/Colorize";

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SettingsRemoteOutlinedIcon from "@mui/icons-material/SettingsRemoteOutlined";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MapIcon from "@mui/icons-material/Map";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";

import Colors from "../utils/Colors";
import LanguageBasic from "../languages/Basic";
import languageState from "../../recoil/atoms/languageState";
import { useRecoilState, useRecoilValue } from "recoil";
import Typography from "../parts-ui/Typography";

import menuExtendedState from "../../recoil/atoms/menuExtended";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Settings } from "@mui/icons-material";

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    overflow: "hidden",
    mx: 2,
    pt: 1,
    transition: "all .32s ease-out;",
    position: "relative",
    height: "calc( 100vh - 120px )",
    overflowY: "auto",
  })
);
const List = styled(MuiList)(({ theme }) =>
  theme.unstable_sx({
    width: "400px",
    overflow: "hidden",
  })
);
const ListItem = styled(MuiListItem)(({ theme }) =>
  theme.unstable_sx({
    m: 0,
    px: 0,
    py: 0,
  })
);
const ListItemButton = styled(MuiListItemButton)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.common.white,
    minHeight: 48,
    px: 2.5,
  })
);

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) =>
  theme.unstable_sx({
    minWidth: 0,
    justifyContent: "center",
    color: theme.palette.common.white,
  })
);
const ListItemText = styled(MuiListItemText)(({ theme }) =>
  theme.unstable_sx({
    ml: 2,
    transition: "all .32s ease-out;",
    "& .MuiListItemText-primary": {
      fontWeight: 700,
      letterSpacing: ".em",
    },
  })
);

const Link = styled(MuiLink)(({ theme }) =>
  theme.unstable_sx({
    minHeight: 48,
    px: 2.5,
  })
);
const Divider = styled(MuiDivider)(({ theme }) =>
  theme.unstable_sx({
    borderColor: theme.palette.common.white,
    opacity: 0.4,
    p: 0,
    mx: 0,
    my: 0.5,
  })
);
const ExtendIconButton = styled(IconButton)(({ theme }) =>
  theme.unstable_sx({
    position: "absolute",
    bottom: "10px",
    right: "14px",
    background: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      "& .MuiSvgIcon-root": {
        color: theme.palette.secondary.main,
      },
    },
  })
);

const ExtendIcon = styled(ArrowBackIosNewIcon)(({ theme }) =>
  theme.unstable_sx({
    transition: "all .32s ease-out;",
    color: theme.palette.common.grey,
  })
);

export default function Menu(props) {
  const params = useParams();
  const pathBase = "/" + params.facilityId;
  const language = useRecoilValue(languageState);
  const theme = useTheme();
  const [extended, setExtended] = useRecoilState(menuExtendedState);
  const robot = props.robot === "1" ? true : false;

  return (
    <>
      <Container
        sx={{
          width: extended ? "13em" : "calc(1.5em + 40px)",
          position: "sticky",
          top: 0,
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={pathBase}>
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={LanguageBasic.common.menu.home[language]}
                sx={{ opacity: extended ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={NavLink} to={pathBase + "/dashboard"}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary={LanguageBasic.common.menu.dashboard[language]}
                sx={{ opacity: extended ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />

        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={pathBase + "/users"}>
              <ListItemIcon>
                <PeopleAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={LanguageBasic.common.menu.user[language]}
                sx={{ opacity: extended ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={pathBase + "/settings"}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText
                primary={LanguageBasic.common.menu.setting[language]}
                sx={{ opacity: extended ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={pathBase + "/sensors"}>
              <ListItemIcon>
                <SettingsRemoteOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={LanguageBasic.common.menu.sensor[language]}
                sx={{ opacity: extended ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          {robot === true ? (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={pathBase + "/robots"}>
                <ListItemIcon>
                  <SmartToyOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={LanguageBasic.common.menu.robots[language]}
                  sx={{ opacity: extended ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <></>
          )}

          <ListItem disablePadding>
            <ListItemButton component={Link} to={pathBase + "/qr"}>
              <ListItemIcon>
                <QrCode2OutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={LanguageBasic.common.menu.qr[language]}
                sx={{ opacity: extended ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to={pathBase + "/atp"}>
              <ListItemIcon>
                <ColorizeIcon />
              </ListItemIcon>
              <ListItemText
                primary={LanguageBasic.common.menu.atp[language]}
                sx={{ opacity: extended ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to={pathBase + "/reports"}>
              <ListItemIcon>
                <ContentPasteOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={LanguageBasic.common.menu.report[language]}
                sx={{ opacity: extended ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to={pathBase + "/maps"}>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText
                primary={LanguageBasic.common.menu.maps[language]}
                sx={{ opacity: extended ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>

        <ExtendIconButton onClick={() => setExtended((prev) => !prev)}>
          <ExtendIcon
            sx={{ transform: extended ? "rotate(0deg)" : "rotate(180deg)" }}
          />
        </ExtendIconButton>
      </Container>
    </>
  );
}
