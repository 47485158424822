import React from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Tooltip,
  styled,
  useMediaQuery,
} from "@mui/material";

import MuiMenu from "@mui/material/Menu";

import { useTheme } from "@emotion/react";
import { useNavigate, useParams } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import sessionState from "../../recoil/atoms/sessionState";
import languageState from "../../recoil/atoms/languageState";
import fontSizeState from "../../recoil/atoms/fontSizeState";
import BasicLanguage from "../languages/Basic";

import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import { logout } from "../utils/session";

const HeaderIconButton = styled(IconButton)(({ theme }) =>
  theme.unstable_sx({
    textDecoration: "none",
    fontWeight: 700,
    color: theme.palette.common.white,
    height: "50px",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
    borderRadius: "9999px",
    fontFamily: theme.typography.fontFamily,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.dark,
    },
  })
);
const HeaderItem = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    textDecoration: "none",
    fontWeight: 700,
    color: theme.palette.primary.main,
    height: "50px",
    px: "25px",
    minWidth: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
    borderRadius: "9999px",
    fontFamily: theme.typography.fontFamily,
    lineHeight: "1em",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.dark,
    },
  })
);

function stringToColor(string) {
  let hash = 0;
  let i;
  if (!string) return "#FFFFFF";
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const StringAvatar = styled(Avatar)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.common.white,
    "&:hover": {
      opacity: 0.6,
    },
  })
);

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name ? name[0] : ""}`,
  };
}

const CommonHeaderItems = (props) => {
  const mediaQuery = useMediaQuery("(min-width:600px)");
  const params = useParams();
  const theme = useTheme();
  let navigate = useNavigate();
  const facilityId = params.facilityId;
  const session = useRecoilValue(sessionState);
  const [languageSelectionDialog, setLanguageSelectionDialog] =
    React.useState(false);
  const [language, setLanguage] = useRecoilState(languageState);
  const [fontSize, setFontSize] = useRecoilState(fontSizeState);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElMobile, setAnchorElMobile] = React.useState(null);

  const menuOpen = Boolean(anchorEl);
  const menuOpenMobile = Boolean(anchorElMobile);

  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const handleCloseMobile = (e) => {
    setAnchorElMobile(null);
  };
  return (
    <>
      {mediaQuery ? (
        <Stack direction="row" spacing={1.5} alignItems="center">
          <HeaderItem
            onClick={() => setFontSize((prev) => (prev === 14 ? 18 : 14))}
          >
            {BasicLanguage.header.textSize[language]}: &nbsp;
            <FormatColorTextIcon
              sx={{ fontSize: 14, opacity: fontSize === 14 ? 1 : 0.3 }}
            />
            &nbsp;
            <FormatColorTextIcon
              sx={{ fontSize: 20, opacity: fontSize === 18 ? 1 : 0.3 }}
            />
          </HeaderItem>

          <HeaderItem onClick={() => setLanguageSelectionDialog(true)}>
            <LanguageOutlinedIcon sx={{ mr: 1 }} />
            {language === "ja" ? " 日本語" : <></>}
            {language === "en" ? "English" : <></>}
          </HeaderItem>
          {session ? (
            <HeaderIconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <Tooltip title={session?.displayName} placement="left">
                <StringAvatar {...stringAvatar(session?.displayName)} />
              </Tooltip>
            </HeaderIconButton>
          ) : (
            <></>
          )}

          <MuiMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <ListItem>
              <ListItemText>
                <span style={{ fontWeight: 700 }}>{session?.displayName}</span>
              </ListItemText>
            </ListItem>
            <Divider />
            <MenuItem onClick={() => navigate("/account")}>
              {BasicLanguage.header.profile[language]}
            </MenuItem>
            <MenuItem onClick={() => logout()}>
              {BasicLanguage.header.logout[language]}
            </MenuItem>
          </MuiMenu>
        </Stack>
      ) : (
        <>
          <Box
            sx={{
              position: "absolute",
              right: 5,
              top: 5,
              background: theme.palette.common.white,
              borderRadius: 2,
            }}
          >
            <Button onClick={(e) => setAnchorElMobile(e.currentTarget)}>
              {BasicLanguage.header.menu[language]}
            </Button>
          </Box>
          <Box>
            <MuiMenu
              id="basic-menu"
              anchorEl={anchorElMobile}
              open={menuOpenMobile}
              onClose={handleCloseMobile}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {session && [
                <ListItem key="profile">
                  <ListItemText>
                    <span style={{ fontWeight: 700 }}>
                      {session?.displayName}
                    </span>
                  </ListItemText>
                </ListItem>,
                <Divider key="divider" />,
                <MenuItem
                  key="profileMenuItem"
                  onClick={() => navigate("/account")}
                >
                  {BasicLanguage.header.profile[language]}
                </MenuItem>,
                <MenuItem key="logoutMenuItem" onClick={() => logout()}>
                  {BasicLanguage.header.logout[language]}
                </MenuItem>,
                <Divider key="divider2" />,
              ]}
              <MenuItem
                onClick={() => setFontSize((prev) => (prev === 14 ? 18 : 14))}
              >
                {fontSize === 14
                  ? BasicLanguage.header.big[language]
                  : BasicLanguage.header.small[language]}
              </MenuItem>
              <MenuItem onClick={() => setLanguageSelectionDialog(true)}>
                {BasicLanguage.common.langauge[language]}
              </MenuItem>
            </MuiMenu>
          </Box>
        </>
      )}

      <Dialog
        open={languageSelectionDialog}
        onClose={() => setLanguageSelectionDialog(false)}
      >
        <DialogTitle sx={{ width: 340 }}>言語/Language</DialogTitle>
        <List>
          <ListItem>
            <ListItemButton
              onClick={() => {
                setLanguage("ja");
                setLanguageSelectionDialog(false);
              }}
            >
              <ListItemIcon>
                {language === "ja" ? (
                  <RadioButtonCheckedOutlinedIcon />
                ) : (
                  <RadioButtonUncheckedOutlinedIcon />
                )}
              </ListItemIcon>
              <ListItemText>日本語</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                setLanguage("en");
                setLanguageSelectionDialog(false);
              }}
            >
              <ListItemIcon>
                {language === "en" ? (
                  <RadioButtonCheckedOutlinedIcon />
                ) : (
                  <RadioButtonUncheckedOutlinedIcon />
                )}
              </ListItemIcon>
              <ListItemText>English</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
    </>
  );
};

export default CommonHeaderItems;
