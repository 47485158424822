import React from 'react'
import { useRecoilValue } from 'recoil';
import languageState from '../recoil/atoms/languageState';
import { useParams } from 'react-router';
import SBXStyles from '../components/utils/Styles';
import { Box, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import BasicLanguage from '../components/languages/Basic';
import ReportsEditForm from '../components/parts/forms/ReportsEditForm';

const ReportsEdit = () => {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;
  const taskId = params.taskId;

  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      <Typography className="pageTitle" variant="h4">
        <IconButton LinkComponent={Link} to={"/" + facilityId + "/reports"}>
          <ArrowBackIcon />
        </IconButton>
        {BasicLanguage.reportsEdit.title[language]}
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}></Typography>
      <Box sx={SBXStyles.mainBox}>
        <ReportsEditForm
          language={language}
          facilityId={facilityId}
          buttonTitle={BasicLanguage.reportsEdit.button[language]}
          taskId={taskId}
        />
      </Box>
    </Box>
  );
}
export default ReportsEdit;