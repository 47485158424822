import {  Divider, Stack, Typography } from "@mui/material";
import React from "react";
import languageState from "../../../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";
import BasicLanguage from "../../languages/Basic";
import PropTypes from "prop-types";
import RequestChart from "./shared/RequestChart";
export default function CleaningRequestChart({
  date,
  dateDisplay,
  requestData,
}) {
  const language = useRecoilValue(languageState);

  return (
    <Stack sx={{ width: "50%" }} justifyContent="center">
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "1.2em",
        }}
      >
        {BasicLanguage.dashboard.kpi.cleaningRequest.title[language]}
      </Typography>

      <Divider sx={{ my: 2 }} />
      <RequestChart
        requestData={requestData}
        date={date}
        dateDisplay={dateDisplay}
      />
    </Stack>
  );
}

CleaningRequestChart.propTypes = {
  date: PropTypes.object.isRequired,
  dateDisplay: PropTypes.string.isRequired,
};
