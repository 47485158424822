import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import StorageImage from "../StorageImage";
import { useTheme } from "@emotion/react";
import { useRecoilState } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import { LanguageOutlined } from "@mui/icons-material";
import BasicLanguage from "../../languages/Basic";
import Colors from "../../utils/Colors";
import PropTypes from "prop-types";

export default function RequestLayout({
  logo,
  facility,
  place,
  qrRequestColor,
  children,
}) {
  const theme = useTheme();
  const [language, setLanguage] = useRecoilState(languageState);
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100dvh",
        backgroundColor: qrRequestColor || Colors.primary.main,
      }}
    >
      <Stack direction="row" sx={{ my: 3, mx: 2 }}>
        <Box sx={{ width: "20%" }}></Box>
        <Box sx={{ width: "60%", textAlign: "center" }}>
          {logo ? (
            <StorageImage imagePath={logo} sx={{ width: 200 }} />
          ) : (
            <img
              src="/logo-removebg-preview.png"
              style={{ width: 200 }}
              alt="logo"
            />
          )}
        </Box>
        <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
          <Button
            sx={{
              textDecoration: "underline",
              color: theme.palette.common.white,
            }}
            onClick={() => setLanguage(language === "en" ? "ja" : "en")}
          >
            <LanguageOutlined sx={{ mr: 0.5 }} />
            {BasicLanguage.languages[language]}
          </Button>
        </Box>
      </Stack>
      <Box
        sx={{
          maxWidth: "400px",
          width: "80%",
          margin: "0px auto",
          color: "primary.main",
        }}
      >
        <Box
          sx={{
            backgroundColor: Colors.white,
            pt: 3,
            pb: 3,
            ...(qrRequestColor && { color: "#999999" }),
          }}
        >
          <Box
            sx={{
              color: "#999999",
              backgroundColor: "#F5F5F5",
              p: 2,
              textAlign: "center",
              ml: 3,
              mr: 3,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>{facility}</Typography>
            <Typography>{place}</Typography>
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

RequestLayout.propTypes = {
  logo: PropTypes.string,
  facility: PropTypes.string,
  place: PropTypes.string,
  qrRequestColor: PropTypes.string,
  children: PropTypes.element,
};
