import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import SBXStyles from "../../utils/Styles";
import BasicLanguage from "../../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import {
  deleteField,
  doc,
  getDoc,
  runTransaction,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import { useNavigate, useParams } from "react-router";
import Colors from "../../utils/Colors";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import validations from "../../utils/Validations";

const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);
const { v4: uuidv4 } = require("uuid");

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const areaTemplate = {
  id: {
    value: "",
    error: false,
    message: "",
  },
  name: {
    value: "",
    error: false,
  },
  apiName: {
    value: "",
    error: false,
  },
  restrictedStartTime: {
    value: null,
    error: false,
  },
  restrictedEndTime: {
    value: null,
    error: false,
    message: "",
  },
  willCleanAfterRestriction: false,
};

export default function RobotFloorForm() {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;
  const robotId = params.robotId;
  const floorId = params.floorId;
  const [robot, setRobot] = useState({});
  const navigate = useNavigate();
  const [floorIndex, setFloorIndex] = useState("");
  const [indexError, setIndexError] = useState(false);
  const [indexErrorMessage, setIndexErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [mapId, setMapId] = useState("");
  const [mapIdError, setMapIdError] = useState(false);
  const [areas, setAreas] = useState([]);
  const [areasToDelete, setAreasToDelete] = useState([]);

  const getRobot = useCallback(() => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "robots", robotId))
      .then((docsnap) => {
        setRobot({ id: docsnap.id, ...docsnap.data() });
      })
      .catch((e) => {
        console.error(e);
      });
  }, [facilityId, robotId]);

  useEffect(() => {
    getRobot();
  }, [getRobot]);

  useEffect(() => {
    if (robot?.floors && robot.floors[floorId]) {
      const floor = robot.floors[floorId];
      setFloorIndex(floorId);
      setName(floor.name);
      setMapId(floor.mapId);

      if (floor.areas) {
        setAreas(
          Object.keys(floor.areas).map((areaId) => {
            const areaObject = structuredClone(areaTemplate);
            const area = floor.areas[areaId];

            areaObject.key = uuidv4();
            areaObject.registered = true;
            areaObject.id.value = areaId;
            areaObject.name.value = area.name;
            areaObject.apiName.value = area.apiName;
            areaObject.restrictedStartTime.value =
              area.restrictedStartTime || area.restrictedStartTime === 0
                ? dayjs().hour(area.restrictedStartTime)
                : null;
            areaObject.restrictedEndTime.value =
              area.restrictedEndTime || area.restrictedEndTime === 0
                ? dayjs().hour(area.restrictedEndTime)
                : null;
            areaObject.willCleanAfterRestriction =
              area.willCleanAfterRestriction;
            areaObject.triggerIds = area.triggerIds;
            areaObject.qrIds = area.qrIds;
            return areaObject;
          })
        );
      }
    }
  }, [floorId, robot]);

  const handleIndexChange = (e) => {
    if (e.target.value === "" || validations.integer.test(e.target.value)) {
      setFloorIndex(e.target.value);
    }
  };

  const formIsValid = () => {
    setIndexError(false);
    setNameError(false);
    setMapIdError(false);
    let isValid = true;

    if (!floorIndex || floorIndex === "") {
      setIndexError(true);
      setIndexErrorMessage(BasicLanguage.robotFloor.error.index[language]);
      isValid = false;
    }
    if (!name || name === "") {
      setNameError(true);
      isValid = false;
    }
    if (!mapId || mapId === "") {
      setMapIdError(true);
      isValid = false;
    }

    const areaIds = [];
    areas?.forEach((area, index) => {
      setAreas((prevAreas) => {
        prevAreas[index].id.error = false;
        prevAreas[index].name.error = false;
        prevAreas[index].apiName.error = false;
        prevAreas[index].restrictedStartTime.error = false;
        prevAreas[index].restrictedEndTime.error = false;
        return [...prevAreas];
      });
      if (!area.id.value || area.id.value === "") {
        setAreas((prevAreas) => {
          prevAreas[index].id.error = true;
          prevAreas[index].id.message =
            BasicLanguage.robotFloor.error.areaId[language];
          return [...prevAreas];
        });
        isValid = false;
      } else if (areaIds.includes(area.id.value)) {
        setAreas((prevAreas) => {
          prevAreas[index].id.error = true;
          prevAreas[index].id.message =
            BasicLanguage.robotFloor.error.areaIdExists[language];
          return [...prevAreas];
        });
        isValid = false;
      } else {
        areaIds.push(area.id.value);
      }

      if (!area.name.value || area.name.value === "") {
        setAreas((prevAreas) => {
          prevAreas[index].name.error = true;
          return [...prevAreas];
        });
        isValid = false;
      }

      if (!area.apiName.value || area.apiName.value === "") {
        setAreas((prevAreas) => {
          prevAreas[index].apiName.error = true;
          return [...prevAreas];
        });
        isValid = false;
      }

      if (area.restrictedEndTime.value && !area.restrictedStartTime.value) {
        setAreas((prevAreas) => {
          prevAreas[index].restrictedStartTime.error = true;
          prevAreas[index].restrictedStartTime.message =
            BasicLanguage.robotFloor.error.startTime[language];
          return [...prevAreas];
        });
        isValid = false;
      }

      if (area.restrictedStartTime.value && !area.restrictedEndTime.value) {
        setAreas((prevAreas) => {
          prevAreas[index].restrictedEndTime.error = true;
          prevAreas[index].restrictedEndTime.message =
            BasicLanguage.robotFloor.error.endTime[language];
          return [...prevAreas];
        });
        isValid = false;
      }

      if (area.restrictedStartTime.value && area.restrictedEndTime.value) {
        if (
          area.restrictedEndTime.value.isSameOrBefore(
            area.restrictedStartTime.value
          )
        ) {
          setAreas((prevAreas) => {
            prevAreas[index].restrictedEndTime.error = true;
            prevAreas[index].restrictedEndTime.message =
              BasicLanguage.robotFloor.error.endBeforeStart[language];
            return [...prevAreas];
          });
          isValid = false;
        }
      }
    });

    return isValid;
  };

  const floorIndexExists = () => {
    return robot.floors && robot.floors[floorIndex];
  };

  const writeFirestoreFloor = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        areasToDelete.forEach((areaToDelete) => {
          transaction.update(
            doc(db, ENV, VERSION, "facilities", facilityId, "robots", robotId),
            {
              [`floors.${floorIndex}.areas.${areaToDelete.id}`]: deleteField(),
            }
          );
        });

        transaction.set(
          doc(db, ENV, VERSION, "facilities", facilityId, "robots", robotId),
          {
            floors: {
              [floorIndex]: {
                name: name,
                mapId: mapId,
                ...(areas.length !== 0 && {
                  areas: Object.assign(
                    {},
                    ...areas.map((area) => {
                      let restrictedStartTime = area.restrictedStartTime.value;
                      let restrictedEndTime = area.restrictedEndTime.value;
                      if (restrictedStartTime !== null) {
                        restrictedStartTime = dayjs(
                          area.restrictedStartTime.value
                        ).hour();
                      }
                      if (restrictedEndTime !== null) {
                        restrictedEndTime = dayjs(
                          area.restrictedEndTime.value
                        ).hour();
                      }
                      return {
                        [area.id.value]: {
                          name: area.name.value,
                          willCleanAfterRestriction:
                            area.willCleanAfterRestriction,
                          apiName: area.apiName.value,
                          restrictedStartTime: restrictedStartTime,
                          restrictedEndTime: restrictedEndTime,
                        },
                      };
                    })
                  ),
                }),
              },
            },
          },
          { merge: true }
        );
      });
      floorId
        ? alert(BasicLanguage.alert.edited[language])
        : alert(BasicLanguage.alert.added[language]);
      navigate(`../robots/${robotId}`);
    } catch (e) {
      console.error(e);
      alert(BasicLanguage.alert.error.default[language]);
    }
  };

  const submitHandler = async () => {
    if (!formIsValid()) {
      return;
    }

    if (!floorId && (await floorIndexExists())) {
      setIndexError(true);
      setIndexErrorMessage(
        BasicLanguage.robotFloor.error.indexExists[language]
      );
      return;
    }
    writeFirestoreFloor();
  };

  const addArea = () => {
    const newArea = structuredClone(areaTemplate);
    newArea.key = uuidv4();
    setAreas((prevAreas) => {
      if (prevAreas) {
        return [...prevAreas, newArea];
      } else {
        return [newArea];
      }
    });
  };

  const cannotDeleteArea = (area) => {
    return (
      (area.triggerIds !== undefined && area.triggerIds.length !== 0) ||
      (area.qrIds !== undefined && area.qrIds.length !== 0)
    );
  };

  const removeArea = (area, index) => {
    if (cannotDeleteArea(area)) {
      return alert(BasicLanguage.alert.cannotDeleteArea[language]);
    }
    if (area.registered) {
      const areasToDeleteCopy = areasToDelete;
      setAreasToDelete([
        ...areasToDeleteCopy,
        { id: area.id.value, areaId: area.areaId },
      ]);
    }
    const areasCopy = areas;
    areas.splice(index, 1);
    setAreas([...areasCopy]);
  };

  const handleAreaIdChange = (e, index) => {
    setAreas((prevAreas) => {
      prevAreas[index].id.value = e.target.value;
      return [...prevAreas];
    });
  };

  const handleAreaNameChange = (e, index) => {
    setAreas((prevAreas) => {
      prevAreas[index].name.value = e.target.value;
      return [...prevAreas];
    });
  };

  const handleAreaApiNameChange = (e, index) => {
    if (e.target.value === "" || validations.english.test(e.target.value)) {
      setAreas((prevAreas) => {
        prevAreas[index].apiName.value = e.target.value;
        return [...prevAreas];
      });
    }
  };

  const handleRestrictedStartTimeChange = (value, index) => {
    setAreas((prevAreas) => {
      prevAreas[index].restrictedStartTime.value = value;
      return [...prevAreas];
    });
  };

  const handleRestrictedEndTimeChange = (value, index) => {
    setAreas((prevAreas) => {
      prevAreas[index].restrictedEndTime.value = value;
      return [...prevAreas];
    });
  };

  const handleWillCleanAfterRestrictionChange = (index) => {
    setAreas((prevAreas) => {
      prevAreas[index].willCleanAfterRestriction =
        !prevAreas[index].willCleanAfterRestriction;
      return [...prevAreas];
    });
  };

  const handleDelete = () => {
    for (const area of areas) {
      if (cannotDeleteArea(area)) {
        alert(BasicLanguage.alert.cannotDeleteFloor[language]);
        return;
      }
    }

    updateDoc(
      doc(db, ENV, VERSION, "facilities", facilityId, "robots", robotId),
      {
        [`floors.${floorId}`]: deleteField(),
      }
    )
      .then(() => {
        alert(BasicLanguage.alert.deleted[language]);
        navigate(`../robots/${robotId}`);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <Box sx={SBXStyles.mainBox}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography varinat="h5">
                {BasicLanguage.robotFloor.index[language]}
              </Typography>
            </TableCell>
            <TableCell>
              <FormControl error={indexError}>
                <TextField
                  value={floorIndex}
                  onChange={handleIndexChange}
                  error={indexError}
                  disabled={!!floorId}
                />
                {indexError && (
                  <FormHelperText>{indexErrorMessage}</FormHelperText>
                )}
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography varinat="h5">
                {BasicLanguage.robotFloor.name[language]}
              </Typography>
            </TableCell>
            <TableCell>
              <FormControl error={nameError}>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={nameError}
                />
                {nameError && (
                  <FormHelperText>
                    {BasicLanguage.robotFloor.error.name[language]}
                  </FormHelperText>
                )}
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography varinat="h5">
                {BasicLanguage.robotFloor.mapId[language]}
              </Typography>
            </TableCell>
            <TableCell>
              <FormControl error={mapIdError}>
                <TextField
                  value={mapId}
                  onChange={(e) => setMapId(e.target.value)}
                  error={mapIdError}
                />
                {mapIdError && (
                  <FormHelperText>
                    {BasicLanguage.robotFloor.error.mapId[language]}
                  </FormHelperText>
                )}
              </FormControl>
            </TableCell>
          </TableRow>
          {areas?.map((area, index) => {
            return (
              <TableRow key={area.key}>
                <TableCell sx={{ verticalAlign: "top" }}>
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Typography>{`${BasicLanguage.robotFloor.area[language]}${
                      index + 1
                    }`}</Typography>
                    <IconButton
                      color="warning"
                      onClick={() => removeArea(area, index)}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography sx={{ mb: 2 }}>ID</Typography>
                    <FormControl error={area.id.error}>
                      <TextField
                        value={area.id.value}
                        onChange={(e) => handleAreaIdChange(e, index)}
                        error={area.id.error}
                        disabled={area.registered}
                      />
                      {area.id.error && (
                        <FormHelperText>{area.id.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography sx={{ my: 2 }}>
                      {BasicLanguage.robotFloor.name[language]}
                    </Typography>
                    <FormControl error={area.name.error}>
                      <TextField
                        value={area.name.value}
                        onChange={(e) => handleAreaNameChange(e, index)}
                        error={area.name.error}
                      />
                      {area.name.error && (
                        <FormHelperText>
                          {BasicLanguage.robotFloor.error.areaName[language]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography sx={{ my: 2 }}>
                      {BasicLanguage.robotFloor.apiName[language]}
                    </Typography>
                    <FormControl error={area.apiName.error}>
                      <TextField
                        value={area.apiName.value}
                        onChange={(e) => handleAreaApiNameChange(e, index)}
                        error={area.apiName.error}
                      />
                      {area.apiName.error && (
                        <FormHelperText>
                          {BasicLanguage.robotFloor.error.apiName[language]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={language}
                  >
                    <Box>
                      <Typography sx={{ my: 2 }}>
                        {BasicLanguage.robotFloor.restrictedTime[language]}
                      </Typography>
                      <Box sx={{ display: "flex", gap: 2, mb: 2, pl: 2 }}>
                        <Typography sx={{ my: 2 }}>
                          {BasicLanguage.robotFloor.start[language]}
                        </Typography>
                        <FormControl error={area.restrictedStartTime.error}>
                          <TimePicker
                            value={area.restrictedStartTime.value}
                            onChange={(value) =>
                              handleRestrictedStartTimeChange(value, index)
                            }
                            sx={{ width: "50%" }}
                            views={["hours"]}
                            slotProps={{
                              textField: {
                                error: area.restrictedStartTime.error,
                              },
                            }}
                          />
                          {area.restrictedStartTime.error && (
                            <FormHelperText>
                              {
                                BasicLanguage.robotFloor.error.startTime[
                                  language
                                ]
                              }
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Box>
                      <Box sx={{ display: "flex", gap: 2, mb: 2, pl: 2 }}>
                        <Typography sx={{ my: 2 }}>
                          {BasicLanguage.robotFloor.end[language]}
                        </Typography>
                        <FormControl error={area.restrictedEndTime.error}>
                          <TimePicker
                            value={area.restrictedEndTime.value}
                            onChange={(value) =>
                              handleRestrictedEndTimeChange(value, index)
                            }
                            sx={{ width: "50%" }}
                            views={["hours"]}
                            slotProps={{
                              textField: {
                                error: area.restrictedEndTime.error,
                              },
                            }}
                          />
                          {area.restrictedEndTime.error && (
                            <FormHelperText>
                              {area.restrictedEndTime.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Box>
                    </Box>
                  </LocalizationProvider>
                  <Box>
                    <Typography sx={{ my: 2 }}>
                      {BasicLanguage.robotFloor.cleanAfterRestriction[language]}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={area.willCleanAfterRestriction}
                          onChange={() =>
                            handleWillCleanAfterRestrictionChange(index)
                          }
                        />
                      }
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell />
            <TableCell>
              <Button
                component="span"
                variant="outlined"
                startIcon={<AddCircleIcon sx={{ mr: 1 }} />}
                sx={{
                  height: "3em",
                  borderRadius: "1.5em",
                  backgroundColor: Colors.white,
                  width: "45%",
                }}
                onClick={addArea}
              >
                {BasicLanguage.robotFloor.addAreaButton[language]}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Button onClick={submitHandler} fullWidth variant="contained">
        {floorId
          ? BasicLanguage.robotFloor.edit.button[language]
          : BasicLanguage.robotFloor.add.button[language]}
      </Button>
      {floorId && (
        <Button
          onClick={handleDelete}
          sx={{ mt: 1.5 }}
          fullWidth
          variant="contained"
          color="error"
        >
          {BasicLanguage.robotFloor.delete.button[language]}
        </Button>
      )}
    </Box>
  );
}
