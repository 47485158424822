import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import SBXStyles from "../components/utils/Styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import Typography from "../components/parts-ui/Typography";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../components/utils/firebase";

const ENV = process.env.REACT_APP_FIRESTORE_ENV
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION

export default function AtpAdd() {
  const params = useParams();
  const facilityId = params.facilityId;
  const language = useRecoilValue(languageState);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [testLocation, setTestLocation] = useState("");
  const [testLocationError, setTestLocationError] = useState(false);
  const [manufacturer, setManufacturer] = useState("");
  const [memo, setMemo] = useState("");

  const submitHandler = () => {
    if (!formIsValid()) {
      return;
    }

    writeFirestoreATP();
  };

  const writeFirestoreATP = () => {
    addDoc(collection(db, ENV, VERSION, "facilities", facilityId, "atps"), {
      name: name,
      testLocation: testLocation,
      manufacturer: manufacturer,
      memo: memo,
    })
      .then(() => {
        alert(BasicLanguage.alert.added[language]);
        navigate("../atp");
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  const formIsValid = () => {
    let isValid = true;
    setNameError(false);
    setTestLocationError(false);

    if (name === "") {
      setNameError(true);
      isValid = false;
    }

    if (testLocation === "") {
      setTestLocationError(true);
      isValid = false;
    }

    return isValid;
  };

  return (
    <>
      <Box component="main" sx={SBXStyles.mainContainer}>
        <Typography variant="h4">
          <IconButton
            LinkComponent={Link}
            to={"/" + params.facilityId + "/atp"}
          >
            <ArrowBackIcon />
          </IconButton>
          {BasicLanguage.atpAdd.title[language]}
        </Typography>
        <Box sx={SBXStyles.mainBox}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography varinat="h5">
                    {BasicLanguage.atpAdd.name[language]}
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl error={nameError}>
                    <TextField
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={nameError}
                    />
                    {nameError && <FormHelperText>{BasicLanguage.atpAdd.error.name[language]}</FormHelperText>}
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography varinat="h5">
                    {BasicLanguage.atpAdd.testLocation[language]}
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl error={testLocationError}>
                    <TextField
                      value={testLocation}
                      onChange={(e) => setTestLocation(e.target.value)}
                      error={testLocationError}
                    />
                    {testLocationError && (
                      <FormHelperText>{BasicLanguage.atpAdd.error.testLocation[language]}</FormHelperText>
                    )}
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography varinat="h5">
                    {BasicLanguage.atpAdd.manufacturer[language]}
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl>
                    <TextField
                      value={manufacturer}
                      onChange={(e) => setManufacturer(e.target.value)}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography varinat="h5">
                    {BasicLanguage.atpAdd.memo[language]}
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl>
                    <TextField
                      value={memo}
                      onChange={(e) => setMemo(e.target.value)}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Button onClick={submitHandler} fullWidth variant="contained">
            {BasicLanguage.atpAdd.button[language]}
          </Button>
        </Box>
      </Box>
    </>
  );
}
