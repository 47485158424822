import * as React from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SBXStyles from "../components/utils/Styles";
import { useNavigate, useParams } from "react-router";
import CommonError from "./CommonError";
import { Link } from "react-router-dom";
import { auth, db } from "../components/utils/firebase";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import { DialogYesNo } from "../components/parts/Dialog";
import UserTimetableDownload from "../components/parts/UserTimetableDownload";
import { userUpdateNumber } from "../components/utils/functions";
import UserRegisterationForm from "../components/parts/forms/userNumberForm";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function UserDetail() {
  const navigate = useNavigate();
  const language = useRecoilValue(languageState);

  const params = useParams();
  const facilityId = params.facilityId;
  const userId = params.userId;

  const [deletable, setDeletable] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [facilityData, setFacilityData] = React.useState();
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [activityHistoryData, setActivityHistory] = React.useState();
  const [Error, setError] = React.useState(false);
  const [WhatsAppNumber, setWhatsAppNumber] = React.useState("");
  const [isWhatsAppRegistered, setIsWhatsAppRegistered] = React.useState();
  const [whatsAppnotifications, setwhatsAppnotifications] =
    React.useState(false);

  const [user, setUser] = React.useState({
    role: -1,
    displayName: "",
    email: "",
    status: 0,
  });

  const isMe = () => {
    return userId === auth.currentUser.uid;
  };

  const deleteUser = async () => {
    if (!isMe()) {
      let oldUsersData = Object.assign({}, { ...facilityData.users });
      let oldAdminData = Object.assign({}, { ...facilityData.admin });

      delete oldUsersData[user.uid];
      delete oldAdminData[user.uid];

      await updateDoc(doc(db, ENV, VERSION, "facilities", facilityId), {
        admin: oldAdminData,
        users: oldUsersData,
      }).catch((e) => console.error(e));

      alert(BasicLanguage.alert.deleted[language]);
      navigate("../users");
    } else {
      alert(BasicLanguage.alert.error.default[language]);
    }

    setDeleteConfirmModal(false);
  };

  const isValidWhatsAppNumber = (number) => {
    const pattern = /^([0|\+[0-9]{1,5})?([0-9]{10})$/;
    return pattern.test(number);
  };
  const onChangeDisplayNumber = (e) => {
    setWhatsAppNumber(e.target.value);
  };

  const onChangeDisplaySwitch = (e) => {
    setwhatsAppnotifications(!whatsAppnotifications);
  };

  const submitUserDisplayPhoneNumber = async (e) => {
    let phoneNumber = whatsAppnotifications ? WhatsAppNumber : null;
    let notifications = whatsAppnotifications || false;

    if (!whatsAppnotifications && !WhatsAppNumber) {
      setLoading(false);
      return;
    }

    if (whatsAppnotifications) {
      if (!WhatsAppNumber) {
        alert(BasicLanguage.whatsAppRegisteration.noEntry[language]);
        setError(true);
        return "ERROR";
      }

      if (!isValidWhatsAppNumber(WhatsAppNumber)) {
        alert(BasicLanguage.whatsAppRegisteration.valid[language]);
        setError(true);
        return "ERROR";
      }
    }

    return userUpdateNumber({
      phoneNumber,
      userId,
      whatsAppnotifications: notifications,
    })
      .then((x) => {
        if (x.data.status == "success") {
          return "SUCCESS";
        } else {
          alert("ERROR");
          return "ERROR";
        }
      })
      .finally(() => {
        setError(false);
        if (whatsAppnotifications) {
          setWhatsAppNumber("");
        }
      });
  };

  const updateUser = async () => {
    if (!facilityData) {
      return;
    } else {
      const result = await submitUserDisplayPhoneNumber();
      if (result === "ERROR") {
        return;
      }
    }

    const oldUsersData = Object.assign({}, facilityData.users);
    const oldAdminData = Object.assign({}, facilityData.admin);
    let newUsersData, newAdminData;

    if (user.role === 0) {
      delete oldUsersData[user.uid];
      newUsersData = Object.assign({}, oldUsersData);
      // 管理者として更新する場合はステータスを空白にする
      user.status = "";
      newAdminData = Object.assign(
        Object.fromEntries([[user.uid, user]]),
        oldAdminData
      );
    } else if (user.role === 1) {
      // ユーザーとして更新する場合はactivityHistoryの最新の状態を入れる
      user.status = activityHistoryData;
      newUsersData = Object.assign(
        Object.fromEntries([[user.uid, user]]),
        oldUsersData
      );
      delete oldAdminData[user.uid];
      newAdminData = Object.assign({}, oldAdminData);
    }

    await updateDoc(doc(db, ENV, VERSION, "facilities", facilityId), {
      admin: newAdminData,
      users: newUsersData,
    })
      .then(() => {
        alert(BasicLanguage.alert.updated[language]);
        navigate("../users");
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  const checkTriggerNotify = React.useCallback(async () => {
    const sensorSnapshots = await getDocs(
      collection(db, ENV, VERSION, "facilities", facilityId, "sensors")
    ).catch((e) => console.error(e));
    const sensorList = sensorSnapshots.docs.map((doc) => doc.id);

    const assigneeDetail = await Promise.all(
      sensorList.map(async (sensorId) => {
        const triggerSnapshot = await getDocs(
          collection(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "sensors",
            sensorId,
            "triggers"
          )
        ).catch((e) => console.error(e));
        const triggerData = triggerSnapshot.docs.map((doc) => doc.data());
        const thisUserNotify = triggerData.filter((trigger) => {
          return trigger.address.indexOf(userId) > -1;
        });

        return thisUserNotify.length;
      })
    );

    return assigneeDetail.filter((assignee) => assignee > 0).length > 0;
  }, [facilityId, userId]);

  const checkQrNotify = React.useCallback(async () => {
    try {
      const qrQuery = query(
        collection(db, ENV, VERSION, "facilities", facilityId, "qr"),
        where("active", "==", true)
      );

      const activityHistorySnapshot = await getDocs(qrQuery);
      let status = "";
      activityHistorySnapshot.forEach((history) => {
        status = history.data().status;
      });
      setActivityHistory(status);

      const qrSnapshots = await getDocs(qrQuery);
      const qrList = qrSnapshots.docs.map((doc) => doc.data());

      return qrList.some((qr) => qr.assignee.includes(userId));
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [facilityId, userId]);

  const getData = React.useCallback(async () => {
    setLoading(true);
    //ユーザーが通知に入っている場合は削除不可
    const isExistsTriggerNotify = await checkTriggerNotify();

    if (!isExistsTriggerNotify) {
      const isExistsQrNotify = await checkQrNotify();
      if (!isExistsQrNotify) setDeletable(true);
    }

    getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
      .then((facility) => {
        let userData = {};
        setFacilityData(facility.data());

        if (
          facility.data().admin[userId] &&
          !facility.data().admin[userId].isDeleted
        ) {
          userData = { ...facility.data().admin[userId], role: 0 };
        } else if (
          facility.data().users[userId] &&
          !facility.data().users[userId].isDeleted
        ) {
          userData = { ...facility.data().users[userId], role: 1 };
        } else {
          userData = null;
        }

        setLoading(false);
        setUser(userData);
      })
      .catch((e) => console.error(e));

    getDocs(
      query(
        collection(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "activityHistory"
        ),
        where("uid", "==", userId),
        orderBy("createdAt", "desc"),
        limit(1)
      )
    )
      .then((activityHistory) => {
        let status = "";
        activityHistory.forEach((history) => {
          status = history.data().status;
        });
        setActivityHistory(status);
      })
      .catch((e) => console.error(e));
  }, [checkQrNotify, checkTriggerNotify, facilityId, userId]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  const fetchWhatsAppData = React.useCallback(async () => {
    try {
      const userDoc = await getDoc(doc(db, ENV, VERSION, "users", userId));
      const userData = userDoc.data();
      if (userData) {
        setWhatsAppNumber(userData.phoneNumber || "");
        setwhatsAppnotifications(userData.whatsappNotifications || false);
        setIsWhatsAppRegistered(!!userData.phoneNumber);
        if (
          userData.phoneNumber &&
          !isValidWhatsAppNumber(userData.phoneNumber)
        ) {
          setWhatsAppNumber("");
          console.warn("invalid Phone Number", userData.phoneNumber);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [userId]);

  React.useEffect(() => {
    fetchWhatsAppData();
  }, [fetchWhatsAppData]);
  return (
    <>
      <Box component="main" sx={SBXStyles.mainContainer}>
        {
          <Box>
            <Typography className="pageTitle" variant="h4">
              <IconButton
                LinkComponent={Link}
                to={"/" + params.facilityId + "/users"}
              >
                <ArrowBackIcon />
              </IconButton>
              {user?.displayName}
            </Typography>
            {user ? (
              <>
                <Box sx={SBXStyles.mainBox}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {BasicLanguage.users.email[language]}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {BasicLanguage.users.status[language]}
                        </TableCell>
                        <TableCell>
                          {
                            BasicLanguage.users.statusName[user.status]?.[
                              language
                            ]
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {BasicLanguage.users.role[language]}
                        </TableCell>
                        <TableCell>
                          <Select
                            disabled={isMe()}
                            value={user?.role === -1 ? "" : user?.role}
                            onChange={(e, v) =>
                              setUser((prev) => {
                                return { ...prev, role: e.target.value };
                              })
                            }
                          >
                            <MenuItem value={0}>
                              {BasicLanguage.common.role[0][language]}
                            </MenuItem>
                            <MenuItem value={1}>
                              {BasicLanguage.common.role[1][language]}
                            </MenuItem>
                          </Select>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color={isMe() ? "grey" : "inherit"}
                            varinat="h5"
                          >
                            {
                              BasicLanguage.whatsAppRegisteration.notifications[
                                language
                              ]
                            }
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <FormControl error={Error}>
                            <Switch
                              disabled={isMe()}
                              checked={whatsAppnotifications}
                              onChange={onChangeDisplaySwitch}
                            />
                          </FormControl>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color={
                              isMe() || !whatsAppnotifications
                                ? "grey"
                                : "inherit"
                            }
                            varinat="h5"
                          >
                            {" "}
                            {
                              BasicLanguage.whatsAppRegisteration.phoneLabel[
                                language
                              ]
                            }{" "}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            type="text"
                            value={WhatsAppNumber}
                            error={Error}
                            disabled={isMe() || !whatsAppnotifications}
                            onChange={onChangeDisplayNumber}
                          />
                          {Error && (
                            <FormHelperText>
                              {
                                BasicLanguage.whatsAppRegisteration.valid[
                                  language
                                ]
                              }
                            </FormHelperText>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Stack spacing={2} direction="row-reverse">
                    <Button
                      onClick={updateUser}
                      fullWidth
                      variant="contained"
                      disabled={isMe()}
                    >
                      {BasicLanguage.common.update[language]}
                    </Button>
                    <Button
                      onClick={() => setDeleteConfirmModal(true)}
                      fullWidth
                      variant="contained"
                      color="error"
                      disabled={isMe() || !deletable}
                    >
                      {BasicLanguage.common.delete[language]}
                    </Button>
                  </Stack>
                  <Stack spacing={1} sx={{ mt: 2 }}>
                    {isMe() ? (
                      <Alert severity="info">
                        {BasicLanguage.users.self[language]}
                      </Alert>
                    ) : (
                      <></>
                    )}
                    {!deletable && !loading ? (
                      <Alert severity="info">
                        {BasicLanguage.users.remove[language]}
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Box>
              </>
            ) : (
              <CommonError></CommonError>
            )}
          </Box>
        }
        {user?.role === 1 && (
          <UserTimetableDownload
            user={user}
            language={language}
            facilityId={facilityId}
          />
        )}
      </Box>

      <DialogYesNo
        open={[deleteConfirmModal, setDeleteConfirmModal]}
        yesAction={deleteUser}
        noAction={() => setDeleteConfirmModal(false)}
        title={BasicLanguage.users.deleteDialog.title[language]}
        message={BasicLanguage.users.deleteDialog.message[language]}
      />
    </>
  );
}
