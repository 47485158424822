import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import SBXStyles from "../../utils/Styles";
import JanitorCoverageChart from "./JanitorCoverageChart";
import moment from "moment";
import CleaningRequestChart from "./CleaningRequestChart";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { useParams } from "react-router";
import "moment/locale/ja";
import RobotFloorCoverageChart from "./RobotFloorCoverageChart";
import PropTypes from "prop-types";
import OnDemandCleaningChart from "./OnDemandCleaningChart";
import DashboardControls from "./shared/DashboardControls";
import { TimeZoneContext } from "../../utils/setTimeZoneContext";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function OperationKpi({
  robotFloorCoverage,
  janitorCoverage,
  onDemandCleaningResponse,
  cleaningRequestResponse,
}) {
  const [date, setDate] = useState(moment());
  const [dateDisplay, setDateDisplay] = useState("day");
  const [janitorData, setJanitorData] = useState([]);
  const [qrRequestData, setQrRequestData] = useState([]);
  const [sensorRequestData, setSensorRequestData] = useState([]);
  const [robotFloorRequestData, setRobotFloorRequestData] = useState([]);
  const params = useParams();
  const facilityId = params.facilityId;
  const { currentTimeZoneName } = useContext(TimeZoneContext);
  const getJanitorActivity = useCallback(() => {
    getDocs(
      query(
        collection(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "activityHistory"
        ),
        where("createdAt", ">=", moment(date).startOf("month").toDate()),
        where("createdAt", "<=", moment(date).endOf("month").toDate()),
        where("status", "==", "1")
      )
    )
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return {
            createdAt: moment(doc.data().createdAt.toDate()).tz(
              currentTimeZoneName
            ),
          };
        });
        setJanitorData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [facilityId, date]);

  const getQrRequestData = useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "notify"),
        where("createdAt", ">=", moment(date).startOf("month").toDate()),
        where("createdAt", "<=", moment(date).endOf("month").toDate()),
        where("type", "==", "QR")
      )
    )
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return {
            completed: !!doc.data().completed,
            createdAt: moment(doc.data().createdAt.toDate()).tz(
              currentTimeZoneName
            ),
          };
        });
        setQrRequestData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [facilityId, date]);

  const getSensorRequestData = useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "notify"),
        where("createdAt", ">=", moment(date).startOf("month").toDate()),
        where("createdAt", "<=", moment(date).endOf("month").toDate()),
        where("type", "==", "SENSOR")
      )
    )
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return {
            completed: !!doc.data().completed,
            createdAt: moment(doc.data().createdAt.toDate()).tz(
              currentTimeZoneName
            ),
          };
        });
        setSensorRequestData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [facilityId, date]);

  const getRobotFloorCoverageRequestData = useCallback(() => {
    const queryRef = query(
      collection(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        "robotFloorCoverage"
      ),
      orderBy("createdAt", "desc")
    );

    getDocs(queryRef)
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          createdAt: moment(doc.data().createdAt.toDate())
            .tz(currentTimeZoneName)
            .format("YYYY-M-D"),
        }));
        data = data.filter((item) =>
          moment(item.createdAt).isSame(moment(date), "month")
        );

        setRobotFloorRequestData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [facilityId, date]);
  useEffect(() => {
    getJanitorActivity();
    getSensorRequestData();
    getQrRequestData();
    getRobotFloorCoverageRequestData();
  }, [
    getJanitorActivity,
    getQrRequestData,
    getSensorRequestData,
    getRobotFloorCoverageRequestData,
  ]);

  return (
    <Box
      sx={SBXStyles.mainBox}
      id="operation-kpi"
      className="dashboard-download-all"
    >
      <Stack
        justifyContent="space-between"
        sx={{ width: "100%" }}
        direction="row"
      >
        <Typography
          variant="h5"
          sx={{ display: "block", textAlign: "", mb: 3, fontWeight: 700 }}
        >
          Operation KPI
        </Typography>

        <DashboardControls
          date={date}
          setDate={setDate}
          dateDisplay={dateDisplay}
          setDateDisplay={setDateDisplay}
          elementId="operation-kpi"
        />
      </Stack>

      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {(robotFloorCoverage || janitorCoverage) && (
          <>
            {robotFloorCoverage && (
              <RobotFloorCoverageChart
                date={date}
                dateDisplay={dateDisplay}
                robotData={robotFloorRequestData}
              />
            )}

            {janitorCoverage && (
              <JanitorCoverageChart
                date={date}
                dateDisplay={dateDisplay}
                janitorData={janitorData}
              />
            )}
          </>
        )}

        {(onDemandCleaningResponse || cleaningRequestResponse) && (
          <>
            {onDemandCleaningResponse && (
              <OnDemandCleaningChart
                date={date}
                dateDisplay={dateDisplay}
                requestData={sensorRequestData}
              />
            )}
            {cleaningRequestResponse && (
              <CleaningRequestChart
                date={date}
                dateDisplay={dateDisplay}
                requestData={qrRequestData}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

OperationKpi.propTypes = {
  robotFloorCoverage: PropTypes.bool.isRequired,
  janitorCoverage: PropTypes.bool.isRequired,
  onDemandCleaningResponse: PropTypes.bool.isRequired,
  cleaningRequestResponse: PropTypes.bool.isRequired,
};
