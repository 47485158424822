import React, { useState } from 'react'
import { Box, Button, IconButton, Stack, TextField } from '@mui/material'
import BasicLanguage from '../components/languages/Basic';
import SBXStyles from '../components/utils/Styles';
import AddRounded from '@mui/icons-material/AddRounded';
import HeaderSidebar from '../components/parts/HeaderSidebar';
import { useRecoilValue } from 'recoil';
import languageState from '../recoil/atoms/languageState';

import Typography from '../components/parts-ui/Typography';


const Facility = (props) => {
    const [status, setStatus] = useState(false);
    const [request, setRequest] = useState();

    const language = useRecoilValue(languageState);


    return <>

        <Box component="main" sx={SBXStyles.mainContainer}>

            <Typography variant="h4" sx={{ display: "flex", alignItems: "center" }}>
                {BasicLanguage.common.menu.facility[language]}

            </Typography>
            <Typography sx={{ mt: 2, mb: 2 }}>
                {BasicLanguage.facility.description[language]}
            </Typography>
            <Box sx={SBXStyles.mainBox}>

            </Box>
        </Box>
    </>


}

export default Facility