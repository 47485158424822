import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import BasicLanguage from "../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../recoil/atoms/languageState";
import PropTypes from "prop-types";
import { acceptInput, isImageFile } from "../utils/Conditions";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function LoadPngButton(props) {
  const setUploadedImage = props.setUploadedImage;
  const setUploadedImageFile = props.setUploadedImageFile;
  const language = useRecoilValue(languageState);

  const onChangeHandler = (e) => {
    if (e.target.files.length === 0) return;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      /// ファイルサイズ取得
      const fileSize = file.size;
      if (fileSize === 0) return;

      const fileMib = fileSize / 1024 ** 2;
      if (fileMib <= 10 && isImageFile(file)) {
        setUploadedImage(reader.result);
        setUploadedImageFile(file);
      } else {
        alert(BasicLanguage.alert.file[language]);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box>
      <Button
        component="label"
        variant="outlined"
        tabIndex={-1}
        startIcon={<AddCircleOutlineOutlined />}
      >
        {BasicLanguage.common.addImage[language]}
        <VisuallyHiddenInput
          type="file"
          onChange={onChangeHandler}
          accept={acceptInput}
        />
      </Button>
    </Box>
  );
}

LoadPngButton.propTypes = {
  setUploadedImage: PropTypes.func,
  setUploadedImageFile: PropTypes.func,
};
