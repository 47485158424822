import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../utils/firebase";
import { useRecoilValue } from "recoil";
import languageState from "../../recoil/atoms/languageState";
import { useParams } from "react-router";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import BasicLanguage from "../languages/Basic";
import PropTypes from "prop-types";

RobotCleaningMode.propTypes = {
  mode: PropTypes.string,
  robot: PropTypes.object,
  getRobot: PropTypes.func,
};

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;
const PHANTAS_CLEANING_MODES = ["清洗", "清扫", "尘推", "吸尘"];

export default function RobotCleaningMode(props) {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;
  const robotId = params.robotId;
  const mode = props.mode;
  const robot = props.robot;
  const getRobot = props.getRobot;
  const [currentModeDays, setCurrentModeDays] = useState([]);
  const [usedModeDays, setUsedModeDays] = useState([]);
  const [cleaningModeEditMode, setCleaningModeEditMode] = React.useState(false);

  useEffect(() => {
    if (robot.cleaningModes) {
      setCurrentModeDays(robot.cleaningModes[mode] || []);
      const { [mode]: _, ...rest } = robot.cleaningModes;
      setUsedModeDays([...Object.values(rest)].flat());
    }
  }, [mode, robot]);

  const toCleaningModeEditMode = () => {
    setCleaningModeEditMode(true);
  };

  const cancelCleaningModeEdit = () => {
    if (robot.cleaningModes && robot.cleaningModes[mode]) {
      setCurrentModeDays(robot.cleaningModes[mode]);
    } else {
      setCurrentModeDays([]);
    }
    setCleaningModeEditMode(false);
  };

  const handleCleaningModeDayChange = (e) => {
    setCurrentModeDays((prevCurrentModeDays) => {
      if (e.target.checked) {
        return [...prevCurrentModeDays, e.target.value];
      } else {
        const i = prevCurrentModeDays.indexOf(e.target.value);
        prevCurrentModeDays.splice(i, 1);
        return [...prevCurrentModeDays];
      }
    });
  };

  const submit = (e) => {
    e.preventDefault();
    updateDoc(
      doc(db, ENV, VERSION, "facilities", facilityId, "robots", robotId),
      { [`cleaningModes.${mode}`]: currentModeDays.toSorted() }
    ).then(() => {
      getRobot();
      setCleaningModeEditMode(false);
    });
  };

  return (
    <TableRow>
      <TableCell width="30%">
        {PHANTAS_CLEANING_MODES.includes(mode)
          ? BasicLanguage.robots.detail.cleaningModes.mode[mode][language]
          : mode}
      </TableCell>
      <TableCell>
        {cleaningModeEditMode ? (
          <form
            style={{ display: "flex", justifyContent: "space-between" }}
            onSubmit={submit}
          >
            <FormGroup sx={{ display: "flex", flexDirection: "row", gap: 2.5 }}>
              {[...new Array(7)].map((_, index) => {
                return (
                  <FormControlLabel
                    value={String(index)}
                    checked={currentModeDays?.includes(String(index))}
                    disabled={usedModeDays?.includes(String(index))}
                    onChange={handleCleaningModeDayChange}
                    sx={{ width: "fit-content" }}
                    key={
                      BasicLanguage.robots.detail.cleaningModes.day.short[
                        index
                      ][language]
                    }
                    control={<Checkbox value={index} />}
                    label={
                      BasicLanguage.robots.detail.cleaningModes.day.short[
                        index
                      ][language]
                    }
                    labelPlacement="top"
                  />
                );
              })}
            </FormGroup>
            <Box sx={{ alignSelf: "end" }}>
              <IconButton type="submit">
                <CheckCircleIcon color="success" />
              </IconButton>
              <IconButton onClick={cancelCleaningModeEdit}>
                <CancelIcon color="error" />
              </IconButton>
            </Box>
          </form>
        ) : (
          <>
            {currentModeDays
              ?.map(
                (day) =>
                  BasicLanguage.robots.detail.cleaningModes.day.long[day][
                    language
                  ]
              )
              .join("、")}
            <IconButton sx={{ ml: 1 }} onClick={toCleaningModeEditMode}>
              <EditIcon sx={{ fontSize: "0.7em" }} />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}
