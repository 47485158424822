import React, { useCallback, useEffect, useState } from "react";
import RequestLayout from "../../shared/RequestLayout";
import { useRecoilValue } from "recoil";
import languageState from "../../../../recoil/atoms/languageState";
import BasicLanguage from "../../../languages/Basic";
import {
  addDoc,
  arrayRemove,
  collection,
  getDocs,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import { db } from "../../../utils/firebase";
import { useParams } from "react-router";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function QrRequests() {
  const language = useRecoilValue(languageState);
  const { facilityId } = useParams();

  const [qrRequests, setQrRequests] = useState([]);

  const getQrRequests = useCallback(() => {
    getDocs(
      collection(db, ENV, VERSION, "facilities", facilityId, "qrRequest")
    ).then((qrRequestDocSnap) => {
      setQrRequests(
        qrRequestDocSnap.docs.map((doc) => {
          return { id: doc.id, ref: doc.ref, ...doc.data() };
        })
      );
    });
  }, [facilityId]);

  const addQrRequest = (requestName) => {
    return addDoc(
      collection(db, ENV, VERSION, "facilities", facilityId, "qrRequest"),
      {
        name: requestName,
      }
    )
      .then(() => getQrRequests())
      .catch((e) => {
        throw new Error(e);
      });
  };

  const deleteQrRequest = async (request) => {
    try {
      const qrCollection = await getDocs(
        query(
          collection(db, ENV, VERSION, "facilities", facilityId, "qr"),
          where("requestsList", "array-contains", request.name)
        )
      );
      await runTransaction(db, async (transaction) => {
        qrCollection.docs.forEach((doc) => {
          //QRからリクエスト項目を削除
          transaction.update(doc.ref, {
            requestsList: arrayRemove(request.name),
          });
        });
        //QRリクエストを削除
        transaction.delete(request.ref);
      });
      alert(BasicLanguage.alert.deleted[language]);
      getQrRequests();
    } catch (error) {
      console.error(error);
      alert(BasicLanguage.alert.error.default[language]);
    }
  };

  useEffect(() => {
    getQrRequests();
  }, [getQrRequests]);

  return (
    <RequestLayout
      title={BasicLanguage.facilitySettings.qrRequests.title[language]}
      requests={qrRequests}
      addRequest={addQrRequest}
      deleteRequest={deleteQrRequest}
    />
  );
}
