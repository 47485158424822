import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { SbxBarChartLib } from "../SbxChart";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import BasicLanguage from "../../languages/Basic";
import PropTypes from "prop-types";
import moment from "moment";

export default function JanitorCoverageChart({
  date,
  dateDisplay,
  janitorData,
}) {
  const language = useRecoilValue(languageState);

  if (dateDisplay === "day") {
    janitorData = generateDataByDay(janitorData, language, date);
  } else if (dateDisplay === "week") {
    janitorData = generateDataByWeek(janitorData, language, date);
  } else if (dateDisplay === "month") {
    janitorData = generateDataByMonth(janitorData, language, date);
  }

  return (
    <Stack sx={{ width: "50%" }} justifyContent="center">
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "1.2em",
        }}
      >
        {BasicLanguage.dashboard.kpi.janitorCoverage.title[language]}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ width: "100%", height: "300px" }}>
        <SbxBarChartLib
          data={janitorData}
          dataKey={
            BasicLanguage.dashboard.kpi.janitorCoverage.dataKey[language]
          }
        />
      </Box>
    </Stack>
  );
}

JanitorCoverageChart.propTypes = {
  date: PropTypes.object.isRequired,
  dateDisplay: PropTypes.string.isRequired,
  janitorData: PropTypes.array.isRequired,
};

const generateDataByDay = (activityHistory, language, date) => {
  const defaultDays = {};
  for (let i = 1; i <= moment(date).daysInMonth(); i++) {
    defaultDays[i] = 0;
  }

  const activityHistoryCount = activityHistory.reduce(
    (activityHistoryObject, history) => {
      const historyDate = moment(history.createdAt).date();
      activityHistoryObject[historyDate] = (activityHistoryObject[historyDate] || 0) + 1;
      return activityHistoryObject;
    },
    defaultDays
  );

  return Object.keys(activityHistoryCount)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        [BasicLanguage.dashboard.kpi.janitorCoverage.dataKey[language]]:
          activityHistoryCount[key],
      };
    });
};

const generateDataByWeek = (activityHistory, language, date) => {
  const startWeek = moment(date).startOf("month").week();
  const endWeek = moment(date).endOf("month").week();
  const defaultWeeks = {};
  for (let i = startWeek; i <= endWeek; i++) {
    defaultWeeks[i] = 0;
  }

  const activityHistoryCount = activityHistory.reduce(
    (activityHistoryObject, history) => {
      const week = moment(history.createdAt).week();
      activityHistoryObject[week] = (activityHistoryObject[week] || 0) + 1;
      return activityHistoryObject;
    },
    defaultWeeks
  );

  return Object.keys(activityHistoryCount)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        [BasicLanguage.dashboard.kpi.janitorCoverage.dataKey[language]]:
          activityHistoryCount[key],
      };
    });
};

const generateDataByMonth = (activityHistory, language, date) => {
  const activityHistoryCount = activityHistory.reduce(
    (activityHistoryObject) => {
      activityHistoryObject.month++;
      return activityHistoryObject;
    },
    { month: 0 }
  );

  return Object.keys(activityHistoryCount).map((key) => {
    return {
      name: moment(date).format(
        BasicLanguage.dashboard.kpi.janitorCoverage.yearMonthFormat[language]
      ),
      [BasicLanguage.dashboard.kpi.janitorCoverage.dataKey[language]]:
        activityHistoryCount[key],
    };
  });
};
