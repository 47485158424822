import * as React from 'react';
import { Box, Button, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import BasicLanguage from '../components/languages/Basic';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import SBXStyles from '../components/utils/Styles';
import { useNavigate, useParams } from 'react-router';
import CommonError from './CommonError';
import { Link } from 'react-router-dom';
import HeaderSidebar from '../components/parts/HeaderSidebar';
import languageState from '../recoil/atoms/languageState';
import Validations from '../components/utils/Validations';
import Typography from '../components/parts-ui/Typography';
import { checkUserToFacility } from '../components/utils/functions';


import { db } from "../components/utils/firebase";
import { arrayUnion, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useRecoilState, useRecoilValue } from "recoil";
import facilityState from "../recoil/atoms/facilityState";


const validationEmailPattern = Validations.email;
const ENV = process.env.REACT_APP_FIRESTORE_ENV
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION

export default function UserAdd(props) {
    const language = useRecoilValue(languageState);
    const navigate = useNavigate();

    const params = useParams();
    const facilityId = params.facilityId;

    const [validationEmail, setValidationEmail] = React.useState(false)
    const [email, setEmail] = React.useState()
    const [role, setRole] = React.useState(1)
    const [status, setStatus] = React.useState(0)
    const handleChangeEmail = (e) => {
        const value = e.target.value;
        setValidationEmail(value === "" ? false : !validate(value))
        setEmail(value);
        setStatus("0");
    }

    const regist = async (prop) => {
        console.log(email, role)
        const checkUser = await checkUserToFacility({ email: email }).catch(e => console.error(e));

        if (checkUser.data) {
            const _role = role == 0 ? "admin" : "users";
            const oldDataRef = await getDoc(doc(db, ENV, VERSION, "facilities", facilityId)).catch((e) => console.error(e));
            if (!oldDataRef) {
                alert(BasicLanguage.alert.accessDenied[language]);
                navigate('/')
                return false;
            }
            const oldData = oldDataRef.data()

            const alreadyFlag = Object.keys(oldData["users"]).indexOf(checkUser.data.uid) > -1 || Object.keys(oldData["admin"]).indexOf(checkUser.data.uid) > -1
            if (alreadyFlag) {
                alert(BasicLanguage.alert.alreadyAdded[language])
            } else {

                const newData = oldData[_role];
                console.log(">>", _role, role, oldData, newData)
                if (role == 1) {
                    // roleがユーザーの場合は、出退勤のstatusに初期値「2（ブランク表示用）」を入れる
                    checkUser.data.status = "2";
                }
                newData[checkUser.data.uid] = checkUser.data;

                await updateDoc(doc(db, ENV, VERSION, "facilities", facilityId),
                    _role === "admin" ?
                        { admin: newData } :
                        { users: newData }).then(x => {
                            console.log("SUCCESS!!!!")
                        })
                    .catch((e) => console.error(e))
                alert("SUCCESS")
                navigate('../users');




            }



        } else {
            alert(BasicLanguage.alert.notRegistered[language])
        }
        // addUserToFacility({ email: "yama@ysyk.net" }).catch(e => { console.error(e) })

    }

    const validate = (e) => {
        return e.match(validationEmailPattern) ? true : false;
    }

    React.useEffect(() => {
        // addUserToFacility({ email: "yama@ysyk.net" }).catch(e => { console.error(e) })
    }, [])
    return <>


        <Box component="main" sx={SBXStyles.mainContainer}>
            <Typography className='pageTitle' variant='h4'>
                <IconButton LinkComponent={Link} to={"/" + params.facilityId + "/users"}>
                    <ArrowBackIcon />
                </IconButton>
                {BasicLanguage.userAdd.title[language]}
            </Typography>
            <Box sx={SBXStyles.mainBox}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography varinat="h5">
                                    {BasicLanguage.userAdd.email[language]}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    label={null}
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                    value={email}
                                    error={validationEmail}
                                    onChange={handleChangeEmail}
                                    helperText={!validationEmail ? null : BasicLanguage.userAdd.errorEmail[language]} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography varinat="h5">
                                    {BasicLanguage.userAdd.role[language]}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        value={role}
                                        onChange={(e, v) => setRole(Number(v))}
                                    >
                                        <FormControlLabel value={0} control={<Radio />} label={BasicLanguage.common.role[0][language]} />
                                        <FormControlLabel value={1} control={<Radio />} label={BasicLanguage.common.role[1][language]} />
                                    </RadioGroup>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Button onClick={regist} fullWidth variant="contained">{BasicLanguage.userAdd.button[language]}</Button>

            </Box>
        </Box>
    </>

}