const PHANTAS_CLEANING_MODES = ["清洗", "清扫", "尘推", "吸尘"];

const facilityCleaningModes = (robots) => {
  return new Set(
    robots
      .filter((robot) => robot.type === "Phantas" || robot.cleaningModes)
      .map((robot) => {
        return robot.type === "Phantas"
          ? PHANTAS_CLEANING_MODES
          : Object.keys(robot.cleaningModes);
      })
      .flat()
  );
};

export default facilityCleaningModes;
