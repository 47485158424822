import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import BasicLanguage from "../../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import PropTypes from "prop-types";
import facilityCleaningModes from "../../utils/facilityCleaningModes";

const PHANTAS_CLEANING_MODES = ["清洗", "清扫", "尘推", "吸尘"];

QrSensorRobotForm.propTypes = {
  cleaningMode: PropTypes.string,
  handleCleaningModeChange: PropTypes.func,
  selectedAreas: PropTypes.array,
  handleRobotAreaChange: PropTypes.func,
  robotAreas: PropTypes.array,
  robots: PropTypes.array,
};

export default function QrSensorRobotForm(props) {
  const language = useRecoilValue(languageState);
  const cleaningMode = props.cleaningMode;
  const handleCleaningModeChange = props.handleCleaningModeChange;
  const selectedAreas = props.selectedAreas;
  const handleRobotAreaChange = props.handleRobotAreaChange;
  const robotAreas = props.robotAreas;
  const robots = props.robots;

  const cleaningModes = facilityCleaningModes(robots);

  return (
    <>
      <TableRow>
        <TableCell>
          {BasicLanguage.sensor.trigger.cleaningMode[language]}
        </TableCell>
        <TableCell>
          <FormControl
            fullWidth
            error={cleaningMode && ![...cleaningModes].includes(cleaningMode)}
          >
            <Select
              name="cleaningMode"
              value={cleaningMode}
              onChange={handleCleaningModeChange}
              error={cleaningMode && ![...cleaningModes].includes(cleaningMode)}
            >
              <MenuItem sx={{ height: 30 }} value={""}></MenuItem>
              {[...cleaningModes].map((mode) => {
                return (
                  <MenuItem key={mode} value={mode}>
                    {PHANTAS_CLEANING_MODES.includes(mode)
                      ? BasicLanguage.robots.detail.cleaningModes.mode[mode][
                          language
                        ]
                      : mode}
                  </MenuItem>
                );
              })}
            </Select>
            {cleaningMode && ![...cleaningModes].includes(cleaningMode) && (
              <FormHelperText>
                {
                  BasicLanguage.robots.detail.cleaningModes.cleaningModeDeleted[
                    language
                  ]
                }
              </FormHelperText>
            )}
          </FormControl>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          {BasicLanguage.sensor.trigger.robotAreas[language]}
        </TableCell>
        <TableCell>
          <FormControl fullWidth>
            <Select
              name="robotAreas"
              value={selectedAreas}
              multiple
              onChange={handleRobotAreaChange}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  {selected.map((value) => (
                    <Chip
                      key={`${value.robotId}_${value.floorId}_${value.areaId}`}
                      label={`${value.areaName} (${
                        value.robotName ||
                        BasicLanguage.robots.undefined[language]
                      })`}
                    />
                  ))}
                </Box>
              )}
            >
              {robotAreas.map((robotArea) => (
                <MenuItem
                  key={`${robotArea.robotId}_${robotArea.floorId}_${robotArea.areaId}`}
                  value={robotArea}
                >
                  {`${robotArea.areaName} (${
                    robotArea.robotName ||
                    BasicLanguage.robots.undefined[language]
                  })`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    </>
  );
}
