import { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../components/utils/firebase";
import BasicLanguage from "../components/languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export const useQrs = (facilityId) => {
  const language = useRecoilValue(languageState);

  const [qrs, setQrs] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const qrCollectionRef = collection(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "qr"
    );
    const unsub = onSnapshot(
      qrCollectionRef,
      (querySnapshot) => {
        setQrs(
          querySnapshot.docs
            .filter((doc) => !doc.data().displayDisable)
            .map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      },
      (error) => {
        console.error(error);
        setQrs([]);
        alert(BasicLanguage.alert.error.default[language]);
      }
    );

    return unsub;
  }, [facilityId, language]);

  useEffect(() => {
    if (qrs) {
      setLoading(false);
    }
  }, [qrs]);

  return { qrs, loading };
};
