import { v4 as uuidv4 } from "uuid"
// IDはuuidの頭になにかつけて、なんのIDかわかったほうがいいかね


const randomAtpScore = () => {
    return Math.floor(Math.random() * 14000 + 1000);
}

const randomPeopleCounter = () => {
    return Math.floor(Math.random() * 140);
}

const dummySensorHistoryBase = [68, 62, 53, 56, 64, 63, 69, 63, 69, 63, 72, 69, 75, 54, 65, 64, 61, 63, 64, 69, 61, 60, 72, 62, 68, 72, 73, 60, 57, 52, 39, 47, 39, 37, 30, 51, 51, 43, 46, 53, 54, 43, 45, 35, 30, 36, 25, 12, 11, 12, 6, 2, 1, 2, 0, 3, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 8, 7, 6, 43, 46, 45, 71, 70, 68, 87, 79, 66, 68, 74, 74, 80, 70, 67, 70, 56, 58, 59, 70, 67]
const generateSensorHistory = (count) => {
    const pos = Math.floor(Math.random() * (dummySensorHistoryBase.length - count - 1));
    return dummySensorHistoryBase.slice(pos, pos + count - 1).map(x => {
        const _x = x + Math.floor(Math.random() * 20) - 10;
        return _x < 0 || x == 0 ? 0 : _x
    })
}
const requestDummy = [
    'Clean',
    'Lost property',
    'Tidy up',];
const place = ["メインフロア", "サブフロア", "トイレ前"]
const today = new Date();
//
//
//  Facilityの中に入れるべき
//
//
export const qr = [
    { id: "76c97b30-053a-4db4-8439-3e494a1c59c0", active: true, place: "7F East free space", facility: "nangintakeshiba", assignee: ["39751faa-290e-4dba-99e2-e82e065aa9a0", "0a1f5975-0ad0-4c1c-8869-2cb1bf490557",], request: requestDummy },
    { id: "899c5abf-6b54-4dda-9522-6a1fb2a9cb6a", active: true, place: "7F West free space", facility: "nangintakeshiba", assignee: ["39751faa-290e-4dba-99e2-e82e065aa9a0", "0a1f5975-0ad0-4c1c-8869-2cb1bf490557",], request: requestDummy },
    { id: "a4531bed-328e-4994-a647-b044754e9184", active: true, place: "会議室廊下", facility: "nanginfukuoka", assignee: ["39751faa-290e-4dba-99e2-e82e065aa9a0", "0a1f5975-0ad0-4c1c-8869-2cb1bf490557",], request: requestDummy },
    { id: "e5038e97-f650-4c0b-a6ca-54137fe82dcb", active: true, place: "社長室", facility: "nanginfukuoka", assignee: ["39751faa-290e-4dba-99e2-e82e065aa9a0", "0a1f5975-0ad0-4c1c-8869-2cb1bf490557",], request: requestDummy },
    { id: "0de12833-c387-431a-9342-e27653cb3d50", active: true, place: "エントランス", facility: "shiodomefoods", assignee: ["39751faa-290e-4dba-99e2-e82e065aa9a0", "0a1f5975-0ad0-4c1c-8869-2cb1bf490557",], request: requestDummy },
    { id: "4cfa2fa8-146c-4307-b870-7184eb31f2b3", active: true, place: "6F Elevator hall", facility: "nangintakeshiba", assignee: ["39751faa-290e-4dba-99e2-e82e065aa9a0", "0a1f5975-0ad0-4c1c-8869-2cb1bf490557",], request: requestDummy },
    { id: "a5994db7-f42e-4600-a34e-75ef55bc893a", active: true, place: "7F Elevator hall", facility: "nangintakeshiba", assignee: ["39751faa-290e-4dba-99e2-e82e065aa9a0", "0a1f5975-0ad0-4c1c-8869-2cb1bf490557",], request: requestDummy },
    { id: "9e81fa29-c445-4131-a24a-764b3c88da67", active: true, place: "8F Elevator hall", facility: "nangintakeshiba", assignee: ["39751faa-290e-4dba-99e2-e82e065aa9a0", "0a1f5975-0ad0-4c1c-8869-2cb1bf490557",], request: requestDummy },
    { id: "d6474951-4215-429e-bcd5-730a2ce5fc26", active: true, place: "エスカレーター上り", facility: "weichbank", request: requestDummy },
    { id: "5941cc79-1dfb-48e9-a779-4f69f716a0b2", active: true, place: "エスカレーター下り", facility: "weichbank", request: requestDummy },
]

const createDummyNotify = (num) => {
    const _notify = Array.from(new Array(num ? num : 10), function (x) {
        return {
            id: uuidv4(),
            date: new Date(2022, 8, 1, 0, Math.floor(Math.random() * 360000, 0)),
            place: place[Math.floor(Math.random() * place.length)],
            request: requestDummy[Math.floor(Math.random() * requestDummy.length)],
            image: null,
        }
    })
    _notify.sort((a, b) => {
        if (a.date > b.date) {
            return -1;
        }
        if (a.date < b.date) {
            return 1;
        }
        return 0;

    })
    return _notify
}

export const notify = createDummyNotify()
export const me = { id: "0a1f5975-0ad0-4c1c-8869-2cb1bf490557", name: "Hisaya Morishige", role: 0, "email": "hisaya.morishige@example.com" }


//
// メインのデータ群
//
export const facilities = [
    {
        id: "yoshimoto-ngk",
        name: "SoftBank Takeshiba Office",
        sensors: [
            { type: "pc", map: "8F", id: "22b7367c-143c-494c-a84c-33a5664546d6", name: "PC_C1", threshold: 200, condition: ">", value: randomPeopleCounter(), "coordinates": [10, 10], status: Math.random() < 22 ? false : true, battery: Math.floor(Math.random() * 1550), },
            { type: "pc", map: "8F", id: "ea262087-0a59-47a5-866b-e6f075d39231", name: "PC_C3", threshold: 200, condition: ">", value: randomPeopleCounter(), "coordinates": [0, 0], status: Math.random() < 0.02 ? false : true, battery: Math.floor(Math.random() * 150), },
            { type: "pc", map: "6F", id: "13bf3b7a-c743-4a1a-959b-35088be9a568", name: "PC_C4", threshold: 200, condition: ">", value: randomPeopleCounter(), "coordinates": [79, 0], status: Math.random() < 0.02 ? false : true, battery: Math.floor(Math.random() * 150), },
        ],
        atps: [
            { map: "8F", id: "fc7a9cd2-fe9e-11ed-be56-0242ac120002", name: "ATP No.1", value: 2733, "coordinates": [-70, -40], images: [], manufacture: "", memo: "" },
            { map: "8F", id: "05a284b4-fe9f-11ed-be56-0242ac120002", name: "ATP No.2", value: randomAtpScore(), "coordinates": [-70, -0], images: [], manufacture: "", memo: "" },
            { map: "6F", id: "08f11a5e-fe9f-11ed-be56-0242ac120002", name: "Floor 1.1.2", value: randomAtpScore(), "coordinates": [-112, 47], images: ["/ATPimage1.png", "/ATPimage2.png"], manufacture: "", memo: "" },
        ],
        sensorsHistory: [
            { id: "22b7367c-143c-494c-a84c-33a5664546d6", name: "PC_C1", data: generateSensorHistory(90), },
            { id: "ea262087-0a59-47a5-866b-e6f075d39231", name: "PC_C3", data: generateSensorHistory(90), },
            { id: "13bf3b7a-c743-4a1a-959b-35088be9a568", name: "PC_C4", data: generateSensorHistory(90), },
        ],
        maps: [
            { name: "6F", image: "/Concourse.png" },
            { name: "7F", image: "/Concourse.png" },
            { name: "8F", image: "/Concourse.png" }
        ],
        notify: [
            ...createDummyNotify(5)
        ],
        users: [
            "7778e7d6-53ad-45b4-bae0-340e14dcc36d",
            "41dfdbce-8cc4-4bc5-97db-981697ae94f9",
            "34fbfd1d-9b88-46ab-8001-a4b0d28f0bd2",
        ],
        admin: [
            "LcpEuEUfRGFVeXM2C97TfDVfOcnR"
        ],
        reports: [
            //completedDateなど入れる
            { id: uuidv4(), type: "regular", place: "1F Corridor", title: "Corridor cleaning", assignee: [{ id: "34fbfd1d-9b88-46ab-8001-a4b0d28f0bd2", name: "Daisuke Kato" }], memo: "Time needed: 30mins\nTasks: sweep cleaning, Wipe cleaning\nRemarks: None", timeNeeded: 30, status: 1, dueDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9, 0), creteadAt: null, images: [] },
            { id: uuidv4(), type: "request", place: "1F Free space", title: "Lost property", assignee: [{ id: "34fbfd1d-9b88-46ab-8001-a4b0d28f0bd2", name: "Daisuke Kato" }], memo: "Time&Date: 2023/06/01 11:10\nTime needed: 30mins\nTasks:\nRemarks:", timeNeeded: 30, status: 0, dueDate: null, creteadAt: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 10, 0), images: [{ url: "/otoshimono.png", id: uuidv4() }] }
        ],
        qr: qr.filter(x => x.facility === "nangintakeshiba")
    },
    {
        id: "nanginfukuoka",
        name: "SoftBank Fukuoka Branch",
        sensors: [
        ],
        sensorsHistory: [
            { id: "0be796d4-fe9f-11ed-be56-0242ac120002", data: generateSensorHistory(), },
        ],
        atps: [
            { map: "1F", id: "0be796d4-fe9f-11ed-be56-0242ac120002", name: "ATP No.4", value: randomAtpScore(), "coordinates": [-179, 35], images: [], manufacture: "", memo: "" },
        ],
        maps: [
            { name: "1F", image: "/map.jpeg" }
        ],
        notify: [
            ...createDummyNotify(3)
        ],
        users: [

            "LcpEuEUfRGFVeXM2C97TfDVfOcnR",
            "7778e7d6-53ad-45b4-bae0-340e14dcc36d",
            "41dfdbce-8cc4-4bc5-97db-981697ae94f9"
        ],
        admin: [

            "LcpEuEUfRGFVeXM2C97TfDVfOcnR"

        ],
        qr: qr.filter(x => x.facility === "nanginfukuoka"),

        reports: []
    },
    {
        id: "shiodomefoods",
        name: "汐留フーヅ本社ビル",
        sensors: [
            { type: "pc", map: "ALL", id: "49508217-94ed-49ee-97e1-e3411bc01068", name: "Human No.4", threshold: 200, condition: ">", value: randomPeopleCounter(), "coordinates": [0, 20], status: Math.random() < 0.02 ? false : true, battery: Math.floor(Math.random() * 150) },
        ],

        sensorsHistory: [
            { id: "49508217-94ed-49ee-97e1-e3411bc01068", data: generateSensorHistory(), },
        ],
        atps: [],
        maps: [
            { name: "ALL", image: "/map.jpeg" }
        ],
        notify: [
            ...createDummyNotify(8)
        ],
        users: [
            "LcpEuEUfRGFVeXM2C97TfDVfOcnR",
            "7778e7d6-53ad-45b4-bae0-340e14dcc36d",
            "41dfdbce-8cc4-4bc5-97db-981697ae94f9"
        ],
        admin: [

            "LcpEuEUfRGFVeXM2C97TfDVfOcnR"

        ],
        qr: qr.filter(x => x.facility === "shiodomefoods"),
        reports: []

    },
    // {
    //     id: "weichbank", name: "Weich Bank Berlin", sensors: [], maps: [], notify: [], users: [

    //         { id: "0a1f5975-0ad0-4c1c-8869-2cb1bf490557", role: 0 },
    //     ],
    //     qr: qr.filter(x => x.facility === "weichbank"),
    //     sensorsHistory: [
    //     ],
    //     atps: [],
    //     reports: []
    // }
];

//
// Facility とN:Nの関係
//

export const users = [
    { id: "LcpEuEUfRGFVeXM2C97TfDVfOcnR", name: "Hisaya Morishige", "email": "hisaya.morishige@example.com", admin: false },
    { id: "34fbfd1d-9b88-46ab-8001-a4b0d28f0bd2", name: "Daisuke Kato", "email": "kato.daisuke@example.com", admin: false },
    { id: "39751faa-290e-4dba-99e2-e82e065aa9a0", name: "Norihei Miki", "email": "norihei.miki@example.com", admin: false },
    { id: "dd677b4d-7742-4e00-b0a5-7fde0b1e44d8", name: "Keiju Kobayahsi", "email": "keiju.kobayashi@example.com", admin: false },
    { id: "7778e7d6-53ad-45b4-bae0-340e14dcc36d", name: "Kaoru Yumi", "email": "kaoru.yumi@example.com", admin: false },
    { id: "41dfdbce-8cc4-4bc5-97db-981697ae94f9", name: "Chishu Ryu", "email": "chishu.ryu@example.com", admin: false },
    { id: uuidv4(), name: "Shima Iwashita(SBX)", "email": "root@example.com", admin: true },
];


//
// QRに紐づく。一般公開されるデータなのでrootに置く。
//
const _requests = {}
facilities.map(x => {
    x.qr.map(q => {
        _requests[q.id] = { facility: x.name, place: q.place, request: q.request }
    });

})

export const requests = _requests