import { useCallback, useEffect, useState } from "react";
import BasicLanguage from "../../languages/Basic";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import CreateTask from "../CreateTask";
import { useNavigate } from "react-router";
import Loading from "../Loading";

const blank = (value) => {
  return !value || value.length === 0;
};

const ENV = process.env.REACT_APP_FIRESTORE_ENV
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION

const ReportsEditForm = (props) => {
  const navigate = useNavigate();
  const language = props.language;
  const facilityId = props.facilityId;
  const taskId = props.taskId;

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState({});
  const [usersAreLoaded, setUsersAreLoaded] = useState(false);
  const [requests, setRequests] = useState([]);
  const [taskType, setTaskType] = useState("");
  const [fetch, setFetch] = useState();
  const [editTaskForm, setEditTaskForm] = useState({
    assignee: {
      value: [],
      hasError: false,
      validation: {
        notBlank: {
          hasError() {
            return blank(editTaskForm.assignee.value);
          },
          message: BasicLanguage.reportsAdd.error.assignee,
        },
      },
    },
    request: {
      value: "",
      hasError: false,
      validation: {
        notBlank: {
          hasError() {
            return blank(editTaskForm.request.value);
          },
          message: BasicLanguage.reportsAdd.error.request,
        },
      },
    },
    location: {
      value: "",
      hasError: false,
      validation: {
        notBlank: {
          hasError() {
            return blank(editTaskForm.location.value);
          },
          message: BasicLanguage.reportsAdd.error.location,
        },
      },
    },
    taskMemo: {
      value: "",
      hasError: false,
      validation: {},
    },
  });

  const getUsers = () => {
    // 施設ユーザー全員を取得
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
      .then((facilitySnapshot) => {
        const usersArray = [
          ...Object.values(facilitySnapshot.data().admin),
          ...Object.values(facilitySnapshot.data().users),
        ]
        .filter((user) => !user.isDeleted);
        const userObj = {};
        usersArray.forEach((user) => {
          userObj[user.uid] = user.displayName;
        });
        setUsers(userObj);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setUsersAreLoaded(true);
      });
  };

  const getRequests = () => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "taskRequest")
      )
    )
      .then((querysnapshot) =>
        setRequests(
          querysnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
        )
      )
      .catch((e) => console.error(e));
  };

  const getTask = useCallback(() => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "notify", taskId))
      .then((docSnap) => {
        if (!docSnap.data() || docSnap.data().task.completed) {
          return navigate("../reports");
        }
        const task = docSnap.data().task;
        setTaskType(docSnap.data().type);
        setEditTaskForm((prevEditTaskForm) => {
          prevEditTaskForm.assignee.value = task.assignee.filter(
            (userId) => users[userId]
          );
          if (docSnap.data().type === "TASK") {
            prevEditTaskForm.request.value = task.request;
            prevEditTaskForm.location.value = task.place;
            prevEditTaskForm.taskMemo.value = task.taskMemo;
          } else {
            delete prevEditTaskForm.request;
            delete prevEditTaskForm.location;
            delete prevEditTaskForm.taskMemo;
          }
          return prevEditTaskForm;
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [facilityId, navigate, taskId, users]);

  useEffect(() => {
    getUsers();
    getRequests();
  }, [fetch]);

  useEffect(() => {
    if (usersAreLoaded) {
      getTask();
    }
  }, [getTask, usersAreLoaded]);

  const handleAssigneeChange = (event) => {
    const {
      target: { value },
    } = event;
    setEditTaskForm((prevAddTaskForm) => {
      prevAddTaskForm.assignee.value =
        typeof value === "string" ? value.split(",") : value;
      return { ...prevAddTaskForm };
    });
  };

  const handleChange = (event) => {
    setEditTaskForm((prevAddTaskForm) => {
      prevAddTaskForm[event.target.name].value = event.target.value;
      return { ...prevAddTaskForm };
    });
  };

  const checkForError = (object, item) => {
    const validation = object[item].validation;
    if (validation.notBlank && validation.notBlank.hasError()) {
      return validation.notBlank.message;
    }
  };

  const submitHandler = (event) => {
    let editTaskFormCopy = { ...editTaskForm };
    let formIsValid = true;

    Object.keys(editTaskFormCopy).forEach((item) => {
      let errorMessage = checkForError(editTaskFormCopy, item);
      if (errorMessage) {
        editTaskFormCopy[item].errorMessage = errorMessage;
        editTaskFormCopy[item].hasError = true;
        formIsValid = false;
      } else {
        editTaskFormCopy[item].hasError = false;
      }
    });
    setEditTaskForm({ ...editTaskFormCopy });

    if (formIsValid) {
      editFirestoreTask();
    }
  };

  const editFirestoreTask = () => {
    updateDoc(
      doc(db, ENV, VERSION, "facilities", facilityId, "notify", taskId),
      {
        "task.assignee": editTaskForm.assignee.value,
        ...(taskType === "TASK" && {
          "task.request": editTaskForm.request.value,
          "task.place": editTaskForm.location.value,
          "task.taskMemo": editTaskForm.taskMemo.value,
        }),
      }
    )
      .then(() => {
        alert(BasicLanguage.alert.edited[language]);
        return navigate("../reports");
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <Stack justifyContent="center" spacing={2}>
          <Box sx={{ width: "100%" }}>
            {/* *****assignee 開始 ***** */}
            <FormControl
              sx={{ m: 1, width: "100%" }}
              error={editTaskForm.assignee.hasError}
            >
              <FormLabel>
                {BasicLanguage.reportsAdd.assignee[language]}
              </FormLabel>
              <Select
                name="assignee"
                value={editTaskForm.assignee.value}
                onChange={handleAssigneeChange}
                multiple
                MenuProps={{
                  PaperProps: {
                    sx: { maxHeight: 200 },
                  },
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={users[value]} />
                    ))}
                  </Box>
                )}
              >
                {Object.keys(users).map((userId) => {
                  return (
                    <MenuItem key={userId} value={userId}>
                      {users[userId]}
                    </MenuItem>
                  );
                })}
              </Select>
              {editTaskForm.assignee.hasError && (
                <FormHelperText>
                  {editTaskForm.assignee.errorMessage[language]}
                </FormHelperText>
              )}
            </FormControl>
            {/* *****assignee 終了 ***** */}
            {/* *****request 開始 ***** */}
            {taskType === "TASK" && (
              <>
                <FormControl
                  sx={{ m: 1, width: "100%" }}
                  error={editTaskForm.request.hasError}
                >
                  <FormLabel>
                    {BasicLanguage.reportsAdd.request[language]}
                  </FormLabel>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Select
                      name="request"
                      value={editTaskForm.request.value}
                      onChange={handleChange}
                      MenuProps={{
                        PaperProps: {
                          sx: { maxHeight: 200 },
                        },
                      }}
                      sx={{ width: "90%" }}
                    >
                      {requests.map((request) => {
                        return (
                          <MenuItem key={request.id} value={request.name}>
                            {request.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <CreateTask
                      existingTasks={requests}
                      taskType="taskRequest"
                      fetch={setFetch}
                    />
                  </Box>
                  {editTaskForm.request.hasError && (
                    <FormHelperText>
                      {editTaskForm.request.errorMessage[language]}
                    </FormHelperText>
                  )}
                </FormControl>
                {/* *****request 終了 ***** */}
                {/* *****location 開始 ***** */}
                <FormControl
                  sx={{ m: 1, width: "100%" }}
                  error={editTaskForm.location.hasError}
                >
                  <FormLabel>
                    {BasicLanguage.reportsAdd.location[language]}
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    name="location"
                    sx={{ m: 0 }}
                    value={editTaskForm.location.value}
                    onChange={handleChange}
                    error={editTaskForm.location.hasError}
                  />
                  {editTaskForm.location.hasError && (
                    <FormHelperText>
                      {editTaskForm.location.errorMessage[language]}
                    </FormHelperText>
                  )}
                </FormControl>
                {/* *****location 終了 ***** */}
                {/* *****taskMemo 開始 ***** */}
                <FormControl
                  sx={{ m: 1, width: "100%" }}
                  error={editTaskForm.taskMemo.hasError}
                >
                  <FormLabel>
                    {BasicLanguage.reportsAdd.memo[language]}
                  </FormLabel>
                  <TextField
                    multiline
                    variant="outlined"
                    name="taskMemo"
                    sx={{ m: 0 }}
                    value={editTaskForm.taskMemo.value}
                    onChange={handleChange}
                    error={editTaskForm.taskMemo.hasError}
                  />
                  {editTaskForm.taskMemo.hasError && (
                    <FormHelperText>
                      {editTaskForm.taskMemo.errorMessage[language]}
                    </FormHelperText>
                  )}
                </FormControl>
                {/* *****taskMemo 終了 ***** */}
              </>
            )}

            <Button onClick={submitHandler} fullWidth variant="contained">
              {props.buttonTitle}
            </Button>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default ReportsEditForm;
