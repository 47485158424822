import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router";
import SBXStyles from "../utils/Styles";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import {
  TextField,
  CircularProgress,
  Button,
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BasicLanguage from "../languages/Basic";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { MuiFileInput } from "mui-file-input";
import { useRecoilValue } from "recoil";
import languageState from "../../recoil/atoms/languageState";
import { RobotFloorCoverage } from "../utils/RobotFloorCoverage";
import { db } from "../utils/firebase";
import sessionState from "../../recoil/atoms/sessionState";
import { DialogYesNo } from "./Dialog";
import moment, { lang } from "moment";
import { TimeZoneContext } from "../utils/setTimeZoneContext";
const ALLOWED_FILE_TYPES = ["text/csv", "application/vnd.ms-excel"];

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function RobotCleaningModeRegistration() {
  const language = useRecoilValue(languageState);
  const [robotData, setRobotData] = useState([]);
  const { facilityId } = useParams();
  const [File, setFile] = useState(null);
  const [FileContents, setFileContents] = useState([]);
  const session = useRecoilValue(sessionState);
  const [isUploading, setIsUploading] = useState(false);
  const [Uploading, setUploading] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { currentTimeZoneName } = useContext(TimeZoneContext);
  const handleEditClick = (docId, currentValue) => {
    setEditMode(docId);
    setEditedValue(currentValue);
  };
  const handleDelete = useCallback(
    async (docId) => {
      const docRef = doc(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        "robotFloorCoverage",
        docId
      );
      try {
        await deleteDoc(docRef);
        setRobotData((prevData) =>
          prevData.filter((item) => item.id !== docId)
        );
        alert(BasicLanguage.facilitySettings.RobotCleaning.Delete[language]);
      } catch (error) {
        console.error(error);
        alert(
          BasicLanguage.facilitySettings.RobotCleaning.errorDelete[language]
        );
      } finally {
        setDeleteModalOpen(false);
      }
    },
    [facilityId, language]
  );
  const handleClick = async () => {
    if (!File || FileContents.length === 0) {
      alert(BasicLanguage.facilitySettings.RobotCleaning.noFIle[language]);
      return;
    }
    setIsUploading(true);
    try {
      const failedFiles = await RobotFloorCoverage(
        FileContents,
        facilityId,
        currentTimeZoneName
      );
      setFileErrors(failedFiles);
      if (failedFiles.length === 0) {
        alert(
          BasicLanguage.facilitySettings.customerSatisfactionCsvUpload.alert
            .uploadSuccess[language]
        );
      } else {
        alert(
          BasicLanguage.facilitySettings.customerSatisfactionCsvUpload.alert
            .uploadError[language]
        );
      }
    } catch (e) {
      console.error(e);
      alert(BasicLanguage.alert.error.default[language]);
    } finally {
      setFile(null);
      setFileContents([]);
      setIsUploading(false);
      fetchData();
    }
  };

  const handleSubmit = useCallback(
    async (index) => {
      setUploading(true);

      if (index >= 0 && index < robotData.length) {
        if ("id" in robotData[index]) {
          const docId = robotData[index].id;
          const docRef = doc(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "robotFloorCoverage",
            docId
          );
          try {
            await updateDoc(docRef, {
              value: Number(editedValue),
              optionalValue: session.displayName || session.uid,
            });
            alert(BasicLanguage.alert.updated[language]);
            setEditMode(null);
            setUploading(false);
          } catch (error) {
            console.error(error);
          }
        } else {
          alert(BasicLanguage.alert.error.default[language]);
        }
      } else {
        console.error();
      }
    },
    [
      editedValue,
      facilityId,
      language,
      robotData,
      session.displayName,
      session.uid,
    ]
  );
  const handleChange = (file) => {
    setFileContents([]);
    setFileErrors([]);
    if (!file) {
      return;
    }

    if (!file.name.toLowerCase().endsWith(".csv")) {
      alert(
        BasicLanguage.facilitySettings.RobotCleaning.fileTypeRobot[language]
      );
      setFile(null);
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      alert(
        BasicLanguage.facilitySettings.RobotCleaning.fileTypeRobot[language]
      );
      setFile(null);
      return;
    }

    setFile(file);
    getContents(file);
  };

  const clearFile = () => {
    setFile(null);
    setFileContents([]);
  };

  const fetchData = useCallback(async () => {
    const q = query(
      collection(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        "robotFloorCoverage"
      ),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    let data = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setRobotData(data);
  }, [facilityId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getContents = (File) => {
    if (!File) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const uint8Array = new Uint8Array(event.target.result);
      const decoder = new TextDecoder("Shift_JIS");
      const text = decoder.decode(uint8Array);
      setFileContents((prev) => [...prev, { content: text, name: File?.name }]);
    };

    reader.onerror = (e) => {
      console.error("Error reading file:", e);
    };

    reader.readAsArrayBuffer(File);
  };

  return (
    <Box sx={SBXStyles.mainBox}>
      <Typography className="" variant="h5">
        {BasicLanguage.facilitySettings.RobotCleaning.title[language]}
      </Typography>
      <Box
        display={"flex"}
        gap={2}
        alignContent={"center"}
        justifyContent={"space-between"}
        alignItems={"end"}
      >
        <Box>
          <MuiFileInput
            value={File}
            sx={{ marginTop: 2 }}
            onChange={handleChange}
            placeholder={
              BasicLanguage.facilitySettings.customerSatisfactionCsvUpload
                .select[language]
            }
            hideSizeText
            clearIconButtonProps={{
              children: <ClearIcon onClick={clearFile} />,
            }}
          />
          {fileErrors.length > 0 && (
            <Alert severity="error" sx={{ mt: 2 }}>
              <AlertTitle>
                {
                  BasicLanguage.facilitySettings.customerSatisfactionCsvUpload
                    .alert.uploadError[language]
                }
              </AlertTitle>
              <ul>
                {fileErrors.map((error) => (
                  <li key={error.fileName}>{error.fileName}</li>
                ))}
              </ul>
            </Alert>
          )}
        </Box>
        <Box>
          <Button
            variant="outlined"
            disabled={isUploading}
            onClick={handleClick}
            size="large"
          >
            {isUploading ? (
              <CircularProgress />
            ) : (
              BasicLanguage.facilitySettings.upload[language]
            )}
          </Button>
        </Box>
      </Box>

      {robotData.length !== 0 && (
        <TableContainer
          sx={{
            mt: 2,
            maxHeight: "400px",
            "& .MuiTableCell-head": {
              backgroundColor: "rgba(220,220,220,1)",
              fontWeight: "bold",
            },
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "3rm" }}>
                  {BasicLanguage.facilitySettings.tableRobot.value[language]}
                </TableCell>
                <TableCell>
                  {BasicLanguage.facilitySettings.tableRobot.person[language]}
                </TableCell>
                <TableCell>
                  {" "}
                  {BasicLanguage.facilitySettings.RobotCleaning.date[language]}
                </TableCell>
                <TableCell>
                  {" "}
                  {BasicLanguage.facilitySettings.RobotCleaning.time[language]}
                </TableCell>
                <TableCell sx={{ width: "3rm" }}> </TableCell>
                <TableCell sx={{ width: "3rm" }}></TableCell>
                <TableCell sx={{ width: "3rm" }}> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {robotData.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {editMode === index ? (
                      <TextField
                        type="number"
                        value={editedValue}
                        onChange={(e) => setEditedValue(e.target.value)}
                      />
                    ) : (
                      data.value
                    )}
                  </TableCell>
                  <TableCell>{data.optionalValue}</TableCell>
                  <TableCell>
                    {moment(data?.createdAt.toDate())
                      .local(language)
                      .tz(currentTimeZoneName)
                      .format(
                        BasicLanguage.common.date.datePicker.long[language]
                      )}
                  </TableCell>
                  <TableCell>
                    {moment(data.createdAt.toDate())
                      .tz(currentTimeZoneName)
                      .format("HH:mm:ss")}
                  </TableCell>
                  <TableCell sx={{ width: "3rm" }}>
                    {editMode === index ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleSubmit(index)}
                      >
                        {Uploading ? (
                          <CircularProgress />
                        ) : (
                          BasicLanguage.facilitySettings.RobotCleaning.submit[
                            language
                          ]
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => handleEditClick(index, data.value)}
                      >
                        {BasicLanguage.map.edit[language]}
                      </Button>
                    )}
                  </TableCell>

                  {editMode === index && (
                    <TableCell sx={{ width: "3rm" }}>
                      <CancelIcon
                        color="error"
                        sx={{ marginLeft: "1px", cursor: "pointer" }}
                        onClick={() => {
                          setEditMode(null);
                          setEditedValue("");
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell sx={{ width: "3rm" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setDeleteModalOpen(data.id)}
                      sx={{ cursor: "pointer" }}
                    >
                      {BasicLanguage.map.delete[language]}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <DialogYesNo
        open={[deleteModalOpen, setDeleteModalOpen]}
        yesAction={() => handleDelete(deleteModalOpen)}
        noAction={() => setDeleteModalOpen(false)}
        title={BasicLanguage.admin.common.delete[language]}
        message={deleteModalOpen && BasicLanguage.qr.delete[language]}
      />
    </Box>
  );
}
