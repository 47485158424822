import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import BasicLanguage from "../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../recoil/atoms/languageState";

const PlotList = (props) => {
  const plots = props.plots;
  const language = useRecoilValue(languageState);

  const listItemClick = (plot) => {
    props.selectDeviceClick(plot, props.deviceType);
    props.setSelectedRadio("");
  };

  return (
    <List>
      {plots.map((plot) => (
        <ListItem disableGutters key={plot.id}>
          <ListItemButton onClick={() => listItemClick(plot)}>
            <ListItemText>
              {plot.name && plot.name !== ""
                ? plot.name
                : BasicLanguage.map.noName[language]}
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default function PlotDialog(props) {
  const language = useRecoilValue(languageState);
  const modalOpen = props.modalOpen;
  const setModalOpen = props.setModalOpen;
  const [selectedRadio, setSelectedRadio] = useState("");
  const atps = props.atps;
  const sensors = props.sensors;

  return (
    <Dialog
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>{BasicLanguage.map.dialog.plot.title[language]}</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel>{BasicLanguage.map.dialog.plot.type[language]}</FormLabel>
          <RadioGroup
            row
            value={selectedRadio}
            onChange={(e) => setSelectedRadio(e.target.value)}
          >
            <FormControlLabel value="atp" control={<Radio />} label="ATP" />
            <FormControlLabel
              value="sensor"
              control={<Radio />}
              label="Sensor"
            />
          </RadioGroup>
        </FormControl>
        {selectedRadio === "atp" && (
          <PlotList
            plots={atps}
            selectDeviceClick={props.selectDeviceClick}
            deviceType={selectedRadio}
            setSelectedRadio={setSelectedRadio}
          />
        )}
        {selectedRadio === "sensor" && (
          <PlotList
            plots={sensors}
            selectDeviceClick={props.selectDeviceClick}
            deviceType={selectedRadio}
            setSelectedRadio={setSelectedRadio}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
