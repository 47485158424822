import * as React from "react";
import { IconButton, Typography, Stack, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import BasicLanguage from "../languages/Basic";
import EditIcon from "@mui/icons-material/Edit";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useParams } from "react-router";
import { Button } from "@mui/material";
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../utils/firebase";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useRecoilValue } from "recoil";
import languageState from "../../recoil/atoms/languageState";
import SBXStyles from "../utils/Styles";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

//TODO: センサー詳細と施設設定のセンサーリセット機能を分ける
//TODO: センサーリセット時間をDateオブジェクトに変更する
const SensorResetTime = (props) => {
  const { facilityId } = useParams();
  const { sensorId, enableResetBatch } = props;
  const language = useRecoilValue(languageState);

  const [resetTime, setResetTime] = React.useState("");
  const [ErrorTime, setErrorTime] = React.useState(null);
  const [resetTimeEditMode, setResetTimeEditMode] = React.useState(false);
  const [newResetTime, setNewResetTime] = React.useState(resetTime);

  const [newResetTimeAllSensor, setNewResetTimeAllSensor] =
    React.useState("--:--");

  const formatTime12Hour = (time24h) => {
    if (!time24h || time24h.includes("--")) return "--:--";

    const [hours, minutes] = time24h.split(":");
    if (isNaN(parseInt(minutes, 10))) return "--:--";

    const hoursInt = parseInt(hours, 10);
    const ampm = hoursInt >= 12 ? "PM" : "AM";
    const hours12h = hoursInt % 12 || 12;

    return `${hours12h.toString().padStart(2, "0")}:${minutes} ${ampm}`;
  };

  const handleTimeChange = (newValue) => {
    const selectedTime = `${String(newValue.$H).padStart(2, "0")}:${String(
      newValue.$m
    ).padStart(2, "0")}`;
    setNewResetTime(selectedTime);
    setNewResetTimeAllSensor(selectedTime);
  };

  const resetSensorValue = async () => {
    const sensorDocRef = doc(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "sensors",
      sensorId
    );
    try {
      await updateDoc(sensorDocRef, {
        resetTime: deleteField(),
      });
      setResetTime(null);
      alert(BasicLanguage.sensor.currentValue.resetTimeSuccess[language]);
    } catch (error) {
      console.error(error);
      alert(BasicLanguage.alert.sensorResetFailed[language]);
    }
  };

  const resetSensorData = async () => {
    const sensorColllection = collection(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "sensors"
    );
    const sensorDocs = await getDocs(sensorColllection);

    try {
      sensorDocs.forEach(async (sensorDoc) => {
        await updateDoc(sensorDoc.ref, { resetTime: null });
      });
      alert(BasicLanguage.alert.resetAllSensor.Success[language]);
      setNewResetTimeAllSensor("--:--");
    } catch (error) {
      console.error(error);
    }
  };

  const submitResetTime = async () => {
    if (!formIsValid()) {
      setErrorTime(BasicLanguage.alert.resetAllSensor.noValue[language]);
      return;
    }
    const updatedResetTime = newResetTime;
    const sensorDocRef = doc(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "sensors",
      sensorId
    );
    try {
      await updateDoc(sensorDocRef, { resetTime: updatedResetTime });
      alert([BasicLanguage.sensor.currentValue.resetTimeSuccess[language]]);
      setResetTime(updatedResetTime);
      setNewResetTime(updatedResetTime);
      setResetTimeEditMode(false);
    } catch (error) {
      console.error(
        [BasicLanguage.sensor.currentValue.resetTimeError[language]],
        error
      );
    }
  };
  const submitResetTimeAllSensors = async () => {
    if (!formIsValid()) {
      setErrorTime(BasicLanguage.alert.resetAllSensor.noValue[language]);
      return;
    }
    const updatedResetTimeAllSensors = newResetTimeAllSensor;
    const sensorColllection = collection(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "sensors"
    );
    const sensorDocs = await getDocs(sensorColllection);

    try {
      sensorDocs.forEach(async (sensorDoc) => {
        await updateDoc(sensorDoc.ref, {
          resetTime: updatedResetTimeAllSensors,
        });
      });
      setResetTimeEditMode(false);
      setNewResetTime(null);
      setErrorTime(null);
      alert(
        BasicLanguage.alert.resetAllSensor.SuccessResetTimeSetAll[language]
      );
    } catch (error) {
      console.error(
        [BasicLanguage.sensor.currentValue.resetTimeError[language]],
        error
      );
    }
  };

  const cancelResetTimeEdit = () => {
    setNewResetTime(null);
    setErrorTime(null);
    setResetTimeEditMode(false);
  };

  React.useEffect(() => {
    if (sensorId) {
      const fetchSensorData = async () => {
        try {
          const sensorDocRef = doc(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "sensors",
            sensorId
          );
          const sensorDocSnap = await getDoc(sensorDocRef);
          if (sensorDocSnap.exists()) {
            const sensorData = sensorDocSnap.data();
            if (sensorData) {
              const newResetTime =
                sensorData.resetTime !== undefined
                  ? sensorData.resetTime
                  : null;

              setResetTime(newResetTime);
            } else {
              console.error(BasicLanguage.alert.error[language]);
            }
          } else {
            console.error(
              BasicLanguage.alert.error[language],
              facilityId,
              "sensorId:",
              sensorId
            );
          }
        } catch (error) {
          console.error(BasicLanguage.alert.error[language], error);
        }
      };

      if (sensorId !== undefined) {
        fetchSensorData();
      } else {
        console.error("センサ ID undefined");
      }
    }
  }, [facilityId, sensorId, language]);

  const formIsValid = () => {
    let isValid = true;
    setErrorTime(false);

    if (!newResetTime) {
      setErrorTime(BasicLanguage.alert.resetAllSensor.noValue[language]);
      isValid = false;
    }

    return isValid;
  };
  return (
    <Box sx={enableResetBatch ? SBXStyles.mainBox : {}}>
      {enableResetBatch && (
        <Typography className="" variant="h5">
          {BasicLanguage.sensor.resetSensorFacilities[language]}
        </Typography>
      )}
      <Stack direction={"row"} gap={10}>
        {enableResetBatch ? (
          <>
            <Stack direction={"column"} gap={2} alignItems={"flex-start"}>
              <Button
                variant="contained"
                sx={{ marginY: "20px" }}
                onClick={resetSensorData}
              >
                {BasicLanguage.alert.resetAllSensor.button[language]}
              </Button>
              <Box display={"flex"} gap={5} alignItems={"center"}>
                {resetTimeEditMode ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label={[
                        BasicLanguage.sensor.currentValue.value[language],
                      ]}
                      onClose={null}
                      open={resetTimeEditMode}
                      ampm={language === "en" ? true : false}
                      sx={{
                        size: "small",
                        "& .Mui-disabled": {
                          "-webkit-text-fill-color": !!ErrorTime
                            ? "red"
                            : "black",
                        },
                        "& .MuiOutlinedInput-root.Mui-disabled": {
                          "& > fieldset": {
                            border: !!ErrorTime
                              ? "1px solid red"
                              : "1px solid black",
                          },
                        },
                      }}
                      value={newResetTimeAllSensor ? newResetTimeAllSensor : ""}
                      onChange={(newValue) => handleTimeChange(newValue)}
                      slotProps={{
                        actionBar: {
                          sx: { display: "none" },
                        },

                        textField: {
                          disabled: true,
                          error: !!ErrorTime,
                          helperText: ErrorTime,
                        },
                      }}
                    />
                    <IconButton onClick={submitResetTimeAllSensors}>
                      <CheckCircleIcon color="success" />
                    </IconButton>
                    <IconButton onClick={cancelResetTimeEdit}>
                      <CancelIcon color="error" />
                    </IconButton>
                  </LocalizationProvider>
                ) : (
                  <>
                    {newResetTimeAllSensor ? (
                      <>
                        {" "}
                        {[
                          BasicLanguage.sensor.currentValue.resetTime[language],
                        ]}{" "}
                        <b>
                          {language === "en"
                            ? formatTime12Hour(newResetTimeAllSensor)
                            : newResetTimeAllSensor}
                        </b>
                      </>
                    ) : (
                      <>
                        {[
                          BasicLanguage.sensor.currentValue.resetTimeError[
                            language
                          ],
                        ]}
                      </>
                    )}

                    <IconButton onClick={() => setResetTimeEditMode(true)}>
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Stack>
          </>
        ) : (
          <>
            <Stack direction={"row"} gap={2} alignItems={"center"}>
              {" "}
              {resetTimeEditMode ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label={[BasicLanguage.sensor.currentValue.value[language]]}
                    ampm={language === "en" ? true : false}
                    value={newResetTime ? newResetTime : ""}
                    open={resetTimeEditMode}
                    onClose={null}
                    sx={{
                      size: "small",
                      "& .Mui-disabled": {
                        "-webkit-text-fill-color": !!ErrorTime
                          ? "red"
                          : "black",
                      },
                      "& .MuiOutlinedInput-root.Mui-disabled": {
                        "& > fieldset": {
                          border: !!ErrorTime
                            ? "1px solid red"
                            : "1px solid black",
                        },
                      },
                    }}
                    onChange={(newValue) => handleTimeChange(newValue)}
                    slotProps={{
                      actionBar: {
                        sx: { display: "none" },
                      },
                      textField: {
                        disabled: true,
                        error: !!ErrorTime,
                        helperText: ErrorTime,
                      },
                    }}
                  />
                  <IconButton onClick={submitResetTime}>
                    <CheckCircleIcon color="success" />
                  </IconButton>
                  <IconButton onClick={cancelResetTimeEdit}>
                    <CancelIcon color="error" />
                  </IconButton>
                </LocalizationProvider>
              ) : (
                <>
                  {resetTime ? (
                    <>
                      {language === "en"
                        ? formatTime12Hour(resetTime)
                        : resetTime}
                    </>
                  ) : (
                    <>
                      {[
                        BasicLanguage.sensor.currentValue.resetTimeError[
                          language
                        ],
                      ]}
                    </>
                  )}

                  <IconButton onClick={() => setResetTimeEditMode(true)}>
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </Stack>
            <Button variant="outlined" onClick={resetSensorValue}>
              {" "}
              {BasicLanguage.sensor.reset[language]}
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default SensorResetTime;
