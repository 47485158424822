import { useCallback, useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Link,
  TableContainer,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";
import SBXStyles from "../components/utils/Styles";
import languageState from "../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Colors from "../components/utils/Colors";
import Typography from "../components/parts-ui/Typography";
import { db } from "../components/utils/firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import "moment-timezone";
import "moment/locale/ja";
import Loading from "../components/parts/Loading";
import { DialogYesNo } from "../components/parts/Dialog";
import StorageImage from "../components/parts/StorageImage";
import { getReportImage } from "../components/utils/functions";
import { TimeZoneContext } from "../components/utils/setTimeZoneContext";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const TaskProgressBar = (props) => {
  const tasks = props.tasks;
  const value =
    tasks.length === 0
      ? 0
      : (tasks.filter((report) => report.task.completed === true).length /
          tasks.length) *
        100;

  return (
    <LinearProgress variant="determinate" value={value} sx={{ height: 12 }} />
  );
};

const TaskSummary = (props) => {
  const task = props.task;
  const currentTimeZone = props.currentTimeZone;
  const type = task.type;
  let createdAt =
    task.createdAt && currentTimeZone
      ? moment(task.createdAt.toDate())
          .tz(currentTimeZone)
          .format("YYYY/MM/DD HH:mm:ss")
      : "";
  const place = task.task.place;

  const [isChecked, setIsChecked] = useState(task.task.completed);

  let completed;
  if (task.taskInProgress) {
    completed = BasicLanguage.tasks.status.inProgress[props.language];
  } else {
    completed = task.task.completed
      ? BasicLanguage.tasks.status.true[props.language]
      : BasicLanguage.tasks.status.false[props.language];
  }

  const comparison = task.task.comparison ? task.task.comparison : "";
  const threshold = task.task.threshold ? task.task.threshold : "";
  let request = task.task.request;
  if (type === "SENSOR" && threshold !== "" && comparison !== "") {
    request = `${request} (${
      BasicLanguage.sensor.terms.title[props.language]
    }: ${threshold} ${
      BasicLanguage.sensor.terms[comparison][props.language]
    } )`;
  }

  return (
    <AccordionSummary>
      <Stack direction="row" spacing={2}>
        <Table>
          <TableBody>
            <TableRow sx={{ "& td": { border: "none" } }}>
              <TableCell sx={{ width: "5%" }}>
                <Checkbox
                  onClick={(e) => {
                    setIsChecked((prevIsChecked) => !prevIsChecked);
                    props.checkboxClick(e);
                  }}
                  value={task.id}
                  checked={isChecked}
                />
              </TableCell>
              <TableCell sx={{ width: "10%" }}>{type}</TableCell>
              <TableCell sx={{ width: "15%" }}>{place}</TableCell>
              <TableCell sx={{ width: "25%" }}>{request}</TableCell>
              <TableCell sx={{ width: "20%" }}>{createdAt}</TableCell>
              <TableCell sx={{ width: "10%" }}>{completed}</TableCell>
              <TableCell sx={{ width: "10%" }}>
                <IconButton
                  LinkComponent={Link}
                  href={`/c/${props.facilityId}/${task.id}`}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  <OpenInNewIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </AccordionSummary>
  );
};

const ImageTag = ({ imagePath, robotImagePath, language }) => {
  if (imagePath !== "") {
    return <StorageImage imagePath={imagePath} sx={{ width: "100%" }} />;
  } else if (robotImagePath !== "" && typeof robotImagePath !== "undefined") {
    return (
      <a href={robotImagePath} target="_blank" rel="noreferrer">
        {BasicLanguage.tasks.titles.robotImage[language]}
      </a>
    );
  } else {
    return "";
  }
};

const TaskDetails = (props) => {
  const language = props.language;
  const titles = BasicLanguage.tasks.titles;
  const task = props.task;
  const getReports = props.getReports;
  const taskMemo = task.task.taskMemo;
  const reportOutline = task.report?.outline;
  const requestImagePath = task.imagePath;
  const reportImagePaths = task.reportImagePaths;
  const mostRecentReportImagePath =
    reportImagePaths && reportImagePaths.length > 0
      ? reportImagePaths[reportImagePaths.length - 1]
      : "";
  const robotImagePath = task.task.robotImagePath;
  const [modalOpen, setModalOpen] = useState(false);
  const assignedToUser = task.task.assignedTo
    ? props.facilityUsers[task.task.assignedTo] || "Unknown user"
    : BasicLanguage.tasks.titles.assignedToNone[language];

  const users = task.task.assignee
    .filter((userId) => {
      return Object.keys(props.facilityUsers).includes(userId);
    })
    .map((userId) => {
      return props.facilityUsers[userId];
    });
  const completedByUser = task.task.completed
    ? props.facilityUsers[task.task.completedBy]
    : "-";
  const deleteTask = () => {
    deleteDoc(
      doc(db, ENV, VERSION, "facilities", props.facilityId, "notify", task.id)
    )
      .then(() => {
        alert(BasicLanguage.alert.deleted[language]);
        getReports();
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      })
      .finally(setModalOpen(false));
  };

  return (
    <AccordionDetails>
      <TableContainer
        sx={{
          mt: 2,
          maxHeight: "400px",
          "& .MuiTableCell-head": {
            backgroundColor: "rgba(220,220,220,1)",
            fontWeight: "bold",
          },
        }}
      >
        <Table sx={{ tableLayout: "fixed", width: "100%", overflow: "hidden" }}>
          <TableHead>
            <TableRow>
              <TableCell>{titles.completedBy[language]}</TableCell>
              <TableCell>{titles.assignee[language]}</TableCell>

              <TableCell>{titles.assignedTo[language]}</TableCell>

              <TableCell>{titles.taskMemo[language]}</TableCell>
              <TableCell>{titles.reportOutline[language]}</TableCell>

              <TableCell>
                {task.task.completed
                  ? titles.completedCleaniningImage[language]
                  : titles.requestCleaningImage[language]}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{completedByUser}</TableCell>
              <TableCell>
                {users.map((user) => (
                  <div key={user}>
                    {user}
                    <br />
                  </div>
                ))}
              </TableCell>

              <TableCell sx={{ whiteSpace: "pre-line" }}>
                <b>{task.task.completed ? "-" : assignedToUser}</b>
              </TableCell>

              <TableCell sx={{ whiteSpace: "pre-line" }}>{taskMemo}</TableCell>
              <TableCell sx={{ whiteSpace: "pre-line" }}>
                {reportOutline}
              </TableCell>
              <TableCell>
                <ImageTag
                  imagePath={
                    task.task.completed
                      ? mostRecentReportImagePath
                      : requestImagePath
                  }
                  robotImagePath={robotImagePath}
                  language={language}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="space-between">
        <IconButton onClick={() => setModalOpen(true)}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
        {!task.task.completed && (
          <IconButton>
            <Link href={`./edit/${task.id}`}>
              <EditOutlined />
            </Link>
          </IconButton>
        )}
      </Box>
      {modalOpen && (
        <DialogYesNo
          open={[modalOpen, setModalOpen]}
          yesAction={deleteTask}
          noAction={() => setModalOpen(false)}
          title={BasicLanguage.reports.deleteDialog.title[language]}
          message={BasicLanguage.reports.deleteDialog.message[language]}
        />
      )}
    </AccordionDetails>
  );
};

const TasksTable = (props) => {
  const facilityId = props.facilityId;
  const language = props.language;
  const { currentTimeZoneName } = useContext(TimeZoneContext);
  return props.tasks.map((task) => {
    return (
      <Accordion
        key={task.id}
        sx={{
          "& .MuiAccordionSummary-content": { display: "block" },
          backgroundColor: task.taskInProgress
            ? "#FFE1BF"
            : task.task.completed
            ? "rgba(70, 240, 120, 0.1)"
            : null,
        }}
      >
        <TaskSummary
          task={task}
          facilityId={facilityId}
          language={language}
          checkboxClick={props.checkboxClick}
          currentTimeZone={currentTimeZoneName}
        />
        <TaskDetails
          task={task}
          language={language}
          facilityUsers={props.facilityUsers}
          facilityId={facilityId}
          getReports={props.getReports}
        />
      </Accordion>
    );
  });
};

export default function Reports(props) {
  const params = useParams();
  const language = useRecoilValue(languageState);
  const facilityId = params.facilityId;
  const { currentTimeZoneName } = useContext(TimeZoneContext);
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [facilityUsers, setFacilityUsers] = useState({});

  const getReports = useCallback(() => {
    setTasks([]);
    setSelectedTasks([]);
    setIsLoading(true);

    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "notify"),
        orderBy("createdAt", "desc"),
        where("createdAt", ">=", startDate.toDate()),
        where("createdAt", "<=", endDate.toDate())
      )
    )
      .then((querysnapshot) => {
        const tasks = querysnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setTasks(tasks);
        setSelectedTasks(tasks.filter((task) => task.task?.completed));
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(setIsLoading(false));
  }, [facilityId, startDate, endDate]);

  useEffect(() => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
      .then((facilitySnapshot) => {
        const usersArray = [
          ...Object.values(facilitySnapshot.data().admin),
          ...Object.values(facilitySnapshot.data().users),
        ];
        const userObj = {};
        usersArray.forEach((user) => {
          userObj[user.uid] = user.displayName;
        });
        setFacilityUsers(userObj);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [facilityId, TimeZoneContext]);

  useEffect(() => {
    getReports();
  }, [getReports]);

  useEffect(() => {
    tasks.forEach(async function (task) {
      if (task?.task?.completed === true && task?.task?.robotAreas !== "") {
        await getReportImage({
          robotId: task?.task?.completedRobot,
          notifyId: task?.id,
          facilityId: facilityId,
          taskName: task?.task?.taskName,
        });
      }
    });
  }, [facilityId, tasks]);

  const downloadDayReport = (e) => {
    const titles = BasicLanguage.tasks.titles;
    const csvTitleString = `${titles.type[language]},${titles.location[language]},${titles.contents[language]},${titles.createdAt[language]},${titles.completionStatus[language]},${titles.completedBy[language]},${titles.assignee[language]},${titles.taskMemo[language]},${titles.reportOutline[language]},${titles.requestImageUrl[language]},${titles.reportImageUrl[language]}\n`;
    let csvBodyString = selectedTasks
      .sort((a, b) => b.createdAt - a.createdAt)
      .map((task) => {
        const type = task.type;
        const location = task.task.place;
        const comparison = task.task.comparison ? task.task.comparison : "";
        const threshold = task.task.threshold ? task.task.threshold : "";
        let request = task.task.request;
        if (type === "SENSOR" && threshold !== "" && comparison !== "") {
          request = `${request} (${BasicLanguage.sensor.terms.title[language]}: ${threshold} ${BasicLanguage.sensor.terms[comparison][language]} )`;
        }
        const createdAt = moment(task.createdAt.toDate())
          .tz(currentTimeZoneName)
          .format("YYYY-MM-DD HH:mm:ss");

        let completed;
        if (task.taskInProgress) {
          completed = BasicLanguage.tasks.status.inProgress[language];
        } else if (task.task.completed) {
          completed = BasicLanguage.tasks.status.true[language];
        } else {
          completed = BasicLanguage.tasks.status.false[language];
        }
        let completedBy = task.task.completed
          ? facilityUsers[task.task.completedBy]
          : "-";
        if (typeof completedBy === "undefined") {
          completedBy = "";
        }

        const assignees = task.task.assignee
          .filter((userId) => {
            return Object.keys(facilityUsers).includes(userId);
          })
          .map((userId) => {
            return facilityUsers[userId];
          })
          .join("\n");

        const taskMemo = task.task.taskMemo;
        const reportOutline = task.report?.outline ? task.report.outline : "";
        const requestImagePath = task.task.robotImagePath
          ? task.task.robotImagePath
          : task.imagePath;

        const reportImagePaths = task.reportImagePaths?.join("\n");

        return `"${type}","${location}","${request}","${createdAt}","${completed}","${completedBy}","${assignees}","${taskMemo}","${reportOutline}","${requestImagePath}","${reportImagePaths}"`;
      });

    const csvString = csvTitleString + csvBodyString.join("\n");
    const file_name = `${facilityId}_report_${startDate.format(
      "YYYYMMDD"
    )}.csv`;

    const bom = new Uint8Array([0xef, 0xbb, 0xbf]); //UTF-8文字コード設定
    let blob = new Blob([bom, csvString], { type: "text/csv" });
    let uri = URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.download = file_name;
    link.href = uri;
    link.click();
  };

  const prevDay = (e) => {
    setStartDate((prevStartDate) => prevStartDate.clone().subtract(1, "d"));
    setEndDate((prevEndDate) => prevEndDate.clone().subtract(1, "d"));
  };

  const nextDay = (e) => {
    setStartDate((prevStartDate) => prevStartDate.clone().add(1, "d"));
    setEndDate((prevEndDate) => prevEndDate.clone().add(1, "d"));
  };

  const checkboxOnClick = (e) => {
    e.stopPropagation();
    const reportId = e.target.value;
    if (e.target.checked) {
      setSelectedTasks((prevSelectedTasks) => [
        ...prevSelectedTasks,
        ...tasks.filter((report) => report.id === reportId),
      ]);
    } else {
      setSelectedTasks((prevSelectedTasks) => [
        ...prevSelectedTasks.filter((report) => report.id !== reportId),
      ]);
    }
  };

  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      <Typography className="pageTitle" variant="h4">
        {BasicLanguage.common.menu.report[language]}
      </Typography>
      <Button LinkComponent={Link} to="./add" variant="outlined" sx={{ m: 1 }}>
        {BasicLanguage.reports.addTask[language]}
      </Button>
      <Button
        LinkComponent={Link}
        to="./addComplete"
        variant="outlined"
        sx={{ m: 1 }}
      >
        {BasicLanguage.reports.addExecutedTask[language]}
      </Button>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mt: 2, maxWidth: 1200, ml: "auto", mr: "auto" }}
      >
        <Box>
          <IconButton disabled={isLoading} onClick={prevDay}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Box>
          <Typography variant="h4" sx={{ color: Colors.black }}>
            <Moment format="YYYY/MM/DD">{startDate}</Moment>
          </Typography>
        </Box>
        <Box>
          <IconButton disabled={isLoading} onClick={nextDay}>
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Stack>
      <Box sx={SBXStyles.mainBox}>
        <Stack direction="row" sx={{ mb: 2 }}>
          <Box sx={{ pl: 2, pr: 2, width: "100%" }}>
            <Typography>{BasicLanguage.reports.progress[language]}</Typography>
            <Typography sx={{ fontSize: "1.1em" }}>
              {tasks.filter((x) => x.task.completed === true).length}/
              {tasks.length}
            </Typography>
            <TaskProgressBar tasks={tasks} />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              LinkComponent={Link}
              variant="outlined"
              sx={{ m: 1 }}
              onClick={downloadDayReport}
            >
              {BasicLanguage.reports.generateTheDay[language]}
            </Button>
          </Box>
        </Stack>
        {isLoading ? (
          <Loading />
        ) : (
          <TasksTable
            tasks={tasks}
            facilityId={facilityId}
            language={language}
            facilityUsers={facilityUsers}
            checkboxClick={checkboxOnClick}
            getReports={getReports}
          />
        )}
      </Box>
    </Box>
  );
}
