import {
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { auth } from "../../utils/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import BasicLanguage from "../../languages/Basic";
import languageState from "../../../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import { deleteAuthUser, updateUserNameAndEmail } from "../../utils/functions";
import { logout } from "../../utils/session";
import PropTypes from "prop-types";
import { DialogYesNo } from "../Dialog";

const AdminButton = (props) => {
  const user = props.user;
  const removeAdmin = props.removeAdmin;
  const addAdmin = props.addAdmin;
  const [hover, setHover] = useState(false);
  const language = useRecoilValue(languageState);
  return (
    <>
      {user.customClaims?.admin ? (
        <>
          {auth.currentUser.uid === user.uid ? (
            <Tooltip
              title={BasicLanguage.admin.users.auth.selfAdmin[language]}
              placement="top"
            >
              <LocalPoliceIcon />
            </Tooltip>
          ) : (
            <IconButton
              onClick={() =>
                removeAdmin({ uid: user.uid, displayName: user.displayName })
              }
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {hover ? <RemoveModeratorIcon /> : <LocalPoliceIcon />}
            </IconButton>
          )}
        </>
      ) : (
        <IconButton
          onClick={() =>
            addAdmin({ uid: user.uid, displayName: user.displayName })
          }
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <AddModeratorIcon sx={{ opacity: hover ? 1 : 0 }} />
        </IconButton>
      )}
    </>
  );
};

export default function UserAuth(props) {
  const language = useRecoilValue(languageState);
  const user = props.user;
  const setAddAdminConfirmModal = props.setAddAdminConfirmModal;
  const setRemoveAdminConfirmModal = props.setRemoveAdminConfirmModal;
  const getAuthUsers = props.getAuthUsers;
  const addAdmin = async (params) => setAddAdminConfirmModal(params);
  const removeAdmin = async (params) => setRemoveAdminConfirmModal(params);

  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(user.displayName);
  const [email, setEmail] = useState(user.email);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalFunction, setModalFunction] = useState(null);
  const [modalMessage, setModalMessage] = useState();

  function submitEdit() {
    updateUserNameAndEmail({ uid: user.uid, displayName: name, email: email })
      .then((res) => {
        if (res.data?.status === "success") {
          if (user.uid === auth.currentUser.uid && email !== user.email) {
            alert(BasicLanguage.alert.emailChangeSuccess[language]);
            return logout();
          }
          getAuthUsers();
          setEditMode(false);
          handleModalClose();
        } else {
          throw new Error(res.data?.status);
        }
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  }

  function submitDelete() {
    deleteAuthUser({ uid: user.uid })
      .then((res) => {
        if (res.data?.status === "success") {
          if (user.uid === auth.currentUser.uid) {
            alert(BasicLanguage.admin.users.auth.selfDelete[language]);
            return logout();
          }
          getAuthUsers();
          setEditMode(false);
          handleModalClose();
        } else {
          throw new Error(res.data?.status);
        }
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  }

  const cancel = () => {
    setName(user.displayName);
    setEmail(user.email);
    setEditMode(false);
  };

  const handleModalOpen = (func, type) => {
    setModalFunction(() => func);
    setModalMessage(BasicLanguage.admin.users.auth[type][language]);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalFunction(null);
    setModalMessage(null);
    setModalOpen(false);
  };

  return (
    <>
      <TableRow
        sx={{
          "& td": {
            fontWeight: auth.currentUser.uid === user.uid ? "bold" : "",
          },
        }}
      >
        <TableCell> {user.uid ? user.uid : ""}</TableCell>
        <TableCell>
          {editMode ? (
            <TextField value={name} onChange={(e) => setName(e.target.value)} />
          ) : (
            user.displayName || ""
          )}
        </TableCell>
        <TableCell>
          {editMode ? (
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          ) : (
            user.email || ""
          )}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              sendPasswordResetEmail(auth, user.email).catch((error) => {
                console.error(error);
              });
            }}
          >
            {BasicLanguage.admin.common.send[language]}
          </Button>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          {user.emailVerified ? <MarkEmailReadIcon /> : "-"}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <AdminButton
            user={user}
            addAdmin={addAdmin}
            removeAdmin={removeAdmin}
          />
        </TableCell>
        <TableCell>
          {editMode ? (
            <Stack spacing={1}>
              <Button
                onClick={() => handleModalOpen(submitEdit, "edit")}
                variant="outlined"
                color="success"
              >
                {BasicLanguage.admin.common.save[language]}
              </Button>
              <Button
                onClick={() => handleModalOpen(submitDelete, "delete")}
                variant="outlined"
                color="error"
                name="delete"
              >
                {BasicLanguage.admin.common.delete[language]}
              </Button>
              <Button onClick={cancel} variant="outlined" color="primary">
                {BasicLanguage.admin.common.cancel[language]}
              </Button>
            </Stack>
          ) : (
            <Button
              onClick={() => setEditMode((prev) => !prev)}
              variant="outlined"
              fullWidth
            >
              {BasicLanguage.admin.common.edit[language]}
            </Button>
          )}
        </TableCell>
      </TableRow>
      {modalFunction !== null && (
        <DialogYesNo
          open={[modalOpen, setModalOpen]}
          yesAction={modalFunction}
          noAction={handleModalClose}
          title={user.displayName}
          message={modalMessage}
        />
      )}
    </>
  );
}

AdminButton.propTypes = {
  user: PropTypes.object,
  removeAdmin: PropTypes.func,
  addAdmin: PropTypes.func,
};

UserAuth.propTypes = {
  user: PropTypes.object,
  setAddAdminConfirmModal: PropTypes.func,
  setRemoveAdminConfirmModal: PropTypes.func,
  getAuthUsers: PropTypes.func,
  getFirestoreUsers: PropTypes.func,
};
