import React, { useCallback, useEffect, useMemo } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/ja";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BasicLanguage from "../../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

export default function DateRangePicker({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  startDateError,
  setStartDateError,
  endDateError,
  setEndDateError,
}) {
  const language = useRecoilValue(languageState);

  const handleStartDateChange = (date) => {
    const endDateLimit = moment(date).add(31, "days");
    if (endDate && (endDate.isAfter(endDateLimit) || endDate.isBefore(date))) {
      setEndDate(null);
    }

    setStartDate(date);
  };

  const dateError = useCallback(
    (error) => {
      switch (error) {
        case "minDate":
        case "maxDate":
        case "disableFuture": {
          return BasicLanguage.common.date.outOfRange[language];
        }
        case "invalidDate": {
          return BasicLanguage.common.date.invalidDate[language];
        }
        default: {
          return "";
        }
      }
    },
    [language]
  );

  const startErrorMessage = useMemo(() => {
    return dateError(startDateError);
  }, [dateError, startDateError]);

  const endErrorMessage = useMemo(() => {
    return dateError(endDateError);
  }, [dateError, endDateError]);

  useEffect(() => {
    moment.locale(language);
  }, [language]);

  return (
    <Box display={"flex"} justifyContent={"end"} gap={2} width={"50%"}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={language}
      >
        <DatePicker
          disableFuture
          label={BasicLanguage.common.date.startDate[language]}
          value={startDate}
          language={language}
          onError={setStartDateError}
          onChange={handleStartDateChange}
          slotProps={{
            textField: {
              size: "small",
              helperText: startErrorMessage,
            },
          }}
        />
        <DatePicker
          disableFuture
          language={language}
          label={BasicLanguage.common.date.endDate[language]}
          value={endDate}
          onError={setEndDateError}
          onChange={setEndDate}
          slotProps={{
            textField: {
              size: "small",
              helperText: endErrorMessage,
            },
          }}
          minDate={startDate}
          maxDate={moment(startDate).add(31, "days")}
          disabled={!startDate || !startDate.isValid()}
        />
      </LocalizationProvider>
    </Box>
  );
}

DateRangePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  startDateError: PropTypes.string,
  setStartDateError: PropTypes.func,
  endDateError: PropTypes.string,
  setEndDateError: PropTypes.func,
};
