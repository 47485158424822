import * as React from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SBXStyles from "../components/utils/Styles";
import { useNavigate, useParams } from "react-router";
import CommonError from "./CommonError";
import { Link } from "react-router-dom";
import Typography from "../components/parts-ui/Typography";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  runTransaction,
  updateDoc,
  where,
} from "firebase/firestore";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import Loading from "../components/parts/Loading";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import StorageImage from "../components/parts/StorageImage";
import {
  getDownloadURL,
  ref,
  deleteObject,
  listAll,
  uploadBytes,
} from "@firebase/storage";
import { db, storage } from "../components/utils/firebase";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DialogYesNo } from "../components/parts/Dialog";
import PropTypes from "prop-types";
import LoadPngButton from "../components/parts/LoadPngButton";
import PreviewImage from "../components/parts/PreviewImage";
import { storageRefImageAtp } from "../components/utils/Conditions";
import moment from "moment";
import { TimeZoneContext } from "../components/utils/setTimeZoneContext";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const getLatestAtpHistoryDoc = async (facilityId, atpId) => {
  return await getDocs(
    query(
      collection(db, ENV, VERSION, "facilities", facilityId, "atpHistory"),
      where("atpId", "==", atpId),
      orderBy("createdAt", "desc"),
      limit(1)
    )
  );
};

const EditableDetail = (props) => {
  const params = useParams();
  const facilityId = params.facilityId;
  const atpId = params.atpId;
  const atp = props.atp;
  const name = props.name;
  const getATP = props.getATP;
  const language = useRecoilValue(languageState);

  const input = React.useRef();
  const [editing, setEditing] = React.useState(false);

  const onClickHandler = () => {
    updateDoc(doc(db, ENV, VERSION, "facilities", facilityId, "atps", atpId), {
      [name]: input.current.value,
    })
      .then(() => {
        setEditing(false);
        getATP();
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  return (
    <>
      {editing ? (
        <>
          <TextField
            size="small"
            variant="outlined"
            inputRef={input}
            defaultValue={atp[name]}
            autoFocus={true}
          />
          <IconButton type="submit" onClick={onClickHandler}>
            <CheckCircleIcon color="success" />
          </IconButton>
          <IconButton onClick={() => setEditing(false)}>
            <CancelIcon color="error" />
          </IconButton>
        </>
      ) : (
        <>
          {atp[name]}
          <IconButton sx={{ ml: 1 }} onClick={() => setEditing(true)}>
            <EditIcon sx={{ fontSize: "0.7em" }} />
          </IconButton>
        </>
      )}
    </>
  );
};

EditableDetail.propTypes = {
  atp: PropTypes.object,
  name: PropTypes.string,
  getATP: PropTypes.func,
};

const DeletableImage = (props) => {
  const params = useParams();
  const facilityId = params.facilityId;
  const atpId = params.atpId;
  const imagePath = props.imagePath;
  const getATP = props.getATP;
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const language = useRecoilValue(languageState);

  const deleteImage = async () => {
    try {
      await deleteObject(ref(storage, imagePath));

      const latestAtpHistoryDoc = await getLatestAtpHistoryDoc(
        facilityId,
        atpId
      );
      await runTransaction(db, async (transaction) => {
        transaction.update(
          doc(db, ENV, VERSION, "facilities", facilityId, "atps", atpId),
          {
            images: arrayRemove(imagePath),
          }
        );
        transaction.update(latestAtpHistoryDoc.docs[0].ref, {
          images: arrayRemove(imagePath),
        });
      });
      getATP();
    } catch (e) {
      console.error(e);
      alert(BasicLanguage.alert.error.default[language]);
    } finally {
      setDeleteModalOpen(false);
    }
  };

  const onDeleteHandler = () => {
    setDeleteModalOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          width: "200px",
          height: "200px",
          border: "1px solid #ddd",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <StorageImage
          imagePath={imagePath}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0 }}
          onClick={onDeleteHandler}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Box>
      {deleteModalOpen && (
        <DialogYesNo
          open={[deleteModalOpen, setDeleteModalOpen]}
          yesAction={deleteImage}
          noAction={() => setDeleteModalOpen(false)}
          title={BasicLanguage.atpDetail.dialog.delete.title[language]}
          message={BasicLanguage.atpDetail.dialog.delete.message[language]}
        />
      )}
    </>
  );
};

DeletableImage.propTypes = {
  getATP: PropTypes.func,
  imagePath: PropTypes.string,
};

const NewImage = (props) => {
  const params = useParams();
  const facilityId = params.facilityId;
  const atpId = params.atpId;
  const getATP = props.getATP;
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [uploadedImageFile, setUploadedImageFile] = React.useState(null);
  const language = useRecoilValue(languageState);

  const addImage = async () => {
    const storageRef = ref(storage, storageRefImageAtp(atpId, facilityId));
    try {
      const storageImage = await uploadBytes(storageRef, uploadedImageFile);
      const imagePath = storageImage.ref._location.path;
      const latestAtpHistoryDoc = await getLatestAtpHistoryDoc(
        facilityId,
        atpId
      );
      await runTransaction(db, async (transaction) => {
        transaction.update(
          doc(db, ENV, VERSION, "facilities", facilityId, "atps", atpId),
          {
            images: arrayUnion(imagePath),
          }
        );
        transaction.update(latestAtpHistoryDoc.docs[0].ref, {
          images: arrayUnion(imagePath),
        });
      });

      getATP();
      setUploadedImage(null);
      setUploadedImageFile(null);
    } catch (e) {
      console.error(e);
      alert(BasicLanguage.alert.error.default[language]);
    }
  };

  const clearImage = () => {
    setUploadedImage(null);
    setUploadedImageFile(null);
  };

  return (
    <Box>
      {uploadedImage ? (
        <PreviewImage
          uploadedImage={uploadedImage}
          addImage={addImage}
          clearImage={clearImage}
        />
      ) : (
        <LoadPngButton
          setUploadedImage={setUploadedImage}
          setUploadedImageFile={setUploadedImageFile}
        />
      )}
    </Box>
  );
};

NewImage.propTypes = {
  getATP: PropTypes.func,
};

const ATPDetailsTable = () => {
  const params = useParams();
  const facilityId = params.facilityId;
  const atpId = params.atpId;
  const language = useRecoilValue(languageState);
  const [atp, setATP] = React.useState(null);
  const [isAtpLoading, setAtpIsLoading] = React.useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const navigate = useNavigate();

  const getATP = React.useCallback(() => {
    setAtpIsLoading(true);
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "atps", atpId))
      .then((docSnap) => {
        if (!docSnap.data()) {
          navigate("../atp");
        }
        setATP({ ...docSnap.data() });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setAtpIsLoading(false);
      });
  }, [atpId, facilityId, navigate]);

  React.useEffect(() => {
    getATP();
  }, [getATP]);

  const handleDelete = async () => {
    setDeleteModalOpen(false);
    try {
      const atpRef = doc(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        "atps",
        atpId
      );
      const dashboardAtp = await getDocs(
        query(
          collection(db, ENV, VERSION, "facilities", facilityId, "atpHistory"),
          where("atpId", "==", atpId),
          where("showDashboard", "==", true),
          limit(1)
        )
      ).then((atpDocs) => {
        if (!atpDocs.empty) {
          return atpDocs.docs[0];
        }
      });

      //Firestoreからの削除
      await runTransaction(db, async (transaction) => {
        transaction.delete(atpRef);
        //Dashboardに表示するATP履歴があったら、非表示にする
        if (dashboardAtp) {
          transaction.update(dashboardAtp.ref, {
            showDashboard: false,
          });
        }
      });

      const atpImagesRef = ref(
        storage,
        `uploaded_image/${facilityId}/atps/${atpId}`
      );

      //Storageから画像を全て削除する
      listAll(atpImagesRef).then((res) => {
        res.items.forEach((atpImageRef) => {
          deleteObject(atpImageRef);
        });
      });

      alert(BasicLanguage.alert.deleted[language]);
      navigate("../atp");
    } catch (e) {
      console.error(e);
      alert(BasicLanguage.alert.error.default[language]);
    }
  };

  const table = (
    <>
      {!atp ? (
        <CommonError />
      ) : (
        <Box>
          <Typography variant="h4">
            <IconButton LinkComponent={Link} to={"../atp"}>
              <ArrowBackIcon />
            </IconButton>
            {atp.name}
          </Typography>
          <Box sx={SBXStyles.mainBox}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {BasicLanguage.atpDetail.table.category[language]}
                  </TableCell>
                  <TableCell width="70%">
                    {BasicLanguage.atpDetail.table.content[language]}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {BasicLanguage.atpDetail.table.testLocation[language]}
                  </TableCell>
                  <TableCell>
                    <EditableDetail
                      atp={atp}
                      name="testLocation"
                      getATP={getATP}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {BasicLanguage.atpDetail.table.value[language]}
                  </TableCell>
                  <TableCell>{atp.value}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {BasicLanguage.atpDetail.table.coordinates[language]}
                  </TableCell>
                  <TableCell>
                    <Box>{atp.coordinates?.[0]}</Box>
                    <Box>{atp.coordinates?.[1]}</Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {BasicLanguage.atpDetail.table.manufacturer[language]}
                  </TableCell>
                  <TableCell>
                    <EditableDetail
                      atp={atp}
                      name="manufacturer"
                      getAtp={getATP}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {BasicLanguage.atpDetail.table.memo[language]}
                  </TableCell>
                  <TableCell>
                    <EditableDetail atp={atp} name="memo" getAtp={getATP} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {BasicLanguage.atpDetail.table.images[language]}
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={3}
                      flexWrap="wrap"
                    >
                      {atp.images?.map((imagePath) => {
                        return (
                          <DeletableImage
                            key={imagePath}
                            imagePath={imagePath}
                            atp={atp}
                            getATP={getATP}
                          />
                        );
                      })}
                      {atp.images?.length < 6 && <NewImage getATP={getATP} />}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Button
              variant="contained"
              color="error"
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => setDeleteModalOpen(true)}
            >
              {BasicLanguage.common.delete[language]}
            </Button>
          </Box>
          <DialogYesNo
            open={[deleteModalOpen, setDeleteModalOpen]}
            yesAction={handleDelete}
            noAction={() => setDeleteModalOpen(false)}
            title={atp.name}
            message={BasicLanguage.atpDetail.delete[language]}
          />
        </Box>
      )}
    </>
  );
  return (
    <>
      {isAtpLoading ? (
        <Box sx={SBXStyles.mainBox}>
          <Loading />
        </Box>
      ) : (
        table
      )}
    </>
  );
};

const ATPHistoryTable = () => {
  const { currentTimeZoneName } = React.useContext(TimeZoneContext);
  const language = useRecoilValue(languageState);
  const params = useParams();
  const atpId = params.atpId;
  const facilityId = params.facilityId;

  const [atpHistory, setATPHistory] = React.useState([]);
  const [isAtpHistoryLoading, setAtpHistoryIsLoading] = React.useState(true);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [showDashboardDisplay, setShowDashboardDisplay] = React.useState(false);

  const getATPHistory = React.useCallback(() => {
    setAtpHistoryIsLoading(true);
    const historyCollection = collection(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "atpHistory"
    );
    let historyQuery = query(
      historyCollection,
      where("atpId", "==", atpId),
      orderBy("createdAt", "desc"),
      limit(100),
      where("createdAt", "<=", dayjs().toDate())
    );
    if (selectedDate) {
      historyQuery = query(
        historyCollection,
        where("atpId", "==", atpId),
        orderBy("createdAt", "desc"),
        limit(100),
        where("createdAt", ">=", selectedDate.startOf("date").toDate()),
        where("createdAt", "<", selectedDate.endOf("date").toDate())
      );
    }
    getDocs(historyQuery)
      .then((querySnap) => {
        setATPHistory(
          querySnap.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setAtpHistoryIsLoading(false);
      });
  }, [atpId, facilityId, selectedDate]);

  React.useEffect(() => {
    getATPHistory();
  }, [getATPHistory]);

  React.useEffect(() => {
    dayjs.locale(language);
  }, [language]);

  React.useEffect(() => {
    getDocs(collection(db, ENV, VERSION, "facilities", facilityId, "kpis"))
      .then((kpisSnapshot) => {
        if (!kpisSnapshot.empty) {
          return kpisSnapshot.docs[0].data();
        }
      })
      .then((kpi) => {
        if (kpi.active && kpi.facilityCleanliness) {
          setShowDashboardDisplay(true);
        }
      })
      .catch((e) => console.error(e));
  }, [facilityId]);

  const resetDate = () => {
    setSelectedDate(null);
  };

  const changeDate = (value) => {
    if (value && value.isValid()) {
      setSelectedDate(value);
    }
  };

  /**
   * ATPスコア履歴のCSV中身を生成する
   *
   * @returns {Promise<string>} csvString
   */
  const generateCSV = async () => {
    const csvHeader = [
      BasicLanguage.atpDetail.history.table.value[language],
      BasicLanguage.atpDetail.history.table.dateTime[language],
      ...[1, 2, 3, 4, 5, 6].map(
        (n) => `${BasicLanguage.atpCreate.table.image[language]}${n}`
      ), // 画像1 ... 画像6
    ].join(",");

    const csvRows = await Promise.all(
      atpHistory.map(async (atp) => {
        const value = atp.value;
        const dateTime = moment(atp.createdAt.toDate())
          .tz(currentTimeZoneName)
          .format("YYYY-MM-DD HH:mm:ss");
        const imageUrls = await Promise.all(
          atp.images?.map((imagePath) =>
            getDownloadURL(ref(storage, imagePath))
          )
        );

        return [value, dateTime, ...imageUrls].join(",");
      })
    );
    return [csvHeader, ...csvRows].join("\n");
  };

  const downloadCSV = () =>
    generateCSV()
      .then((csvString) => {
        const date = selectedDate || moment().tz(currentTimeZoneName);

        const csvFileName = `${facilityId}_ATP_${atpId}_${date.format(
          "YYYYMMDD"
        )}.csv`;

        const bom = new Uint8Array([0xef, 0xbb, 0xbf]); //UTF-8文字コード設定
        const blob = new Blob([bom, csvString], { type: "text/csv" });
        const uri = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.download = csvFileName;
        link.href = uri;
        link.click();
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });

  const handleShowHistoryClick = async (id) => {
    const historyRef = doc(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "atpHistory",
      id
    );
    const historyQuery = query(
      collection(db, ENV, VERSION, "facilities", facilityId, "atpHistory"),
      where("showDashboard", "==", true),
      where("atpId", "==", atpId),
      limit(1)
    );

    try {
      const prevHistoryDocs = await getDocs(historyQuery);

      await runTransaction(db, async (transaction) => {
        prevHistoryDocs.forEach((doc) => {
          transaction.update(doc.ref, {
            showDashboard: false,
          });
        });

        transaction.update(historyRef, {
          showDashboard: true,
        });
      });

      alert(BasicLanguage.alert.updated[language]);
      getATPHistory();
    } catch (e) {
      console.error(e);
      alert(BasicLanguage.alert.error.default[language]);
    }
  };

  const handleHideHistoryClick = (id) => {
    const historyRef = doc(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "atpHistory",
      id
    );

    updateDoc(historyRef, {
      showDashboard: false,
    })
      .then(() => {
        alert(BasicLanguage.alert.updated[language]);
        getATPHistory();
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  return (
    <Box sx={SBXStyles.mainBox}>
      <Typography variant="h5">
        {BasicLanguage.atpDetail.history.title[language]}
      </Typography>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button variant="outlined" onClick={resetDate}>
          {BasicLanguage.sensor.recent[language]}
        </Button>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={language}
        >
          <DatePicker
            disableFuture={true}
            value={selectedDate}
            onChange={changeDate}
            format={BasicLanguage.common.date.datePicker.long[language]}
          />
        </LocalizationProvider>
      </Stack>

      <TableContainer
        sx={{
          mt: 2,
          maxHeight: "400px",
          "& .MuiTableCell-head": {
            backgroundColor: "rgba(220,220,220,1)",
            fontWeight: "bold",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                {BasicLanguage.atpDetail.history.table.value[language]}
              </TableCell>
              <TableCell>
                {BasicLanguage.atpDetail.history.table.dateTime[language]}
              </TableCell>
              {showDashboardDisplay && (
                <TableCell width="20%">
                  {
                    BasicLanguage.atpDetail.history.table.showDashboard[
                      language
                    ]
                  }
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isAtpHistoryLoading ? (
              <TableRow>
                <TableCell colSpan="100%">
                  <Loading />
                </TableCell>
              </TableRow>
            ) : (
              atpHistory.map((atp) => {
                return (
                  <TableRow key={atp.id}>
                    <TableCell>{atp.value}</TableCell>
                    <TableCell>
                      {moment(atp.createdAt.seconds * 1000)
                        .tz(currentTimeZoneName)
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                    {showDashboardDisplay && (
                      <TableCell>
                        {atp.showDashboard ? (
                          <IconButton
                            color="error"
                            onClick={() => handleHideHistoryClick(atp.id)}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => handleShowHistoryClick(atp.id)}
                            color="primary"
                          >
                            <CheckCircleOutlineIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        {atpHistory.length > 0 && (
          <Button variant="outlined" onClick={downloadCSV}>
            {BasicLanguage.common.download[language]}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default function AtpDetail() {
  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      <ATPDetailsTable />
      <ATPHistoryTable />
    </Box>
  );
}
