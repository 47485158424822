import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import {
  addDoc,
  collection,
  deleteField,
  doc,
  getDocs,
  query,
  runTransaction,
  updateDoc,
  where,
} from "firebase/firestore";

import { db, storage } from "../../utils/firebase";
import {
  acceptInput,
  fileExtension,
  isImageFile,
  storageRefImageMaps,
} from "../../utils/Conditions";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import BasicLanguage from "../../languages/Basic";
import { DialogYesNo } from "../Dialog";
const { v4: uuidv4 } = require("uuid");
const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function MapsForm(props) {
  const params = useParams();
  const facilityId = params.facilityId;
  const language = useRecoilValue(languageState);
  const navigate = useNavigate();
  const map = props.map;
  const [name, setName] = useState(map ? map.name : "");
  const [nameError, setNameError] = useState(false);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const refFile = useRef();

  useEffect(() => {
    if (map) {
      const storageRef = ref(storage, map.image);
      getDownloadURL(storageRef).then((url) => {
        setImage(url);
      });
    }
  }, [map]);

  const submitHandler = () => {
    if (!formIsValid()) {
      return;
    }
    map ? editFirestoreMap() : addFirestoreMap();
  };

  const formIsValid = () => {
    let isValid = true;
    setNameError(false);
    setImageError(false);

    if (name === "") {
      setNameError(true);
      isValid = false;
    }

    if (image === null) {
      setImageError(true);
      isValid = false;
    }

    return isValid;
  };

  const addFirestoreMap = () => {
    const imagePath =
      "uploaded_image/" + facilityId + "/maps/" + uuidv4() + fileExtension;
    addDoc(collection(db, ENV, VERSION, "facilities", facilityId, "maps"), {
      name: name,
      image: imagePath,
    })
      .then(() => {
        refFile.current.value = null;
        const storageRef = ref(storage, imagePath);
        uploadBytes(storageRef, imageFile)
          .then((snapshot) => {
            alert(BasicLanguage.alert.added[language]);
            navigate("../maps");
          })
          .catch((e) => {
            console.error(e);
            alert(BasicLanguage.alert.error.default[language]);
          });
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  const editFirestoreMap = async () => {
    const editData = {
      ...(name !== map.name && {
        name: name,
      }),
    };

    const imagePath = storageRefImageMaps(facilityId);
    if (imageFile) {
      editData.image = imagePath;
    }

    try {
      if (Object.keys(editData).length !== 0) {
        const docRef = doc(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "maps",
          map.id
        );
        await updateDoc(docRef, editData);

        if (imageFile) {
          const storageRef = ref(storage, imagePath);
          await uploadBytes(storageRef, imageFile);
        }
      }

      alert(BasicLanguage.alert.edited[language]);
      return navigate("../maps");
    } catch (e) {
      console.error(e);
      alert(BasicLanguage.alert.error.default[language]);
    }
  };

  const deleteFirestoreMap = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const sensorQuery = query(
          collection(db, ENV, VERSION, "facilities", facilityId, "sensors"),
          where("map", "==", map.id)
        );
        const atpQuery = query(
          collection(db, ENV, VERSION, "facilities", facilityId, "atps"),
          where("map", "==", map.id)
        );
        const mapDoc = doc(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "maps",
          map.id
        );

        const sensorDocs = await getDocs(sensorQuery);
        const atpDocs = await getDocs(atpQuery);

        sensorDocs.docs.forEach((doc) => {
          transaction.update(doc.ref, {
            map: deleteField(),
            coordinates: deleteField(),
          });
        });

        atpDocs.docs.forEach((doc) => {
          transaction.update(doc.ref, {
            map: deleteField(),
            coordinates: deleteField(),
          });
        });

        transaction.delete(mapDoc);
        alert(BasicLanguage.alert.deleted[language]);
        return navigate("../maps");
      });
    } catch (e) {
      console.error(e);
      alert(BasicLanguage.alert.error.default[language]);
      setDeleteModalOpen(false);
    }
  };

  const imageSelected = (e) => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const fileSize = file.size;
      if (fileSize === 0) return false;

      const fileMib = fileSize / 1024 ** 2;
      if (fileMib <= 10 && isImageFile(file)) {
        setImage(reader.result);
        setImageFile(file);
      } else {
        alert(BasicLanguage.alert.file[language]);
        return false;
      }
    };
    reader.readAsDataURL(file);
  };
  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography varinat="h5">
                {BasicLanguage.mapsForm.floorName[language]}
              </Typography>
            </TableCell>
            <TableCell>
              <FormControl error={nameError}>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={nameError}
                />
                {nameError && (
                  <FormHelperText>
                    {BasicLanguage.mapsForm.error.floorName[language]}
                  </FormHelperText>
                )}
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography varinat="h5">
                {BasicLanguage.mapsForm.uploadImage[language]}
              </Typography>
            </TableCell>
            <TableCell>
              {image ? (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={image}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Typography sx={{ textAlign: "left" }}>
                  {BasicLanguage.mapsForm.noImage[language]}
                </Typography>
              )}
              <FormControl error={imageError}>
                <label htmlFor="imageFileButton">
                  <input
                    id="imageFileButton"
                    type="file"
                    onChange={imageSelected}
                    accept={acceptInput}
                    style={{ display: "none" }}
                    ref={refFile}
                  />
                  <Button component="span" variant="outlined" fullWidth>
                    {BasicLanguage.mapsForm.imageButton[language]}
                  </Button>
                </label>
                {imageError && (
                  <FormHelperText>
                    {BasicLanguage.alert.file[language]}
                  </FormHelperText>
                )}
              </FormControl>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box display="flex" sx={{ gap: 2 }}>
        {map && (
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
            color="error"
            onClick={() => setDeleteModalOpen(true)}
          >
            {BasicLanguage.mapsForm.delete[language]}
          </Button>
        )}
        <Button
          fullWidth
          onClick={submitHandler}
          variant="contained"
          sx={{ mt: 2 }}
          type="submit"
        >
          {map
            ? BasicLanguage.mapsForm.edit[language]
            : BasicLanguage.mapsForm.add[language]}
        </Button>
      </Box>
      {map && (
        <DialogYesNo
          open={[deleteModalOpen, setDeleteModalOpen]}
          yesAction={deleteFirestoreMap}
          noAction={() => setDeleteModalOpen(false)}
          title={BasicLanguage.mapsForm.dialog.yesNo.title[language]}
          message={BasicLanguage.mapsForm.dialog.yesNo.message[language]}
        />
      )}
    </>
  );
}
