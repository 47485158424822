import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { SbxBarChartLine } from "../SbxChart";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import moment from "moment";
import BasicLanguage from "../../languages/Basic";
import PropTypes from "prop-types";

export default function PeopleCountSensorChart({
  date,
  dateDisplay,
  peopleCountSensorData,
  sensorIds,
}) {
  const language = useRecoilValue(languageState);

  if (dateDisplay === "day") {
    peopleCountSensorData = generateDataByDay(
      peopleCountSensorData,
      sensorIds,
      date
    );
  } else if (dateDisplay === "week") {
    peopleCountSensorData = generateDataByWeek(
      peopleCountSensorData,
      sensorIds,
      date
    );
  } else if (dateDisplay === "month") {
    peopleCountSensorData = generateDataByMonth(
      peopleCountSensorData,
      sensorIds,
      language,
      date
    );
  }

  return (
    <Stack sx={{ width: "100%" }} justifyContent="center">
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "1.2em",
        }}
      >
        {BasicLanguage.dashboard.kpi.peopleCountSensor.title[language]}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ width: "100%", height: "300px" }}>
        <SbxBarChartLine data={peopleCountSensorData} dataKeys={sensorIds} />
      </Box>
    </Stack>
  );
}

PeopleCountSensorChart.propTypes = {
  date: PropTypes.object.isRequired,
  dateDisplay: PropTypes.string.isRequired,
  peopleCountSensorData: PropTypes.array.isRequired,
  sensorIds: PropTypes.array.isRequired,
};

const generateDataByDay = (sensorHistory, sensorIds, date) => {
  const defaultDays = {};
  for (let i = 1; i <= moment(date).daysInMonth(); i++) {
    defaultDays[i] = {};
    for (let j = 0; j < sensorIds.length; j++) {
      defaultDays[i][sensorIds[j]] = 0;
    }
  }

  const sensorHistoryPeopleCount = sensorHistory.reduce(
    (sensorHistoryObject, history) => {
      const date = moment(history.createdAt).date();
      sensorHistoryObject[date][history.deviceId] =
        sensorHistoryObject[date][history.deviceId] + history.value;
      return sensorHistoryObject;
    },
    defaultDays
  );

  return Object.keys(sensorHistoryPeopleCount)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        ...sensorHistoryPeopleCount[key],
      };
    });
};

const generateDataByWeek = (sensorHistory, sensorIds, date) => {
  const defaultWeeks = {};
  const startWeek = moment(date).startOf("month").week();
  const endWeek = moment(date).endOf("month").week();
  for (let i = startWeek; i <= endWeek; i++) {
    defaultWeeks[i] = {};
    for (let j = 0; j < sensorIds.length; j++) {
      defaultWeeks[i][sensorIds[j]] = 0;
    }
  }

  const sensorHistoryPeopleCount = sensorHistory.reduce(
    (sensorHistoryObject, history) => {
      const week = moment(history.createdAt).week();
      sensorHistoryObject[week][history.deviceId] =
        sensorHistoryObject[week][history.deviceId] + history.value;
      return sensorHistoryObject;
    },
    defaultWeeks
  );

  return Object.keys(sensorHistoryPeopleCount)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        ...sensorHistoryPeopleCount[key],
      };
    });
};

const generateDataByMonth = (sensorHistory, sensorIds, language, date) => {
  const defaultMonth = {month: {}};
  for (let i = 0; i < sensorIds.length; i++) {
    defaultMonth.month[sensorIds[i]] = 0;
  }

  const sensorHistoryPeopleCount = sensorHistory.reduce(
    (sensorHistoryObject, history) => {
      sensorHistoryObject.month[history.deviceId] += history.value;
      return sensorHistoryObject;
    },
    defaultMonth
  );

  return Object.keys(sensorHistoryPeopleCount).map((key) => {
    return {
      name: moment(date).format(
        BasicLanguage.dashboard.kpi.peopleCountAll.yearMonthFormat[language]
      ),
        ...sensorHistoryPeopleCount[key],
    };
  });
};
