import React from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import BasicLanguage from "../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../recoil/atoms/languageState";
import { Link } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

FloorAreas.propTypes = {
  floorAreas: PropTypes.array,
  floorId: PropTypes.number,
};

export default function FloorAreas(props) {
  const areas = props.floorAreas;
  const language = useRecoilValue(languageState);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" sx={{ width: "50%", textAlign: "center" }}>
        {BasicLanguage.robots.detail.floors.areas[language]}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>
              {BasicLanguage.robots.detail.floors.name[language]}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(areas).map((areaId) => {
            return (
              <TableRow key={areaId}>
                <TableCell>{areaId}</TableCell>
                <TableCell>{areas[areaId].name}</TableCell>
                <TableCell>
                  <IconButton
                    LinkComponent={Link}
                    to={`./floors/${props.floorId}/areas/${areaId}`}
                  >
                    <SettingsOutlinedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}
