import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom"
import HeaderSidebar from "../parts/HeaderSidebar"
import { AppBar, Avatar, Box, IconButton, Menu, MenuItem, Stack, Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { useRecoilState, useRecoilValue } from "recoil";
import userState from '../../recoil/atoms/userState';
import { useSession } from "../utils/session";
import { Helmet } from "react-helmet-async";
import facilityState from "../../recoil/atoms/facilityState";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../utils/firebase";
import { useTheme } from "@emotion/react";
import CommonHeaderItems from "../parts/CommonHeaderItems";

const ENV = process.env.REACT_APP_FIRESTORE_ENV
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION

const CleanerLayout = (props) => {
    useSession();
    const navigate = useNavigate();
    const theme = useTheme();
    const params = useParams();
    const facilityId = params.facilityId;

    const [facility, setFacility] = useRecoilState(facilityState);

    const user = useRecoilValue(userState);

    React.useEffect(() => {


        setFacility({})
        getDoc(doc(db, ENV, VERSION, "facilities", facilityId)).then(x => {
            if (x.data()) {
                setFacility(x.data())
            } else {
                navigate("/")
            }
        })
            .catch((e) => {
                navigate("/")
                console.error(e);
            })
    }, [facilityId, auth])


    return <>


        <Helmet>
            <title>{(facility ? facility.name : "") + " - SmartBX Connect"}</title>
        </Helmet>

        <Box sx={{
            height: "80px",
            display: "flex",
            alignItems: "center",
            mr: 5,
        }}>
            <Stack direction="row" justifyContent="space-between" sx={{ flex: 1, height: "80px", }}>
                <Box sx={{ mt: "10px", mb: "10px" }}>
                    <Stack direction="row" alignItems="center" sx={{ flex: 1 }} spacing={2}>
                        <Box sx={{ width: "13em", pl: "24px", transition: "all .32s ease-out;", }}>
                            <Link to="/">
                                <img src="/logo-removebg-preview.png" style={{ height: "30px", width: "auto" }} />
                            </Link>
                        </Box>
                    </Stack>
                </Box>
                <CommonHeaderItems />
            </Stack>
        </Box >

        <Outlet />
    </>
}
export default CleanerLayout;