import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const menuExtendedState = atom({
    key: 'menuExtended',
    default: true,
    effects_UNSTABLE: [persistAtom]
});

export default menuExtendedState;