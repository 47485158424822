import { Typography as MuiTypography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Colors from '../utils/Colors';

const Typography = styled(MuiTypography)(({ theme }) => ({

    '&.pageTitle': {
        fontSize: "1.8em",
        marginBottom: 12,
        fontWeight: 700,
    },
    '&.subTitle': {
        fontSize: "1.2em",
        marginBottom: 12,
        fontWeight: 700,
        marginTop: ".6em",
    },
    '&.pageDescription': {
        fontSize: "1em",
        marginBottom: 12,
        marginTop: 12,
    },

}))

export default Typography