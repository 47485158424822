import * as React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import SBXStyles from "../components/utils/Styles";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import BasicLanguage from "../components/languages/Basic";

import Moment from "react-moment";

import Map from "../components/parts/Map";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
} from "recharts";
import Chart from "../components/parts/Chart";
import Alerts from "./Alerts";
import { Link, useNavigate, useParams } from "react-router-dom";
import Colors from "../components/utils/Colors";

import ColorizeIcon from "@mui/icons-material/Colorize";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SettingsRemoteOutlinedIcon from "@mui/icons-material/SettingsRemoteOutlined";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MapIcon from "@mui/icons-material/Map";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";

import DashboardIcon from "@mui/icons-material/Dashboard";

import { useRecoilState, useRecoilValue } from "recoil";
import HeaderSidebar from "../components/parts/HeaderSidebar";
import languageState from "../recoil/atoms/languageState";
import CircularProgress from "@mui/material/CircularProgress";

import Typography from "../components/parts-ui/Typography";
import facilityState from "../recoil/atoms/facilityState";
import { useTheme } from "@emotion/react";

import { db } from "../components/utils/firebase";
import { getDocs, query, collection, where } from "firebase/firestore";
import { Settings } from "@mui/icons-material";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const styleQuickAccess = {
  fontSize: "1.4em",
  fontWeight: "bold",
};

export default function Home(props) {
  const params = useParams();
  const sensors = props.sensors ? props.sensors : [];
  const theme = useTheme();

  const [dashboardTab, setDashboardTab] = React.useState(0);
  const [alertSensorDetailVisible, setAlertSensorDetailVisible] =
    React.useState(false);
  const [alertBatteryDtailVisible, setBatterySensorDetailVisible] =
    React.useState(false);

  const [selectedSensor, setSelectedSensor] = React.useState();

  const facilityId = params.facilityId;
  const facility = useRecoilValue(facilityState);

  const language = useRecoilValue(languageState);

  const [robot, setRobot] = React.useState(false);

  React.useEffect(() => {
    getActiveRobot();
  }, [facility]);

  const getActiveRobot = React.useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "robots"),
        where("active", "==", true)
      )
    )
      .then((docsnap) => {
        if (docsnap.empty) {
          setRobot(false);
        } else {
          setRobot(true);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [facilityId]);

  return (
    <>
      <Box
        component="main"
        sx={{
          ...SBXStyles.mainContainer,
          maxWidth: "1200px",
          ml: "auto",
          mr: "auto",
          "& a": {
            textDecoration: "none",
            "&:hover": { color: Colors.secondary.main },
          },
        }}
      >
        <Typography className="pageTitle" variant="h4">
          {facility?.name ? facility.name : <CircularProgress />}
        </Typography>
        <Alerts sensors={sensors} language={language}></Alerts>

        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Box
            sx={{
              ...SBXStyles.mainBox2,
              textAlign: "center",
              color: theme.palette.primary.dark,
            }}
            component={Link}
            to="./qr"
          >
            <Box>
              <QrCode2OutlinedIcon sx={{ fontSize: 104 }} />
            </Box>
            <Box sx={{ fontWeight: 700, letterSpacing: "0.1em" }}>
              {BasicLanguage.home.btnQr[language]}
            </Box>
          </Box>
          <Box
            sx={{
              ...SBXStyles.mainBox2,
              textAlign: "center",
              color: theme.palette.primary.dark,
            }}
            component={Link}
            to="./sensors"
          >
            <Box>
              <SettingsRemoteOutlinedIcon sx={{ fontSize: 104 }} />
            </Box>
            <Box sx={{ fontWeight: 700, letterSpacing: "0.1em" }}>
              {BasicLanguage.home.btnSensor[language]}
            </Box>
          </Box>
          <Box
            sx={{
              ...SBXStyles.mainBox2,
              textAlign: "center",
              color: theme.palette.primary.dark,
            }}
            component={Link}
            to="./atp"
          >
            <Box>
              <ColorizeIcon sx={{ fontSize: 104 }} />
            </Box>
            <Box sx={{ fontWeight: 700, letterSpacing: "0.1em" }}>
              {BasicLanguage.home.btnAtp[language]}
            </Box>
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Box
            sx={{
              ...SBXStyles.mainBox2,
              textAlign: "center",
              color: theme.palette.primary.dark,
            }}
            component={Link}
            to="./dashboard"
          >
            <Box>
              <DashboardIcon sx={{ fontSize: 104 }} />
            </Box>
            <Box sx={{ fontWeight: 700, letterSpacing: "0.1em" }}>
              {BasicLanguage.home.btnDashboard[language]}
            </Box>
          </Box>

          <Box
            sx={{
              ...SBXStyles.mainBox2,
              textAlign: "center",
              color: theme.palette.primary.dark,
            }}
            component={Link}
            to="./reports"
          >
            <Box>
              <ContentPasteOutlinedIcon sx={{ fontSize: 104 }} />
            </Box>
            <Box sx={{ fontWeight: 700, letterSpacing: "0.1em" }}>
              {BasicLanguage.home.btnReport[language]}
            </Box>
          </Box>

          <Box
            sx={{
              ...SBXStyles.mainBox2,
              textAlign: "center",
              color: theme.palette.primary.dark,
            }}
            component={Link}
            to="./maps"
          >
            <Box>
              <MapIcon sx={{ fontSize: 104 }} />
            </Box>
            <Box sx={{ fontWeight: 700, letterSpacing: "0.1em" }}>
              {BasicLanguage.home.btnMaps[language]}
            </Box>
          </Box>
        </Stack>
        {robot === true ? (
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Box
              sx={{
                ...SBXStyles.mainBox2,
                textAlign: "center",
                color: theme.palette.primary.dark,
              }}
              component={Link}
              to="./robots"
            >
              <Box>
                <SmartToyOutlinedIcon sx={{ fontSize: 104 }} />
              </Box>
              <Box sx={{ fontWeight: 700, letterSpacing: "0.1em" }}>
                {BasicLanguage.home.btnRobot[language]}
              </Box>
            </Box>
            <Box
              sx={{
                ...SBXStyles.mainBox2,
                textAlign: "center",
                color: theme.palette.primary.dark,
              }}
              component={Link}
              to="./users"
            >
              <Box>
                <PeopleAltOutlinedIcon sx={{ fontSize: 104 }} />
              </Box>
              <Box sx={{ fontWeight: 700, letterSpacing: "0.1em" }}>
                {BasicLanguage.home.btnUser[language]}
              </Box>
            </Box>
            <Box
              sx={{
                ...SBXStyles.mainBox2,
                textAlign: "center",
                color: theme.palette.primary.dark,
              }}
              component={Link}
              to="./settings"
            >
              <Box>
                <Settings sx={{ fontSize: 104 }} />
              </Box>
              <Box sx={{ fontWeight: 700, letterSpacing: "0.1em" }}>
                {BasicLanguage.home.btnTimeZone[language]}
              </Box>
            </Box>
          </Stack>
        ) : (
          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            <Box
              sx={{
                ...SBXStyles.mainBox2,
                textAlign: "center",
                color: theme.palette.primary.dark,
              }}
              component={Link}
              to="./users"
            >
              <Box>
                <PeopleAltOutlinedIcon sx={{ fontSize: 104 }} />
              </Box>
              <Box sx={{ fontWeight: 700, letterSpacing: "0.1em" }}>
                {BasicLanguage.home.btnUser[language]}
              </Box>
            </Box>
            <Box
              sx={{
                ...SBXStyles.mainBox2,
                textAlign: "center",
                color: theme.palette.primary.dark,
              }}
              component={Link}
              to="./settings"
            >
              <Box>
                <Settings sx={{ fontSize: 104 }} />
              </Box>
              <Box sx={{ fontWeight: 700, letterSpacing: "0.1em" }}>
                {BasicLanguage.home.btnTimeZone[language]}
              </Box>
            </Box>
          </Stack>
        )}
      </Box>
    </>
  );
}
