import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import languageState from "../../recoil/atoms/languageState";
import { Typography, Box, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  TextField,
  CircularProgress,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import BasicLanguage from "../languages/Basic";
import SBXStyles from "../utils/Styles";
import { db } from "../utils/firebase";
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

import moment from "moment";
import { DialogYesNo } from "./Dialog";
import sessionState from "../../recoil/atoms/sessionState";
import { TimeZoneContext } from "../utils/setTimeZoneContext";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function KpiFacilitySettings(props) {
  const language = useRecoilValue(languageState);

  const [loading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { facilityId } = useParams();
  const [co2, setCo2] = React.useState("");
  const [co2Error, setCo2Error] = useState(false);
  const [meterError, setMeterError] = useState(false);
  const [meterErrorEdited, setMeterErrorEdited] = useState(false);
  const [co2ErrorEdited, setco2ErrorEdited] = useState(false);
  const [history, setHistory] = useState([]);
  const [meters, setMeters] = useState("");
  const [editMode, setEditMode] = useState(null);
  const [Uploading, setUploading] = useState(false);
  const [editedValueMeters, setEditedValueMeters] = useState("");
  const [editedValueCo2, setEditedValueCo2] = useState("");
  const session = useRecoilValue(sessionState);
  const { currentTimeZoneName } = useContext(TimeZoneContext);
  const formIsValid = useCallback(() => {
    let isValid = true;
    setCo2Error(false);
    setMeterError(false);

    if (!co2 || /^(-\d+|0|)$/.test(co2)) {
      setCo2Error(true);
      isValid = false;
      setCo2Error(BasicLanguage.facilitySettings.Co2Error[language]);
    }

    if (!meters || /^(-\d+|0|)$/.test(meters)) {
      setMeterError(true);
      isValid = false;
      setMeterError(BasicLanguage.facilitySettings.MeterError[language]);
    }

    return isValid;
  }, [co2, meters, language]);
  const formIsValidEdited = useCallback(() => {
    let isValid = true;
    setco2ErrorEdited(false);
    setMeterErrorEdited(false);

    if (!editedValueMeters || /^(-\d+|0|)$/.test(editedValueMeters)) {
      isValid = false;
      setMeterErrorEdited(BasicLanguage.facilitySettings.MeterError[language]);
      setUploading(false);
    }
    if (!editedValueCo2 || /^(-\d+|0|)$/.test(editedValueCo2)) {
      isValid = false;
      setco2ErrorEdited(BasicLanguage.facilitySettings.Co2Error[language]);
      setUploading(false);
    }

    return isValid;
  }, [language, editedValueCo2, editedValueMeters]);

  const setData = useCallback(async () => {
    if (!formIsValid()) {
      return;
    }

    const co2Value = parseInt(co2);
    const meterValue = parseInt(meters);

    const docRef = doc(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "co2",
      "data"
    );

    const docSnap = await getDoc(docRef);
    const existingData = docSnap.exists() ? docSnap.data() : {};

    const newHistoryEntry = {
      co2: co2Value,
      meters: meterValue,
      createdAt: new Date().toISOString(),
      uid: Math.random(),
      updatedBy: "-",
    };

    const historyArray = Array.isArray(existingData.history)
      ? existingData.history
      : [];

    const newData = {
      co2: co2Value,
      meters: meterValue,
      history: [newHistoryEntry, ...historyArray],
      updatedBy: "-",
    };

    try {
      setLoading(true);
      await setDoc(docRef, newData);
      setCo2("");
      setMeters("");
      setHistory([newHistoryEntry, ...historyArray]);
      alert(BasicLanguage.admin.kpi.kpiDataSuccess[language]);
    } catch (error) {
      console.error(BasicLanguage.admin.kpi.failFetch[language], error);
      alert(BasicLanguage.alert.error[language]);
    } finally {
      setLoading(false);
    }
  }, [co2, facilityId, formIsValid, language, meters]);

  const handleEditClick = (docId, currentValueCo2, currentValueMeters) => {
    setEditMode(docId);
    setEditedValueMeters(currentValueMeters);
    setEditedValueCo2(currentValueCo2);
  };

  const deleteHistory = useCallback(
    async (historyEntry) => {
      try {
        const { uid } = historyEntry;
        const docRef = doc(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "co2",
          "data"
        );

        await deleteDoc(docRef);

        setHistory((prevHistory) =>
          prevHistory.filter((entry) => entry.uid !== uid)
        );

        setDeleteModalOpen(false);
        alert(BasicLanguage.alert.deleted[language]);
      } catch (error) {
        console.error(BasicLanguage.alert.error[language], error);
        alert(BasicLanguage.alert.error[language]);
      } finally {
        setDeleteModalOpen(false);
      }
    },
    [facilityId, language]
  );
  const fetchHistory = useCallback(async () => {
    try {
      const docRef = doc(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        "co2",
        "data"
      );
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        setLoading(false);
        return;
      }
      const data = docSnap.data();
      if (data.history) {
        setHistory(data.history);
      } else {
        console.error(BasicLanguage.admin.kpi.failFetch[language]);
      }
    } catch (error) {
      console.error(BasicLanguage.alert.error[language], error);
    } finally {
      setLoading(false);
    }
  }, [facilityId, language]);
  useEffect(() => {
    if (!editMode) {
      fetchHistory();
    }
  }, [fetchHistory]);
  const editData = useCallback(
    async (index) => {
      setUploading(true);
      if (!formIsValidEdited()) {
        return;
      }

      const co2ValueEdited = parseInt(editedValueCo2);
      const meterValueEdited = parseInt(editedValueMeters);

      if (index >= 0 && index < history.length) {
        if ("uid" in history[index]) {
          const docId = history[index].uid;
          const docRef = doc(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "co2",
            "data"
          );
          try {
            await updateDoc(docRef, {
              history: history.map((entry) => {
                if (entry.uid === docId) {
                  return {
                    ...entry,
                    co2: co2ValueEdited,
                    meters: meterValueEdited,
                    updatedBy: session.displayName || session.uid,
                  };
                } else {
                  return entry;
                }
              }),
            });
            alert(BasicLanguage.alert.updated[language]);
            setEditMode(null);
            setUploading(false);
            fetchHistory();
          } catch (error) {
            console.error(error);
          }
        } else {
          alert(BasicLanguage.alert.error.default[language]);
        }
      } else {
        console.error();
      }
    },
    [
      editedValueCo2,
      editedValueMeters,
      facilityId,
      language,
      history,
      session.displayName,
      session.uid,
      formIsValidEdited,
      fetchHistory,
    ]
  );

  return (
    <>
      {/* CO2 and 平米数 */}
      <Box sx={SBXStyles.mainBox}>
        <Typography className="" variant="h5">
          {BasicLanguage.facilitySettings.Co2Title[language]}
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <InputLabel sx={{ margin: "2" }}>
                  {BasicLanguage.admin.kpi.co2[language]}
                </InputLabel>
              </TableCell>
              <TableCell sx={{ position: "relative" }} align="right">
                <TextField
                  error={co2Error}
                  type="number"
                  variant="outlined"
                  value={co2}
                  onChange={(e) => setCo2(e.target.value)}
                />
                <Box position={"absolute"} bottom={"15%"} right={"-1.5%"}>
                  kg
                </Box>
              </TableCell>
            </TableRow>
            <Box color={"red"} marginLeft={"1rem"}>
              {co2Error}
            </Box>
            <TableRow>
              <TableCell>
                <InputLabel sx={{ margin: "2" }}>
                  {BasicLanguage.admin.kpi.meter[language]}
                </InputLabel>
              </TableCell>

              <TableCell sx={{ position: "relative" }} align="right">
                <TextField
                  required
                  error={meterError}
                  type="number"
                  variant="outlined"
                  value={meters}
                  onChange={(e) => setMeters(e.target.value)}
                />
                <Box position={"absolute"} bottom={"15%"} right={"-2%"}>
                  m²
                </Box>
              </TableCell>
            </TableRow>
            <Box color={"red"} marginLeft={"1rem"}>
              {meterError}
            </Box>
          </TableBody>
        </Table>
        <Box>
          {" "}
          <Button
            variant="outlined"
            sx={{ marginY: "20px" }}
            onClick={setData}
            disabled={loading}
          >
            {BasicLanguage.admin.common.save[language]}
          </Button>
        </Box>
        {/* //table  Co2*/}
        <TableContainer
          sx={{
            mt: 2,
            maxHeight: "400px",
            "& .MuiTableCell-head": {
              backgroundColor: "rgba(220,220,220,1)",
              fontWeight: "bold",
            },
          }}
        >
          {history.length > 0 && (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{BasicLanguage.admin.kpi.co2[language]}</TableCell>
                  <TableCell>
                    {BasicLanguage.admin.kpi.meter[language]}
                  </TableCell>
                  <TableCell>
                    {BasicLanguage.facilitySettings.tableRobot.person[language]}
                  </TableCell>
                  <TableCell>
                    {BasicLanguage.common.dateTime[language]}
                  </TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Box
                        sx={{
                          display: "flex",
                          height: 200,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  history.map((historyEntry, index) => {
                    return (
                      <TableRow key={historyEntry.uid}>
                        <TableCell width={200}>
                          {editMode === index ? (
                            <TextField
                              error={co2ErrorEdited}
                              type="number"
                              value={editedValueCo2}
                              onChange={(e) =>
                                setEditedValueCo2(e.target.value)
                              }
                              size="small"
                            />
                          ) : (
                            <>{historyEntry.co2}</>
                          )}
                        </TableCell>
                        <TableCell width={200}>
                          {editMode === index ? (
                            <TextField
                              error={meterErrorEdited}
                              type="number"
                              value={editedValueMeters}
                              size="small"
                              onChange={(e) =>
                                setEditedValueMeters(e.target.value)
                              }
                            />
                          ) : (
                            <>{historyEntry.meters}</>
                          )}
                        </TableCell>

                        <TableCell>{historyEntry.updatedBy}</TableCell>
                        <TableCell>
                          {moment
                            .tz(historyEntry.createdAt, currentTimeZoneName)
                            .local(language)
                            .format(
                              BasicLanguage.timezoneCollection.format[language]
                            )}
                        </TableCell>
                        <TableCell sx={{ width: "3rm" }}>
                          {editMode === index ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => editData(index, historyEntry.uid)}
                            >
                              {Uploading ? (
                                <CircularProgress />
                              ) : (
                                BasicLanguage.facilitySettings.RobotCleaning
                                  .submit[language]
                              )}
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="success"
                              onClick={() =>
                                handleEditClick(
                                  index,
                                  historyEntry.co2,
                                  historyEntry.meters
                                )
                              }
                            >
                              {BasicLanguage.map.edit[language]}
                            </Button>
                          )}
                        </TableCell>

                        {editMode === index && (
                          <TableCell sx={{ width: "3rm" }}>
                            <CancelIcon
                              color="error"
                              sx={{ marginLeft: "1px", cursor: "pointer" }}
                              onClick={() => {
                                setEditMode(null);
                                setEditedValueMeters("");
                                setEditedValueCo2("");
                              }}
                            />
                          </TableCell>
                        )}

                        <TableCell>
                          <Button
                            onClick={() => setDeleteModalOpen(historyEntry)}
                            variant="outlined"
                            color="error"
                          >
                            {BasicLanguage.admin.common.delete[language]}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Box>
      <DialogYesNo
        open={[deleteModalOpen]}
        yesAction={() => deleteHistory(deleteModalOpen)}
        noAction={() => setDeleteModalOpen(false)}
        title={BasicLanguage.admin.common.delete[language]}
        message={deleteModalOpen && BasicLanguage.qr.delete[language]}
      />
    </>
  );
}
