import * as React from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import { useNavigate, useParams } from "react-router-dom";
import BasicLanguage from "../components/languages/Basic";
import Typography from "../components/parts-ui/Typography";
import facilityState from "../recoil/atoms/facilityState";
import {
  addDoc,
  setDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  documentId,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { db, storage } from "../components/utils/firebase";
import sessionState from "../recoil/atoms/sessionState";
import styled from "@emotion/styled";
import moment from "moment";
import StorageImage from "../components/parts/StorageImage";
import Loading from "../components/parts/Loading";
import {
  requestGet,
  requestGetRobots,
  robotStart,
  robotStartSend
} from "../components/utils/functions";
import dayjs from "dayjs";
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    background: "linear-gradient(145deg, rgb(244,244,248), rgb(251,251,253))",
    py: "3rem",
    px: 2,
    mx: "auto",
    mb: "40px",
    borderRadius: "40px",

    minWidth: "320px",
    maxWidth: "600px",
  })
);

const EmptyConatiner = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    background: "linear-gradient(145deg, rgb(244,244,248), rgb(251,251,253))",
    py: "3rem",
    px: 2,
    mx: "auto",
    mb: "40px",
    borderRadius: "40px",

    width: "fit-content",
  })
);

const CreateTaskName = (props) => {
  const type = props.data.type;
  const task = props.data.task;
  const language = props.language || "ja";

  let name = task?.request;
  if (type === "SENSOR" && task?.threshold && task?.comparison) {
    name = `${task?.request} (${BasicLanguage.sensor.terms.title[language]}: ${
      task?.threshold
    } ${BasicLanguage.sensor.terms[task?.comparison][language]} )`;
  }

  return name;
};

export default function RobotStart(props) {
  let ignore = false;
  const navigate = useNavigate();
  const tasks = BasicLanguage.tasks;
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;
  const taskId = params.taskId;
  const facility = useRecoilValue(facilityState);
  const session = useRecoilValue(sessionState);
  const [task, setTask] = React.useState({});
  const [request, setRequest] = React.useState({});
  const [qrRobots, setQrRobots] = React.useState({});
  const [progress, setProgress] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const getTask = async () => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "notify", taskId))
      .then((doc) => {
        setTask({ id: doc.id, ...doc.data() });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getQrData = async (qrId) => {
    requestGet({ id: qrId })
      .then((x) => {
        if (x.data.status === "success") {
          const content = x.data.content;
          setRequest(content);
          if (content.robotAreas?.length !== 0) {
            getQrRobots(qrId, content.robotAreas);
          }
        } else {
          console.error("cannot get data");
          setRequest(null);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getQrRobots = async (qrId, robotAreas) => {
    const robotIds = robotAreas?.map((robotArea) => robotArea.robotId);
    requestGetRobots({ qrId: qrId, robotIds: robotIds })
      .then((res) => {
        if (res.data.status === "success") {
          const robots = res.data.content;
          setQrRobots(robots);
        } else {
          console.error("cannot get data");
        }
      })
      .catch((e) => console.error(e));
  };

  const submitReport = async (e) => {
    e.preventDefault();
    setProgress(true);
    const facilityId = params.facilityId;
    const taskId = params.taskId;
    let res = "";

    try {
      // ロボット清掃
      robotStart({
        taskId: taskId,
        facilityId: facilityId,
        task: task,
        qrRobots: qrRobots,
        robotAreas: task.task.robotAreas,
        request: request
      })
        .then((x) => {
          console.log(x)
          if (x.data.status === "success") {
            robotStartSend(x.data)
              .then((res) => {
                if (res.data.status === "success" && x.data.robot) {
                  alert(BasicLanguage.alert.successRobotStart[language]);
                  setIsLoading(false);
                } else {
                  alert(BasicLanguage.alert.failRobotStart[language]);
                  setProgress(false);
                }
              })
          } else {
            alert(BasicLanguage.alert.errorOccurred[language]);
            setProgress(false);
          }
        })
        .catch((e) => console.error(e));

    } catch (e) {
      alert(BasicLanguage.alert.error.default[language]);
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (session && !ignore) {
      setIsLoading(true);
      getTask();
    }

    return () => {
      ignore = true;
    };
  }, [session, facility]);

  React.useEffect(() => {
    if (task && Object.keys(task).length !== 0 && taskId) {
      getQrData(task.origin.id);
    }
  }, [task , taskId]);

  const taskView = task.task && task.task.completed === false ? (
    <Box sx={{ p: 1 }}>
      <Container>
        <Box>
          <Link href="../">{BasicLanguage.cleaners.return[language]}</Link>
        </Box>
        {task.id ? (
          <>
            <Box sx={{ my: 2 }}>
              <Chip
                label={tasks.status[task.task.completed][language]}
                color={task.task.completed ? "success" : "error"}
              />
            </Box>
            <Typography className="pageTitle" variant="h5">
              {task?.task?.place} :{" "}
              <CreateTaskName data={task} language={language} />
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20%" }}></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{tasks.titles.createdAt[language]}</TableCell>
                  <TableCell>
                    {moment(task.createdAt.toDate()).format(
                      "YYYY/MM/DD HH:mm:ss"
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tasks.titles.location[language]}</TableCell>
                  <TableCell>{task.task.place}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tasks.titles.contents[language]}</TableCell>
                  <TableCell>
                    <CreateTaskName data={task} language={language} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tasks.titles.taskMemo[language]}</TableCell>
                  <TableCell>{task.task.taskMemo}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tasks.titles.type[language]}</TableCell>
                  <TableCell>{task.type}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tasks.titles.assignee[language]}</TableCell>
                  <TableCell>
                    {task.task.assignee.indexOf(session.uid) > -1
                      ? BasicLanguage.cleaners.assignee.self[language]
                      : BasicLanguage.cleaners.assignee.others[language]}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography className="subTitle">
                {BasicLanguage.cleaners.robot[language]}
            </Typography>
            <form onSubmit={submitReport}>
              <Stack spacing={1}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={progress}
                >
                  {progress ? (
                    <CircularProgress />
                  ) : (
                    BasicLanguage.cleaners.robot[language]
                  )}
                </Button>
              </Stack>
            </form>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Box>
  ) : (
    <Box sx={{ p: 1 }}>
      <EmptyConatiner
        display="flex"
        alignContent="center"
        justifyContent="center"
      >
        <Box sx={{ p: 3 }}>
          <Box sx={{ mb: 3 }}>
            <Link href="../">{BasicLanguage.cleaners.return[language]}</Link>
          </Box>
          <Typography px={3} variant="h4">
            {BasicLanguage.cleaners.notExist[language]}
          </Typography>
        </Box>
      </EmptyConatiner>
    </Box>
  );

  const loadingView = (
    <Container>
      <Loading />
    </Container>
  );

  return <>{isLoading ? loadingView : taskView}</>;
}
