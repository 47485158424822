import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import { useParams } from "react-router";
import SBXStyles from "../components/utils/Styles";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import BasicLanguage from "../components/languages/Basic";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReportsAddForm from "../components/parts/forms/ReportsAddForm";

const ReportsAdd = (props) => {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;

  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      <Typography className="pageTitle" variant="h4">
        <IconButton LinkComponent={Link} to={"/" + facilityId + "/reports"}>
          <ArrowBackIcon />
        </IconButton>
        {BasicLanguage.reportsAdd.title[language]}
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}></Typography>
      <Box sx={SBXStyles.mainBox}>
        <ReportsAddForm
          language={language}
          facilityId={facilityId}
          buttonTitle={BasicLanguage.reportsAdd.button[language]}
        />
      </Box>
    </Box>
  );
};

export default ReportsAdd;
