import * as React from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import { useParams } from "react-router-dom";
import BasicLanguage from "../components/languages/Basic";
import Typography from "../components/parts-ui/Typography";
import facilityState from "../recoil/atoms/facilityState";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../components/utils/firebase";
import sessionState from "../recoil/atoms/sessionState";
import styled from "@emotion/styled";
import moment from "moment";
import StorageImage from "../components/parts/StorageImage";
import { ref, uploadBytes } from "@firebase/storage";
import Colors from "../components/utils/Colors";
import Loading from "../components/parts/Loading";
import { getMetadata } from "firebase/storage";
import PropTypes from "prop-types";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const MIME_TYPES = {
  "image/jpeg": "jpeg",
  "image/png": "png",
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    background: "linear-gradient(145deg, rgb(244,244,248), rgb(251,251,253))",
    py: "3rem",
    px: 2,
    mx: "auto",
    mb: "40px",
    borderRadius: "40px",
    minWidth: "320px",
    maxWidth: "600px",
  })
);

const EmptyConatiner = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    background: "linear-gradient(145deg, rgb(244,244,248), rgb(251,251,253))",
    py: "3rem",
    px: 2,
    mx: "auto",
    mb: "40px",
    borderRadius: "40px",
    width: "fit-content",
  })
);

const CreateTaskName = (props) => {
  const type = props.data.type;
  const task = props.data.task;
  const language = props.language || "ja";
  let name = task?.request;
  if (type === "SENSOR" && task?.threshold && task?.comparison) {
    name = `${task?.request} (${BasicLanguage.sensor.terms.title[language]}: ${
      task?.threshold
    } ${BasicLanguage.sensor.terms[task?.comparison][language]} )`;
  }
  return name;
};

export default function CleanerTaskDetail() {
  const tasks = BasicLanguage.tasks;
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;
  const taskId = params.taskId;
  const facility = useRecoilValue(facilityState);
  const session = useRecoilValue(sessionState);
  const [progress, setProgress] = React.useState(false);
  const [task, setTask] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [uploadedFile, setUploadedFile] = React.useState("");
  const [uploadedImage, setUploadedImage] = React.useState("");
  const [completed, setCompleted] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [AssignedTo, setAssignedTo] = React.useState([]);
  const [Assignee, setAssignee] = React.useState([]);
  const [userNames, setUserNames] = React.useState({});
  const getTask = React.useCallback(async () => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "notify", taskId))
      .then((doc) => {
        const taskData = doc.data();
        setTask({
          id: doc.id,
          ...taskData,
        });
        setInProgress(taskData.taskInProgress || false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [facilityId, taskId]);

  const fetchUserNames = React.useCallback(async () => {
    const facilitySnapshot = await getDoc(
      doc(db, ENV, VERSION, "facilities", facilityId)
    );
    const facilityData = facilitySnapshot.data();

    const usersArray = [
      ...Object.values(facilityData.admin),
      ...Object.values(facilityData.users),
    ].filter((user) => !user.isDeleted);

    const userObj = {};
    usersArray.forEach((user) => {
      userObj[user.uid] = user.displayName;
    });

    return userObj;
  }, [facilityId]);

  React.useEffect(() => {
    fetchUserNames().then(setUserNames);
  }, [fetchUserNames]);

  const getTaskDetails = React.useCallback(async () => {
    const taskRef = doc(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "notify",
      taskId
    );
    try {
      const taskDoc = await getDoc(taskRef);
      if (taskDoc.exists()) {
        const taskData = taskDoc.data().task;
        setAssignedTo(taskData.assignedTo);
        setAssignee(taskData.assignee);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error(error);
    }
  }, [facilityId, taskId]);
  const submitReport = async (e) => {
    e.preventDefault();
    setProgress(true);
    const completed = e.target.completed.value === "true";
    const taskInProgress = e.target.taskProgress.value === "true";
    const outline = e.target.outline.value;
    let imagePath = task.imagePath || "";
    let reportImagePaths = task.reportImagePaths || [];

    if (uploadedFile !== "") {
      if (!(uploadedFile.type in MIME_TYPES)) {
        alert(BasicLanguage.alert.error.default[language]);
        setProgress(false);
        return;
      }
      const img = await uploadTaskImageFile();
      const reportImagePath = img?.metadata.fullPath;
      reportImagePaths.push(reportImagePath);
    }

    const taskRef = doc(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "notify",
      taskId
    );
    const taskDoc = await getDoc(taskRef);
    if (!taskDoc.exists()) {
      throw new Error("Task document does not exist.");
    }

    const taskData = taskDoc.data();

    let updatedAssignee = taskData.task.assignee || [];
    let updatedAssignedTo = taskData.task.assignedTo || [];

    if (taskInProgress) {
      updatedAssignedTo = [session.uid];
    } else {
      updatedAssignedTo = null;
    }

    await updateDoc(taskRef, {
      "task.assignedTo": updatedAssignedTo,
      "task.assignee": updatedAssignee,
    });

    if (task.type === "SENSOR" && completed) {
      const sensorId = task.origin.raw.deviceId;
      const docSensor = doc(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        "sensors",
        sensorId
      );
      const sensor = await getDoc(docSensor);
      const sensorData = sensor.data();
      if (
        (sensorData.autoReset === undefined || sensorData.autoReset === true) &&
        sensorData.process !== 0
      ) {
        const data = {
          createdAt: serverTimestamp(),
          deviceId: sensorId,
          value: null,
          reset: true,
        };
        await addDoc(
          collection(
            db,
            ENV,
            VERSION,
            "facilities",
            facilityId,
            "sensorHistory"
          ),
          data
        ).catch((e) => {
          alert(BasicLanguage.alert.sensorResetFailed[language]);
          console.error(e);
        });
      }
    }

    updateDoc(
      doc(db, ENV, VERSION, "facilities", facilityId, "notify", taskId),
      {
        completed: completed,
        imagePath: imagePath,
        reportImagePaths: reportImagePaths,
        "task.completed": completed,
        "report.outline": outline,
        "task.completedBy": completed ? session.uid : "-",
        taskInProgress: taskInProgress,
      }
    )
      .then((doc) => {
        getTask();
        alert(BasicLanguage.alert.updated[language]);
        setUploadedFile("");
        setUploadedImage((prevUploadedImage) => {
          URL.revokeObjectURL(prevUploadedImage);
        });
        setProgress(false);
      })
      .catch((e) => {
        alert(BasicLanguage.alert.error.default[language]);
        console.error(e);
      });
  };

  React.useEffect(() => {
    let isMounted = true;
    if (session && isMounted) {
      setIsLoading(true);
      getTask();
      getTaskDetails();
    }
    return () => {
      isMounted = false;
    };
  }, [session, facility, taskId, getTask, getTaskDetails]);
  const selectImage = (event) => {
    if (event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    setUploadedFile(file);
    setUploadedImage((prevUploadedImage) => {
      URL.revokeObjectURL(prevUploadedImage);
      return URL.createObjectURL(file);
    });
  };

  const uploadTaskImageFile = async () => {
    const fileExtension = MIME_TYPES[uploadedFile.type];
    const originalFileName = uploadedFile.name.trim();
    const fileNameWithoutExtension = originalFileName.replace(/\.[^.]+$/, "");
    const fileName = `${taskId}_${fileNameWithoutExtension}.${fileExtension}`;
    const sanitizedFileName = fileName.replace(/\s+/g, "_");
    const storageRef = ref(
      storage,
      `uploaded_image/${facilityId}/tasks/${sanitizedFileName}`
    );
    const image = await uploadBytes(storageRef, uploadedFile);
    return image;
  };

  const taskView = task.task ? (
    <Box sx={{ p: 1 }}>
      <Container>
        <Box>
          <Link href="../">{BasicLanguage.cleaners.return[language]}</Link>
        </Box>
        {task.id ? (
          <>
            <Box sx={{ my: 2 }}>
              <Chip
                label={tasks.status[task.task.completed][language]}
                color={task.task.completed ? "success" : "error"}
              />
            </Box>
            <Typography className="pageTitle" variant="h5">
              {task?.task?.place}
              <CreateTaskName data={task} language={language} />
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "28%" }}></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{tasks.titles.createdAt[language]}</TableCell>
                  <TableCell>
                    {moment(task.createdAt.toDate()).format(
                      "YYYY/MM/DD HH:mm:ss"
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tasks.titles.location[language]}</TableCell>
                  <TableCell>{task.task.place}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tasks.titles.contents[language]}</TableCell>
                  <TableCell>
                    <CreateTaskName data={task} language={language} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tasks.titles.taskMemo[language]}</TableCell>
                  <TableCell>{task.task.taskMemo}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tasks.titles.type[language]}</TableCell>
                  <TableCell>{task.type}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{tasks.titles.assignedTo[language]}</TableCell>
                  <TableCell>
                    {!AssignedTo || AssignedTo.length === 0
                      ? "-"
                      : AssignedTo?.map((userId, index) => (
                          <p key={index}>{userNames[userId]}</p>
                        ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ verticalAlign: "top" }}>
                    {tasks.titles.assignee[language]}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {Assignee?.map((userId, index) => (
                      <p key={index}>{userNames[userId]}</p>
                    ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {task.imagePath && task.imagePath !== "" && (
              <>
                <Typography className="subTitle">
                  {BasicLanguage.tasks.image.request[language]}
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "50%" }}></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <ReportImage imagePath={task.imagePath} />
                  </TableBody>
                </Table>
              </>
            )}
            {task.reportImagePaths && task.reportImagePaths.length > 0 && (
              <>
                <Typography className="subTitle">
                  {BasicLanguage.tasks.image.report[language]}
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "50%" }}></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {task.reportImagePaths.map((imagePath) => (
                      <ReportImage imagePath={imagePath} key={imagePath} />
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
            {uploadedImage && <img src={uploadedImage} alt="" width="100%" />}
            <Typography className="subTitle">
              {BasicLanguage.cleaners.report[language]}
            </Typography>
            <form onSubmit={submitReport}>
              <input
                type="hidden"
                name="taskProgress"
                value={inProgress ? "true" : "false"}
              />
              <Stack spacing={1}>
                <FormControl>
                  <Typography
                    sx={{ fontSize: "0.8em", mb: 1, textAlign: "center" }}
                  >
                    {BasicLanguage.request.uploadPicture[language]}
                  </Typography>
                  <label htmlFor="taskImageUpload">
                    <TextField
                      id="taskImageUpload"
                      name="taskImage"
                      type="file"
                      inputProps={{ accept: Object.keys(MIME_TYPES) }}
                      onChange={selectImage}
                      sx={{ display: "none" }}
                    />
                    <Button
                      component="span"
                      variant="outlined"
                      startIcon={<AddCircleIcon sx={{ mr: 1 }} />}
                      fullWidth
                      sx={{
                        height: "3em",
                        borderRadius: "1.5em",
                        backgroundColor: Colors.white,
                      }}
                    >
                      {BasicLanguage.request.uploadPictureBtn[language]}
                    </Button>
                  </label>
                </FormControl>
                <Typography>{tasks.titles.reportOutline[language]}</Typography>
                <TextField
                  defaultValue={task.report?.outline}
                  name="outline"
                  fullWidth
                  multiline
                  minRows={5}
                  placeholder={BasicLanguage.cleaners.fill[language]}
                />
                <input
                  type="hidden"
                  name="completed"
                  value={completed.toString()}
                />
                <RadioGroup
                  row
                  value={
                    completed ? "true" : inProgress ? "inProgress" : "false"
                  }
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value === "true") {
                      setCompleted(true);
                      setInProgress(false);
                    } else if (value === "false") {
                      setCompleted(false);
                      setInProgress(false);
                    } else if (value === "inProgress") {
                      setCompleted(false);
                      setInProgress(true);
                    }
                  }}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={tasks.status.false[language]}
                  />

                  <FormControlLabel
                    value="inProgress"
                    control={<Radio />}
                    label={tasks.status.inProgress[language]}
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={tasks.status.true[language]}
                  />
                </RadioGroup>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={progress}
                >
                  {BasicLanguage.cleaners.report[language]}
                </Button>
              </Stack>
            </form>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Box>
  ) : (
    <Box sx={{ p: 1 }}>
      <EmptyConatiner
        display="flex"
        alignContent="center"
        justifyContent="center"
      >
        <Box sx={{ p: 3 }}>
          <Box sx={{ mb: 3 }}>
            <Link href="../">{BasicLanguage.cleaners.return[language]}</Link>
          </Box>
          <Typography px={3} variant="h4">
            {BasicLanguage.cleaners.notExist[language]}
          </Typography>
        </Box>
      </EmptyConatiner>
    </Box>
  );

  const loadingView = (
    <Container>
      <Loading />
    </Container>
  );
  return <>{isLoading ? loadingView : taskView}</>;
}

const ReportImage = ({ imagePath }) => {
  const [updatedTime, setUpdatedTime] = React.useState("");
  const language = useRecoilValue(languageState);

  React.useEffect(() => {
    moment.locale(language);
    getMetadata(ref(storage, imagePath)).then((metadata) => {
      setUpdatedTime(moment(metadata.updated).format("YYYY/MM/DD HH:mm"));
    });
  }, [imagePath, language]);

  return (
    <TableRow>
      <TableCell>{updatedTime}</TableCell>
      <TableCell>
        <Box>
          <StorageImage imagePath={imagePath} sx={{ width: "200px" }} />
        </Box>
      </TableCell>
    </TableRow>
  );
};

ReportImage.propTypes = {
  imagePath: PropTypes.string.isRequired,
};
