import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import { useEffect, useState } from "react";
import BasicLanguage from "../../languages/Basic";
import { adminSensorsDelete, adminSensorsUpdate } from "../../utils/functions";
import {
  Button,
  Chip,
  Stack,
  Switch,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { DialogYesNo } from "../Dialog";

export const Sensor = (props) => {
  const language = useRecoilValue(languageState);

  const sensor = props.sensor;
  const getSensors = props.getSensors;

  const [active, setActive] = useState(sensor.active);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(sensor.name);
  const [facility, setFacility] = useState(sensor.facility);
  const [facilityDeleteFlag, setFacilityDeleteFlag] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFacility = () => {
    const confirm = window.confirm(
      BasicLanguage.admin.sensors.deleteFacility[language]
    );
    if (confirm) {
      setFacility("");
      setFacilityDeleteFlag(true);
    }
  };

  const submit = () => {
    const sensorData = {
      id: sensor.id,
      name: name,
      facility: facility,
      active: active,
      type: sensor.type,
    };

    adminSensorsUpdate(sensorData).then((result) => {
      if (result.data?.status === "success") {
        getSensors();
        setEditMode(false);
      } else {
        getSensors();
      }
    });
  };

  const cancel = () => {
    setName(sensor.name);
    setActive(sensor.active);
    setFacility(sensor.facility);
    setEditMode(false);
    setFacilityDeleteFlag(false);
    getSensors();
  };

  useEffect(() => {
    setFacilityDeleteFlag(false);
  }, [props]);

  const sensorFacility = facility ? (
    <Link to={"/" + facility + "/sensors"} target="_blank">
      {facility}
    </Link>
  ) : (
    ""
  );

  const sensorFacilityEdit = sensor.facility ? (
    !facilityDeleteFlag && <Chip label={facility} onDelete={deleteFacility} />
  ) : (
    <TextField
      value={facility}
      onChange={(e) => setFacility(e.target.value)}
    ></TextField>
  );

  const deleteSensor = () => {
    adminSensorsDelete({ sensorId: sensor.id })
      .then((result) => {
        if (result.data?.status === "success") {
          getSensors();
        } else {
          throw new Error(result.data?.status);
        }
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      })
      .finally(() => setDeleteModalOpen(false));
  };

  return (
    <>
      <TableRow key={sensor.id}>
        <TableCell>{sensor.id}</TableCell>
        <TableCell>
          {editMode ? (
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></TextField>
          ) : (
            name
          )}
        </TableCell>
        <TableCell>{editMode ? sensorFacilityEdit : sensorFacility}</TableCell>
        <TableCell>{sensor.type}</TableCell>
        <TableCell>
          <Switch
            checked={active}
            disabled={!editMode}
            onChange={() => setActive((prev) => !prev)}
          />
        </TableCell>
        <TableCell>
          {editMode ? (
            <Stack spacing={1}>
              <Button onClick={submit} variant="outlined" color="success">
                {BasicLanguage.admin.common.save[language]}
              </Button>
              <Button
                onClick={() => setDeleteModalOpen(true)}
                variant="outlined"
                color="error"
              >
                {BasicLanguage.admin.common.delete[language]}
              </Button>
              <Button onClick={cancel} variant="outlined" color="secondary">
                {BasicLanguage.admin.common.cancel[language]}
              </Button>
            </Stack>
          ) : (
            <Button
              onClick={() => setEditMode((prev) => !prev)}
              variant="outlined"
              fullWidth
            >
              {BasicLanguage.admin.common.edit[language]}
            </Button>
          )}
        </TableCell>
      </TableRow>
      <DialogYesNo
        open={[deleteModalOpen, setDeleteModalOpen]}
        yesAction={deleteSensor}
        noAction={() => setDeleteModalOpen(false)}
        title={sensor.id}
        message={BasicLanguage.admin.sensors.deleteMessage[language]}
      />
    </>
  );
};

Sensor.propTypes = {
  sensor: PropTypes.object,
  getSensors: PropTypes.func,
};
