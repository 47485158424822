import { useEffect, useState } from "react";
import { Box, IconButton, Stack, Tab, Tabs } from "@mui/material";
import Typography from "../components/parts-ui/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import BasicLanguage from "../components/languages/Basic";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import CommonHeaderItems from "../components/parts/CommonHeaderItems";
import TabRobots from "../components/parts/admin/TabRobots";
import { TabSensors } from "../components/parts/admin/TabSensors";
import { TabUsersAuth } from "../components/parts/admin/TabUsersAuth";
import { TabUsersFirestore } from "../components/parts/admin/TabUsersFirestore";
import { TabFacilities } from "../components/parts/admin/TabFacilities";
import TabKpi from "../components/parts/admin/TabKpi";
import PropTypes from "prop-types";

const AdminTabs = styled(Tabs)(({ theme }) =>
  theme.unstable_sx({
    fontFamily: theme.typography.fontFamily,

    "&:hover": {
      color: theme.palette.primary.dark,
    },
  })
);
const AdminTab = styled(Tab)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
    "&.Mui-selected": {
      color: theme.palette.primary.dark,
      borderBottom: `4px solid ${theme.palette.primary.dark}`,
      fontWeight: 700,
    },
  })
);

const Admin = ({ path, tab }) => {
  const language = useRecoilValue(languageState);
  const theme = useTheme();
  const navigate = useNavigate();


  useEffect(() => {
    if (path === "/") {
      return navigate("/admin/auth-users");
    }
  }, [navigate, path]);

  return (
    <>
      <Helmet>
        <title>SmartBX Connect</title>
      </Helmet>
      <Box sx={{ m: 2 }}>
        <Stack
          spacing={2}
          alignItems="stretch"
          sx={{ width: "100%", display: "flex" }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="h4"
              className="pageTitle"
              sx={{ color: theme.palette.common.white }}
            >
              <IconButton component={Link} to="/">
                <ArrowBackIcon sx={{ color: theme.palette.common.white }} />
              </IconButton>
              {BasicLanguage.admin.title[language]}
            </Typography>
            <CommonHeaderItems />
          </Stack>
          <AdminTabs value={tab}>
            <AdminTab
              component={Link}
              label={BasicLanguage.admin.headers.users.auth[language]}
              to="/admin/auth-users"
            />
            <AdminTab
              component={Link}
              label={BasicLanguage.admin.headers.users.firestore[language]}
              to="/admin/firestore-users"
            />
            <AdminTab
              component={Link}
              label={BasicLanguage.admin.facilities.title[language]}
              to="/admin/facilities"
            />
            <AdminTab
              component={Link}
              label={BasicLanguage.admin.sensors.title[language]}
              to="/admin/sensors"
            />
            <AdminTab
              component={Link}
              label={BasicLanguage.admin.robots.title[language]}
              to="/admin/robots"
            />
            <AdminTab
              component={Link}
              label={BasicLanguage.admin.kpi.tab[language]}
              to="/admin/kpis"
            />
          </AdminTabs>

          <Box
            sx={{
              borderRadius: "40px",
              backgroundColor: theme.palette.common.white,
              p: 3,
            }}
          >
            {path === "auth-users" && (
              <Box>
                <TabUsersAuth />
              </Box>
            )}
            {path === "firestore-users" && (
              <Box>
                <Typography variant="h5" className="subTitle">
                  {BasicLanguage.admin.users.firestore.title[language]}
                </Typography>
                <TabUsersFirestore />
              </Box>
            )}
            {path === "facilities" && (
              <Box>
                <TabFacilities />
              </Box>
            )}
            {path === "sensors" && (
              <Box>
                <TabSensors />
              </Box>
            )}
            {path === "robots" && (
              <Box>
                <TabRobots
                />
              </Box>
            )}
            {path === "kpis" && (
              <Box>
                <TabKpi/>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

Admin.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Admin;
