import React, { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../components/utils/firebase";
import PropTypes from "prop-types";
import { DialogYesNo } from "../Dialog";
import BasicLanguage from "../../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export const SurveyQuestionEditForm = ({
  facilityId,
  surveyQuestion,
  ...props
}) => {
  const canUpdate = props.canUpdate;
  const language = useRecoilValue(languageState);

  const [canEdit, setCanEdit] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteQuestion = () => {
    const surveyQuestionRef = doc(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "surveyQuestion",
      surveyQuestion.id
    );

    deleteDoc(surveyQuestionRef)
      .then(() => {
        alert(BasicLanguage.alert.deleted[language]);
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  if (canEdit)
    return (
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          const question = event.target[0].value;
          await updateQuestion(facilityId, {
            ...surveyQuestion,
            question,
          });
          setCanEdit(false);
        }}
      >
        <TextField
          variant="standard"
          defaultValue={surveyQuestion.question}
          autoFocus={true}
        />
        <IconButton type="submit">
          <CheckCircleIcon color="success" />
        </IconButton>
        <IconButton onClick={() => setCanEdit(false)}>
          <CancelIcon color="error" />
        </IconButton>
      </form>
    );

  return (
    <>
      {surveyQuestion.question}
      {canUpdate && (
        <>
          <IconButton sx={{ ml: 1 }} onClick={() => setCanEdit(true)}>
            <EditIcon sx={{ fontSize: "0.7em" }} />
          </IconButton>
          <IconButton onClick={() => setDeleteModalOpen(true)}>
            <DeleteForeverIcon />
          </IconButton>
          <DialogYesNo
            open={[deleteModalOpen, setDeleteModalOpen]}
            yesAction={deleteQuestion}
            noAction={() => setDeleteModalOpen(false)}
            title={surveyQuestion.question}
            message={BasicLanguage.qr.survey.deleteQuestion[language]}
          />
        </>
      )}
    </>
  );
};

SurveyQuestionEditForm.propTypes = {
  facilityId: PropTypes.string,
  surveyQuestion: PropTypes.object,
  canUpdate: PropTypes.bool,
};

const updateQuestion = async (facilityId, surveyQuestion) => {
  const docRef = doc(
    db,
    ENV,
    VERSION,
    "facilities",
    facilityId,
    "surveyQuestion",
    surveyQuestion.id
  );
  await updateDoc(docRef, {
    question: surveyQuestion.question,
  });
};
