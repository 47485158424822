import { Outlet } from "react-router";
import { Box } from "@mui/material";

const RequestLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Outlet />
    </Box>
  );
};
export default RequestLayout;
