import Colors from "./Colors";
const SBXStyles = {
    mainBox: {
        background: "linear-gradient(145deg, rgb(244,244,248), rgb(251,251,253))",
        mt: 2,
        ml: "auto",
        mr: "auto",
        p: 3,
        width: "100%",
        maxWidth: "1200px",
        boxShadow: "5px 5px 10px #00000019, -5px -5px 10px rgba(254,254,255,0.9)",
        borderRadius: "40px",

    },
    mainBox2: {
        background: "linear-gradient(145deg, rgb(251,251,253),rgb(244,244,248))",
        boxShadow: "5px 5px 10px #00000019, -5px -5px 10px rgba(254,254,255,0.9)",
        borderRadius: "40px",
        borderWidth: 1,
        boxSizing: "border-box",
        position: "relative",
        "&:hover": {
            background: "linear-gradient(145deg,rgb(241,241,245), rgb(251,251,253))"
        },
        p: 3,
        width: "100%",
    },
    mainContainer: {
        flexGrow: 1,
        maxWidth: "1200px",
        ml: "auto",
        mr: "auto",
    }
}
export default SBXStyles;