import React, { useCallback, useEffect } from "react";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import { useNavigate, useParams } from "react-router";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../components/utils/firebase";
import {
  Box,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SBXStyles from "../components/utils/Styles";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BasicLanguage from "../components/languages/Basic";
import dayjs from "dayjs";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function RobotFloorAreaDetail() {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;
  const robotId = params.robotId;
  const floorId = params.floorId;
  const areaId = params.areaId;
  const navigate = useNavigate();
  const [area, setArea] = React.useState({});

  const getArea = useCallback(() => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "robots", robotId))
      .then((docsnap) => {
        if (!docsnap.data()) {
          return navigate("../robots");
        }

        setArea({
          id: areaId,
          ...docsnap.data().floors[floorId].areas[areaId],
        });
      })
      .catch((e) => console.error(e));
  }, [areaId, facilityId, floorId, navigate, robotId]);

  useEffect(() => {
    getArea();
  }, [getArea]);
  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      <Box>
        <Typography className="pageTitle" variant="h4">
          <IconButton LinkComponent={Link} to={`../robots/${robotId}`}>
            <ArrowBackIcon />
          </IconButton>
          {area.id}
        </Typography>
      </Box>
      <Box sx={SBXStyles.mainBox}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {BasicLanguage.robotFloorArea.item[language]}
              </TableCell>
              <TableCell>
                {BasicLanguage.robotFloorArea.content[language]}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {BasicLanguage.robotFloorArea.name[language]}
              </TableCell>
              <TableCell>{area.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {BasicLanguage.robotFloorArea.apiName[language]}
              </TableCell>
              <TableCell>{area.apiName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {BasicLanguage.robotFloorArea.restrictedTime[language]}
              </TableCell>
              <TableCell>
                {area.restrictedStartTime !== null &&
                  area.restrictedStartTime !== undefined &&
                  area.restrictedEndTime !== null &&
                  area.restrictedEndTime !== undefined && (
                    <Box>
                      {dayjs()
                        .hour(area.restrictedStartTime)
                        .format(BasicLanguage.robotFloorArea.hour[language])}
                      ～
                      {dayjs()
                        .hour(area.restrictedEndTime)
                        .format(BasicLanguage.robotFloorArea.hour[language])}
                    </Box>
                  )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {BasicLanguage.robotFloorArea.cleanAfterRestriction[language]}
              </TableCell>
              <TableCell>
                <Switch checked={area.willCleanAfterRestriction} disabled />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}
