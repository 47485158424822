import { Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from "prop-types";

const ITEMS_PER_PAGE = 20;

export default function Pager({ page, setPage, items, setPagerItems }) {
  const handlePageChange = (newPage) => {
    setPage(newPage);
    setPagerItems(
      items.slice((newPage - 1) * ITEMS_PER_PAGE, newPage * ITEMS_PER_PAGE)
    );
  };
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(items.length / ITEMS_PER_PAGE));
    setPagerItems(
      items.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
    );
  }, [items, page, setPagerItems]);

  useEffect(() => {
    if (page !== 1 && page > pageCount ){
      setPage(prevPage => prevPage - 1);
    }
  }, [items, page, pageCount, setPage]);

  return (
    items.length > ITEMS_PER_PAGE && (
      <Stack
        direction="row"
        justifyContent="center"
        gap={2}
        borderRadius={"100%"}
        sx={{ marginTop: 2 }}
      >
        <Button
          size="small"
          variant="contained"
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          <KeyboardArrowLeftIcon />
        </Button>
        <PagerNumberButtons
          page={page}
          setPage={setPage}
          pageCount={pageCount}
        />
        <Button
          size="small"
          variant="contained"
          onClick={() => handlePageChange(page + 1)}
          disabled={page === pageCount}
        >
          <KeyboardArrowRightIcon />
        </Button>
      </Stack>
    )
  );
}

Pager.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  setPagerItems: PropTypes.func.isRequired,
};

const PagerNumberButtons = ({ page, setPage, pageCount }) => {
  if (pageCount <= 1) return null;

  const buttons = [];
  for (let i = 1; i <= pageCount; i++) {
    buttons.push(
      <PagerNumberButton key={i} page={page} setPage={setPage} number={i} />
    );
  }

  return buttons;
};

PagerNumberButtons.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
};

const PagerNumberButton = ({ page, setPage, number }) => {
  return (
    <Button
      size="small"
      variant="contained"
      onClick={() => setPage(number)}
      disabled={page === number}
    >
      {number}
    </Button>
  );
};

PagerNumberButton.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired,
};
