import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import SBXStyles from "../../utils/Styles";
import DashboardControls from "./shared/DashboardControls";
import moment from "moment";
import PeopleCountAllChart from "./PeopleCountAllChart";
import PeopleCountSensorChart from "./PeopleCountSensorChart";
import {
  and,
  collection,
  getDocs,
  limit,
  or,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import { useParams } from "react-router";
import BasicLanguage from "../../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import { TimeZoneContext } from "../../utils/setTimeZoneContext";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function PeopleCount() {
  const [date, setDate] = useState(moment());
  const [dateDisplay, setDateDisplay] = useState("day");
  const [peopleCountAllData, setPeopleCountAllData] = useState([]);
  const [peopleCountSensorData, setPeopleCountSensorData] = useState([]);
  const [sensorIds, setSensorIds] = useState([]);
  const language = useRecoilValue(languageState);
  const { currentTimeZoneName } = useContext(TimeZoneContext);

  const params = useParams();
  const facilityId = params.facilityId;

  const getPeopleCountSensorHistory = useCallback(async () => {
    const ids = await getDocs(
      collection(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        "registeredSensors"
      ),
      limit(1)
    )
      .then((snapshot) => {
        if (snapshot.empty) return [];

        return snapshot.docs[0].data().sensors;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "sensorHistory"),
        and(
          where("createdAt", ">=", moment(date).startOf("month").toDate()),
          where("createdAt", "<=", moment(date).endOf("month").toDate()),
          where("deviceType", "==", "contact"),
          or(
            where("raw.deviceType", "==", "unamotionV2"),
            where("raw.deviceType", "==", "px25piroccupancy1.0.0"),
            and(
              where("manufacturer", "==", "webiot"),
              where("raw.datatype", "==", "motion")
            )
          )
        )
      )
    )
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return {
            createdAt: moment(doc.data().createdAt.toDate()).tz(
              currentTimeZoneName
            ),
            value: parseInt(doc.data().value),
            deviceId: doc.data().deviceId,
          };
        });
        setPeopleCountAllData(data);
        setPeopleCountSensorData(data.filter((d) => ids.includes(d.deviceId)));
        setSensorIds(ids);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [facilityId, date]);

  useEffect(() => {
    getPeopleCountSensorHistory();
  }, [getPeopleCountSensorHistory]);

  return (
    <Box
      sx={SBXStyles.mainBox}
      id="people-count"
      className="dashboard-download-all"
    >
      <Stack
        justifyContent="space-between"
        sx={{ width: "100%" }}
        direction="row"
      >
        <Typography
          variant="h5"
          sx={{ display: "block", textAlign: "", mb: 3, fontWeight: 700 }}
        >
          {BasicLanguage.dashboard.kpi.peopleCount.title[language]}
        </Typography>

        <DashboardControls
          date={date}
          setDate={setDate}
          dateDisplay={dateDisplay}
          setDateDisplay={setDateDisplay}
          elementId="people-count"
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
        <PeopleCountAllChart
          date={date}
          dateDisplay={dateDisplay}
          peopleCountAllData={peopleCountAllData}
        />
        <PeopleCountSensorChart
          date={date}
          dateDisplay={dateDisplay}
          peopleCountSensorData={peopleCountSensorData}
          sensorIds={sensorIds}
        />
      </Stack>
    </Box>
  );
}
