import * as React from 'react';
import { Alert, AlertTitle, Avatar, Badge, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, List, ListItem, ListItemText, Radio, RadioGroup, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip } from '@mui/material';

import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import languageState from '../recoil/atoms/languageState';

import userState from '../recoil/atoms/userState';
import { Link, useParams } from 'react-router-dom';
import BasicLanguage from '../components/languages/Basic';
import Moment from 'react-moment';

import Typography from '../components/parts-ui/Typography';
import facilityState from '../recoil/atoms/facilityState';
import { and, collection, collectionGroup, doc, getDoc, getDocs, limit, orderBy, query, where, updateDoc, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../components/utils/firebase';
import sessionState from '../recoil/atoms/sessionState';
import styled from '@emotion/styled';
import moment from 'moment';
import StorageImage from '../components/parts/StorageImage';
import { useTheme } from '@emotion/react';

const ENV = process.env.REACT_APP_FIRESTORE_ENV
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION

const Container = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        background: "linear-gradient(145deg, rgb(244,244,248), rgb(251,251,253))",
        py: "3rem",
        px: 2,
        mx: "auto",
        mb: "40px",
        borderRadius: "40px",

        minWidth: "320px",
        maxWidth: "600px",
    }));

export default function CleanerHome(props) {
    let ignore = false;
    const [language, setLanguage] = useRecoilState(languageState);
    const params = useParams();
    const theme = useTheme();
    const facilityId = params.facilityId;
    const facility = useRecoilValue(facilityState);
    const session = useRecoilValue(sessionState);  
    const [progress, setProgress] = React.useState(false);
    const [facilityData, setFacilityData] = React.useState();
    const [user, setUser] = React.useState({ displayName: "", email: "" ,status: "-1" });

    const [loading, setLoading] = React.useState(true);
    const targetDate = new Date();
    targetDate.setHours(23);
    targetDate.setMinutes(59);
    targetDate.setSeconds(59);

    const [tasks, setTasks] = React.useState([]);
    const now = moment()
    const due = tasks

        .filter(x => (x.dueDate > now.unix()) || (x.dueDate == undefined && x.createdAt > 0));

    const getTasks = async () => {
        setLoading(true);
        setTasks([]);

        const q = query(
            collection(db, ENV, VERSION, "facilities", facilityId, "notify"),
            and(
                where("task.completed", "==", false),
                where("task.assignee", "array-contains", session.uid),
                where("createdAt", "<=", targetDate)
            ),
            orderBy("createdAt", "desc")
        );

        const querySnapshot = await getDocs(q);

        setTasks(querySnapshot.docs.map((doc) => {
            // doc.data() is never undefined for query doc snapshots
            return { id: doc.id, ...doc.data() }
        })
        );
        setLoading(false)
    }

    const getUserData = async () => {

        setLoading(true);
        getDoc(doc(db, ENV, VERSION, "facilities", facilityId)).then(x => {
            let userData = {}
            setFacilityData(x.data());
            
            if (x.data().users[session.uid]) {
                userData = { ...x.data().users[session.uid] }
            } else {
                userData = null
            }
            setUser(userData);
            setLoading(false);
        })
        .catch((e) => console.error(e))
    }

    const submitAttendance = async (e) => {
        e.preventDefault();
        setProgress(true);
        addDoc(collection(db, ENV, VERSION, "facilities", facilityId, "activityHistory"),
            {
                status: "1",
                uid: session.uid,
                createdAt: serverTimestamp()
            })
            .then(() => {

                const oldUsersData = Object.assign({}, facilityData.users);
                let newUsersData, updateData;
                user.status = "1";
                updateData = Object.fromEntries([[user.uid, user]]);
                newUsersData = Object.assign(oldUsersData, updateData);

                console.log(newUsersData);

                updateDoc(doc(db, ENV, VERSION, "facilities", facilityId),
                {
                    users: newUsersData
                })
                .then(x => {
                    alert(BasicLanguage.cleaner.attendanceAlert[language]);
                    // 最新ステータス取得
                    getUserData();
                    // ボタンの表示変更
                    AttendanceButton();
                    setProgress(false);
                })
                .catch((e) => console.error(e))
            })
            .catch(e => console.error(e))
    }

    const submitLeaveing = async (e) => {
        e.preventDefault();
        setProgress(true)
        addDoc(collection(db, ENV, VERSION, "facilities", facilityId, "activityHistory"),
            {
                status: "0",
                uid:session.uid,
                createdAt: serverTimestamp()
            })
            .then(() => {

                const oldUsersData = Object.assign({}, facilityData.users);
                let newUsersData, updateData;
                user.status = "0";
                updateData = Object.fromEntries([[user.uid, user]]);
                newUsersData = Object.assign(oldUsersData, updateData);

                console.log(newUsersData);

                updateDoc(doc(db, ENV, VERSION, "facilities", facilityId),
                {
                    users: newUsersData
                })
                .then(x => {
                    alert(BasicLanguage.cleaner.leaveAlert[language]);
                    // 最新ステータス取得
                    getUserData();
                    // ボタンの表示変更
                    AttendanceButton();
                    setProgress(false);
                })
                .catch((e) => console.error(e))
            })
            .catch(e => console.error(e))
    }
    React.useEffect(() => {
        if (session && !ignore) {
            getTasks();
            getUserData();
            console.log()
        }

        return () => {
            ignore = true;
        };

    }, [session, facility])

    function AttendanceButton() {

        if (user != null) {
            if (user.status === "1") {
                return <Container>
                    <Typography className='subTitle'>{BasicLanguage.cleaner.attendanceTitle[language]}</Typography>
                    <form onSubmit={submitLeaveing}>
                        <Stack spacing={1}>
                            <Button fullWidth variant="contained" color="error" type="submit" disabled={progress}>{progress ? <CircularProgress /> : BasicLanguage.cleaner.leave[language]}</Button>
                        </Stack>
                    </form>
                </Container>
            } else if (user.status === "0" || user.status === "2" || user.status === "") {
                return <Container>
                    <Typography className='subTitle'>{BasicLanguage.cleaner.attendanceTitle[language]}</Typography>
                    <form onSubmit={submitAttendance}>
                        <Stack spacing={1}>
                            <Button fullWidth variant='contained' color="secondary" type="submit" disabled={progress}>{progress ? <CircularProgress /> : BasicLanguage.cleaner.attendance[language]}</Button>
                        </Stack>
                    </form>
                    </Container>
            }
        }
     }

    return (
        <>
            <Box sx={{ p: 1 }}>
                <AttendanceButton/>
                <Container>
                    <Typography className='subTitle'>{BasicLanguage.cleaners.title[language]}</Typography>
                    <Typography sx={{ mb: 3 }}>{BasicLanguage.cleaners.subtitle[language]}</Typography>
                    <Stack spacing={1}>

                        {loading ?
                            <Box sx={{ width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CircularProgress />
                            </Box> :
                            due.map(x => {
                                return <Box key={x.id}>
                                    <Stack spacing={1}>
                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                            <Typography variant="h6">{x.task.place}</Typography>
                                            <Typography>{moment(x.createdAt.seconds * 1000).format("YYYY/MM/DD HH:mm:ss")}</Typography>
                                        </Stack>
                                        <Typography>{x.type}</Typography>
                                        <Typography>{x.task.request}</Typography>
                                        <Typography>{x.task.taskMemo}</Typography>
                                    </Stack>
                                    <Box sx={{ textAlign: "center" }}>
                                        {
                                            x.imagePath ?
                                                <StorageImage imagePath={x.imagePath} sx={{ width: "50%", margin: "auto" }} />
                                                : <></>
                                        }
                                    </Box>
                                    <Button color="primary" variant="contained" fullWidth
                                        LinkComponent={Link}
                                        to={"./" + x.id}
                                        sx={{
                                            bgcolor: theme.palette.primary.dark,
                                            borderColor: theme.palette.primary.dark,
                                        }}>{BasicLanguage.cleaners.details[language]}</Button>
                                    <Divider sx={{ mt: 4, mb: 2 }} />
                                </Box>

                            })
                        }
                    </Stack>

                </Container>
            </Box>
        </>
    );
}