import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";

import sessionState from "../../recoil/atoms/sessionState";
import { useRecoilState } from "recoil";
import { auth } from "./firebase";
import { useEffect } from "react";
import { isAdmin } from "./functions";
import { useLocation, useNavigate } from "react-router";

export const useSession = () => {
  const [session, setSession] = useRecoilState(sessionState);
  const location = useLocation();
  const navigate = useNavigate();

  const redirectToLogin = () => {
    setSession(null);
    if (location.pathname !== "/login") {
      navigate("/login", { state: { from: location.pathname } });
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const admin = await isAdmin().catch((e) => console.error(e));
        if (admin) {
          const idTokenResult = await user.getIdTokenResult();
          const customClaims = idTokenResult.claims;
          setSession({
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            admin: admin.data.admin,
            emailVerified: user.emailVerified,
            phoneNumber: user.phoneNumber,
            customClaims: customClaims,
          });
        } else {
          redirectToLogin();
        }
      } else {
        redirectToLogin();
      }

      console.log("changed!!", user);
    });
  }, []);
};
export const login = (email, password) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {})
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      alert("ログインに失敗しました");
      // setDisabledSubmitButton(false);
    });
};
export const logout = () => {
  window.location.pathname = "/login";
  auth.signOut();
};
