import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "../components/utils/firebase";
import Loading from "../components/parts/Loading";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SBXStyles from "../components/utils/Styles";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BasicLanguage from "../components/languages/Basic";
import { ref, uploadBytes } from "@firebase/storage";
import { v4 as uuidv4 } from "uuid";
import validations from "../components/utils/Validations";
import { acceptInput, isImageFile } from "../components/utils/Conditions";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const FileUpload = (props) => {
  const language = useRecoilValue(languageState);
  const [image, setImage] = useState();
  const setImages = props.setImages;
  const setImageFiles = props.setImageFiles;
  const index = props.index;

  const imageSelected = (e) => {
    if (e.target.files.length === 0) return false;

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      /// ファイルサイズ取得
      const fileSize = file.size;
      if (fileSize === 0) return false;

      const fileMib = fileSize / 1024 ** 2;
      if (fileMib <= 10 && isImageFile(file)) {
        setImage(reader.result);
        setImages((prevImages) => {
          if (prevImages.length < 6 && prevImages[index] === "") {
            prevImages = [...prevImages, ""];
          }
          prevImages[index] = reader.result;
          return prevImages;
        });
        setImageFiles((prevImageFiles) => {
          prevImageFiles[index] = file;
          return prevImageFiles;
        });
      } else {
        alert(BasicLanguage.alert.atpFile[language]);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <Stack spacing={1} width="fit-content">
      <Box
        sx={{
          width: "200px",
          height: "200px",
          border: "1px solid #ddd",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {image ? (
          <img
            id={props.id}
            src={image}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            alt="ATP"
          />
        ) : (
          <Typography sx={{ textAlign: "center" }}>
            {BasicLanguage.qr.noFile[language]}
          </Typography>
        )}
      </Box>

      <label htmlFor={`image${index}`}>
        <input
          id={`image${index}`}
          type="file"
          onChange={imageSelected}
          accept={acceptInput}
          style={{ display: "none" }}
        />
        <Button component="span" variant="outlined" fullWidth>
          {BasicLanguage.request.uploadPictureBtn[language]}
        </Button>
      </label>
    </Stack>
  );
};

export default function AtpHistoryCreate() {
  const params = useParams();
  const facilityId = params.facilityId;
  const atpId = params.atpId;
  const language = useRecoilValue(languageState);
  const [atpIsLoading, setAtpIsLoading] = useState(false);
  const [images, setImages] = useState([""]);
  const [imageFiles, setImageFiles] = useState(Array(6));
  const [scoreHasError, setScoreHasError] = useState(false);
  const navigate = useNavigate();

  const [score, setScore] = useState("");

  useEffect(() => {
    setAtpIsLoading(true);
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "atps", atpId))
      .then((docSnapshot) => {
        if (!docSnapshot.exists()) {
          return navigate("../atp");
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(setAtpIsLoading(false));
  }, [atpId, facilityId, navigate]);

  const submitHandler = async () => {
    if (score === "") {
      setScoreHasError(true);
      return;
    }
    setScoreHasError(false);
    const filePaths = [];
    const files = imageFiles.filter((n) => n);
    //storageに画像を保存
    for (const [index, file] of Object.entries(files)) {
      const filePath = await writeStorageImage(file, index);
      if (filePath) {
        filePaths.push(filePath);
      }
    }
    // atpを更新
    updateDoc(doc(db, ENV, VERSION, "facilities", facilityId, "atps", atpId), {
      value: score,
      images: filePaths,
    })
      .then(async () => {
        // atp履歴を追加

        try {
          const showDashboardDocs = await getDocs(
            query(
              collection(
                db,
                ENV,
                VERSION,
                "facilities",
                facilityId,
                "atpHistory"
              ),
              where("atpId", "==", atpId),
              where("showDashboard", "==", true),
              limit(1)
            )
          );
          showDashboardDocs.forEach((doc) => {
            updateDoc(doc.ref, {
              showDashboard: false,
            });
          });
          await addDoc(
            collection(
              db,
              ENV,
              VERSION,
              "facilities",
              facilityId,
              "atpHistory"
            ),
            {
              createdAt: serverTimestamp(),
              value: score,
              images: filePaths,
              atpId: atpId,
              showDashboard: true,
            }
          );
          alert(BasicLanguage.alert.added[language]);
          navigate("../atp");
        } catch (e) {
          console.error(e);
          alert(BasicLanguage.alert.error.default[language]);
        }
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  const writeStorageImage = (file, index) => {
    const path =
      `uploaded_image/${facilityId}/atps/${atpId}/${uuidv4()}-${
        parseInt(index) + 1
      }.png` ||
      `uploaded_image/${facilityId}/atps/${atpId}/${uuidv4()}-${
        parseInt(index) + 1
      }.jpeg`;
    const storageRef = ref(storage, path);
    return uploadBytes(storageRef, file)
      .then(() => {
        return path;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const scoreChange = (e) => {
    if (validations.integer.test(e.target.value)) {
      setScore(e.target.value);
    }
  };
  const form = (
    <>
      <Box>
        <Typography variant="h4">
          <IconButton LinkComponent={Link} to={"../atp"}>
            <ArrowBackIcon />
          </IconButton>
          {BasicLanguage.atpCreate.title[language]}
        </Typography>
        <Box sx={SBXStyles.mainBox}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  {BasicLanguage.atpCreate.table.value[language]}
                </TableCell>
                <TableCell>
                  <FormControl error={scoreHasError}>
                    <TextField
                      sx={{ maxWidth: 200 }}
                      value={score}
                      onChange={scoreChange}
                      error={scoreHasError}
                    />
                    {scoreHasError && (
                      <FormHelperText>
                        {BasicLanguage.atpCreate.error.score[language]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </TableCell>
              </TableRow>
              {images.map((_, index) => {
                const key = `image${index}`;
                return (
                  <TableRow key={key}>
                    <TableCell>
                      {BasicLanguage.atpCreate.table.image[language]}
                      {index + 1}
                    </TableCell>
                    <TableCell>
                      <FileUpload
                        images={images}
                        setImages={setImages}
                        setImageFiles={setImageFiles}
                        index={index}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Button onClick={submitHandler} fullWidth variant="contained">
            {BasicLanguage.atpCreate.button[language]}
          </Button>
        </Box>
      </Box>
    </>
  );

  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      {atpIsLoading ? <Loading /> : form}
    </Box>
  );
}
