import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { Box, Button, Stack, TextField } from "@mui/material";

import BasicLanguage from '../components/languages/Basic';

import { useSession, login } from '../components/utils/session';
import { useRecoilValue } from "recoil";
import sessionState from "../recoil/atoms/sessionState";
import CommonHeaderItems from "../components/parts/CommonHeaderItems";
import Container from "../components/parts/Container";
import styled from "@emotion/styled";
import languageState from "../recoil/atoms/languageState";
import firstUrlState from '../recoil/atoms/firstUl';

const Header = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        height: "80px",
        display: "flex",
        alignItems: "center",
        mr: 5,
    }));

const Login = () => {
    const language = useRecoilValue(languageState);
    const session = useRecoilValue(sessionState);
    const firstUrl = useRecoilValue(firstUrlState);
    const navigate = useNavigate();
    useSession();



    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false)

    useEffect(() => {
        if (session) {
            if (firstUrl && firstUrl.indexOf('/login') !== 0 && firstUrl.indexOf('/logout') !== 0 && firstUrl !== '/') {
                navigate(firstUrl);
            } else {
                navigate('/');
            } 
        }
    }, [session])

    const handleSubmit = (event) => {
        login(email, password)
    };

    return (
        <>
            <Header>
                <Box sx={{ flex: 1 }}></Box>
                <CommonHeaderItems />
            </Header>
            <Container>
                <Box className="containerChild">
                    <Stack justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                        <img src="/logo-removebg-preview.png" style={{ width: "50%" }} />
                    </Stack>
                    <Stack spacing={2}>
                        <Box>
                            <TextField label={BasicLanguage.login.email[language]} variant="outlined" type="email" name="email" value={email}
                                onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        handleSubmit(e)
                                    }
                                }}
                                onChange={(e) => setEmail(e.target.value)} fullWidth />
                        </Box>
                        <Box>
                            <TextField label={BasicLanguage.login.password[language]} variant="outlined" type="password" name="password" value={password}
                                onChange={(e) => setPassword(e.target.value)}

                                onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        handleSubmit(e)
                                    }
                                }}
                                fullWidth />
                        </Box>
                        <Box>
                            <Button disabled={disabledSubmitButton} fullWidth variant="outlined" onClick={handleSubmit}>{BasicLanguage.login.login[language]}</Button>
                        </Box>
                    </Stack>
                </Box>
            </Container >
        </>
    );
};

export default Login;