import { Box, Button, ButtonGroup } from "@mui/material";
import { useRecoilValue } from "recoil";
import languageState from "../../../../recoil/atoms/languageState";
import BasicLanguage from "../../../languages/Basic";
import PropTypes from "prop-types";

export const DashboardButtons = ({ dateDisplay, setDateDisplay }) => {
  return (
    <Box>
      <ButtonGroup size="large">
        <DateDisplayButton
          buttonDate="month"
          dateDisplay={dateDisplay}
          setDateDisplay={setDateDisplay}
        />
        <DateDisplayButton
          buttonDate="week"
          dateDisplay={dateDisplay}
          setDateDisplay={setDateDisplay}
        />
        <DateDisplayButton
          buttonDate="day"
          dateDisplay={dateDisplay}
          setDateDisplay={setDateDisplay}
        />
      </ButtonGroup>
    </Box>
  );
};

DashboardButtons.propTypes = {
  dateDisplay: PropTypes.string.isRequired,
  setDateDisplay: PropTypes.func.isRequired,
};

const DateDisplayButton = ({ buttonDate, dateDisplay, setDateDisplay }) => {
  const language = useRecoilValue(languageState);
  const style = (theme) => ({
    borderRadius: "20px",
    "&.selected span": {
      borderBottom: "2px solid white",
    },
  });

  return (
    <Button
      sx={style}
      className={dateDisplay === buttonDate ? "selected" : ""}
      variant="contained"
      onClick={() => setDateDisplay(buttonDate)}
    >
      <span>{BasicLanguage.common.date[buttonDate][language]}</span>
    </Button>
  );
};

DateDisplayButton.propTypes = {
  buttonDate: PropTypes.string.isRequired,
  dateDisplay: PropTypes.string.isRequired,
  setDateDisplay: PropTypes.func.isRequired,
};
