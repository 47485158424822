import { Box, Button } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BasicLanguage from "../../../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../../../recoil/atoms/languageState";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/ja";
import { DateCalendar } from "@mui/x-date-pickers";
import PropTypes from "prop-types";

export default function DashboardDatePicker({ date, setDate }) {
  const language = useRecoilValue(languageState);

  const [selectedDate, setSelectedDate] = useState(moment());
  const [monthSelectorOpen, setMonthSelectorOpen] = useState(false);

  const handleDatePickerChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const handleDatePickerClose = () => {
    setSelectedDate(date);
    setMonthSelectorOpen(false);
  };

  const handleDatePickerSubmit = () => {
    setDate(selectedDate);
    setMonthSelectorOpen(false);
  };

  const handleDatePickerClick = () => {
    setMonthSelectorOpen((isOpen) => {
      if (isOpen) {
        setSelectedDate(date);
      }
      return !isOpen;
    });
  };

  return (
    <Box position="relative">
      <Button
        sx={{ borderRadius: "20px", fontWeight: 700 }}
        size="large"
        variant="contained"
        endIcon={<ArrowDropDownIcon />}
        onClick={handleDatePickerClick}
      >
        {date.format(
          BasicLanguage.common.date.datePicker.yearAndMonth[language]
        )}
      </Button>
      {monthSelectorOpen && (
        <LocalizationProvider dateAdapter={AdapterMoment} locale={language}>
          <Box
            sx={{
              position: "absolute",
              bottom: "100",
              right: "0",
              zIndex: 100,
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <DateCalendar
              value={selectedDate}
              onChange={handleDatePickerChange}
              views={["month", "year"]}
              format={BasicLanguage.common.date.datePicker.long[language]}
            />
            <Box>
              <Button onClick={handleDatePickerClose}>
                {BasicLanguage.common.date.datePicker.cancel[language]}
              </Button>
              <Button onClick={handleDatePickerSubmit}>OK</Button>
            </Box>
          </Box>
        </LocalizationProvider>
      )}
    </Box>
  );
}

DashboardDatePicker.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
};