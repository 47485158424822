import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from "./firebase";
import moment from "moment";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;
const TIMEZONE = process.env.REACT_APP_TIMEZONE;
/**
 * 特定のCSV形式のファイルを処理し、Firestoreにデータを保存します。
 * @param {object[]} files - 処理するファイルオブジェクトの配列。
 * @param {string} facilityId - 施設のID。
 * @returns {object[]} - 失敗したファイルオブジェクトの配列。
 */
export const RobotFloorCoverage = async (
  files,
  facilityId,
  currentTimeZoneName
) => {
  const selectedTimeZone = currentTimeZoneName;
  const failedFiles = [];

  for (const file of files) {
    try {
      const csvText = await getCsvFileData(file.content);
      const csvData = parseCsvData(csvText, selectedTimeZone);

      if (csvData.length === 0) {
        failedFiles.push({ fileName: file.name });
      } else {
        await saveData(csvData, facilityId);
      }
    } catch (err) {
      console.error(err);
      failedFiles.push({ fileName: file.name });
    }
  }

  return failedFiles;
};

/**
 * ファイルの情報がテキストように読む
 * @param {string} file - 読むファイ
 * @returns {Promise<string>} -ファイルのテキスト内容で解決するプロミス。
 */
const getCsvFileData = (content) => {
  if (content instanceof Blob) {
    const reader = new FileReader();
    reader.readAsText(content);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
    });
  } else if (typeof content === "string") {
    return Promise.resolve(content);
  } else {
    return Promise.reject(
      new TypeError("The content must be a Blob or a string.")
    );
  }
};

/**
 * CSVテキストをオブジェクトの配列に解析します。
 * @param {string} csvText - 解析するCSVテキスト。
 * @returns {object[]} - データオブジェクトの配列。
 */
/**
 * CSVテキストをオブジェクトの配列に解析します。
 * @param {string} csvText - 解析するCSVテキスト。
 * @returns {object[]} - データオブジェクトの配列。
 */

function getOffsetBetweenTimezonesForDate(date, timezone1, timezone2) {
  const timezone1Date = convertDateToAnotherTimeZone(date, timezone1);
  const timezone2Date = convertDateToAnotherTimeZone(date, timezone2);
  return timezone2Date.getTime() - timezone1Date.getTime();
}

function convertDateToAnotherTimeZone(date, timezone) {
  const dateString = date.toLocaleString("en-US", {
    timeZone: timezone,
  });
  return new Date(dateString);
}

const parseCsvData = (csvText, currentTimeZoneName) => {
  const lines = csvText.split("\n").filter((line) => line.trim() !== "");
  const rows = [];
  const dateTimeRegex = /^(\d{4}-\d{2}-\d{2})\s(\d{2}:\d{2}:\d{2})$/;

  for (let i = 1; i < lines.length; i++) {
    const data = lines[i].split(",");
    if (data.length < 2) {
      console.error(`Invalid data format in line ${i + 1}: ${lines[i]}`);
      continue;
    }
    const optionalValue = data[2]?.trim() || "-";
    const dateTimeMatch = data[0]?.trim().match(dateTimeRegex);
    const value = parseFloat(data[1]?.trim());

    if (!dateTimeMatch) {
      console.error(
        `Invalid date-time format in line ${i + 1}: ${data[0]?.trim()}`
      );
      continue;
    }
    const dateTimeString = `${dateTimeMatch[1]} ${dateTimeMatch[2]}`;

    const initialTimeZone = moment.tz(dateTimeString, TIMEZONE);

    const offset = getOffsetBetweenTimezonesForDate(
      initialTimeZone.toDate(),
      currentTimeZoneName,
      TIMEZONE
    );

    const createdAt = initialTimeZone
      .clone()
      .add(offset, "milliseconds")
      .toDate();

    if (isNaN(value)) {
      console.error(
        `Invalid value format in line ${i + 1}: ${data[0]?.trim()}`
      );
      continue;
    }

    rows.push({
      createdAt: createdAt,
      value,
      optionalValue,
      timeConvertedFromRegion: currentTimeZoneName,
    });
  }
  return rows;
};
/**
 * 解析されたデータをFirestoreに保存します。
 * @param {object[]} csvData - 保存するデータ。
 * @param {string} facilityId - 施設のID。
 */
const saveData = async (csvData, facilityId) => {
  const docRef = collection(
    db,
    ENV,
    VERSION,
    "facilities",
    facilityId,
    "robotFloorCoverage"
  );
  for (const data of csvData) {
    await addDoc(docRef, data);
  }
};
