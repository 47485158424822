import * as React from 'react';
import { Alert, AlertTitle, Box, Button, Chip, Divider, FormControl, FormControlLabel, FormLabel, Link, List, ListItem, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip } from '@mui/material';
import SBXStyles from '../components/utils/Styles';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BasicLanguage from '../components/languages/Basic';
import Moment from 'react-moment';

import Map from '../components/parts/Map';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis } from 'recharts';
import Chart from '../components/parts/Chart';
import Alerts from './Alerts';
import { NavLink, useNavigate } from 'react-router-dom';
import Colors from '../components/utils/Colors';

import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';


import fontSizeState from '../recoil/atoms/fontSizeState';
import userState from '../recoil/atoms/userState';
import languageState from '../recoil/atoms/languageState';

import Typography from '../components/parts-ui/Typography';



import { auth, db, functions } from "../components/utils/firebase";
import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
import facilityState from "../recoil/atoms/facilityState";
import { httpsCallable } from 'firebase/functions';
import { getFacilities, isAdmin } from '../components/utils/functions';
import { useSession } from '../components/utils/session';
import sessionState from '../recoil/atoms/sessionState';
import StorageImage from '../components/parts/StorageImage';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import CommonHeaderItems from '../components/parts/CommonHeaderItems';

const Header = styled(Box)(({ theme }) =>
    theme.unstable_sx({


        height: "80px",
        display: "flex",
        alignItems: "center",
        mr: 5,
    }));


const Container = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        minWidth: "320px",
        maxWidth: "600px",
        mx: "auto",
        '& .containerChild':
        {
            background: "linear-gradient(145deg, rgb(244,244,248), rgb(251,251,253))",
            py: "3rem",
            px: 5,
            mx: 1,
            mb: "40px",
            borderRadius: "40px",

        }
    }));
const FacilityButton = (props) => {
    const [alert, setAlert] = React.useState(false);
    const facilities = props.facility;
    const pcDevice = props.pcDevice;
    const language = props.language;

    React.useEffect(() => {
        const index = facilities.findIndex(x => x.role === 0);
        // 少なくても1つ以上の施設管理者 かつ スマホ表示の場合
        if (index !== -1 && !pcDevice) {
            setAlert(true);
        }
    }, [facilities, pcDevice]);

    return (
        <>
        {
            alert ? (
                <>
                <Typography sx={{ mt: 2, mb: 2 }}>
                    {BasicLanguage.rootHome.alert[language]}
                </Typography>
                {facilities.filter((facility) => facility.role !== 0).map(x =>
                    <Button key={x.id} LinkComponent={Link} href={x.role === 0 ? x.id : "/c/" + x.id} color={x.role === 0 ? "primary" : "secondary"} variant="contained" size="large">
                        {x.name}
                    </Button>
                )}
                </>
            ) : (
                facilities.map(x =>
                    <Button key={x.id} LinkComponent={Link} href={x.role === 0 ? x.id : "/c/" + x.id} color={x.role === 0 ? "primary" : "secondary"} variant="contained" size="large">
                        {x.name}
                    </Button>
                )
            )
        }
        </>
    )
}

export default function RootHome(props) {
    const navigate = useNavigate();
    const sensors = props.sensors ? props.sensors : [];
    const notification = props.notification ? props.notification : [];
    const maps = props.maps ? props.maps : [];
    const theme = useTheme();

    const [admin, setAdmin] = React.useState(false);
    const [user, setUser] = useRecoilState(userState);

    const [selectedSensor, setSelectedSensor] = React.useState();
    const [language, setLanguage] = useRecoilState(languageState);
    const [fontSize, setFontSize] = useRecoilState(fontSizeState);

    const [facilities, setFacilities] = React.useState([]);

    const [emailVerified, setEmailVerified] = React.useState(true)
    const [pcDevice, setPcDvice] = React.useState(true)

    const session = useRecoilValue(sessionState);

    useSession();


    React.useEffect(() => {
        if (auth?.currentUser) {
            if (auth.currentUser.emailVerified === true) setEmailVerified(true)
            if (auth.currentUser.emailVerified === false) setEmailVerified(false)
        }
    }, [auth]);

    React.useEffect(() => {

        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf("android") !== -1 || ua.indexOf("iphone") !== -1 || ua.indexOf("ipad") !== -1) setPcDvice(false)

        if (session) {

            setAdmin(session.admin)

            getFacilities()
                .then((result) => {
                    setFacilities(result.data)
                }).catch(e => {
                    setFacilities([]);
                    console.error(e)
                });
        } else {
            setFacilities([])
            setAdmin(false)
        }


    }, [session]);




    return (
        <>
            <Header>
                <Box sx={{ flex: 1 }}></Box>
                <CommonHeaderItems />
            </Header>
            <Container>
                <Box className='containerChild'>
                    <Stack sx={{ maxWidth: 500, margin: "auto" }}>

                        {emailVerified ? <></> :
                            <Alert severity='error' sx={{ mb: 2, fontSize:"1.2em" }}><AlertTitle sx={{ mb: 2, fontSize:"1.2em" }}>{BasicLanguage.rootHome.mailTitle[language]}</AlertTitle>{BasicLanguage.rootHome.mail1[language]}<Link href="/account">{BasicLanguage.rootHome.mail2[language]}</Link>{BasicLanguage.rootHome.mail3[language]}</Alert>}
                        <Stack justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                            <img src="/logo-removebg-preview.png" style={{ width: "50%" }} />
                        </Stack>
                        <Typography className='subTitle' sx={{ textAlign: "center" }} variant="h5">
                            {BasicLanguage.rootHome.selectFacility[language]}
                        </Typography>
                        <Stack spacing={1}>
                            <FacilityButton facility={facilities} pcDevice={pcDevice} language={language}/>
                        </Stack>

                        {admin ?
                            <Box>
                                <Typography className='subTitle' sx={{ textAlign: "center" }} variant="h5">
                                    ADMIN MODE
                                </Typography>
                                <Box>
                                    <Button LinkComponent={Link} href="/admin" color="primary" variant="contained" size="large">
                                        {BasicLanguage.rootHome.adminMode[language]}
                                    </Button>
                                </Box>
                            </Box> : <></>
                        }
                    </Stack >


                </Box>
            </Container>
        </>
    );
}