import React, { useEffect } from "react";
import { Outlet } from "react-router";
import {
  Route,
  useLocation,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Link as RouterLink,
} from "react-router-dom";

import Colors from "./components/utils/Colors";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";

import LanguageBasic from "./components/languages/Basic";
import Menu from "./components/parts/Menu";

import Home from "./pages/Home";
import {
  RequestPage,
  loadRequest,
  RequestErrorPage,
} from "./pages/RequestPage";
import Qr from "./pages/Qr";
import Facility from "./pages/Facility";
import Sensors from "./pages/Sensors";
import Atp from "./pages/Atp";
import Reports from "./pages/Reports";
import ReportsAdd from "./pages/ReportsAdd";
import ReportsAddComplete from "./pages/ReportsAddComplete";
import Users from "./pages/Users";
import SensorDetail from "./pages/SensorDetail";
import Dashboard from "./pages/Dashboard";
import AtpDetail from "./pages/AtpDetail";
import RootHome from "./pages/RootHome";
import HeaderSidebar from "./components/parts/HeaderSidebar";
import languageState from "./recoil/atoms/languageState";
import fontSizeState from "./recoil/atoms/fontSizeState";
import firstUrlState from "./recoil/atoms/firstUl";
import UserDetail from "./pages/UserDetail";

import { users, sensorsHistory, facilities } from "./_dummy/dummyData";
import UserAdd from "./pages/UserAdd";
import QrAdd from "./pages/QrAdd";
import QrDetail from "./pages/QrDetail";
import RequestPageThanks, {
  loadRequestThanks,
} from "./pages/RequestPageThanks";
import Maps from "./pages/Maps";
import MapsAdd from "./pages/MapsAdd";
import CleanerHome from "./pages/CleanerHome";
import AtpAdd from "./pages/AtpAdd";
import Admin from "./pages/Admin";
import AdminLayout from "./components/layouts/Admin";
import FacilityLayout from "./components/layouts/Facility";
import CleanerLayout from "./components/layouts/Cleaner";
import RequestLayout from "./components/layouts/Request";
import Login from "./pages/Login";

import { app } from "./components/utils/firebase";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useSession } from "./components/utils/session";
import SensorTriggerAdd from "./pages/SensorTriggerAdd";
import SensorTrigger from "./pages/SensorTrigger";
import PropTypes from "prop-types";

import { Helmet, HelmetProvider } from "react-helmet-async";
import Account from "./pages/Account";

import CleanerTaskDetail from "./pages/CleanerTaskDetail";
import ReportsEdit from "./pages/ReportsEdit";
import AtpHistoryCreate from "./pages/AtpHistoryCreate";
import MapsEdit from "./pages/MapsEdit";
import Robots from "./pages/Robots";
import RobotDetail from "./pages/RobotDetail";
import RobotFloorAdd from "./pages/RobotFloorAdd";
import RobotFloorEdit from "./pages/RobotFloorEdit";
import RobotFloorAreaDetail from "./pages/RobotFloorAreaDetail";
import RobotStart from "./pages/RobotStart";

import DashboardSample from "./pages/DashboardSample";
import RequestPageSurvery, {
  loadRequestSurvey,
} from "./pages/RequestPageSurvery";
import FacilitySettings from "./pages/FacilitySettings";

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
};

const MemoryFirstUrl = (props) => {
  const location = useLocation();
  const [firstUrl, setFirstUrl] = useRecoilState(firstUrlState);
  useEffect((X) => {
    setFirstUrl(location.pathname);
  }, []);
  return <></>;
};

const App = () => {
  const isDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const fontSize = useRecoilValue(fontSizeState);
  const theme = createTheme({
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
          sx: {
            color: Colors.primary.darkness,
            "&:hover": {
              color: Colors.primary.light,
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
    palette: {
      mode: "light",
      background: {
        // default: '#F1F9FF',
        default: Colors.primary.main,
        paper: Colors.white,
        mainArea: "#f7f7f7",
      },
      primary: {
        main: Colors.primary.main,
        light: Colors.primary.light,
        dark: Colors.primary.dark,
        contrastText: Colors.white,
        // dark: '#0000ff'
      },
      secondary: {
        main: Colors.secondary.main,
        contrastText: Colors.white,
        // light: '#00ff00',
        // dark: '#0000ff'
      },
      tertiary: {
        main: Colors.tertiary,
        contrastText: "#fff",
      },
      common: {
        white: Colors.white,
        grey: Colors.grey,
        black: Colors.black,
      },
      text: {
        primary: Colors.text,
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
      fontSize: fontSize,
      fontFamily: ['"Noto Sans JP"', "Roboto", "sans-serif"].join(","),
    },
  });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Layout />}>
        <Route path="/" element={<RootHome title="" />} />
        <Route path="/login" index element={<Login title="" />} />
        <Route path="/account" index element={<Account title="" />} />

        <Route path="/request" element={<RequestLayout />}>
          <Route
            path="/request/:qrId"
            loader={loadRequest}
            element={<RequestPage />}
            errorElement={<RequestErrorPage />}
          />
          <Route
            path="/request/:qrId/survey"
            loader={loadRequestSurvey}
            element={<RequestPageSurvery title="" />}
            errorElement={<RequestErrorPage />}
          />
          <Route
            path="/request/:qrId/thanks"
            loader={loadRequestThanks}
            element={<RequestPageThanks title="" />}
            errorElement={<RequestErrorPage />}
          />
        </Route>

        <Route path="/admin" element={<AdminLayout title="" />}>
          <Route index element={<Admin title="" path="/" />} />
          <Route path="/admin/auth-users" element={<Admin title="" path="auth-users" tab={0} />} />
          <Route path="/admin/firestore-users" element={<Admin title="" path="firestore-users" tab={1} />} />
          <Route path="/admin/facilities" element={<Admin title="" path="facilities" tab={2} />} />
          <Route path="/admin/sensors" element={<Admin title="" path="sensors" tab={3} />} />
          <Route path="/admin/robots" element={<Admin title="" path="robots" tab={4} />} />
          <Route path="/admin/kpis" element={<Admin title="" path="kpis" tab={5} />} />
        </Route>

        <Route path="/c/:facilityId" element={<CleanerLayout />}>
          <Route index element={<CleanerHome title="" />} />
          <Route
            path="/c/:facilityId/:taskId"
            element={<CleanerTaskDetail title="" />}
          />
          <Route
            path="/c/:facilityId/robots/start/:taskId"
            element={<RobotStart title="" />}
          />
        </Route>

        <Route path="/:facilityId" element={<FacilityLayout />}>
          <Route index element={<Home />} />
          <Route path="/:facilityId/qr" element={<Qr />} />
          <Route path="/:facilityId/settings" element={<FacilitySettings />} />
          <Route path="/:facilityId/dashboard" element={<Dashboard />} />
          <Route path="/:facilityId/facility" element={<Facility />} />
          <Route path="/:facilityId/users" element={<Users />} />
          <Route path="/:facilityId/users/add" element={<UserAdd />} />
          <Route path="/:facilityId/users/:userId" element={<UserDetail />} />
          <Route path="/:facilityId/sensors" element={<Sensors />} />
          <Route
            path="/:facilityId/sensors/:sensorId"
            element={<SensorDetail />}
          />
          <Route
            path="/:facilityId/sensors/:sensorId/trigger"
            element={<SensorTriggerAdd />}
          />
          <Route
            path="/:facilityId/sensors/:sensorId/trigger/:triggerId"
            element={<SensorTrigger />}
          />
          <Route path="/:facilityId/robots" element={<Robots />} />
          <Route
            path="/:facilityId/robots/:robotId"
            element={<RobotDetail />}
          />
          <Route
            path="/:facilityId/robots/:robotId/floors"
            element={<RobotFloorAdd />}
          />
          <Route
            path="/:facilityId/robots/:robotId/floors/:floorId"
            element={<RobotFloorEdit />}
          />
          <Route
            path="/:facilityId/robots/:robotId/floors/:floorId/areas/:areaId"
            element={<RobotFloorAreaDetail />}
          />
          <Route path="/:facilityId/qr/add" element={<QrAdd />} />
          <Route path="/:facilityId/qr/:qrId" element={<QrDetail />} />
          <Route path="/:facilityId/atp" element={<Atp />} />
          <Route path="/:facilityId/atp/add" element={<AtpAdd />} />
          <Route path="/:facilityId/atp/:atpId" element={<AtpDetail />} />
          <Route
            path="/:facilityId/atp/:atpId/create"
            element={<AtpHistoryCreate />}
          />
          <Route path="/:facilityId/reports" element={<Reports />} />
          <Route path="/:facilityId/reports/add" element={<ReportsAdd />} />
          <Route
            path="/:facilityId/reports/addComplete"
            element={<ReportsAddComplete />}
          />
          <Route
            path="/:facilityId/reports/edit/:taskId"
            element={<ReportsEdit />}
          />
          <Route path="/:facilityId/maps" element={<Maps />} />
          <Route path="/:facilityId/maps/add" element={<MapsAdd />} />
          <Route path="/:facilityId/maps/edit/:mapId" element={<MapsEdit />} />
          {process.env.NODE_ENV === "development" && (
            <Route
              path="/:facilityId/dashboard_sample"
              element={<DashboardSample />}
            />
          )}
        </Route>
      </Route>
    )
  );
  return (
    <>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <RouterProvider router={router} />
        </HelmetProvider>
      </ThemeProvider>
    </>
  );
};

const Layout = () => {
  return (
    <>
      <MemoryFirstUrl />
      <CssBaseline />
      <Outlet />
    </>
  );
};

export default App;
