import React, { useCallback, useEffect } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import SBXStyles from "../components/utils/Styles";
import { useNavigate, useParams } from "react-router";
import CommonError from "./CommonError";
import { Link } from "react-router-dom";

import Typography from "../components/parts-ui/Typography";
import { db } from "../components/utils/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import languageState from "../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import "dayjs/locale/ja";
import RobotFloors from "../components/parts/RobotFloors";
import RobotCleaningModes from "../components/parts/RobotCleaningModes";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function RobotDetail() {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;
  const robotId = params.robotId;
  const navigate = useNavigate();
  const [robot, setRobot] = React.useState({});
  const [nameEditMode, setNameEditMode] = React.useState(false);
  const [newName, setNewName] = React.useState("");

  const getRobot = useCallback(() => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "robots", robotId))
      .then((docSnap) => {
        if (!docSnap.data()) {
          return navigate("../robots");
        }
        setRobot({ ...docSnap.data(), id: docSnap.id });
      })
      .catch((e) => console.error(e));
  }, [facilityId, navigate, robotId]);

  useEffect(() => {
    getRobot();
  }, [getRobot]);

  const submitName = (e) => {
    e.preventDefault();
    updateDoc(
      doc(db, ENV, VERSION, "facilities", facilityId, "robots", robotId),
      { name: newName }
    )
      .then(() => {
        setNameEditMode(false);
        getRobot();
      })
      .catch((e) => console.error(e));
  };

  const toNameEditMode = () => {
    setNewName(robot ? robot.name : "");
    setNameEditMode(true);
  };

  const cancelNameEdit = () => {
    setNewName("");
    setNameEditMode(false);
  };

  const changeNewName = (e) => {
    setNewName(e.target.value);
  };

  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      {robot ? (
        Object.keys(robot).length !== 0 && (
          <Box>
            <Typography className="pageTitle" variant="h4">
              <IconButton LinkComponent={Link} to={"../robots"}>
                <ArrowBackIcon />
              </IconButton>
              {robot.name}
            </Typography>
            <Box sx={SBXStyles.mainBox}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "30%" }}>
                      {BasicLanguage.robots.detail.table.item[language]}
                    </TableCell>
                    <TableCell>
                      {BasicLanguage.robots.detail.table.content[language]}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.robots.table.id[language]}
                    </TableCell>
                    <TableCell>{robot.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.robots.table.type[language]}
                    </TableCell>
                    <TableCell>{robot.type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.robots.table.name[language]}
                    </TableCell>
                    <TableCell>
                      {nameEditMode ? (
                        <form onSubmit={submitName}>
                          <TextField
                            variant="standard"
                            onChange={changeNewName}
                            defaultValue={robot.name}
                            autoFocus={true}
                          />
                          <IconButton type="submit">
                            <CheckCircleIcon color="success" />
                          </IconButton>
                          <IconButton onClick={cancelNameEdit}>
                            <CancelIcon color="error" />
                          </IconButton>
                        </form>
                      ) : (
                        <>
                          {robot.name}
                          <IconButton sx={{ ml: 1 }} onClick={toNameEditMode}>
                            <EditIcon sx={{ fontSize: "0.7em" }} />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <RobotCleaningModes getRobot={getRobot} robot={robot} />
            <RobotFloors robot={robot} />
          </Box>
        )
      ) : (
        <CommonError></CommonError>
      )}
    </Box>
  );
}
