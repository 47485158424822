import { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  runTransaction,
  getDocs,
} from "firebase/firestore";
import { db } from "../components/utils/firebase";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export const useSurveyQuestions = (facilityId) => {
  const [surveyQuestions, setSurveyQuestions] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, ENV, VERSION, "facilities", facilityId, "surveyQuestion"),
      orderBy("createdAt", "asc")
    );
    const unsub = onSnapshot(q, (querySnapshot) => {
      setSurveyQuestions(
        querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        })
      );
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "removed" && querySnapshot.empty) {
          disableAllQrs(facilityId);
        }
      });
    });
    return unsub;
  }, [facilityId]);

  return surveyQuestions;
};

const disableAllQrs = async (facilityId) => {
  const qrCollectionRef = collection(
    db,
    ENV,
    VERSION,
    "facilities",
    facilityId,
    "qr"
  );
  try {
    const qrDocs = await getDocs(qrCollectionRef);
    await runTransaction(db, async (transaction) => {
      qrDocs.forEach((doc) => {
        transaction.update(doc.ref, {
          displaySurvey: false,
        });
      });
    });
  } catch (e) {
    console.error(e);
  }
};
