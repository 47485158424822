import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import moment from "moment";
import { useRecoilValue } from "recoil";
import languageState from "../../../../recoil/atoms/languageState";
import BasicLanguage from "../../../languages/Basic";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../utils/firebase";
import { useParams } from "react-router";
import "moment/locale/ja";
import DateRangePicker from "../../shared/DateRangePicker";

// 週の開始曜日を月曜日に設定
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

moment.updateLocale("ja", {
  week: {
    dow: 1,
  },
});

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function QrDownload() {
  const language = useRecoilValue(languageState);
  const { facilityId } = useParams();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [groupingEnabled, setGroupingEnabled] = useState(false);

  const getRequests = () => {
    setIsLoading(true);
    const start = moment(startDate).startOf("day").toDate();
    const end = moment(endDate).endOf("day").toDate();
    return getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "notify"),
        where("createdAt", ">=", start),
        where("createdAt", "<=", end),
        where("type", "==", "QR"),
        orderBy("createdAt", "asc")
      )
    )
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        return data;
      })
      .catch((error) => {
        console.error(error);
        return [];
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const buttonDisabled = useMemo(() => {
    return (
      !startDate || !endDate || !!startDateError || !!endDateError || isLoading
    );
  }, [startDate, endDate, startDateError, endDateError, isLoading]);

  const downloadQrHistory = async () => {
    setIsLoading(true);
    const requests = await getRequests();

    if (requests.length === 0) {
      setIsLoading(false);
      alert(BasicLanguage.qr.csv.noData[language]);
      return;
    }

    // ヘッダーの設定
    const mainHeader = setMainHeader(startDate, endDate, language);
    const subheaderTemplate = setSubheaderTemplate(startDate, endDate);
    const summayHeader = [
      BasicLanguage.qr.csv.summaryHeader[language],
      ...subheaderTemplate,
    ];
    const dayOfWeekHeader = [
      BasicLanguage.qr.csv.dayOfWeekHeader[language],
      ...subheaderTemplate,
    ];
    const placeHeader = [
      BasicLanguage.qr.csv.placeHeader[language],
      ...subheaderTemplate,
    ];

    // データの設定
    const requestCount = groupRequestByWeekandRequest(
      requests,
      startDate,
      endDate,
      language
    );
    const weekDayCount = groupRequestByWeekAndDay(
      requests,
      startDate,
      endDate,
      language
    );
    const placeCount = groupReqestByWeekPlace(
      requests,
      startDate,
      endDate,
      groupingEnabled
    );

    // CSVデータの作成
    let data = mainHeader.join(",") + "\n";
    data += summayHeader.join(",") + "\n";
    data += requestCount.map((request) => request.join(",")).join("\n") + "\n";
    data += dayOfWeekHeader.join(",") + "\n";
    data += weekDayCount.map((week) => week.join(",")).join("\n") + "\n";
    data += placeHeader.join(",") + "\n";
    data += placeCount.map((place) => place.join(",")).join("\n");

    //CSVファイルのダウンロード
    const filename = `${facilityId}_2Dbarcode_request_${moment(
      startDate
    ).format("YYYYMMDD")}~${moment(endDate).format("YYYMMDD")}.csv`;
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // 文字化け対策
    const blob = new Blob([bom, data], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = filename;
    a.href = url;
    a.click();
    URL.revokeObjectURL(url);
    setIsLoading(false);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
      <FormControlLabel
        control={
          <Checkbox
            value={groupingEnabled}
            onChange={(e) => setGroupingEnabled(e.target.checked)}
          />
        }
        label={BasicLanguage.qr.csv.grouping[language]}
        labelPlacement="end"
      />
      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        startDateError={startDateError}
        setStartDateError={setStartDateError}
        endDateError={endDateError}
        setEndDateError={setEndDateError}
      />
      <Button
        variant="outlined"
        color="primary"
        disabled={isLoading || buttonDisabled}
        onClick={downloadQrHistory}
        sx={{ minWidth: "150px" }}
      >
        {isLoading ? (
          <CircularProgress size="1.75rem" sx={{ color: "white" }} />
        ) : (
          BasicLanguage.common.download[language]
        )}
      </Button>
    </Box>
  );
}

/**
 * @param {object} startDay
 * @param {object} endDay
 * @param {string} language
 * @returns Array
 */
const setMainHeader = (startDay, endDay, language) => {
  const header = [""];
  let count = 0;
  let start = moment(startDay).startOf("week");
  let end = moment(endDay).endOf("week");
  while (start.isSameOrBefore(end)) {
    count++;
    if (language === "ja") {
      header.push(`${count}週目`);
    } else if (language === "en") {
      header.push(`Week ${count}`);
    }
    start = start.add(1, "w");
  }
  return header;
};

/**
 * @param {object} startDay
 * @param {object} endDay
 * @returns Array
 */
const setSubheaderTemplate = (startDay, endDay) => {
  const header = [];
  if (startDay.week() === endDay.week()) {
    const start = moment(startDay).format("M/DD");
    const end = moment(endDay).format("M/DD");
    header.push(`${start}-${end}`);
    return header;
  }
  let start = moment(startDay);
  let end = moment(endDay);
  let startView, endView;
  while (start.isSameOrBefore(moment(end).endOf("week"))) {
    if (start.week() === startDay.week()) {
      startView = moment(start).format("M/DD");
      endView = moment(start).endOf("week").format("M/DD");
    } else if (start.week() === endDay.week()) {
      startView = moment(start).startOf("week").format("M/DD");
      endView = moment(end).format("M/DD");
    } else {
      startView = moment(start).startOf("week").format("M/DD");
      endView = moment(start).endOf("week").format("M/DD");
    }
    header.push(`${startView}-${endView}`);
    start = moment(start).add(1, "w");
  }
  return header;
};

/**
 * @param {Object[]} requests
 * @param {object} startDay
 * @param {object} endDay
 * @param {string} language
 * @returns Array
 */
const groupRequestByWeekandRequest = (requests, startDay, endDay, language) => {
  const weekGroup = [];
  let start = moment(startDay);
  let end = moment(endDay);
  while (start.isSameOrBefore(moment(end).endOf("week"))) {
    weekGroup.push(0);
    start = moment(start).add(1, "w");
  }

  const requestGroup = {};
  for (let request of requests) {
    if (request.task && request.task.request) {
      requestGroup[request.task.request] = [...weekGroup];
    }
  }
  requestGroup[BasicLanguage.qr.csv.total[language]] = [...weekGroup];

  const requestCount = requests.reduce((acc, request) => {
    let week = moment(request.createdAt.toDate()).week();
    const year = moment(request.createdAt.toDate()).year();
    if (year > startDay.year()) {
      week += 52;
    }
    const task = request.task?.request;
    if (task) {
      acc[task][week - startDay.week()]++;
      acc[BasicLanguage.qr.csv.total[language]][week - startDay.week()]++;
    }
    return acc;
  }, requestGroup);

  return Object.keys(requestCount).map((key) => {
    return [key, ...requestCount[key]];
  });
};

/**
 *
 * @param {Object[]} requests
 * @param {object} startDay
 * @param {object} endDay
 * @param {string} language
 * @returns Array
 */
const groupRequestByWeekAndDay = (requests, startDay, endDay, language) => {
  const weekGroup = [];
  let start = moment(startDay);
  let end = moment(endDay);
  while (start.isSameOrBefore(moment(end).endOf("week"))) {
    weekGroup.push([0, 0, 0, 0, 0, 0, 0]);
    start = moment(start).add(1, "w");
  }

  const weekCount = requests.reduce((acc, request) => {
    let week = moment(request.createdAt.toDate()).week();
    const year = moment(request.createdAt.toDate()).year();
    if (year > startDay.year()) {
      week += 52;
    }
    const day = moment(request.createdAt.toDate()).isoWeekday();
    acc[week - startDay.week()][day - 1]++;
    return acc;
  }, weekGroup);

  const dayGroup = [];
  for (let i = 1; i <= 7; i++) {
    dayGroup.push([BasicLanguage.reportsAdd.weekDay[i][language]]);
  }

  for (let i = 0; i < 7; i++) {
    for (let j = 0; j < weekCount.length; j++) {
      dayGroup[i].push(weekCount[j][i]);
    }
  }

  return dayGroup;
};

/**
 *
 * @param {Object[]} requests
 * @param {object} startDay
 * @param {object} endDay
 * @param {boolean} groupingEnabled
 * @returns Array
 */
const groupReqestByWeekPlace = (
  requests,
  startDay,
  endDay,
  groupingEnabled
) => {
  const placeGroup = {};
  const weekGroup = [];
  let start = moment(startDay);
  let end = moment(endDay);
  while (start.isSameOrBefore(moment(end).endOf("week"))) {
    weekGroup.push(0);
    start = moment(start).add(1, "w");
  }

  for (let request of requests) {
    if (request.task && request.task.place) {
      if (groupingEnabled) {
        placeGroup[request.task.place.split("_")[0]] = [...weekGroup];
      } else {
        placeGroup[request.task.place] = [...weekGroup];
      }
    }
  }

  const placeRequestCount = requests.reduce((acc, request) => {
    let week = moment(request.createdAt.toDate()).week();
    const year = moment(request.createdAt.toDate()).year();
    if (year > startDay.year()) {
      week += 52;
    }
    if (request.task && request.task.place) {
      const place = request.task.place;
      if (groupingEnabled) {
        acc[place.split("_")[0]][week - startDay.week()]++;
      } else {
        acc[place][week - startDay.week()]++;
      }
    }
    return acc;
  }, placeGroup);

  return Object.keys(placeRequestCount).map((key) => {
    return [key, ...placeRequestCount[key]];
  });
};
