import { addDoc, collection } from "firebase/firestore";
import { db } from "../utils/firebase";

const MAIN_ITEM_COLUMN_INDEX = 0;
const SUB_ITEM_COLUMN_INDEX = 1;
const QUESTION_COLUMN_INDEX = 2;
const IMPORTANT_ITEM_COLUMN_INDEX = 3;
const ANSWER_COLUMN_INDEX = 4;
const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

/**
 *
 * @param {object[]} files
 * @param {string} facilityId
 *
 * @returns object[]
 */
export const processCustomerSatisfactionFiles = async (files, facilityId) => {
  const failedFiles = [];
  for (const file of files) {
    try {
      const csvText = await getCsvFileData(file.content);
      const csvData = getCustomerSatisfactionAnswers(csvText);
      const fireStoreData = csvDataToFirestoreData(csvData, facilityId);
      await saveCustomerSatisfactionData(fireStoreData, facilityId);
    } catch (err) {
      console.error(err);
      failedFiles.push({ fileName: file.name });
    }
  }
  return failedFiles;
};

/**
 *
 * @param {Blob} file
 * @returns promise
 */
const getCsvFileData = async (file) => {
  const reader = new FileReader();
  reader.readAsText(file);
  return new Promise((resolve) => {
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

/**
 *
 * @param {string} csvText
 * @returns object[]
 */
const getCustomerSatisfactionAnswers = (csvText) => {
  const lines = csvText.split("\n");
  const rows = [];
  for (let i = 1; i < lines.length; i++) {
    const data = lines[i].split(",");
    const tmpObj = {};
    tmpObj["main_item"] = data[MAIN_ITEM_COLUMN_INDEX].trim();
    tmpObj["sub_item"] = data[SUB_ITEM_COLUMN_INDEX].trim();
    tmpObj["question"] = data[QUESTION_COLUMN_INDEX].trim();
    tmpObj["importantItem"] = data[IMPORTANT_ITEM_COLUMN_INDEX].trim();
    tmpObj["answer"] = data[ANSWER_COLUMN_INDEX].trim();
    rows.push(tmpObj);
  }
  return rows;
};

/**
 *
 * @param {object[]} csvData
 * @param {string} facilityId
 * @returns object
 */
const csvDataToFirestoreData = (csvData, facilityId) => {
  const data = { csat: [], free_comments: [], important_items: [], npss:[] };
  for (const row of csvData) {
    const mainItem = parseInt(row.main_item);
    const subItem = parseInt(row.sub_item);
    const question = row.question;
    const importantItem = row.importantItem;
    const answer = row.answer;

    if (mainItem === 1 && subItem === 9) {
      data.free_comments.push({
        related_ids: ["1-1", "1-2", "1-3", "1-4", "1-5", "1-6", "1-7", "1-8"],
        comments: {
          [facilityId]: answer,
        },
      });
    } else if (mainItem === 2 && subItem === 12) {
      data.free_comments.push({
        related_ids: [
          "2-1",
          "2-2",
          "2-3",
          "2-4",
          "2-5",
          "2-6",
          "2-7",
          "2-8",
          "2-9",
          "2-10",
          "2-11",
        ],
        comments: {
          [facilityId]: answer,
        },
      });
    } else if (mainItem === 3 && subItem === 6) {
      data.free_comments.push({
        related_ids: ["3-1", "3-2", "3-3", "3-4", "3-5"],
        comments: {
          [facilityId]: answer,
        },
      });
    } else if (mainItem === 5 && subItem !== 1) {
      data.important_items.push({
        item_title: importantItem,
        point: answer === "○" ? 1 : 0,
      });
    } else if (mainItem === 5 && subItem === 1) {
      data.npss.push({
        id: `${mainItem}-${subItem}`,
        question: question,
        scores: {
          [facilityId]: parseInt(answer),
        },
      });
    } else {
      data.csat.push({
        id: `${mainItem}-${subItem}`,
        question: question,
        scores: {
          [facilityId]: parseInt(answer),
        },
      });
    }
  }
  return data;
};

/**
 *
 * @param {object} data
 * @param {string} facilityId
 */
const saveCustomerSatisfactionData = async (data, facilityId) => {
  const docRef = collection(
    db,
    ENV,
    VERSION,
    "facilities",
    facilityId,
    "customerSatisfactionResults"
  );
  await addDoc(docRef, data);
};
