import * as React from "react";
import { Tooltip } from "@mui/material";

import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import Battery1BarIcon from "@mui/icons-material/Battery1Bar";
import Battery2BarIcon from "@mui/icons-material/Battery2Bar";
import Battery4BarIcon from "@mui/icons-material/Battery4Bar";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import BatteryUnknownIcon from "@mui/icons-material/BatteryUnknown";

import { db } from "../utils/firebase";
import {
  and,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const UNA_PB25_MAX = 388;
const UNA_PX25A_MAX = 277;

const BatteryStatus = (props) => {
  const facilityId = props.facilityId;
  const sensorId = props.sensorId;
  const sensorType = props.sensorType;

  const [voltage, setVoltage] = React.useState("?");
  const [batteryPercent, setBatteryPercent] = React.useState(null);

  const getCurrentBattery = React.useCallback(() => {
    const q = query(
      collection(db, ENV, VERSION, "facilities", facilityId, "sensorHistory"),
      and(
        where("deviceId", "==", sensorId),
        where("dataType", "==", "battery")
      ),
      orderBy("createdAt", "desc"),
      limit(1)
    );

    getDocs(q)
      .then((querysnapshot) => {
        const docs = querysnapshot?.docs;
        let value = null;
        const data = docs?.map((x) => x.data());
        if (data?.length === 0 || !data[0].value) {
          return;
        }
        value = data[0].value;

        setVoltage(value);
        switch (sensorType) {
          case "una_px25a":
            setBatteryPercent(
              Math.floor(((UNA_PX25A_MAX - value) / UNA_PX25A_MAX) * 100)
            );
            break;
          case "una_pb25":
            setBatteryPercent(
              Math.floor(((UNA_PB25_MAX - value) / UNA_PB25_MAX) * 100)
            );
            break;
          default:
            setBatteryPercent(Math.floor((value / 3) * 100));
            break;
        }
      })
      .catch((e) => console.error(e));
  }, [facilityId, sensorId, sensorType]);

  React.useEffect(() => {
    getCurrentBattery();
  }, [getCurrentBattery]);

  return (
    <Tooltip title={voltage} placement="right">
      {!batteryPercent ? (
        <BatteryUnknownIcon color="error" />
      ) : batteryPercent < 10 ? (
        <Battery0BarIcon color="error" />
      ) : batteryPercent < 20 ? (
        <Battery1BarIcon color="warning" />
      ) : batteryPercent < 55 ? (
        <Battery2BarIcon />
      ) : batteryPercent < 80 ? (
        <Battery4BarIcon />
      ) : (
        <BatteryFullIcon />
      )}
    </Tooltip>
  );
};
export default BatteryStatus;
