import { Box, IconButton } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import SBXStyles from "../components/utils/Styles";
import { Link, useParams } from "react-router-dom";
import Typography from "../components/parts-ui/Typography";
import MapsForm from "../components/parts/forms/MapsForm";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import BasicLanguage from "../components/languages/Basic";

export default function MapsAdd() {
  const language = useRecoilValue(languageState);
  const params = useParams();

  return (
    <>
      <Box component="main" sx={SBXStyles.mainContainer}>
        <Typography variant="h4">
          <IconButton
            LinkComponent={Link}
            to={"/" + params.facilityId + "/maps"}
          >
            <ArrowBackIcon />
          </IconButton>
          {BasicLanguage.mapsAdd.title[language]}
        </Typography>
        <MapsForm />
      </Box>
    </>
  );
}
