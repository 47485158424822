import { Outlet, useNavigate, useParams } from "react-router";
import { Box, MenuItem, Select, Stack, styled } from "@mui/material";
import React, { useState } from "react";
import TimeZoneProvider from "../utils/setTimeZoneContext";
import { db } from "../utils/firebase";
import {
  doc,
  getDoc,
  getDocs,
  query,
  collection,
  where,
} from "firebase/firestore";
import { useRecoilState, useRecoilValue } from "recoil";
import facilityState from "../../recoil/atoms/facilityState";
import sessionState from "../../recoil/atoms/sessionState";
import { useSession } from "../utils/session";
import { Helmet } from "react-helmet-async";
import Menu from "../parts/Menu";
import { getFacilities } from "../utils/functions";
import { useTheme } from "@emotion/react";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Link } from "react-router-dom";
import CommonHeaderItems from "../parts/CommonHeaderItems";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const Main = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    flex: 1,
    background: "linear-gradient(145deg, rgb(244,244,248), rgb(251,251,253))",
    py: "3rem",
    px: 5,
    mr: 5,
    mb: "40px",
    borderRadius: "40px",
    minHeight: `calc(100vh - 120px);`,
  })
);

function Sidebar(robot) {
  const robotFlag = robot === true ? "1" : "2";
  return (
    <Box>
      <Menu robot={robotFlag} />
    </Box>
  );
}

const Header = () => {
  const params = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const facilityId = params.facilityId;
  const session = useRecoilValue(sessionState);
  const [facilities, setFacilities] = React.useState([]);

  React.useEffect(() => {
    if (session) {
      getFacilities()
        .then((result) => {
          console.log(result);
          setFacilities(result.data.filter((x) => x.role === 0));
        })
        .catch((e) => {
          setFacilities([]);
          console.error(e);
        });
    } else {
      setFacilities([]);
    }
  }, []);

  return (
    <Box
      sx={{
        height: "80px",
        display: "flex",
        alignItems: "center",
        mr: 5,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ flex: 1, height: "80px" }}
      >
        <Box sx={{ flex: 1, mt: "10px", mb: "10px" }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ flex: 1 }}
            spacing={2}
          >
            <Box
              sx={{
                width: "13em",
                pl: "24px",
                transition: "all .32s ease-out;",
              }}
            >
              <Link to="/">
                <img
                  src="/logo-removebg-preview.png"
                  style={{ height: "60px" }}
                  alt="Logo"
                />
              </Link>
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ color: theme.palette.common.white }}
            >
              <LocationOnOutlinedIcon sx={{ fontSize: "30px" }} />
              {facilities.length !== 0 && (
                <Select
                  variant="standard"
                  defaultValue={facilityId}
                  onChange={(e) => navigate("/" + e.target.value)}
                  sx={{
                    maxWidth: 260,
                    fontWeight: 700,
                    letterSpacing: ".1em",
                    color: theme.palette.common.white,
                    "&:before": {
                      borderColor: theme.palette.common.white,
                    },
                    "& .MuiSvgIcon-root": {
                      color: theme.palette.common.white,
                    },
                  }}
                >
                  {facilities.map((facility) => (
                    <MenuItem key={facility.id} value={facility.id}>
                      {facility.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Stack>
          </Stack>
        </Box>
        <CommonHeaderItems />
      </Stack>
    </Box>
  );
};

const FacilityLayout = () => {
  const navigate = useNavigate();
  useSession();

  const params = useParams();
  const facilityId = params.facilityId;
  const session = useRecoilValue(sessionState);
  const [facility, setFacility] = useRecoilState(facilityState);
  const [robot, setRobot] = useState(false);

  const getActiveRobot = React.useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "robots"),
        where("active", "==", true)
      )
    )
      .then((docsnap) => {
        if (docsnap.empty) {
          setRobot(false);
        } else {
          setRobot(true);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [facilityId]);

  React.useEffect(() => {
    if (session) {
      setFacility({});
      getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
        .then((x) => {
          const data = x.data();
          console.log(session);
          if (
            Object.keys(data.admin).indexOf(session.uid) < 0 &&
            !session.admin
          ) {
            navigate("/");
          }
          if (data) {
            setFacility(data);
          } else {
            navigate("/");
          }
        })
        .catch((e) => {
          navigate("/");
          console.error(e);
        });

      getActiveRobot();
    }
  }, [facilityId, session]);

  React.useEffect(() => {
    Sidebar(robot);
  }, [robot]);

  return (
    <>
      <TimeZoneProvider>
        <Helmet>
          <title>
            {(facility?.name ? facility.name : "") + " - SmartBX Connect"}
          </title>
        </Helmet>
        {session ? (
          <Box
            sx={{
              minWidth: "1000px",
            }}
          >
            <Header />
            <Stack direction="row" alignItems="stretch">
              {Sidebar(robot)}
              <Main>
                <Outlet />
              </Main>
            </Stack>
          </Box>
        ) : (
          <></>
        )}
      </TimeZoneProvider>
    </>
  );
};
export default FacilityLayout;
