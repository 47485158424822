import html2canvas from "html2canvas";
import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router";
import jsPDF from "jspdf";
import { Box, Button, CircularProgress } from "@mui/material";
import { useRecoilValue } from "recoil";
import languageState from "../../../../recoil/atoms/languageState";
import BasicLanguage from "../../../languages/Basic";

export default function DashboardDownloadAll() {
  const params = useParams();
  const facilityId = params.facilityId;
  const language = useRecoilValue(languageState);
  const [isLoading, setIsLoading] = useState(false);

  const downloadPdf = async () => {
    setIsLoading(true);
    const dashboardDivs = document.getElementsByClassName(
      "dashboard-download-all"
    );

    const pdf = new jsPDF({
      orientation: window.innerWidth < 1300 ? "p" : "l",
      unit: "px",
      format: "a4",
    });

    for (let i = 0; i < dashboardDivs.length; i++) {
      try {
        const canvas = await html2canvas(dashboardDivs[i], {
          scale: 2,
        });
        if (i !== 0) {
          pdf.addPage();
        }
        const imgData = canvas.toDataURL("image/jpeg");
        const width = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * width) / canvas.width;
        const height =
          imgHeight > pdf.internal.pageSize.getHeight()
            ? pdf.internal.pageSize.getHeight()
            : imgHeight;
        pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      } catch (e) {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      }
    }
    pdf.save(
      `${
        BasicLanguage.common.menu.dashboard[language]
      }_${facilityId}_${moment().format("YYYYMMDD")}.pdf`
    );
    setIsLoading(false);
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "end" }}>
      <Button
        size="large"
        variant="contained"
        onClick={downloadPdf}
        sx={{ borderRadius: "20px", minWidth: "150px" }}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size="1.75rem" sx={{ color: "white" }} />
        ) : (
          BasicLanguage.dashboard.kpi.downloadAll[language]
        )}
      </Button>
    </Box>
  );
}
