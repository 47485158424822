import React, { useCallback, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";
import SBXStyles from "../components/utils/Styles";
import AddRounded from "@mui/icons-material/AddRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import languageState from "../recoil/atoms/languageState";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Typography from "../components/parts-ui/Typography";
import { db } from "../components/utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useRecoilValue } from "recoil";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const Users = () => {
  const params = useParams();
  const facilityId = params.facilityId;
  const language = useRecoilValue(languageState);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const getData = useCallback(() => {
    setLoading(true);
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
      .then((facilityDocSnap) => {
        const _users = []
          .concat(
            Object.keys(facilityDocSnap.data().admin).map((uid) => {
              return { ...facilityDocSnap.data().admin[uid], role: 0 };
            })
          )
          .concat(
            Object.keys(facilityDocSnap.data().users).map((uid) => {
              return { ...facilityDocSnap.data().users[uid], role: 1 };
            })
          )
          .filter((user) => !user.isDeleted);
        setLoading(false);
        setUsers(_users);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  }, [facilityId]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Box component="main" sx={SBXStyles.mainContainer}>
        <Box>
          <Typography className="pageTitle" variant="h4">
            {BasicLanguage.common.menu.user[language]}
            <IconButton LinkComponent={Link} to="./add">
              <AddRounded />
            </IconButton>
          </Typography>
          <Typography sx={{ mt: 2, mb: 2 }}>
            {BasicLanguage.users.description[language]}
          </Typography>
          <Box sx={SBXStyles.mainBox}>
            <Table
              sx={{
                "& .MuiTableRow-head .MuiTableCell-head ": {
                  backgroundColor: "rgba(0,0,0,0.05)",
                  fontWeight: "bold",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    {BasicLanguage.users.displayName[language]}
                  </TableCell>
                  <TableCell>{BasicLanguage.users.status[language]}</TableCell>
                  <TableCell>{BasicLanguage.users.role[language]}</TableCell>
                  <TableCell>{BasicLanguage.users.email[language]}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box
                          sx={{
                            width: "100%",
                            height: 200,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  users.map((users) => (
                    <TableRow key={users.uid}>
                      <TableCell>{users.displayName}</TableCell>
                      <TableCell>
                        {BasicLanguage.users.statusName[users.status]?.[language]}
                      </TableCell>
                      <TableCell>
                        {BasicLanguage.common.role[users.role]?.[language]}
                      </TableCell>
                      <TableCell>{users.email}</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        <IconButton LinkComponent={Link} to={"./" + users.uid}>
                          <SettingsOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {!loading && users.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Box
                        sx={{
                          width: "100%",
                          height: 200,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {BasicLanguage.users.none[language]}
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Users;
