import moment from "moment";
import React from "react";
import BasicLanguage from "../../../languages/Basic";
import { Box } from "@mui/material";
import { SbxStackedBarChart } from "../../SbxChart";
import { useRecoilValue } from "recoil";
import languageState from "../../../../recoil/atoms/languageState";
import PropTypes from "prop-types";

export default function RequestChart({ requestData, date, dateDisplay }) {
  const language = useRecoilValue(languageState);

  if (dateDisplay === "day") {
    requestData = generateDataByDay(requestData, language, date);
  } else if (dateDisplay === "week") {
    requestData = generateDataByWeek(requestData, language, date);
  } else if (dateDisplay === "month") {
    requestData = generateDataByMonth(requestData, language, date);
  }

  return (
    <Box sx={{ width: "100%", height: "300px" }}>
      <SbxStackedBarChart
        data={requestData}
        bottomDataKey={
          BasicLanguage.dashboard.kpi.request.completedRequests[language]
        }
        topDataKey={
          BasicLanguage.dashboard.kpi.request.incompleteRequests[language]
        }
      />
    </Box>
  );
}

RequestChart.propTypes = {
  requestData: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
  dateDisplay: PropTypes.string.isRequired,
};

const generateDataByMonth = (tasks, language, date) => {
  const qrTasks = tasks.reduce(
    (tasks, task) => {
      const completed = !!task.completed;
      if (completed) {
        tasks.month.complete++;
      } else {
        tasks.month.incomplete++;
      }
      return tasks;
    },
    { month: { incomplete: 0, complete: 0 } }
  );

  return Object.keys(qrTasks).map((key) => {
    return {
      name: moment(date).format(
        BasicLanguage.dashboard.kpi.request.month[language]
      ),
      [BasicLanguage.dashboard.kpi.request.completedRequests[language]]:
        qrTasks[key].complete,
      [BasicLanguage.dashboard.kpi.request.incompleteRequests[language]]:
        qrTasks[key].incomplete,
    };
  });
};

const generateDataByWeek = (tasks, language, date) => {
  const defaultWeeks = {};
  const startWeek = moment(date).startOf("month").week();
  const endWeek = moment(date).endOf("month").week();
  for (let i = startWeek; i <= endWeek; i++) {
    defaultWeeks[i] = { complete: 0, incomplete: 0 };
  }
  const qrTasks = tasks.reduce((tasks, task) => {
    const week = moment(task.createdAt).week();
    const completed = !!task.completed;
    if (completed) {
      tasks[week] = {
        ...tasks[week],
        complete: (tasks[week]?.complete || 0) + 1,
      };
    } else {
      tasks[week] = {
        ...tasks[week],
        incomplete: (tasks[week]?.incomplete || 0) + 1,
      };
    }
    return tasks;
  }, defaultWeeks);

  return Object.keys(qrTasks)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        [BasicLanguage.dashboard.kpi.request.completedRequests[language]]:
          qrTasks[key].complete,
        [BasicLanguage.dashboard.kpi.request.incompleteRequests[language]]:
          qrTasks[key].incomplete,
      };
    });
};

const generateDataByDay = (tasks, language, date) => {
  const defaultDays = {};
  for (let i = 1; i <= moment(date).daysInMonth(); i++) {
    defaultDays[i] = { complete: 0, incomplete: 0 };
  }
  const qrTasks = tasks.reduce((tasks, task) => {
    const taskDate = moment(task.createdAt).date();
    const completed = !!task.completed;
    if (completed) {
      tasks[taskDate] = {
        ...tasks[taskDate],
        complete: (tasks[taskDate]?.complete || 0) + 1,
      };
    } else {
      tasks[taskDate] = {
        ...tasks[taskDate],
        incomplete: (tasks[taskDate]?.incomplete || 0) + 1,
      };
    }
    return tasks;
  }, defaultDays);

  return Object.keys(qrTasks)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        [BasicLanguage.dashboard.kpi.request.completedRequests[language]]:
          qrTasks[key].complete,
        [BasicLanguage.dashboard.kpi.request.incompleteRequests[language]]:
          qrTasks[key].incomplete,
      };
    });
};
