import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const fontSizeState = atom({
    key: 'fontSize',
    default: 14,
    effects_UNSTABLE: [persistAtom]
});

export default fontSizeState;