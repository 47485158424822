import React, { useEffect } from 'react'

import { BrowserRouter, Routes, Route, useParams, useHistory, useNavigate, Link } from 'react-router-dom'

import { styled, useTheme } from '@mui/material/styles';
import MuiSelect from '@mui/material/Select'


import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Dialog, DialogTitle, ListItem, ListItemButton, ListSubheader, MenuItem } from '@mui/material';

import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';

import Menu from './Menu';
import Colors from '../utils/Colors'

import { useRecoilState, useRecoilValue } from 'recoil';
import languageState from '../../recoil/atoms/languageState';
import fontSizeState from '../../recoil/atoms/fontSizeState';


import sessionState from '../../recoil/atoms/sessionState';
import { getFacilities, isAdmin } from '../utils/functions';



const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));



const Select = styled(MuiSelect)(({ theme }) => ({
    float: "right",
    ...theme

}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const HeaderSidebar = (props) => {
    const theme = useTheme();
    const params = useParams();
    let navigate = useNavigate();

    const facilityId = params.facilityId;
    const session = useRecoilValue(sessionState);

    const [facilities, setFacilities] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [languageSelectionDialog, setLanguageSelectionDialog] = React.useState(false);

    const [language, setLanguage] = useRecoilState(languageState);
    const [fontSize, setFontSize] = useRecoilState(fontSizeState);


    React.useEffect(() => {


        if (session) {

            getFacilities()
                .then((result) => {
                    console.log(result)
                    setFacilities(result.data.filter(x => x.role === 0))
                }).catch(e => {
                    setFacilities([]);
                    console.error(e)
                });
        } else {
            setFacilities([])
        }

    }, [])

    // React.useEffect(() => {

    //     if (facilities.filter(x => x.id === params.facilityId).length === 0) {
    //         navigate("/")
    //     }
    // }, []);





    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    return <>
        <AppBar position="fixed" open={open} sx={{
            backgroundColor: "rgba(247,254,255,0.8)",
            backdropFilter: "blur(3px)",
            color: Colors.primary,
        }}
            elevation={1}>
            <Toolbar>

                <IconButton
                    color="inherit"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        color: "primary.main",
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Box sx={{ flexGrow: 1 }}>
                    <Link to="/">
                        <img src="/logo-removebg-preview.png" style={{ height: "45px", marginLeft: "-20px" }}></img>
                    </Link>
                </Box>
                <Box>
                    <Select variant="standard" defaultValue={facilityId} onChange={(e) => navigate("/" + e.target.value)}>
                        {facilities.map((x, index) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
                    </Select>
                </Box>
                <IconButton disabled={fontSize === 18} onClick={() => setFontSize(18)}>
                    <FormatColorTextIcon sx={{ fontSize: 18 }} />
                </IconButton>
                <IconButton disabled={fontSize === 14} onClick={() => setFontSize(14)}>
                    <FormatColorTextIcon sx={{ fontSize: 14 }} />
                </IconButton>

                <IconButton onClick={() => setLanguageSelectionDialog(true)}>
                    <LanguageOutlinedIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <Menu open={open} language={language}></Menu>
        </Drawer>
        <Dialog open={languageSelectionDialog} onClose={() => setLanguageSelectionDialog(false)}>
            <DialogTitle>言語/Language</DialogTitle>
            <List>
                <ListItem>
                    <ListItemButton onClick={() => {
                        setLanguage("ja")
                        setLanguageSelectionDialog(false)
                    }}>日本語</ListItemButton>

                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => {
                        setLanguage("en")

                        setLanguageSelectionDialog(false)
                    }}>English</ListItemButton>

                </ListItem>
            </List>
        </Dialog>
    </>
}
export default HeaderSidebar;