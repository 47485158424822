import { Stack } from "@mui/material";
import React from "react";
import DashboardDatePicker from "./DashboardDatePicker";
import { DashboardButtons } from "./DashboardButtons";
import PropTypes from "prop-types";
import DashboardDownload from "./DashboardDownload";

export default function DashboardControls({
  date,
  setDate,
  dateDisplay,
  setDateDisplay,
  elementId,
}) {
  return (
    <Stack direction="row" spacing={1} justifyContent="flex-end">
      <DashboardDatePicker date={date} setDate={setDate} />
      <DashboardButtons
        dateDisplay={dateDisplay}
        setDateDisplay={setDateDisplay}
      />
    </Stack>
  );
}

DashboardControls.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  dateDisplay: PropTypes.string.isRequired,
  setDateDisplay: PropTypes.func.isRequired,
};
