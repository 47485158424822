import * as React from "react";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import SBXStyles from "../components/utils/Styles";
import { useNavigate, useParams } from "react-router";
import CommonError from "./CommonError";
import { Link } from "react-router-dom";

import Typography from "../components/parts-ui/Typography";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import { db } from "../components/utils/firebase";
import languageState from "../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";
import { useState } from "react";
import triggerComparison from "../components/utils/triggerComparison";
import Loading from "../components/parts/Loading";
import QrSensorRobotInfo from "../components/parts/forms/QrSensorRobotInfo";
import QrSensorRobotForm from "../components/parts/forms/QrSensorRobotForm";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function SensorTrigger() {
  const params = useParams();
  const navigate = useNavigate();
  const facilityId = params.facilityId;
  const sensorId = params.sensorId;
  const triggerId = params.triggerId;

  const [isLoading, setIsLoading] = useState(true);
  const [areRobotAreasLoading, setAreRobotAreasLoading] = useState(true);
  const [trigger, setTrigger] = useState({});
  const [users, setUsers] = useState([]);
  const [active, setActive] = useState(false);
  const [threshold, setThreshold] = useState(null);
  const [comparison, setComparison] = useState(null);
  const [address, setAddress] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [cleaningMode, setCleaningMode] = useState("");
  const [robots, setRobots] = useState([]);
  const [robotAreas, setRobotAreas] = useState([]);

  const getRobotAreas = React.useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "robots"),
        where("active", "==", true)
      )
    )
      .then((docsnap) => {
        if (docsnap.empty) {
          return;
        }
        setRobots(
          docsnap.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        );
        let robotFloorAreas = [];
        docsnap.docs.forEach((doc) => {
          const floors = doc.data().floors;
          if (!floors) {
            return;
          }
          const robotId = doc.id;
          const robotName = doc.data().name;
          const floorIds = Object.keys(floors);
          const type = doc.data().type;
          floorIds.forEach((floorId) => {
            const areas = floors[floorId].areas;
            if (!areas) {
              return;
            }
            Object.keys(areas).forEach((areaId) => {
              const areaObject = {
                robotId: robotId,
                robotName: robotName,
                floorId: floorId,
                areaId: areaId,
                areaName: areas[areaId].name,
                type: type,
              };

              robotFloorAreas = [...robotFloorAreas, areaObject];
            });
          });
        });
        setRobotAreas(robotFloorAreas);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(setAreRobotAreasLoading(false));
  }, [facilityId]);

  const language = useRecoilValue(languageState);

  const getData = React.useCallback(async () => {
    const res = await getDoc(
      doc(
        db,
        ENV,
        VERSION,
        "facilities",
        facilityId,
        "sensors",
        sensorId,
        "triggers",
        triggerId
      )
    ).catch((e) => console.error(e));
    const data = res.data();
    return data;
  }, [facilityId, sensorId, triggerId]);

  const getUserData = React.useCallback(async () => {
    const res = await getDoc(
      doc(db, ENV, VERSION, "facilities", facilityId)
    ).catch((e) => console.error(e));
    const data = res.data();
    return data;
  }, [facilityId]);

  const submit = async (e) => {
    e.preventDefault();
    const robotIds = selectedAreas.map((ids) => ids.robotId);
    if (new Set(robotIds).size !== robotIds.length) {
      alert(BasicLanguage.alert.oneRobotOnly[language]);
      return;
    }

    if (robotAreas.length === 0 && cleaningMode !== "") {
      alert(BasicLanguage.alert.cleaningModeNoRobots[language]);
      return;
    }
    try {
      await runTransaction(db, async (transaction) => {
        const active = e.target.active.checked;

        const triggerDocRef = doc(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "sensors",
          sensorId,
          "triggers",
          triggerId
        );

        const triggerDoc = await transaction.get(triggerDocRef);
        triggerDoc.data().robotAreas?.forEach((robotArea) => {
          transaction.update(
            doc(
              db,
              ENV,
              VERSION,
              "facilities",
              facilityId,
              "robots",
              robotArea.robotId
            ),
            {
              [`floors.${robotArea.floorId}.areas.${robotArea.areaId}.triggerIds`]:
                arrayRemove(triggerId),
            },
            {
              merge: true,
            }
          );
        });
        transaction.set(triggerDocRef, {
          active: active,
          statements: {
            type: "condition",
            threshold: Number(threshold),
            comparison: comparison,
          },
          address: address.map((user) => user.uid),
          ...(robotAreas.length !== 0 && {
            cleaningMode: cleaningMode,
            robotAreas: selectedAreas.map((area) => {
              return {
                robotId: area.robotId,
                floorId: area.floorId,
                areaId: area.areaId,
              };
            }),
          }),
        });

        selectedAreas.forEach((area) => {
          transaction.update(
            doc(
              db,
              ENV,
              VERSION,
              "facilities",
              facilityId,
              "robots",
              area.robotId
            ),
            {
              [`floors.${area.floorId}.areas.${area.areaId}.triggerIds`]:
                arrayUnion(triggerId),
            },
            {
              merge: true,
            }
          );
        });
      });
      alert(BasicLanguage.alert.updated[language]);
      navigate(`/${facilityId}/sensors/${sensorId}`);
    } catch (e) {
      alert(BasicLanguage.alert.error.default[language]);
      console.error(e);
    }
  };

  const handleAddressChange = (event) => {
    const {
      target: { value },
    } = event;
    setAddress(typeof value === "string" ? value.split(",") : value);
  };

  const initialize = React.useCallback(async () => {
    const users = await getUserData();
    const trigger = await getData();

    let adminAndUsers = Object.keys(users.users ? users.users : {})
      .map((y) => users.users[y])
      .concat(
        Object.keys(users.admin ? users.admin : {}).map((y) => users.admin[y])
      )
      .filter((user) => !user.isDeleted);

    setUsers(adminAndUsers);

    const address = trigger.address.map(
      (id) => adminAndUsers.filter((user) => user.uid === id)[0]
    );

    const id = trigger.id;
    setTrigger({ ...trigger, id: id });

    setActive(trigger.active);
    setThreshold(trigger.statements.threshold);
    setComparison(trigger.statements.comparison);
    setAddress(address.filter((x) => x));
    setInterval(trigger.interval ? trigger.interval : 0);
    setCleaningMode(trigger.cleaningMode || "");
    if (trigger.robotAreas && robotAreas.length !== 0)
      setSelectedAreas(
        trigger.robotAreas.map((area) => {
          return robotAreas.find((robotArea) => {
            return (
              area.robotId === robotArea.robotId &&
              area.floorId === robotArea.floorId &&
              area.areaId === robotArea.areaId
            );
          });
        })
      );
    setIsLoading(false);
  }, [getData, getUserData, robotAreas]);

  const handleCleaningModeChange = (event) => {
    setCleaningMode(event.target.value);
  };

  const handleRobotAreaChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAreas(typeof value === "string" ? value.split(",") : value);
  };

  React.useEffect(() => {
    if (!areRobotAreasLoading) {
      initialize();
    }
  }, [areRobotAreasLoading, initialize, robotAreas]);

  React.useEffect(() => {
    getRobotAreas();
  }, [getRobotAreas]);

  const robotGroups = () => {
    return selectedAreas.reduce((area, nextArea) => {
      (area[nextArea.robotId] = area[nextArea.robotId] || []).push(nextArea);
      return area;
    }, {});
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Box component="main" sx={SBXStyles.mainContainer}>
      {trigger ? (
        <Box>
          <Typography className="pageTitle" variant="h4">
            <IconButton
              LinkComponent={Link}
              to={"/" + params.facilityId + "/sensors/" + sensorId}
            >
              <ArrowBackIcon />
            </IconButton>
          </Typography>

          <Box sx={SBXStyles.mainBox}>
            <Typography variant="h5">
              {BasicLanguage.sensor.triggers.triggers[language]}
            </Typography>

            <form onSubmit={submit}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "30%" }}></TableCell>
                    <TableCell sx={{ width: "70%" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.common.active[language]}
                    </TableCell>
                    <TableCell>
                      <Switch
                        name="active"
                        checked={active}
                        onChange={(_, c) => setActive(c)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.sensor.trigger.threshold[language]}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <TextField
                          name="threshold"
                          value={threshold}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              setThreshold("");
                            } else if (e.target.value === 0) {
                              setThreshold(0);
                            } else if (Number(e.target.value)) {
                              setThreshold(e.target.value);
                            }
                          }}
                        />

                        <Select
                          name="comparison"
                          value={comparison}
                          onChange={(e) => setComparison(e.target.value)}
                        >
                          {Object.keys(triggerComparison).map((k) => (
                            <MenuItem value={k} key={k}>
                              {triggerComparison[k][language]}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {BasicLanguage.sensor.trigger.assignee[language]}
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth error={address.length === 0}>
                        <Select
                          name="address"
                          value={address}
                          multiple
                          onChange={handleAddressChange}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value.uid}
                                  label={value.displayName}
                                />
                              ))}
                            </Box>
                          )}
                        >
                          {users.map((user) => (
                            <MenuItem key={user.uid} value={user}>
                              {user.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                        {address.length === 0 && (
                          <FormHelperText>
                            {
                              BasicLanguage.sensor.trigger.errors.noAssignee[
                                language
                              ]
                            }
                          </FormHelperText>
                        )}
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  {robots.length !== 0 && (
                    <QrSensorRobotForm
                      robotAreas={robotAreas}
                      selectedAreas={selectedAreas}
                      cleaningMode={cleaningMode}
                      handleCleaningModeChange={handleCleaningModeChange}
                      handleRobotAreaChange={handleRobotAreaChange}
                      robots={robots}
                    />
                  )}
                </TableBody>
              </Table>
              {selectedAreas.length !== 0 && (
                <QrSensorRobotInfo
                  robots={robots}
                  robotGroups={robotGroups()}
                  robotAreas={selectedAreas}
                />
              )}
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 2 }}
                disabled={!threshold || address.length === 0 || !comparison}
                type="submit"
              >
                {BasicLanguage.common.update[language]}
              </Button>
            </form>

            <Box sx={{ m: 4 }}>
              <Typography>
                {BasicLanguage.sensor.process[language]}
                <br />
                {BasicLanguage.sensor.increase[language]}：
                {BasicLanguage.sensor.increaseExplanation[language]}
                <br />
                {BasicLanguage.sensor.decrease[language]}：
                {BasicLanguage.sensor.decreaseExplanation[language]}
                <br />
                {BasicLanguage.sensor.maintain[language]}：
                {BasicLanguage.sensor.maintainExplanation[language]}
                <br />
                <br />
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <CommonError></CommonError>
      )}
    </Box>
  );
}
