import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import SBXStyles from "../utils/Styles";
import BasicLanguage from "../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../recoil/atoms/languageState";
import JSZip from "jszip";
import { MuiFileInput } from "mui-file-input";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router";
import { processCustomerSatisfactionFiles } from "../utils/customerSatisfaction";
import { db } from "../utils/firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import moment, { lang } from "moment";

const ALLOWED_FILE_TYPES = ["application/zip", "application/x-zip-compressed"];
const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

export default function CustomerSatisfactionCsv() {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;

  const [zipFile, setZipFile] = useState(null);
  const [zipFileContents, setZipFileContents] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);

  const handleClick = async () => {
    setIsUploading(true);
    processCustomerSatisfactionFiles(zipFileContents, facilityId)
      .then((failedFiles) => {
        setFileErrors(failedFiles);
        alert(
          BasicLanguage.facilitySettings.customerSatisfactionCsvUpload.alert
            .uploadSuccess[language]
        );
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      })
      .finally(() => {
        setZipFile(null);
        setZipFileContents([]);
        setIsUploading(false);
      });
  };

  const handleChange = (file) => {
    setFileErrors([]);
    setZipFileContents([]);

    if (!file) {
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      alert(
        BasicLanguage.facilitySettings.customerSatisfactionCsvUpload.alert
          .fileType[language]
      );
      setZipFile(null);
      return;
    }

    setZipFile(file);
    getZipContents(file);
  };

  const clearFile = () => {
    setZipFile(null);
    setZipFileContents([]);
  };

  const getZipContents = (zipFile) => {
    const decoder = new TextDecoder("Shift_JIS");
    const zip = new JSZip();
    zip
      .loadAsync(zipFile, {
        decodeFileName: function (bytes) {
          return decoder.decode(bytes);
        },
      })
      .then((zip) => {
        zip.forEach((_, file) =>
          file
            .async("blob")
            .then((content) => {
              const fileName = file.name.split("/").pop();
              const nameSplit = fileName.split(".");
              const fileExt = nameSplit[nameSplit.length - 1];
              if (fileExt === "csv" && !fileName.startsWith("._")) {
                setZipFileContents((prev) => [
                  ...prev,
                  { content: content, name: fileName },
                ]);
              }
            })
            .catch((e) => {
              console.error(e);
            })
        );
      });
  };

  const getHistory = useCallback(async () => {
    try {
      const q = query(
        collection(db, ENV, VERSION, "facilities", facilityId, "preEvaluation"),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      let data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setHistory(data);
    } catch (error) {
      console.error(BasicLanguage.alert.error[language], error);
    } finally {
      setLoading(false);
    }
  }, [facilityId, language]);

  useEffect(() => {
    getHistory();
  }, [language, facilityId]);

  return (
    <Box sx={SBXStyles.mainBox}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {
          BasicLanguage.facilitySettings.customerSatisfactionCsvUpload.title[
            language
          ]
        }
      </Typography>
      <Box sx={{ width: "fit-content", maxWidth: "50%" }}>
        <MuiFileInput
          value={zipFile}
          inputProps={{ accept: ".zip" }}
          onChange={handleChange}
          placeholder={
            BasicLanguage.facilitySettings.customerSatisfactionCsvUpload.select[
              language
            ]
          }
          hideSizeText
          clearIconButtonProps={{
            onClick: clearFile,
            children: <ClearIcon />,
          }}
        />
        <Box sx={{ ml: 2 }}>
          {zipFileContents.map((file) => (
            <p key={file.name}>{file.name}</p>
          ))}
        </Box>
        {zipFile && zipFileContents.length !== 0 && (
          <Button
            variant="outlined"
            fullWidth
            onClick={handleClick}
            disabled={isUploading}
          >
            {isUploading ? (
              <CircularProgress />
            ) : (
              BasicLanguage.facilitySettings.customerSatisfactionCsvUpload
                .upload[language]
            )}
          </Button>
        )}
        {fileErrors.length > 0 && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <AlertTitle>
              {
                BasicLanguage.facilitySettings.customerSatisfactionCsvUpload
                  .alert.uploadError[language]
              }
            </AlertTitle>
            <ul>
              {fileErrors.map((error) => (
                <li key={error.fileName}>{error.fileName}</li>
              ))}
            </ul>
          </Alert>
        )}
      </Box>

      {history.length > 0 && (
        <TableContainer
          sx={{
            mt: 2,
            maxHeight: "400px",
            "& .MuiTableCell-head": {
              backgroundColor: "rgba(220,220,220,1)",
              fontWeight: "bold",
            },
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>NPS</TableCell>
                <TableCell>{BasicLanguage.common.dateTime[language]}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{data.nps}</TableCell>
                  <TableCell>
                    {moment(data.createdAt.seconds * 1000)
                      .local(language)
                      .format(
                        BasicLanguage.common.date.datePicker.long[language]
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
