import React from "react";
import { Box } from "@mui/material";
import Colors from "../components/utils/Colors";
import BasicLanguage from "../components/languages/Basic";
import languageState from "../recoil/atoms/languageState";
import Typography from "../components/parts-ui/Typography";
import { requestGetMessage } from "../components/utils/functions";
import RequestLayout from "../components/parts/request/RequestLayout";
import { useLoaderData } from "react-router";
import { useRecoilValue } from "recoil";

const RequestPageThanks = () => {
  const { logo, message, facility, place, qrRequestColor } = useLoaderData();
  const language = useRecoilValue(languageState);

  return (
    <RequestLayout
      logo={logo}
      facility={facility}
      place={place}
      qrRequestColor={qrRequestColor}
    >
      <Box sx={{ backgroundColor: Colors.white, p: 3 }}>
        <Typography sx={{ textAlign: "center" }}>
          {message && message.length > 0
            ? message
            : BasicLanguage.request.thanks[language]}
        </Typography>
      </Box>
    </RequestLayout>
  );
};

export const loadRequestThanks = async ({ params }) => {
  const { qrId } = params;

  return await requestGetMessage({ id: qrId }).then((result) => {
    const { status, content } = result.data;
    if (status === "success") {
      return content;
    }
    throw new Error("Data not found");
  });
};

export default RequestPageThanks;
