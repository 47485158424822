import { Outlet, useNavigate } from "react-router"
import HeaderSidebar from "../parts/HeaderSidebar"
import { Box } from "@mui/material";
import { useEffect } from "react";
import { isAdmin } from "../utils/functions";
import { auth } from "../utils/firebase";
import { useSession } from "../utils/session";


const AdminLayout = (props) => {
    const navigate = useNavigate();
    useSession();


    useEffect(() => {
        isAdmin()
            .then((result) => {
                if (result.data.admin) {

                } else {
                    navigate('/')
                }
            }).catch(e => {
                console.error(e)
            });
    }, []);
    return <>

        <Box>
            <Outlet />
        </Box>
    </>
}
export default AdminLayout;