import { useCallback, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import BasicLanguage from "../components/languages/Basic";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import SBXStyles from "../components/utils/Styles";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import { AddRounded } from "@mui/icons-material";

import Typography from "../components/parts-ui/Typography";
import Loading from "../components/parts/Loading";
import { collection, getDocs, orderBy } from "firebase/firestore";
import { db } from "../components/utils/firebase";
import AddIcon from '@mui/icons-material/Add';

const ENV = process.env.REACT_APP_FIRESTORE_ENV
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION

export default function Atp() {
  const params = useParams();
  const facilityId = params.facilityId;
  const language = useRecoilValue(languageState);
  const [atps, setAtp] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAtps = useCallback(() => {
    setIsLoading(true);
    getDocs(collection(db, ENV, VERSION, "facilities", facilityId, "atps"))
      .then((querySnap) => {
        const collator = new Intl.Collator([], { numeric: true });
        setAtp(querySnap.docs
          .sort((a, b) => collator.compare(a.data().name, b.data().name))
          .map((doc) => ({ ...doc.data(), id: doc.id })));
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [facilityId]);

  useEffect(() => {
    getAtps();
  }, [getAtps]);

  const table =
    atps.length === 0 ? (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography variant="h5">
          {BasicLanguage.atp.table.empty[language]}
        </Typography>
      </Box>
    ) : (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="20%">
              {BasicLanguage.atp.table.name[language]}
            </TableCell>
            <TableCell width="20%">
              {BasicLanguage.atp.table.testLocation[language]}
            </TableCell>
            <TableCell>{BasicLanguage.atp.table.value[language]}</TableCell>
            <TableCell width="5%" />
            <TableCell width="5%" />
          </TableRow>
        </TableHead>
        <TableBody>
          {atps.map((atp) => (
            <TableRow key={atp.id}>
              <TableCell>{atp.name}</TableCell>
              <TableCell>{atp.testLocation}</TableCell>

              <TableCell>{atp.value}</TableCell>
              <TableCell sx={{ textAlign: "right" }}>
                <IconButton
                  sx={{ mr: 2 }}
                  LinkComponent={Link}
                  to={`./${atp.id}/create`}
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
              <TableCell sx={{ textAlign: "right" }}>
                <IconButton
                  sx={{ mr: 2 }}
                  LinkComponent={Link}
                  to={"./" + atp.id}
                >
                  <SettingsOutlinedIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );

  return (
    <>
      <Box component="main" sx={SBXStyles.mainContainer}>
        <Typography className="pageTitle" variant="h4">
          {BasicLanguage.common.menu.atp[language]}
          <IconButton LinkComponent={Link} to="./add">
            <AddRounded />
          </IconButton>
        </Typography>
        <Typography sx={{ mt: 2, mb: 2 }}>
          {BasicLanguage.atp.description[language]}
        </Typography>
        <Box sx={SBXStyles.mainBox}>{isLoading ? <Loading /> : table}</Box>
      </Box>
    </>
  );
}
