import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import BasicLanguage from "../languages/Basic";
import languageState from "../../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";

export const DialogYesNo = (props) => {
  const language = useRecoilValue(languageState);
  const [open, setOpen] = props.open;
  const yesAction = props.yesAction;
  const noAction = props.noAction;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
        {props.warningMessage && (
          <DialogContentText color={"red"}>
            {props.warningMessage}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={noAction} color="error" variant="outlined">
          {BasicLanguage.common.modal.no[language]}
        </Button>
        <Button
          onClick={yesAction}
          autoFocus
          color="primary"
          variant="contained"
        >
          {BasicLanguage.common.modal.yes[language]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
