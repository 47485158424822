import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import { useState } from "react";
import BasicLanguage from "../../languages/Basic";
import { adminRobotsDelete, adminRobotsUpdate } from "../../utils/functions";
import {
  Button,
  Chip,
  Stack,
  Switch,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { DialogYesNo } from "../Dialog";

export const Robot = (props) => {
  const language = useRecoilValue(languageState);

  const robot = props.robot;
  const getRobots = props.getRobots;

  const [active, setActive] = useState(robot.active);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(robot.name);
  const [facility, setFacility] = useState(robot.facility);
  const [facilityDeleteFlag, setFacilityDeleteFlag] = useState(false);
  const [siteId, setSiteId] = useState(robot.siteId);
  const [siteName, setSiteName] = useState(robot.siteName);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFacility = () => {
    const confirm = window.confirm(
      BasicLanguage.admin.robots.deleteFacility[language]
    );
    if (confirm) {
      setFacility("");
      setFacilityDeleteFlag(true);
    }
  };
  const submit = () => {
    const robotData = {
      id: robot.id,
      name: name,
      type: robot.type,
      facility: facility,
      active: active,
      siteName: siteName,
      siteId: siteId,
    };
    adminRobotsUpdate(robotData).then((result) => {
      if (result.data?.status === "success") {
        getRobots();
        setEditMode(false);
      } else {
        getRobots();
      }
    });
  };

  const cancel = () => {
    setName(robot.name);
    setActive(robot.active);
    setFacility(robot.facility);
    setSiteId(robot.siteId);
    setSiteName(robot.siteName);
    setEditMode(false);
    setFacilityDeleteFlag(false);
  };

  const editRobotFacility = robot.facility ? (
    !facilityDeleteFlag && <Chip label={facility} onDelete={deleteFacility} />
  ) : (
    <TextField
      value={facility}
      onChange={(e) => setFacility(e.target.value)}
    ></TextField>
  );

  const showRobotFacility = facility ? (
    <Link to={"/" + facility + "/robots"} target="_blank">
      {facility}
    </Link>
  ) : (
    ""
  );

  const deleteRobot = () => {
    adminRobotsDelete({ robotId: robot.id })
      .then((result) => {
        if (result.data?.status === "success") {
          setEditMode(false);
          getRobots();
        } else {
          throw new Error(result.data?.status);
        }
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      })
      .finally(() => setDeleteModalOpen(false));
  };

  return (
    <>
      <TableRow key={robot.id}>
        <TableCell>{robot.id}</TableCell>
        <TableCell>
          {editMode ? (
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></TextField>
          ) : (
            name
          )}
        </TableCell>
        <TableCell>{robot.type}</TableCell>
        <TableCell>
          {editMode ? editRobotFacility : showRobotFacility}
        </TableCell>
        <TableCell>
          {editMode ? (
            <TextField
              value={siteName}
              onChange={(e) => setSiteName(e.target.value)}
            ></TextField>
          ) : (
            siteName
          )}
        </TableCell>
        <TableCell>
          {editMode ? (
            <TextField
              value={siteId}
              onChange={(e) => setSiteId(e.target.value)}
            ></TextField>
          ) : (
            siteId
          )}
        </TableCell>
        <TableCell>
          <Switch
            checked={active}
            disabled={!editMode}
            onChange={() => setActive((prev) => !prev)}
          />
        </TableCell>
        <TableCell>
          {editMode ? (
            <Stack spacing={1}>
              <Button onClick={submit} variant="outlined" color="success">
                {BasicLanguage.admin.common.save[language]}
              </Button>
              <Button
                onClick={() => setDeleteModalOpen(true)}
                variant="outlined"
                color="error"
              >
                {BasicLanguage.admin.common.delete[language]}
              </Button>
              <Button onClick={cancel} variant="outlined" color="secondary">
                {BasicLanguage.admin.common.cancel[language]}
              </Button>
            </Stack>
          ) : (
            <Button
              onClick={() => setEditMode((prev) => !prev)}
              variant="outlined"
              fullWidth
            >
              {BasicLanguage.admin.common.edit[language]}
            </Button>
          )}
        </TableCell>
      </TableRow>
      <DialogYesNo
        open={[deleteModalOpen, setDeleteModalOpen]}
        yesAction={deleteRobot}
        noAction={() => setDeleteModalOpen(false)}
        title={robot.id}
        message={BasicLanguage.admin.robots.deleteMessage[language]}
      />
    </>
  );
};

Robot.propTypes = {
  robot: PropTypes.object,
  getRobots: PropTypes.func,
};
