import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import { useCallback, useEffect, useState } from "react";
import { adminSensorsAdd, getSensorsList } from "../../utils/functions";
import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  ListSubheader,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import BasicLanguage from "../../languages/Basic";
import { Sensor } from "./Sensor";
import Pager from "../shared/Pager";

export const TabSensors = () => {
  const language = useRecoilValue(languageState);

  const [sensors, setSensors] = useState([]);
  const [sensorsLoading, setSensorsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [sensorType, setSensorType] = useState();
  const [sensorId, setSensorId] = useState();
  const [sensorName, setSensorName] = useState();
  const [page, setPage] = useState(1);
  const [pagerSensors, setPagerSensors] = useState([]);

  const submit = (e) => {
    e.preventDefault();
    const sensorData = { id: sensorId, name: sensorName, type: sensorType };

    adminSensorsAdd(sensorData).then((result) => {
      if (result.data?.status === "success") {
        getSensors();
        handleModalClose();
      } else {
        getSensors();
      }
    });
  };
  const onChangeSensorId = (e) => {
    setSensorId(e.target.value);
  };
  const onChangeSensorName = (e) => {
    const value = e.target.value.slice(0, 30);
    setSensorName(value);
  };
  const handleModalClose = () => {
    setSensorId("");
    setSensorName("");
    setModal(false);
  };

  const getSensors = useCallback(() => {
    setSensorsLoading(true);
    setSensors([]);
    getSensorsList()
      .then((res) => {
        setSensors(res?.data?.sensors ? res.data.sensors : []);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(setSensorsLoading(false));
  }, []);

  useEffect(() => {
    getSensors();
  }, [getSensors]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" className="subTitle">
          {BasicLanguage.admin.sensors.title[language]}
        </Typography>
        <Button onClick={() => setModal(true)}>
          {BasicLanguage.admin.common.add[language]}
        </Button>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "20%" }}>ID</TableCell>
            <TableCell sx={{ width: "25%" }}>
              {BasicLanguage.admin.common.name[language]}
            </TableCell>
            <TableCell sx={{ width: "20%" }}>
              {BasicLanguage.admin.sensors.facility[language]}
            </TableCell>
            <TableCell sx={{ width: "10%" }}>
              {BasicLanguage.admin.sensors.type[language]}
            </TableCell>
            <TableCell sx={{ width: "10%" }}>
              {BasicLanguage.admin.sensors.active[language]}
            </TableCell>
            <TableCell sx={{ width: "20%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sensorsLoading ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            pagerSensors.map((sensor) => (
              <Sensor key={sensor.id} sensor={sensor} getSensors={getSensors} />
            ))
          )}
        </TableBody>
      </Table>
      <Pager
        page={page}
        setPage={setPage}
        items={sensors}
        setPagerItems={setPagerSensors}
      />
      <Modal
        open={modal}
        onClose={handleModalClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            width: "60%",
            maxWidth: "600px",
            minWidth: "400px",
            height: "60%",
            maxHeight: "600px",
            minHeight: "400px",
            overflow: "auto",
            p: 2,
          }}
        >
          <Typography>{BasicLanguage.admin.common.add[language]}</Typography>
          <form onSubmit={submit}>
            <Stack spacing={1}>
              <FormLabel>
                <Typography>
                  {BasicLanguage.admin.sensors.id[language]}
                </Typography>
                <Typography variant="body2"></Typography>
                <TextField
                  fullWidth
                  value={sensorId}
                  onChange={onChangeSensorId}
                  maxLength={20}
                  name="name"
                />
              </FormLabel>
              <FormLabel>
                <Typography>
                  {BasicLanguage.admin.common.name[language]}
                </Typography>
                <Typography variant="body2">
                  {BasicLanguage.admin.sensors.nameUse[language]}
                </Typography>
                <TextField
                  fullWidth
                  value={sensorName}
                  onChange={onChangeSensorName}
                  maxLength={20}
                  name="name"
                />
              </FormLabel>
              <FormLabel>
                <Typography>
                  {BasicLanguage.admin.sensors.type[language]}
                </Typography>
                <Select
                  fullWidth
                  value={sensorType}
                  onChange={(e) => setSensorType(e.target.value)}
                >
                  <ListSubheader>Una</ListSubheader>
                  <MenuItem value="una_motion">Motion</MenuItem>
                  <MenuItem value="una_tof">ToF</MenuItem>
                  <MenuItem value="una_px25a">PX25A</MenuItem>
                  <MenuItem value="una_pb25">PB25</MenuItem>
                  <ListSubheader>Webiot</ListSubheader>
                  <MenuItem value="webiot_tof">Webiot ToF</MenuItem>
                  <MenuItem value="webiot_contact">
                    Webiot {BasicLanguage.admin.sensors.motion[language]}
                  </MenuItem>
                </Select>
              </FormLabel>
              <Button
                type="submit"
                variant="contained"
                disabled={!sensorId || !sensorName || !sensorType}
              >
                {BasicLanguage.admin.common.send[language]}
              </Button>
            </Stack>
          </form>
        </Paper>
      </Modal>
    </>
  );
};
