import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import { adminFacilityAdd, getFacilityList } from "../../utils/functions";
import {
  Button,
  FormLabel,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import BasicLanguage from "../../languages/Basic";
import { Facilities } from "./Facilities";
import { useState, useCallback } from "react";

export const TabFacilities = () => {
  const language = useRecoilValue(languageState);

  const [modal, setModal] = useState(false);
  const [facilityName, setFacilityName] = useState("");
  const [facilityId, setFacilityId] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [validName, setValidName] = useState(false);
  const [validId, setValidId] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorId, setErrorId] = useState(false);
  const [loading, setLoading] = useState(true);


  const submit = (e) => {
    e.preventDefault();
    const id = e.target.id.value;
    const name = e.target.name.value;
    const facilityData = { id: id, name: name };

    adminFacilityAdd(facilityData).then((result) => {
      if (result.data?.status === "success") {
        getFacilities();
        handleModalClose();
      } else {
        setStatusMessage(result.data?.reason);
      }
    });
  };

  const onChangeFacilityName = (e) => {
    const value = e.target.value.slice(0, 30);

    if (value.length <= 30) {
      setErrorName(false);
      setValidName(true);
    } else {
      setValidName(true);
      setErrorName(false);
    }
    setFacilityName(value);
  };

  const onChangeFacilityId = (e) => {
    const value = e.target.value.slice(0, 20);

    if (value.match(/^[a-z\d\-_]{6,20}$/g)) {
      setErrorId(false);
      setValidId(true);
    } else {
      setErrorId(true);
      setValidId(false);
    }

    setFacilityId(value);
  };

  const handleModalClose = () => {
    setFacilityName("");
    setFacilityId("");
    setModal(false);
  };

  const getFacilities = useCallback(() => {
    setLoading(true);
    getFacilityList()
      .then((res) => {
        setFacilities(res?.data?.facilities ? res.data.facilities : []);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(setLoading(false));
  }, []);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" className="subTitle">
          {BasicLanguage.admin.facilities.title[language]}
        </Typography>
        <Button onClick={() => setModal(true)}>
          {BasicLanguage.admin.common.add[language]}
        </Button>
      </Stack>
      <Facilities
        getFacilities={getFacilities}
        isLoading={loading}
        facilities={facilities}
      />
      <Modal
        open={modal}
        onClose={handleModalClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            width: "60%",
            maxWidth: "600px",
            minWidth: "400px",
            height: "60%",
            maxHeight: "600px",
            minHeight: "400px",
            overflow: "auto",
            p: 2,
          }}
        >
          <Typography>{BasicLanguage.admin.common.add[language]}</Typography>
          <form onSubmit={submit}>
            <Stack spacing={1}>
              <FormLabel>
                <Typography>
                  {BasicLanguage.admin.facilities.facilityName[language]}
                </Typography>
                <TextField
                  error={errorName}
                  fullWidth
                  value={facilityName}
                  onChange={onChangeFacilityName}
                  maxLength={20}
                  name="name"
                />
              </FormLabel>
              <FormLabel>
                <Typography>ID</Typography>
                <TextField
                  error={errorId}
                  fullWidth
                  value={facilityId}
                  onChange={onChangeFacilityId}
                  name="id"
                />
              </FormLabel>
              <Button
                type="submit"
                variant="contained"
                disabled={!validName || !validId}
              >
                {BasicLanguage.admin.common.complete[language]}
              </Button>
            </Stack>
          </form>
          <Typography>{statusMessage}</Typography>
        </Paper>
      </Modal>
    </>
  );
};
