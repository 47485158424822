import * as React from "react";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SBXStyles from "../components/utils/Styles";
import BasicLanguage from "../components/languages/Basic";
import Chart from "../components/parts/Chart";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import { useParams } from "react-router";
import Typography from "../components/parts-ui/Typography";
import { db } from "../components/utils/firebase";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";
import moment from "moment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MapsDisplay from "../components/parts/MapsDisplay";
import PropTypes from "prop-types";
import FacilityCleanlinessChart from "../components/parts/dashboard/FacilityCleanlinessChart";
import OperationKpi from "../components/parts/dashboard/OperationKpi";
import PeopleCount from "../components/parts/dashboard/PeopleCount";
import NpsChart from "../components/parts/dashboard/NpsChart";
import Co2Chart from "../components/parts/dashboard/Co2Chart";
import { TimeZoneContext } from "../components/utils/setTimeZoneContext";
import DashboardDownloadAll from "../components/parts/dashboard/shared/DashboardDownloadAll";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const Notify = ({ notification = {} }) => {
  const params = useParams();
  const facilityId = params.facilityId;
  const type = notification?.type || "";
  const place = notification?.task?.place || "";
  const request = notification?.task?.request || "";
  const createdAt = notification?.createdAt?.seconds || 0;
  const comparison = notification?.task?.comparison || "";
  const threshold = notification?.task?.threshold || "";
  const completed = !!notification?.task?.completed;
  const robotAreas = notification?.task?.robotAreas;
  const language = useRecoilValue(languageState);
  const { currentTimeZoneName } = React.useContext(TimeZoneContext);
  let value = request;
  if (type === "SENSOR" && threshold !== "" && comparison !== "") {
    value = `${request} (${BasicLanguage.sensor.terms.title[language]}: ${threshold} ${BasicLanguage.sensor.terms[comparison][language]} )`;
  }

  return (
    <TableRow>
      <TableCell>{type}</TableCell>
      <TableCell>{place}</TableCell>
      <TableCell>{value}</TableCell>
      <TableCell>
        {currentTimeZoneName
          ? moment(createdAt * 1000)
              .tz(currentTimeZoneName)
              .format("YYYY/MM/DD HH:mm:ss")
          : ""}
      </TableCell>
      {completed === false && type === "QR" && robotAreas !== undefined ? (
        <TableCell>
          <a
            href={`/c/${facilityId}/robots/start/${notification.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {BasicLanguage.dashboard.robot[language]}
          </a>
        </TableCell>
      ) : (
        <TableCell></TableCell>
      )}

      <TableCell>
        <IconButton
          LinkComponent={Link}
          href={`/c/${facilityId}/${notification.id}`}
          target="_blank"
        >
          <OpenInNewIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

Notify.propTypes = {
  notification: PropTypes.object,
};

const NotifyTable = (props) => {
  const params = useParams();
  const facilityId = params.facilityId;
  const [notify, setNotify] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const completed = !!props.completed;
  const language = useRecoilValue(languageState);

  const targetDate = React.useMemo(() => {
    const date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }, []);

  const getData = React.useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "notify"),
        orderBy("createdAt", "desc"),
        limit(100),
        where("task.completed", "==", completed),
        where("createdAt", "<=", targetDate)
      )
    )
      .then((querysnapshot) => {
        setLoading(false);
        setNotify(
          querysnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
        );
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  }, [completed, facilityId, targetDate]);

  React.useEffect(() => {
    setLoading(true);
    getData();
  }, [getData]);

  return (
    <TableContainer
      sx={{
        mt: 2,
        maxHeight: "400px",
        "& .MuiTableCell-head": {
          backgroundColor: "rgba(220,220,220,1)",
          fontWeight: "bold",
        },
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>
              {BasicLanguage.dashboard.type[language]}
            </TableCell>
            <TableCell sx={{ width: "25%" }}>
              {BasicLanguage.dashboard.place[language]}
            </TableCell>
            <TableCell sx={{ width: "25%" }}>
              {BasicLanguage.dashboard.content[language]}
            </TableCell>
            <TableCell sx={{ width: "25%" }}>
              {BasicLanguage.dashboard.dateCreated[language]}
            </TableCell>
            <TableCell sx={{ width: "15%" }}></TableCell>
            <TableCell sx={{}}>
              {BasicLanguage.dashboard.detail[language]}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={4}>
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            notify.map((notification) => (
              <Notify notification={notification} key={notification.id} />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

NotifyTable.propTypes = {
  completed: PropTypes.bool,
};

const NotifyCount = (props) => {
  const params = useParams();
  const facilityId = params.facilityId;
  const completed = !!props.completed;
  const language = useRecoilValue(languageState);
  const [count, setCount] = React.useState(0);
  const targetDate = new Date();
  targetDate.setHours(23);
  targetDate.setMinutes(59);
  targetDate.setSeconds(59);

  getCountFromServer(
    query(
      collection(db, ENV, VERSION, "facilities", facilityId, "notify"),
      where("task.completed", "==", completed),
      where("createdAt", "<=", targetDate)
    )
  ).then((querysnapshot) => {
    if (querysnapshot.data().count > 100) {
      setCount("100+");
    } else {
      setCount(querysnapshot.data().count);
    }
  });

  return (
    <>
      {BasicLanguage.dashboard.display[language]} {count}{" "}
      {BasicLanguage.dashboard.item[language]}
    </>
  );
};

NotifyCount.propTypes = {
  completed: PropTypes.bool,
};

const LoadingBox = () => {
  return (
    <Box
      display={"flex"}
      margin={"auto"}
      width={"100%"}
      justifyContent={"center"}
      height={"100%"}
      marginTop={"40%"}
    >
      <CircularProgress size={"100px"} />
    </Box>
  );
};

export default function Dashboard() {
  const params = useParams();
  const facilityId = params.facilityId;
  const language = useRecoilValue(languageState);
  const [kpiActive, setKpiActive] = React.useState(false);
  const [kpiData, setKpiData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getKpi = React.useCallback(() => {
    setKpiData([]);
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "kpis"),
        limit(1)
      )
    )
      .then((querysnapshot) => {
        const data = querysnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
        setKpiData(data[0]);
        if (!querysnapshot.empty) {
          setKpiActive(!!data[0].active);
        }
        setLoading(false);
      })

      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  }, [facilityId]);

  React.useEffect(() => {
    getKpi();
  }, [facilityId, getKpi]);

  // 下記は dashboard_sample から転機（必要に応じてコンポーネントに移動）
  const mode = false;
  const re = 0;
  const thickness = 20;
  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <Typography className="pageTitle" variant="h4">
            {BasicLanguage.dashboard.dashboard[language]}
          </Typography>

          {kpiActive ? (
             <>
            <DashboardPattern
              mode={mode}
              thickness={thickness}
              re={re}
              kpiData={kpiData}
            />
            <Box sx={SBXStyles.mainBox}>
              <Divider />
            </Box>
            </>
          ) : (
            <>
              <Box sx={SBXStyles.mainBox}>
                <Stack spacing={3}>
                  <Box sx={{ height: 350 }}>
                    <Typography variant="h5">
                      {BasicLanguage.dashboard.sensor[language]}
                    </Typography>
                    <Chart language={language} />
                  </Box>
                </Stack>
              </Box>
            </>
          )}
          <Box sx={SBXStyles.mainBox}>
            <MapsDisplay />
          </Box>
          <Box sx={SBXStyles.mainBox}>
            <Stack spacing={3}>
              <Box>
                <Typography variant="h5">
                  {BasicLanguage.dashboard.incomplete[language]}{" "}
                  <NotifyCount completed={false} />
                </Typography>

                <NotifyTable completed={false} />
              </Box>
            </Stack>
          </Box>
          <Box sx={SBXStyles.mainBox}>
            <Stack spacing={3}>
              <Box>
                <Typography variant="h5">
                  {BasicLanguage.dashboard.complete[language]}
                  <NotifyCount completed={true} />
                </Typography>
                <NotifyTable completed={true} />
              </Box>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
}

// 下記は dashboard_sample から転機（必要に応じてコンポーネントに移動）
const DashboardPattern = ({ mode, re, kpiData, thickness = 50 }) => {
  const carbonFootprint = !!kpiData?.carbonFootprint;
  const cleaningRequestResponse = !!kpiData?.cleaningRequestResponse;
  const customerSatisfaction = !!kpiData?.customerSatisfaction;
  const facilityCleanliness = !!kpiData?.facilityCleanliness;
  const janitorCoverage = !!kpiData?.janitorCoverage;
  const onDemandCleaningResponse = !!kpiData?.onDemandCleaningResponse;
  const peopleCount = !!kpiData?.peopleCount;
  const robotFloorCoverage = !!kpiData?.robotFloorCoverage;

  const [nps, setNps] = React.useState(0);
  const [pastNps, setPastNps] = React.useState(0);
  mode = !!mode;

  React.useEffect(() => {
    setNps(Math.round(Math.random() * 200) - 100);
    setPastNps(Math.round(Math.random() * 200) - 100);
  }, [re]);

  return (
    <>
      <DashboardDownloadAll />
      {(customerSatisfaction || facilityCleanliness || carbonFootprint) && (
        <Box sx={SBXStyles.mainBox} className="dashboard-download-all">
          <Typography
            variant="h5"
            sx={{
              display: "block",
              textAlign: "left",
              mb: 3,
              fontWeight: 700,
            }}
          >
            Objective KPI
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: "100%" }}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
          >
            {customerSatisfaction && (
              <NpsChart mode={mode} thickness={thickness} />
            )}

            {facilityCleanliness && (
              <FacilityCleanlinessChart mode={mode} thickness={thickness} />
            )}

            {carbonFootprint && <Co2Chart mode={mode} thickness={thickness} />}
          </Stack>
        </Box>
      )}

      {(robotFloorCoverage ||
        janitorCoverage ||
        onDemandCleaningResponse ||
        cleaningRequestResponse) && (
        <OperationKpi
          robotFloorCoverage={robotFloorCoverage}
          janitorCoverage={janitorCoverage}
          onDemandCleaningResponse={onDemandCleaningResponse}
          cleaningRequestResponse={cleaningRequestResponse}
        />
      )}

      {peopleCount && <PeopleCount />}
    </>
  );
};

DashboardPattern.propTypes = {
  mode: PropTypes.bool,
  re: PropTypes.number,
  thickness: PropTypes.number,
  kpiData: PropTypes.object,
};
